import * as Sentry from "@sentry/react";

export class Log {
    public static error(message: string, e: unknown) {
        console.error(message, e)
    }

    public static reportError(message: string, e: unknown) {
        if (e !== undefined) {
            Sentry.captureException(e, { extra: { message } });
        }
    }

    public static reportAndLogError(message: string, e: unknown) {
        Log.error(message, e);
        Log.reportError(message, e);
    }
}
