import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const RightContainer: React.FC = ({ children }) => {
    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-end gap-3">
                    {children}
                </Col>
            </Row>
        </Container>
    );
};
