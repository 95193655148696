import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { FeatureType, isFeatureEnabled } from '../../domain/Feature';
import { useFeatures } from '../../hooks/useFeatures';
import { LoadingOverlay } from '../ui/elements/LoadingOverlay';
import { RedirectToFirstAppType } from './RedirectToFirstAppType';

export interface PublicRouteProps extends RouteProps {
    features?: FeatureType[];
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ features = [], component, children, ...rest }) => {
    const auth = useContext(AuthenticationContext);
    const { userAccount } = auth.auth;
    const { data: featureFlags } = useFeatures();

    // this route must only be accessible to non-authenticated users
    if (userAccount) {
        // user is authenticated - redirect to the appropriate dashboard page
        return (
            <Route {...rest}>
                <RedirectToFirstAppType />
            </Route>
        );
    }

    if (features.length > 0) {
        // this route is only accessible if all of the specified features are enabled

        if (featureFlags === undefined) {
            // feature data is still loading
            return (
                <Route {...rest}>
                    <LoadingOverlay show />
                </Route>
            );
        }

        if (features.some(f => !isFeatureEnabled(featureFlags, f))) {
            // one or more features are disabled
            return (
                <Route {...rest}>
                    <Redirect to="/login" />
                </Route>
            );
        }
    }

    return <Route {...rest} component={component} children={children} />;
};
