import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {Alert, AlertVariant} from '../../../components/ui/elements/Alert';
import {AlertWithDetails} from '../../../components/ui/elements/AlertWithDetails';
import {LoadingOverlay} from '../../../components/ui/elements/LoadingOverlay';
import {SelectElement} from '../../../components/ui/elements/SelectElement';
import {TextInputElement} from '../../../components/ui/elements/TextInputElement';
import {AppLayout, AppLayoutHeading} from '../../../components/ui/layouts/AppLayout';
import {useAppType, useExternalResource} from '../../../Context/ServiceContext';
import {appType} from '../../../domain/AppType';
import {QueryUsersSortColumn, QueryUsersSortDirection} from '../../../services/queryAllUsers';
import {DefaultUserLimit, useFilteredUsers} from '../../../hooks/useFilteredUsers';
import {WithTestID} from '../../../components/ui/elements/WithTestID';
import {DivisionSelect} from '../../../components/ui/elements/DivisionSelect';
import {AppRoleSelect} from '../../../components/ui/elements/AppRoleSelect';
import {AdminRoleSelect} from '../../../components/ui/elements/AdminRoleSelect';
import {UserStateSelect} from '../../../components/ui/elements/UserStateSelect';
import {ButtonList} from '../../../components/ui/layouts/ButtonList';
import {Icon, IconType} from '../../../components/ui/elements/Icon';

import './ManageUsers.scss';
import {IsSelectable, Sort} from "../../../components/ui/elements/Table";
import {UserTable, UserTableColumns} from "../../../components/ui/elements/UserTable";
import {User} from "../../../domain/User";
import {SendEmailHandler, UsersSendEmailModalButton} from "../../../components/ui/elements/UsersSendEmailModalButton";
import {sendUsersEmail} from "../../../services/sendUsersEmail";
import { useFeatures } from '../../../hooks/useFeatures';

export const ManageUsers: React.VFC<WithTestID> = ({ testID }) => {

    const extern = useExternalResource()
    const theAppType = useAppType();
    const { data:features } = useFeatures();

    const {
        filters,
        users,
        error,
        loading,
        setLimit,
        setSearchText,
        setDivisionId,
        setPPQRole,
        setPAECRole,
        setAdminRole,
        setEnabled,
        changeSort,
    } = useFilteredUsers();
    const [selected, setSelected] = useState<(string|number)[]>()

    const limitOptions = [
        { value: 100, label: 'Show 100 Users' },
        { value: 500, label: 'Show 500 Users' },
        { value: 1000, label: 'Show 1000 Users' },
        { value: 100000, label: 'Show All Users' },
    ];
    const effectiveLimit = filters.limit ?? DefaultUserLimit;
    const numUsers = users.length;
    const limitReached = effectiveLimit > 0 && numUsers >= effectiveLimit;

    const allSelectedLabel = () => selected?.length ? `selected (${selected.length})` : "all"
    const allSelectedQuery = () => selected?.length ? `?ids=${selected.join(",")}` : ""
    const sendEmails: SendEmailHandler = ({subject, note}) => {
        sendUsersEmail(extern, {subject, body: note, users: selected?.join(",")}).then(() => {
            // TODO: provide user feedback
        }).catch(() => {
            // TODO: show user errors
        })
    }
    const onSortChange = (s: Sort<User>) => {
        const lastSort = Object.keys(s).pop() as keyof User
        const direction = s[lastSort] === "DESC" ? QueryUsersSortDirection.Descending : QueryUsersSortDirection.Ascending
        switch (lastSort) {
            case "fullName":
                return changeSort(QueryUsersSortColumn.Name, direction)
            case "login":
                return changeSort(QueryUsersSortColumn.Email, direction)
            case "ppqRole":
                return changeSort(QueryUsersSortColumn.PPQRole, direction)
            case "paecRole":
                return changeSort(QueryUsersSortColumn.PAECRole, direction)
            case "division":
                return changeSort(QueryUsersSortColumn.Division, direction)
            case "adminRole":
                return changeSort(QueryUsersSortColumn.AdminRole, direction)
            case "enabled":
                return changeSort(QueryUsersSortColumn.Enabled, direction)
        }
    }
    const sort: Sort<User> = {}
    switch (filters.sortColumn) {
        case QueryUsersSortColumn.Name:
            sort.fullName = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.Email:
            sort.login = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.PPQRole:
            sort.ppqRole = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.PAECRole:
            sort.paecRole = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.Division:
            sort.division = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.AdminRole:
            sort.adminRole = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
        case QueryUsersSortColumn.Enabled:
            sort.enabled = filters.sortDirection === QueryUsersSortDirection.Ascending ? "ASC" : "DESC"
            break
    }

    const filterActions = () => {
        return <>
            <tr>
                <th colSpan={2} className="border-0">
                    <SelectElement
                        value={filters.limit}
                        options={limitOptions}
                        onChange={(value) => {
                            const limit = value === 0 ? undefined : value;
                            setLimit(limit);
                        }}
                    />
                </th>
                <th colSpan={6} className="border-0">
                    <ButtonList align="right">
                        <Link to={`/app/${theAppType.appType}/admin/user/new`} className="btn btn-outline-primary d-flex gap-2">
                            <Icon icon={IconType.Add} />
                            <span>Add new user</span>
                        </Link>
                        <a className="btn btn-primary d-flex gap-2" href={`/admin/user/export/${allSelectedQuery()}`}>
                            <Icon icon={IconType.Export} />
                            <span>Export {allSelectedLabel()} to CSV</span>
                        </a>
                        <UsersSendEmailModalButton disabled={!selected?.length} allSelectedLabel={allSelectedLabel()} onSubmit={sendEmails} testID={"email-users"} />
                    </ButtonList>
                </th>
            </tr>
            <tr>
                <th colSpan={2}>
                    <TextInputElement
                        value={filters.searchText || ''}
                        onChange={setSearchText}
                        placeholder="Search users"
                        testID={testID ? `${testID}:filter:search` : undefined}
                    />
                </th>
                <th>
                    <DivisionSelect
                        value={filters.divisionId}
                        onChange={setDivisionId}
                        testID={testID ? `${testID}:filter:divisionId` : undefined}
                    />
                </th>
                <th>
                    <AppRoleSelect
                        value={filters.ppqRole}
                        onChange={setPPQRole}
                        appType={appType.PPQ}
                        testID={testID ? `${testID}:filter:ppqRole` : undefined}
                    />
                </th>
                <th>
                    <AppRoleSelect
                        value={filters.paecRole}
                        onChange={setPAECRole}
                        appType={appType.PAEC}
                        testID={testID ? `${testID}:filter:paecRole` : undefined}
                    />
                </th>
                <th>
                    <AdminRoleSelect
                        value={filters.adminRole}
                        onChange={setAdminRole}
                        testID={testID ? `${testID}:filter:adminRole` : undefined}
                    />
                </th>
                <th colSpan={2}>
                    <UserStateSelect
                        value={filters.enabled}
                        onChange={setEnabled}
                        testID={testID ? `${testID}:filter:state` : undefined}
                    />
                </th>
            </tr>
        </>
    }

    const selectedUsers: IsSelectable<User>[] = users.map((u) => ({...u, isSelected: selected?.includes(u.id) ?? false}))

    return (
        <AppLayout>
            <AppLayoutHeading testID={testID ? `${testID}:header` : undefined}>User Management</AppLayoutHeading>

            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <LoadingOverlay show={loading} />

            <UserTable
                testID={'user-table'}
                columns={[...UserTableColumns({theAppType, features}), "SelectColumn"]}
                data={selectedUsers}
                sort={sort}
                onSelect={setSelected}
                onSort={onSortChange}
                extraTheadRows={filterActions()}
            />

            {limitReached && (
                <Alert variant={AlertVariant.INFO}>
                    <strong>Showing maximum {effectiveLimit} users.</strong>{' '}
                    Use the options at the top to show more.
                </Alert>
            )}

        </AppLayout>
    );

};

