import { Unknown } from "../services/TypeUtils"
import { isBoolean } from "./validateUnknown";

export interface DocumentLock {
    ok: boolean;
}

export const isDocumentLock = (data: Unknown<DocumentLock>): data is DocumentLock => {
    return isBoolean(data.ok);
};
