import { Unknown } from "../services/TypeUtils";
import { has, isString } from "./validateUnknown";
import {User} from "./User";

export interface Action {
    code: string;
    label: string;
    role: string
    user: User
}

export const isAction = (data: Unknown<Action>): data is Action => {
    return has(data, 'code', isString)
        && has(data, 'label', isString)
};


export interface SubmitWorkflowAction {
    documentID: string;
    action: string;
    note: string;
}
export interface WorkflowActionResult {

}
export const isWorkflowActionResult = (data: Unknown<WorkflowActionResult>): data is WorkflowActionResult => {
    return true
}
