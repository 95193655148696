import {AppType} from "../domain/AppType";
import {DocumentID, ExternalResource} from "./ExternalResource";
import { DocumentAdminAlterAPIRequest } from "./resources";
import { DateTimeFormat, formatDateTimeString } from "./DateTime";

export interface DocumentAdminAlterRequest {
    dateCreated: Date;
    number: number;
}

const convertRequest = (request: DocumentAdminAlterRequest): DocumentAdminAlterAPIRequest => {
    const dt = formatDateTimeString(request.dateCreated, DateTimeFormat.ISODate);
    return {
        date: dt,
        number: request.number,
    };
};

export function adminAlterDocument(externalResource: ExternalResource, appType: AppType, id: DocumentID, request: DocumentAdminAlterRequest): Promise<null> {
    return externalResource.action(appType.Resources.adminAlterDocument, {
        id,
        body: convertRequest(request),
    });
}
