import { useFormikContext } from "formik";
import { useAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { useAppType } from "../../../Context/ServiceContext";
import { Account, isMinister, isSysAdmin } from "../../../domain/Account";
import { AppType } from "../../../domain/AppType";
import { getReviewStatusLabel, ReviewStatus } from "../../../domain/Document";
import { Feature, FeatureType, isFeatureEnabled } from "../../../domain/Feature";
import { useFeatures } from "../../../hooks/useFeatures";
import { DocumentFilterValues, DocumentStatusFilter } from "../../ui/elements/DocumentFilters";
import { DropDownItem } from "../../ui/elements/MultiDropDown";
import { CheckboxField } from "./CheckboxField";
import { MultiDropDownField } from "./MultiDropDownField";
import { StandardFieldProps } from "./TextField";

import './ReviewStatusDropDownField.scss';

export interface ReviewStatusDropDownFieldProps extends StandardFieldProps {
    showAllId: string;
}

const displayContent = (value: DocumentStatusFilter[]): React.ReactNode => {
    const { length } = value;
    if (length === 0) {
        return 'All Statuses';
    } else if (length === 1) {
        return '1 Status';
    } else {
        return `${length} Statuses`;
    }
};

const isShowAllCheckboxEnabled = (userAccount: Account, appType: AppType, features: Feature[]) => {
    return isMinister(userAccount, appType)
        && appType.MinistersCanShowAllDocuments
        && isFeatureEnabled(features, FeatureType.MINISTER_SHOW_ALL);
};

const getAvailableStatuses = (userAccount: Account, appType: AppType, features: Feature[], showAll: boolean): DropDownItem<DocumentStatusFilter>[] => {

    let reviewStatuses = Object.values(ReviewStatus);
    let showCulled = isSysAdmin(userAccount);

    if (isMinister(userAccount, appType)) {
        // special handling for Minister users

        if (appType.MinistersCanShowAllDocuments) {
            if (!showAll) {
                // when 'Show all' is not ticked, minister may only select WITH_MINISTER
                reviewStatuses = [ ReviewStatus.WithMinister ];
            }
        } else {
            // minister may only select WITH_MINISTER
            reviewStatuses = [ ReviewStatus.WithMinister ];
        }

    }

    if (!appType.ApproverWorkflowEnabled(features)) {
        reviewStatuses = reviewStatuses.filter(status => status !== ReviewStatus.ReadyForPrint);
    }

    const items: DropDownItem<DocumentStatusFilter>[] = reviewStatuses.map((status) => ({
        value: status,
        content: getReviewStatusLabel(status),
        type: 'option',
    }));
    if (showCulled) {
        items.push({ type: 'divider' });
        items.push({ value: 'CULLED', content: 'Culled', type: 'option' });
    }
    return items;
};

export const ReviewStatusDropDownField: React.VFC<ReviewStatusDropDownFieldProps> = ({ showAllId, testID, ...props }) => {
    const appType = useAppType();
    const userAccount = useAuthenticatedUser();
    const { data: features } = useFeatures();
    const { values } = useFormikContext();

    const showAllEnabled = features !== undefined && isShowAllCheckboxEnabled(userAccount, appType, features);
    const showAll = (values as DocumentFilterValues).showAllStatuses ?? false;

    const items = getAvailableStatuses(userAccount, appType, features || [], showAll);

    const cn = ['review-status'];
    if (showAllEnabled) {
        cn.push('review-status--show-all-enabled');
    }

    return (
        <div className={cn.join(' ')}>
            <MultiDropDownField
                {...props}
                testID={testID ? `${testID}:dropdown` : undefined}
                displayContent={displayContent}
                items={items}
            />
            {showAllEnabled && (
                <CheckboxField
                    id={showAllId}
                    label="Show All"
                    testID={testID ? `${testID}:checkbox` : undefined}
                />
            )}
        </div>
    );


};
