import React from 'react';
import {ToastContainer as BSToastContainer} from 'react-bootstrap';

export const ToastContainer: React.FC = ({ children }) => {

    return (
        <BSToastContainer position="top-end" className="position-fixed px-3" style={{padding: '73px 16px 0 0', zIndex: 100}}>
            {children}
        </BSToastContainer>
    );

};
