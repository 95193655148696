import axios, {AxiosRequestConfig} from "axios";

export const AxiosSettings: AxiosRequestConfig = {
    xsrfCookieName: 'CSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-TOKEN',
    withCredentials: (typeof process !== 'object')// Don't try sending credentials for node (i.e. Unit Tests)
}

export type HttpErrorHandler = (err: any) => any;
const errorHandlers: HttpErrorHandler[] = [];
export const registerHttpErrorHandler = (handler: HttpErrorHandler) => {
    if (!errorHandlers.includes(handler)) {
        errorHandlers.push(handler);
    }
};
export const unregisterHttpErrorHandler = (handler: HttpErrorHandler) => {
    const idx = errorHandlers.indexOf(handler);
    if (idx >= 0) {
        errorHandlers.splice(idx, 1);
    }
};

export const getAxios = <T>(config?: Partial<AxiosRequestConfig<T>>) => {
    const axiosInst = axios.create({
        ...AxiosSettings,
        ...config,
    });

    for (let i = 0; i < errorHandlers.length; i++) {
        axiosInst.interceptors.response.use(undefined, errorHandlers[i]);
    }

    return axiosInst;
};
