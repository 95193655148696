import {Unknown} from "../../TypeUtils";
import {AxiosResponse} from "axios";
import {getAxios} from "./AxiosSettings";
import {BustCache} from "../bustCache";

function anyToString(x1: unknown): string {
    if (x1 === null || x1 === undefined) {
        return ""
    }
    if (typeof x1 === "string") {
        return x1
    }
    if (typeof x1 === "number") {
        return `${x1}`
    }
    if (typeof x1 === "object") {
        // noinspection PointlessBooleanExpressionJS - Typescript linting to not picking up the first null check
        if (x1 !== null) {
            return x1.toString();
        }
    }
    return "";
}

export const formUrlEncoded = <T>(x: T) =>
    (Object.keys(x) as (keyof T)[]).reduce((result, c: keyof T) => result + `&${c}=${encodeURIComponent(anyToString(x[c]))}`, '')

export const submitForm = <SEND, RECEIVE, ERROR extends { message: string }>(URL: string, data: SEND): Promise<Unknown<RECEIVE>> => {
    return getAxios()
        .post<string, AxiosResponse<RECEIVE | ERROR>>(BustCache(URL), formUrlEncoded(data))
        .then(r => r.data as RECEIVE);
};

