import React from "react";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import {FormField} from "../../ui/elements/FormField";
import { RichText } from "../../ui/elements/RichText";
import { StandardFieldProps, WithPlaceholder } from "./TextField";
import { Field, FieldProps, useFormikContext } from "formik";
import { validateString } from "./FieldValidation";


export interface RichTextFieldProps extends StandardFieldProps, WithPlaceholder {
};

export const RichTextField: React.VFC<RichTextFieldProps> = ({id, label, disabled, help, required, testID, placeholder, validationRules = [] }) => {
    const formik = useFormikContext();

    return (
        <Field name={id} validate={(value: string) => {
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateString(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && !value) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<string|undefined, any>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        id={id}
                        error={touched ? error : undefined}
                        label={label}
                        help={help}
                        control={
                            <RichText
                                id={id}
                                value={field.value}
                                testID={testID}
                                onChange={val => form.setFieldValue(id, val)}
                                isInvalid={touched && !!error}
                                placeholder={placeholder}
                                disabled={disabled}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
