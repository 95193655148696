import React, { useState } from 'react';

import { Division } from '../../../domain/Division';
import { useDivisionList } from '../../../hooks/useDivisionList';
import { Button, ButtonVariant } from '../elements/Button';
import { SingleDivisionDropDown } from '../elements/DivisionDropDown';
import Modal from '../elements/Modal';
import { WithTestID } from '../elements/WithTestID';

export interface ReassignUsersModalProps extends WithTestID {
    division: Division;
    onCancel: () => void;
    onSubmit: (newDivision: Division) => void;
}

export const ReassignUsersModal: React.VFC<ReassignUsersModalProps> = ({ division, onCancel, onSubmit, testID }) => {
    const [id, setId] = useState<number>();
    const { data } = useDivisionList();

    const selectedDivision = (id === undefined || data === undefined) ? undefined : data.find(d => d.id === id);

    return (
        <Modal show testID={testID}>
            <Modal.Header>
                <Modal.Title>
                    Select new division for users
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select a division to move all users currently belonging to <strong>{ division.name }</strong>.</p>
                <SingleDivisionDropDown
                    value={id}
                    onChange={setId}
                    filter={(div => div.id !== division.id)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={selectedDivision === undefined ? undefined : () => onSubmit(selectedDivision)} variant={ButtonVariant.DANGER} disabled={selectedDivision === undefined}>Archive and move users</Button>
            </Modal.Footer>
        </Modal>
    );
};
