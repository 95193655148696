import { AppType } from "../domain/AppType";
import { DocumentID, ExternalResource } from "./ExternalResource";
import { BulkDocumentRequestWithComment } from "./resources";

export const cullDocuments = (externalResource: ExternalResource, appType: AppType, documentIds: DocumentID[], comment: string) => {
    const req: BulkDocumentRequestWithComment = {
        ids: documentIds,
        comment,
    };
    return externalResource.action(appType.Resources.cullDocuments, req);
};
