import { useEffect, useMemo } from "react";

import { Document } from "../../../domain/Document";
import { useDisableScroll } from "../../../hooks/useDisableScroll";
import { usePDFPreview } from "../../../hooks/usePDFPreview";
import { AlertVariant } from "../elements/Alert";
import { AlertWithDetails } from "../elements/AlertWithDetails";
import { Button } from "../elements/Button";
import { WithTestID } from "../elements/WithTestID";
import { CentreContainerWhiteFlexColumn } from "../layouts/CentreContainerWhiteFlexColumn";
import { FixedOverlay } from "../layouts/FixedOverlay";
import { FlexEndToolbar } from "../layouts/FlexEndToolbar";
import { FlexMainContainer } from "../layouts/FlexMainContainer";

export interface PDFPreviewModalProps extends WithTestID {
    document: Document;
    onClose: () => void;
}

const createDataURI = (data: Blob|undefined) => {
    if (!data) return undefined;
    return window.URL.createObjectURL(data);
};

export const PDFPreviewModal: React.VFC<PDFPreviewModalProps> = ({ document, onClose, testID }) => {
    const { data, error } = usePDFPreview(document);
    const { enableScroll, disableScroll } = useDisableScroll();
    const dataURI = useMemo(() => createDataURI(data), [data]);
    useEffect(() => {
        disableScroll();
        return enableScroll;
    }, [enableScroll, disableScroll]);

    return (
        <FixedOverlay>
            <CentreContainerWhiteFlexColumn>
                <FlexMainContainer>
                    {dataURI && (
                        <object
                            className="h-100 w-100"
                            data={dataURI}
                            type="application/pdf"
                        >
                            Unable to display PDF preview in your browser.
                        </object>
                    )}
                    {error && (
                        <AlertWithDetails variant={AlertVariant.ERROR} {...error} />
                    )}
                    {!dataURI && !error && (
                        <div>
                            Loading PDF Preview...
                        </div>
                    )}
                </FlexMainContainer>
                <FlexEndToolbar>
                    <Button onClick={onClose}>Close</Button>
                </FlexEndToolbar>
            </CentreContainerWhiteFlexColumn>
        </FixedOverlay>
    );
};
