import { AppType } from "../domain/AppType";
import { isPortfolio, Portfolio } from "../domain/Document";
import { validate } from "../domain/validateUnknown";
import { ExternalResource } from "./ExternalResource";
import { CreatePortfolioAPIRequest } from "./resources";

export const createPortfolio = (externalResource: ExternalResource, appType: AppType, portfolio: CreatePortfolioAPIRequest): Promise<Portfolio> => {
    return externalResource.action(appType.Resources.createPortfolio, portfolio)
        .then(validate(isPortfolio, 'Portfolio'));
};
