import React from "react";

import { Col, Row } from "react-bootstrap";

export const SideLayout: React.FC = ({ children }) => <Row>{children}</Row>;

export const SideLayoutSide: React.FC = ({ children }) => (
    <Col md={{ span: 4 }}>{children}</Col>
);

export const SideLayoutMain: React.FC = ({ children }) => (
    <Col md={{ span: 8 }}>{children}</Col>
);
