import React from 'react';

import { ErrorInLayout } from '../../../components/ui/elements/ErrorInLayout';
import { LoadingOverlay } from '../../../components/ui/elements/LoadingOverlay';
import { ViewDocumentDetails } from '../../../components/ui/elements/ViewDocumentDetails';
import { ViewDocumentDownloadButtons } from '../../../components/ui/elements/ViewDocumentDownloadButtons';
import { AppLayout, AppLayoutWithoutContainer } from '../../../components/ui/layouts/AppLayout';
import { ViewDocumentLayout } from '../../../components/ui/layouts/ViewDocumentLayout';
import { useAuthenticatedUser } from '../../../Context/AuthenticationContext';
import { isSysAdmin } from '../../../domain/Account';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useIdParam } from '../../../hooks/useIdParam';
import { NotFound } from '../../NotFound';

export const PPQView: React.VFC = () => {
    const id = useIdParam();

    if (id === undefined) {
        return <NotFound />;
    }

    return <ViewCmp id={id} />;
};

const ViewCmp: React.VFC<{id: number}> = ({ id }) => {

    const { document, error } = useFetchDocument(id);
    const account = useAuthenticatedUser();

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    if (!document) {
        return (
            <AppLayout>
                <LoadingOverlay show />
            </AppLayout>
        );
    }

    if (document.deleted && !isSysAdmin(account)) {
        // non-system-administrator users cannot access culled documents
        return <NotFound />;
    }

    return (
        <AppLayoutWithoutContainer>
            <ViewDocumentLayout buttons={<ViewDocumentDownloadButtons document={document} />}>
                <ViewDocumentDetails document={document} />
            </ViewDocumentLayout>
        </AppLayoutWithoutContainer>
    );
};
