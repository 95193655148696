import { isTranslations, Translations } from "../domain/Translations";
import { validate } from "../domain/validateUnknown";
import { ExternalResource } from "./ExternalResource";
import { resources } from "./resources";

export interface TranslationRequest {
    partName: string;
    ppqDepartment: string;
}

export const fetchTranslations = async (externalResource: ExternalResource, request: TranslationRequest): Promise<Translations> => {
    const response = await externalResource.action(resources.translations, request);
    return validate(isTranslations, 'Translations')(response);
}
