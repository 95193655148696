import {Sort, useSelectableState} from "../components/ui/elements/Table";
import {Document} from "../domain/Document";
import {AppType} from "../domain/AppType";
import {useExternalResource} from "../Context/ServiceContext";
import {useEffect, useState} from "react";
import {DocumentSearchSort, DocumentSearchSortKey, Search, searchDocuments} from "../services/searchDocuments";
import {LocalStorageHook, useLocalStorage} from "./useLocalStorage";
import {
    DocumentFilterValues,
    DocumentSearchKey,
    translateFiltersToSearch
} from "../components/ui/elements/DocumentFilters";


function initialSort(): Sort<Document> {
    return {}
}

export function toDocumentSearchSort(sort: Sort<Document>): DocumentSearchSort | undefined {
    const documentSearchSort: DocumentSearchSort = (Object.keys(sort) as (keyof Document)[])
        .filter((key) => sort[key] !== undefined)
        .map((key) => ({
            key: documentKeyToSortKey(key),
            dir: sort[key]
        }))
    if (documentSearchSort.length) {
        return documentSearchSort
    }
    return undefined
}

export type DocumentSortEventHandler = (sortKey: keyof Document) => void

export const DefaultDashboardLimit = 200;

const getInitialSearch = (storage: LocalStorageHook): Search|undefined => {
    const savedFilterValues = storage.get(DocumentSearchKey) as DocumentFilterValues|undefined;
    if (!savedFilterValues) return undefined;
    return translateFiltersToSearch(savedFilterValues);
};

export function useDashboardList(AppType: AppType) {
    const storage = useLocalStorage();
    const [data, setData, onSelect] = useSelectableState<Document>()
    const [sort, setSort] = useState<Sort<Document>>(initialSort())
    const [search, setSearch] = useState<Search|undefined>(getInitialSearch(storage));
    const externalResource = useExternalResource();
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(DefaultDashboardLimit);

    const doSearch = async () => {
        setLoading(true);
        let results = await searchDocuments(externalResource, AppType, {
            limit,
            sort: toDocumentSearchSort(sort),
            ...search,
        });
        if (limit > 0 && results.length > limit) {
            results = results.slice(0, limit);
        }
        setData(results);
        setLoading(false);
    };

    useEffect(() => {
        doSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalResource, sort, AppType, search, limit]) // adding setData to deps make useEffect look

    const clearSort = () => {
        setSort(initialSort());
    };

    return {
        data: data||[],
        onSelect,
        sort,
        onSort: setSort,
        clearSort,
        setSearch,
        loading,
        limit,
        setLimit,
        doSearch,
    };
}

export function documentKeyToSortKey(field: keyof Document):DocumentSearchSortKey {
    switch (field) {
        case "formattedNumber": return "Number"
        case "title": return "Title"
        case "creator": return "Author"
        case "assignee": return "Assignee"
        case "approver": return "Approver"
        case "dateLastSignificantChange": return "Approved"
        case "status": return "Status"
        case "reviewStatus": return "ReviewStatus"
        default: {
            throw new Error(`Sort by ${field} not implemented`)
        }
    }}
