import { useState, VFC } from "react";
import { useSWRConfig } from "swr";
import { Link } from "react-router-dom";

import { useRouter } from "../../../hooks/useRouter";
import {
    useAppType,
    useExternalResource,
} from "../../../Context/ServiceContext";

import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { AppLayout, AppLayoutHeading } from "../../../components/ui/layouts/AppLayout";
import { SectionSingleColumn } from "../../../components/ui/layouts/SectionSingleColumn";

import { TextField } from "../../../components/form/fields/TextField";
import { SelectFormField } from "../../../components/form/fields/SelectFormField";
import { CheckboxField } from "../../../components/form/fields/CheckboxField";
import { Form } from "../../../components/form/forms/Form";

import { useIdParam } from "../../../hooks/useIdParam";
import { NotFound } from "../../NotFound";
import { useFetchUserById } from "../../../hooks/useFetchUserById";
import { ErrorInLayout } from "../../../components/ui/elements/ErrorInLayout";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { User } from "../../../domain/User";
import { AdminRole, AppRole } from "../../../domain/Account";
import { DivisionDropDownField } from "../../../components/form/fields/DivisionDropDownField";
import { appType, GetAppType } from "../../../domain/AppType";
import { SelectOption } from "../../../components/ui/elements/SelectElement";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { saveUser } from "../../../services/saveUser";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { Alert, AlertVariant } from "../../../components/ui/elements/Alert";
import { ReadOnlyField } from "../../../components/form/fields/ReadOnlyField";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { SaveUserAPIRequest } from "../../../services/resources";
import { fieldMaxLength, fieldMinLength, fieldRequired } from "../../../components/form/fields/FieldValidation";
import { RequestErrorReason } from "../../../errors/RequestError";
import { notificationService } from "../../../services/notificationService";
import { useFeatures } from "../../../hooks/useFeatures";

interface UserFormValues {
    enabled: 'Enabled' | 'Disabled';
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    divisionId: number | undefined;
    ppqRole: AppRole | '';
    paecRole: AppRole | '';
    systemAdmin: boolean;
    email: string;
}

const convertUserToFormValues = (user: User): UserFormValues => ({
    enabled: user.enabled ? 'Enabled' : 'Disabled',
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone || '',
    mobile: user.mobile || '',
    divisionId: user.division?.id,
    ppqRole: user.ppqRole ? user.ppqRole as AppRole : '',
    paecRole: user.paecRole ? user.paecRole as AppRole : '',
    systemAdmin: user.adminRole === AdminRole.SysAdmin,
    email: user.login,
});

const convertFormValuesToRequest = (user: User, formValues: UserFormValues): SaveUserAPIRequest => {
    if (formValues.divisionId === undefined) {
        throw new Error('Division is required');
    }

    return {
        id: user.id,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phone: formValues.phone,
        mobile: formValues.mobile,
        division: { id: formValues.divisionId, name: '' },
        enabled: formValues.enabled === 'Enabled',
        ppqRole: formValues.ppqRole || null,
        paecRole: formValues.paecRole || null,
        adminRole: formValues.systemAdmin ? AdminRole.SysAdmin : null,
    };
};

interface ManageAdminFormProps {
    user: User;
    onSave: () => void;
};

const ManageProfileForm: React.FC<ManageAdminFormProps> = ({ user, onSave }) => {
    const externalResource = useExternalResource();
    const theAppType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();

    const onSubmit = async (data: UserFormValues) => {
        setSaving(true);
        try {
            await saveUser(externalResource, convertFormValuesToRequest(user, data));
            notificationService.showSuccessNotification('User account updated');
            onSave();
            setSaving(false);
            gotoOrReload(`/app/${theAppType.appType}/admin/user`);
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
            setSaving(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <AppLayoutHeading>Edit User</AppLayoutHeading>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            {user.activated ? (
                <div className="mb-4 text-muted">This user account is activated.</div>
            ) : (
                <Alert variant={AlertVariant.INFO}>
                    This user has not activated their account.
                    The account will be activated the first time they log in.
                    If the account is not activated within two days of its creation it will automatically be removed.
                </Alert>
            )}
            <Form
                initialValues={convertUserToFormValues(user)}
                onSubmit={onSubmit}
                FormBody={() => (
                    <div className="manage-admin-form">
                        <ReadOnlyField id="email" label="Email" />
                        <SelectFormField
                            id="enabled"
                            options={[
                                { value: "Enabled", label: "Enabled" },
                                { value: "Disabled", label: "Disabled" },
                            ]}
                            label="Enabled"
                            help="If the user account is disabled, the user will not be able to log in."
                        />
                        <TextField
                            id="firstName"
                            label="First Name"
                            validationRules={[
                                fieldRequired('First Name is required'),
                                fieldMinLength(1, 'First name must be at least 1 character'),
                                fieldMaxLength(50, 'First name cannot be longer than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            validationRules={[
                                fieldRequired('Last Name is required'),
                                fieldMinLength(1, 'Last name must be at least 1 character'),
                                fieldMaxLength(50, 'Last name cannot be longer than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="phone"
                            label="Contact Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Phone Number cannot be longer than 20 characters'),
                            ]}
                        />
                        <TextField
                            id="mobile"
                            label="Contact Mobile Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Mobile Phone Number cannot be longer than 20 characters'),
                            ]}
                        />
                        <DivisionDropDownField
                            id="divisionId"
                            label="Division"
                            multiple={false}
                            validationRules={[
                                fieldRequired('Division is required'),
                            ]}
                        />
                        <AppRoleFormField
                            id="ppqRole"
                            label="PPQ Role"
                            appType={appType.PPQ}
                        />
                        <AppRoleFormField
                            id="paecRole"
                            label="PAEC Role"
                            appType={appType.PAEC}
                            help="If no PPQ or PAEC role is selected, the user will not be able to log in."
                        />
                        <CheckboxField id="systemAdmin" label="System Admin" />
                    </div>
                )}
                FormActions={({ isValid }) => (
                    <ButtonList>
                        <Link to={`/app/${theAppType.appType}/admin/user`} className="btn btn-outline-primary">Cancel</Link>
                        <SaveButton disabled={!isValid}>Save</SaveButton>
                    </ButtonList>
                )}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

interface AppRoleFormFieldProps {
    id: string;
    label: string;
    appType: appType;
    help?: string;
}
export const AppRoleFormField: React.VFC<AppRoleFormFieldProps> = ({ id, label, appType, help }) => {
    const options: SelectOption<string>[] = [
        { value: '', label: 'None' },
    ];
    const {data: features} = useFeatures();
    GetAppType(appType)
        .GetAppRoles(features)
        .forEach((role) => {
            options.push({ value: role.key, label: role.name });
        });

    return (
        <SelectFormField
            id={id}
            options={options}
            label={label}
            help={help}
        />
    );
};

interface EditUserWithIdProps {
    id: number;
}
const EditUserWithId: React.VFC<EditUserWithIdProps> = ({ id }) => {
    const { mutate: globalMutate } = useSWRConfig();
    const { data, error, mutate } = useFetchUserById(id);

    if (error) {
        if (error.reason === RequestErrorReason.NotFound) {
            return <NotFound />;
        }
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    if (!data) {
        return <AppLayout><LoadingOverlay show /></AppLayout>;
    }

    if (!data.user) {
        return <NotFound />;
    }

    return (
        <AppLayout>
            <SectionSingleColumn>
                <ManageProfileForm user={{ ...data.user }} onSave={() => {
                    // tell SWR to clear the cache
                    mutate();
                    // also clear the 'all users' caches
                    globalMutate('allUsers:PPQ:true');
                    globalMutate('allUsers:PPQ:false');
                    globalMutate('allUsers:PAEC:true');
                    globalMutate('allUsers:PAEC:false');
                }} />
            </SectionSingleColumn>
        </AppLayout>
    );
};

export const EditUser: VFC = () => {
    const id = useIdParam();
    if (!id) {
        return <NotFound />;
    }

    return <EditUserWithId id={id} />;
};
