import { useEffect, useRef, useState, VFC } from "react";

import { useRouter } from "../../../hooks/useRouter";
import { Icon, IconType } from "../../../components/ui/elements/Icon";
import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { TextField } from "../../../components/form/fields/TextField";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { ArchiveButton } from "../../../components/ui/elements/ArchiveButton";
import { useDivisionList } from "../../../hooks/useDivisionList";
import { Form } from "../../../components/form/forms/Form";
import { updateDivision } from "../../../services/updateDivision";
import {
    useAppType,
    useExternalResource,
} from "../../../Context/ServiceContext";

import { SectionSelectPanel } from "../../../components/ui/layouts/SectionSelectPanel";
import { Division } from "../../../domain/Division";
import {
    AppLayout,
    AppLayoutHeading,
} from "../../../components/ui/layouts/AppLayout";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { useSidePanelAppId } from "../../../hooks/useSidePanelAppId";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { AlertVariant } from "../../../components/ui/elements/Alert";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { ErrorInLayout } from "../../../components/ui/elements/ErrorInLayout";
import { InstructionInfoContent } from "../../../components/ui/elements/InstructionInfoContent";
import { createDivision } from "../../../services/createDivision";
import { CreateDivisionAPIRequest, DeleteDivisionAPIRequest } from "../../../services/resources";
import { deleteDivision } from "../../../services/deleteDivision";
import { queryAllUsers } from "../../../services/queryAllUsers";
import { ReassignUsersModal } from "../../../components/ui/modals/ReassignUsersModal";
import { useRefreshAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { notificationService } from '../../../services/notificationService';
import { SELECT_PANEL_CREATE_NEW_KEY } from "../../../components/ui/elements/SelectPanel";
import { NotFound } from "../../NotFound";
import { WithTestID } from "../../../components/ui/elements/WithTestID";
import { fieldRequired } from "../../../components/form/fields/FieldValidation";

const useDivisionsUserCount = (id: number|undefined) => {
    const [count, setCount] = useState<number>();
    const [error, setError] = useState<ErrorWithDetails>();
    const externalResource = useExternalResource();
    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;

        if (id) {
            (async () => {
                try {
                    const users = await queryAllUsers(externalResource, { divisionId: id });
                    if (mountedRef.current) {
                        setCount(users.length);
                    }
                } catch (err) {
                    if (mountedRef.current) {
                        setError(convertErrorToErrorWithDetails(err as Error));
                    }
                }
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, [externalResource, id]);

    return { count, error };
};

const ManageDivisionsFallback: React.FC = () => (
    <InstructionInfoContent>
        <Icon icon={IconType.Division} className="m-auto fa-5x" />
        <p className="lead">
            Choose a division or create a new one on the left.
        </p>
    </InstructionInfoContent>
);

interface UpdateDivisionFormProps extends WithTestID {
    division: Division;
    onSaved?: () => void;
}
const UpdateDivisionForm: React.FC<UpdateDivisionFormProps> = ({ division, onSaved, testID }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();
    const [showReassignModal, setShowReassignModal] = useState(false);
    const { count, error: userQueryError } = useDivisionsUserCount(division?.id);

    const onSubmit = async (division: Division) => {
        setSaving(true);
        try {
            await updateDivision(externalResource, appType, division);
            gotoOrReload(`/app/${appType.appType}/admin/division`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Division saved');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    const doArchive = async (newDivision?: Division) => {
        setShowReassignModal(false);
        setSaving(true);

        const req: DeleteDivisionAPIRequest = {
            id: division.id,
            migrateTo: newDivision,
        };
        const result = await deleteDivision(externalResource, req);
        if (result.success) {
            gotoOrReload(`/app/${appType.appType}/admin/division`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Division archived');
        } else {
            if (result.reason === 'documents') {
                setError({
                    message: 'Documents are attached to this Division. Please move them to another Division then try again.',
                    details: '',
                });
            } else {
                setError(result.error);
            }
        }

        setSaving(false);
    };

    const onArchive = async () => {
        if (count === 0) {
            doArchive();
        } else {
            setShowReassignModal(true);
        }
    };

    if (!division) {
        return <LoadingOverlay show />;
    }

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            {userQueryError && <AlertWithDetails variant={AlertVariant.ERROR} {...userQueryError} />}
            <Form
                testID={testID}
                initialValues={{ ...division }}
                onSubmit={onSubmit}
                FormBody={() => (
                    <>
                        <h4>Update Division</h4>
                        <TextField
                            id="name"
                            label="Name"
                            validationRules={[
                                fieldRequired('Please enter a name'),
                            ]}
                        />
                    </>
                )}
                FormActions={({ isValid, dirty }) => {
                    return (
                        <ButtonList className="justify-content-between">
                            <ArchiveButton onClick={onArchive} testID={testID ? `${testID}:archive-btn` : undefined}>
                                Archive
                            </ArchiveButton>
                            <SaveButton disabled={!isValid || !dirty}>
                                Save Division
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
            {showReassignModal && (
                <ReassignUsersModal
                    division={division}
                    onCancel={() => setShowReassignModal(false)}
                    onSubmit={doArchive}
                    testID={testID ? `${testID}:reassign-modal` : undefined}
                />
            )}

        </>
    );
};

interface CreateDivisionFormProps {
    onSaved?: () => void;
}
const CreateDivisionForm: React.FC<CreateDivisionFormProps> = ({ onSaved }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();

    const onSubmit = async (values: Record<string, any>) => {
        setSaving(true);
        try {
            const division: CreateDivisionAPIRequest = {
                name: values.name,
            };
            await createDivision(externalResource, division);
            gotoOrReload(`/app/${appType.appType}/admin/division`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Division created');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <Form
                initialValues={{ name: '' }}
                onSubmit={onSubmit}
                FormBody={() => (
                    <>
                        <h4>Create Division</h4>
                        <TextField
                            id="name"
                            label="Name"
                            validationRules={[
                                fieldRequired('Please enter a name'),
                            ]}
                        />
                    </>
                )}
                FormActions={({ isValid, dirty }) => {
                    return (
                        <ButtonList className="justify-content-end">
                            <SaveButton disabled={!isValid}>
                                Create Division
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

export const ManageDivisions: VFC<WithTestID> = ({ testID }) => {
    const { data, error, mutate } = useDivisionList();
    const appType = useAppType();
    const { selectedItem, setSelectedItem } = useSidePanelAppId(`/app/${appType.appType}/admin/division`);
    const { refresh } = useRefreshAuthenticatedUser();

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    const onSaved = () => {
        mutate();
        // archiving a division may cause the user's division to change, so refresh the user details
        refresh();
    };

    let createFormComponent: VFC = () => null;
    let updateFormComponent: VFC = () => null;

    if (selectedItem === undefined) {
    } else if (selectedItem === SELECT_PANEL_CREATE_NEW_KEY) {
        createFormComponent = () => <CreateDivisionForm onSaved={onSaved} />;
    } else {
        if (data === undefined) {
            updateFormComponent = () => <LoadingOverlay show />;
        } else {
            const division = data?.find(d => d.id === selectedItem);
            if (!division) {
                return <NotFound />;
            }

            updateFormComponent = () => <UpdateDivisionForm division={division} onSaved={onSaved} testID={testID ? `${testID}:update-form` : undefined} />;
        }
    }

    return (
        <AppLayout>
            <AppLayoutHeading>Manage Divisions</AppLayoutHeading>
            <SectionSelectPanel
                testID={testID}
                panelHeading="New Division"
                data={data || []}
                fallBackComponent={ManageDivisionsFallback}
                createFormComponent={createFormComponent}
                updateFormComponent={updateFormComponent}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
            />
        </AppLayout>
    );
};
