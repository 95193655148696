import {CSSProperties, useState, VFC} from "react";
import {WithTestID} from "./WithTestID";
import {AlertVariant, Alert} from "./Alert";


export type ErrorProps = WithTestID & {
    variant: AlertVariant
    message: string
    details: string
}

const errorDetails: CSSProperties = {
    fontFamily: 'monospace',
    background: '#f8f8f8',
    color: '#333',
    border: '1px solid #ccc',
    padding: 5
}

export const AlertWithDetails: VFC<ErrorProps> = ({message, details, ...props}) => {
    const [showDetail, setShowDetail] = useState<boolean>()
    return <Alert {...props}>
            <span role={"button"} title={"Show error detail"} onClick={() => setShowDetail(true)}>{message}</span>
            {showDetail && <p style={errorDetails}>{details}</p>}
    </Alert>
}
