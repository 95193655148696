import { AdminRole, AppRole, AppRoleAny, AppRoleAnyType } from "../domain/Account";
import { isUser, User } from "../domain/User";
import { isList, validateList } from "../domain/validateUnknown";
import { ExternalResource } from "./ExternalResource";
import { QueryUsersAPIAdminRole, QueryUsersAPIAppRole, QueryUsersAPIRequest, resources } from "./resources";
import { assertNever } from "./TypeUtils";

export enum QueryUsersSortColumn {
    Name = 'name',
    Email = 'email',
    Division = 'division',
    PPQRole = 'ppqRole',
    PAECRole = 'paecRole',
    AdminRole = 'adminRole',
    Enabled = 'enabled',
}

export enum QueryUsersSortDirection {
    Ascending = 'ascending',
    Descending = 'descending',
}

export interface QueryUsersRequest {
    adminRole?: AdminRole;
    paecRole?: AppRole | AppRoleAnyType;
    ppqRole?: AppRole | AppRoleAnyType;
    searchText?: string;
    divisionId?: number;
    sortColumn?: QueryUsersSortColumn;
    sortDirection?: QueryUsersSortDirection;
    enabled?: boolean;
    limit?: number;
}

const convertSort = (query: QueryUsersRequest): string|undefined => {
    const { sortColumn, sortDirection } = query;
    if (sortColumn === undefined) return undefined;
    const suffix = (sortDirection === QueryUsersSortDirection.Descending) ? '-' : '+';
    return `${sortColumn}${suffix}`;
};

const convertAdminRole = (role: AdminRole): QueryUsersAPIAdminRole => {
    switch (role) {
        case AdminRole.SysAdmin: return QueryUsersAPIAdminRole.UserAdmin;
        case AdminRole.Super: throw new Error('Cannot query Super user role');
        default: return assertNever(role);
    }
};

const convertAppRole = (role: AppRole | AppRoleAnyType): QueryUsersAPIAppRole => {
    switch (role) {
        case AppRoleAny: return QueryUsersAPIAppRole.Any;
        case AppRole.Admin: return QueryUsersAPIAppRole.Admin;
        case AppRole.Approver: return QueryUsersAPIAppRole.Approver;
        case AppRole.Author: return QueryUsersAPIAppRole.Author;
        case AppRole.Coordinator: return QueryUsersAPIAppRole.Coordinator;
        case AppRole.DTPCoordinator: return QueryUsersAPIAppRole.DTPCoordinator;
        case AppRole.Minister: return QueryUsersAPIAppRole.Minister;
        default: return assertNever(role);
    }
};

export const queryAllUsers = (externalResource: ExternalResource, query: QueryUsersRequest): Promise<User[]> => {

    const request: QueryUsersAPIRequest = {};
    if (query.adminRole !== undefined) request.adminRole = convertAdminRole(query.adminRole);
    if (query.paecRole !== undefined) request.paecRole = convertAppRole(query.paecRole);
    if (query.ppqRole !== undefined) request.ppqRole = convertAppRole(query.ppqRole);
    if (query.searchText !== undefined) request.filter = query.searchText;
    if (query.divisionId !== undefined) request.division = query.divisionId;
    const sort = convertSort(query);
    if (sort !== undefined) request.sort = sort;
    if (query.enabled !== undefined) request.status = query.enabled;
    if (query.limit !== undefined) request.limit = query.limit;

    return externalResource.listAction(resources.queryAllUsers, request)
        .then(validateList(isList(isUser), 'User'));

}
