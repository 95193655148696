import React, { useEffect, useRef, useState } from "react";

import './LoadingOverlay.scss';

export interface LoadingOverlayProps {
    show: boolean;
    message?: string;
}

export const LoadingOverlay: React.VFC<LoadingOverlayProps> = ({ show, message = 'Loading...' }) => {
    const [display, setDisplay] = useState(show);
    const [transition, setTransition] = useState(false);

    const requestIdRef = useRef<number>();

    useEffect(() => {
        if (show) {
            // transitioning from hidden to visible
            setDisplay(true);
            requestIdRef.current = requestAnimationFrame(() => {
                setTransition(true);
                requestIdRef.current = undefined;
            });
        } else {
            // transitioning from visible to hidden
            if (requestIdRef.current !== undefined) {
                cancelAnimationFrame(requestIdRef.current);
                requestIdRef.current = undefined;
            }
            setTransition(false);
            setDisplay(false);
        }
        return () => {
            if (requestIdRef.current !== undefined) {
                cancelAnimationFrame(requestIdRef.current);
                requestIdRef.current = undefined;
            }
        };
    }, [show]);

    const cn = ['loading-overlay'];
    if (display) {
        cn.push('loading-overlay--display');
    }
    if (transition) {
        cn.push('loading-overlay--transition');
    }

    return (
        <div className={cn.join(' ')}>
            <div className="loading-overlay__content">
                {message}
            </div>
        </div>
    );
};
