import React from "react";
import { Icon, IconType } from "./Icon";

import type { WithTestID } from "./WithTestID";

import "./CheckboxSwitch.scss";

export interface CheckboxProps extends WithTestID {
    id?: string;
    checked: boolean;
    onChange: (arg: boolean) => void;
    disabled?: boolean;
    isInvalid?: boolean;
    onBlur?: () => void;
}
export const CheckboxSwitch: React.VFC<CheckboxProps> = ({
    id,
    checked,
    onChange,
    testID,
    disabled = false,
    isInvalid = false,
    onBlur,
}) => {
    const cn = ["toggle btn"];

    if (!checked) {
        cn.push("off");
    }

    if (disabled) {
        cn.push('disabled');
    }

    if (isInvalid) {
        cn.push('btn-danger');
    } else {
        cn.push('btn-primary');
    }

    return (
        <div className={cn.join(" ")} data-testid={testID} onBlur={onBlur}>
            <input
                onChange={(event) => onChange(event.target.checked)}
                className="form-control"
                type="checkbox"
                id={id}
                name={id}
                checked={checked}
                disabled={disabled}
            />
            <div className="toggle-group">
                <label htmlFor={id} className="btn toggle-on">
                    <Icon icon={IconType.Spreadsheet} />
                </label>
                <label htmlFor={id} className="btn toggle-off">
                    <Icon icon={IconType.Spreadsheet} />
                </label>
                <span className="toggle-handle btn"></span>
            </div>
        </div>
    );
};
