import {useAppType, useExternalResource} from "../Context/ServiceContext";
import {useEffect, useState} from "react";
import {UnsavedDocument} from "../domain/Document";
import {convertErrorToErrorWithDetails, ErrorWithDetails} from "./exceptionToError";
import {fetchNewDocument} from "../services/fetchDocument";
import {Log} from "../log/log";
import {RequestErrorReason} from "../errors/RequestError";

export function useFetchNewDocument() {
    const appType = useAppType();
    const externalResource = useExternalResource();

    const [document, setDocument] = useState<UnsavedDocument>();
    const [error, setError] = useState<ErrorWithDetails>();

    useEffect(() => {
        (async () => {
            try {
                const result = await fetchNewDocument(externalResource, appType);
                setDocument(result);
            } catch (e) {
                Log.reportAndLogError('Error fetching document', e);
                const ex = convertErrorToErrorWithDetails(e as Error);
                if (ex.reason === RequestErrorReason.NotFound) {
                    ex.message = 'Document not found';
                } else {
                    ex.message = 'Error loading document';
                }
                setError(ex);
            }
        })();
    }, [externalResource, appType])
    return {document, error}
}
