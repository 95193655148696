import {has, isNumber, isString} from "./validateUnknown";

export interface Division {
    id: number;
    name: string;
}

export function isDivision(division: unknown): division is Division {
    return has(division, 'id', isNumber)
        && has(division, 'name', isString)
}
