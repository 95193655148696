import React, {useState, VFC} from "react";
import {SelectElement, SelectOption} from "./SelectElement";
import {Template} from "../../../domain/Template";
import {useTemplates} from "../../../hooks/useTemplates";
import {WithTestID} from "./WithTestID";


interface Props {
    onChange: (template: Template) => void
}

export const TemplateSelector: VFC<Props & WithTestID> = ({onChange, testID}) => {
    const [templateId, setTemplateId] = useState(-1);

    const { data: templates } = useTemplates();

    const selectOptions: SelectOption<number>[] = [{ value: -1, label: 'Select a template...', disabled: true }];
    if (templates) {
        templates.forEach((template) => {
            selectOptions.push({
                value: template.id,
                label: template.name,
            });
        });
    }

    return <SelectElement
        value={templateId}
        options={selectOptions}
        onChange={(id) => {
            setTemplateId(id);
            const selectedTemplate = templates?.find(template => template.id === id);
            if (selectedTemplate) {
                onChange(selectedTemplate)
            }
        }}
        testID={testID ? `${testID}:template-select` : undefined}
    />
}
