import React, { useContext, useEffect } from 'react';

import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { useGetLocation } from '../../Context/ServiceContext';
import { DefaultAuthentication } from '../../domain/Authentication';
import { RequestErrorReason } from '../../errors/RequestError';
import { useRouter } from '../../hooks/useRouter';
import { HttpErrorHandler, registerHttpErrorHandler, unregisterHttpErrorHandler } from '../../services/HTTP/axios/AxiosSettings';
import { convertAxiosError } from '../../services/HTTP/axios/HttpRequestError';

export const UnauthorizedErrorHandler: React.FC = ({ children }) => {

    const auth = useContext(AuthenticationContext);
    const getLocation = useGetLocation();
    const { gotoOrReload } = useRouter();

    useEffect(() => {
        const handler: HttpErrorHandler = (err) => {
            const error = convertAxiosError(err);
            // return Promise.reject(err);
            if (error.reason === RequestErrorReason.Unauthorized
                || error.reason === RequestErrorReason.Forbidden) {
                // user is on an internal page, but an ajax call returned an Unauthorized/Forbidden response
                // this indicates that their session has timed out
                auth.setAuth(DefaultAuthentication());
                // redirect back to login screen
                gotoOrReload('/login');
                return;
            }

            return Promise.reject(error);
        };

        registerHttpErrorHandler(handler);
        return () => {
            unregisterHttpErrorHandler(handler);
        };
    }, [auth, getLocation, gotoOrReload]);

    return <>{children}</>;
};
