import {Credentials, submitLogin} from "../services/submitLogin";
import {useState} from "react";
import {ExternalResource} from "../services/ExternalResource";

export type LoginError = string | undefined
export type SubmitLogin = (credentials: Credentials) => void

export function useSubmitLogin(externalResource: ExternalResource, onSuccessfulLogin: ()=>void): [SubmitLogin, LoginError] {
    const [loginError, setLoginError] = useState<string>()
    const submitLoginCred: SubmitLogin = (credentials) => {
        submitLogin(externalResource, credentials).then(onSuccessfulLogin).catch(e => setLoginError(e.toString()))
    }
    return [submitLoginCred, loginError]
}
