import React, {FC, useState} from "react";

import {useAppType} from "../../../Context/ServiceContext";
import {TextField} from "../fields/TextField";
import {ButtonList} from "../../ui/layouts/ButtonList";
import {Button, SubmitButton} from "../../ui/elements/Button";
import {AllowedRoles, Document, getReviewStatusLabel, isSavedDocument, UnsavedDocument} from "../../../domain/Document";
import {UserAutoCompleteField} from "../fields/UserAutoCompleteField";
import {DivisionDropDownField} from "../fields/DivisionDropDownField";
import {PortfolioTopicDropDownField} from "../fields/PortfolioTopicDropDownField";
import {useFeatures} from "../../../hooks/useFeatures";
import {useAuthenticatedUser} from "../../../Context/AuthenticationContext";
import {Alert, AlertVariant} from "../../ui/elements/Alert";
import {Account, canEditBriefDivision} from "../../../domain/Account";
import {ReadOnlyField} from "../fields/ReadOnlyField";
import {useFetchAllUsers} from "../../../hooks/useFetchAllUsers";
import {useTranslation} from "../../../hooks/useTranslation";
import {RichTextField} from "../fields/RichTextField";
import {AlertWithDetails} from "../../ui/elements/AlertWithDetails";
import {WithTestID} from "../../ui/elements/WithTestID";
import {assertNever} from "../../../services/TypeUtils";
import {DocumentUpdateAction} from "../../../services/resources";
import {useFetchUser} from "../../../hooks/useFetchUser";
import {PDFPreviewModal} from "../../ui/modals/PDFPreviewModal";
import {useFormikContext} from "formik";
import {useDocumentReadOnly} from "../../../hooks/useDocumentReadOnly";
import {DocumentLock} from "../../../domain/DocumentLock";
import {customValidator, fieldRequired, ValidationRule} from "../fields/FieldValidation";
import {LockAlert} from "./alerts/LockAlert";
import {WithMinisterAlert} from "./alerts/WithMinisterAlert";
import { AppType } from "../../../domain/AppType";

export enum EditFieldType {
    Text = 'Text',
    RichText = 'RichText',
    Division = 'Division',
    Topic = 'Topic',
    User = 'User',
}
export interface EditField {
    id: string;
    label: string;
    type: EditFieldType;
    validationRules?: ValidationRule[];
    help?: string;
}

interface EditFormFieldProps extends WithTestID {
    document: Document | UnsavedDocument;
    field: EditField;
    testID: string | undefined;
    readOnly: boolean;
}
const EditFormField: FC<EditFormFieldProps> = ({ document, field, readOnly, testID }) => {
    const { data: users } = useFetchAllUsers();
    const {type, ...props} = field;

    if (readOnly) {
        if (field.type === EditFieldType.RichText) {
            return <RichTextField {...props} testID={testID} disabled />;
        }

        // override value if needed
        let value: string|undefined;
        switch (field.type) {
            case EditFieldType.User:
                const login = document.roles?.[field.id]?.login;
                const user = users?.find(u => u.login === login);
                value = user?.fullName || '';
                break;
            case EditFieldType.Division:
                value = document.division?.name || '';
                break;
            case EditFieldType.Topic:
                if (document.topic === undefined || !isSavedDocument(document)) {
                    value = '';
                } else {
                    value = `${document.portfolioNumber}.${document.topic.number} ${document.portfolioName} - ${document.topic.name}`;
                }
                break;
        }
        return <ReadOnlyField label={field.label} id={field.id} value={value} />;
    }

    switch (type) {
        case EditFieldType.Text:
            return <TextField {...props} testID={testID} />;
        case EditFieldType.RichText:
            return <RichTextField {...props} testID={testID} />;
        case EditFieldType.Division:
            return <DivisionDropDownField {...props} multiple={false} testID={testID} />;
        case EditFieldType.Topic:
            return <PortfolioTopicDropDownField {...props} multiple={false} testID={testID} />
        case EditFieldType.User:
            return <UserAutoCompleteField {...props} />;
        default:
            return assertNever(type);
    }
};

interface MainFormButtonsProps extends WithTestID {
    isReadOnly: boolean;
    isNewDocument: boolean;
    onPreview: () => void;
    pdfDownloadUrl?: string;
    docxDownloadUrl?: string;
}

const MainFormButtons: React.VFC<MainFormButtonsProps> = ({ isReadOnly, isNewDocument, onPreview, pdfDownloadUrl, docxDownloadUrl, testID }) => {
    const { isSubmitting, isValid } = useFormikContext();
    return (
        <ButtonList>
            {isReadOnly || <SubmitButton testID={testID ? `${testID}:save-document` : undefined} disabled={isSubmitting || !isValid}>{isNewDocument ? 'Create' : 'Save Changes'}</SubmitButton>}
            <Button testID={testID ? `${testID}:preview-pdf` : undefined} onClick={onPreview} disabled={!isValid}>Preview PDF</Button>
            {isNewDocument || <a href={pdfDownloadUrl} className={`btn btn-outline-primary ${(!isValid || !pdfDownloadUrl) ? 'disabled' : ''}`} target="_blank" rel="noreferrer" data-testid={testID ? `${testID}:download-pdf` : undefined}>Download PDF</a>}
            {isNewDocument || <a href={docxDownloadUrl} className={`btn btn-outline-primary ${(!isValid || !docxDownloadUrl) ? 'disabled' : ''}`} target="_blank" rel="noreferrer" data-testid={testID ? `${testID}:download-pdf` : undefined}>Download DOCX</a>}
        </ButtonList>
    );
};

export interface EditFormProps extends WithTestID {
    document: Document|UnsavedDocument;
    lock: DocumentLock|undefined;
    testID: string|undefined;
    handleUpdate: (action: DocumentUpdateAction) => void;
}

const DocumentRoleFields = (appType: AppType, roles: AllowedRoles[], customValidatorCallback?: (role: AllowedRoles, value: string | undefined) => string | undefined): EditField[] => {

    try {
        const userDropdownFilter = appType.GetUserDropdownFilter();
        return roles.map((role) => {
            const {key, label, required, noApprovalAccessWarning} = role;
            const validationRules: ValidationRule[] = [];
            if (required) {
                validationRules.push(fieldRequired(`${label} is required`));
            }
            if (customValidatorCallback) {
                validationRules.push(customValidator((value) => customValidatorCallback(role, value)));
            }
            return {
                id: key,
                type: EditFieldType.User,
                label: `${label} ${required === true ? ' *' : ''}`,
                validationRules,
                noApprovalAccessWarning: noApprovalAccessWarning,
                requiredAppRole: userDropdownFilter[key] ?? undefined
            }
        })
    } catch (e) {
        console.error(e)
        return []
    }
}

export const EditForm: React.VFC<EditFormProps> = ({ document, lock, testID }) => {
    const appType = useAppType()
    const { data: features, error: featuresError } = useFeatures();
    const currentUser = useAuthenticatedUser();

    const { data: lockUser } = useFetchUser(isSavedDocument(document) ? document.lockedBy ?? undefined : undefined);
    const { data: translations, error: translationError } = useTranslation('document');
    const [showPreview, setShowPreview] = useState(false);

    const { values, handleSubmit } = useFormikContext<Document>();
    const readOnly = useDocumentReadOnly(document, lock);

    const isNewDocument = !isSavedDocument(document);
    const isLockedByOtherUser = !!lock && !lock.ok;

    const anyError = featuresError || translationError;
    if (anyError) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...anyError} />;
    }

    if (!features || !translations) {
        return <Alert variant={AlertVariant.INFO}>Loading...</Alert>;
    }

    const briefDivisionEditable = appType.BriefDivisionEnabled(features) && canEditBriefDivision(currentUser, appType);

    const roleValidator = (role: AllowedRoles, value: string | undefined): string | undefined => {

        const currentAssignee = document.roles?.ASSIGNEE?.login;
        const savedValue = document.roles?.[role.key]?.login;
        const assigneeRemovalRules = appType.GetAssigneeRemovalRules();

        for (const obj of assigneeRemovalRules) {
            if (document.reviewStatus === obj.status && obj.roles.includes(role.key)) {
                if (savedValue && savedValue === currentAssignee && !value) {
                    return `${role.label} is required while the document is ${getReviewStatusLabel(document.reviewStatus)}`;
                }
            }
        }

        return undefined;
    };
    const formFields: EditField[] = [
        ...appType.GetEditFields(features, translations),
        ...DocumentRoleFields(appType, document.allowedRoles, roleValidator)];


    const pdfDownloadUrl = isSavedDocument(document) ? `/pdf/${appType.appType}/${document.id}/${appType.appType.toLocaleLowerCase()}_document.pdf` : '';
    const docxDownloadUrl = isSavedDocument(document) ? `/docx/${appType.appType}/${document.id}/${appType.appType.toLocaleLowerCase()}_document.docx` : '';

    return (
        <>
            <LockAlert isLockedByOtherUser={isLockedByOtherUser} lockUser={lockUser} />
            {isSavedDocument(document) && <WithMinisterAlert document={document} />}
            <form data-testid={testID} onSubmit={handleSubmit}>
                {formFields.map(field => (
                    <EditFormField
                        key={field.id}
                        document={document}
                        field={field}
                        readOnly={readOnly || (field.id === 'division.id' && !briefDivisionEditable)}
                        testID={testID ? `${testID}:${field.id}` : undefined}
                    />
                ))}

                <MainFormButtons
                    isNewDocument={isNewDocument}
                    isReadOnly={readOnly}
                    onPreview={() => setShowPreview(true)}
                    testID={testID}
                    pdfDownloadUrl={pdfDownloadUrl}
                    docxDownloadUrl={docxDownloadUrl}
                />
            </form>
            {showPreview && <PDFPreviewModal document={getDocumentForSave(values, currentUser)} onClose={() => setShowPreview(false)}/>}
        </>
    );
};

const getDocumentForSave = (values: any, currentUser: Account): Document => {
    const doc = { ...values } as Document;
    if (!doc.creator) doc.creator = currentUser.login;
    if (!doc.assignee) doc.assignee = currentUser.login;
    return doc;
};
