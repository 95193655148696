import React, { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { Button, ButtonProps, ButtonSize } from './Button';

export interface PanelSectionProps {
    header: React.ReactNode;
    className?: string;
}

export const PanelSection: React.FC<PanelSectionProps> = ({ header, className, children }) => {
    return (
        <Card>
            {header && <Card.Header>{header}</Card.Header>}
            <Card.Body style={{fontSize: '14px'}} className={className}>
                {children}
            </Card.Body>
        </Card>
    );
};

export interface PanelHeaderWithButtonProps {
    button: React.ReactNode;
}
export const PanelHeaderWithButton: React.FC<PanelHeaderWithButtonProps> = ({ button, children }) => {
    return (
        <div className="d-flex align-items-center">
            <span className="flex-grow-1">{children}</span>
            {button}
        </div>
    );
};

export interface PanelSectionWithButtonProps {
    header: React.ReactNode;
    buttonProps: ButtonProps;
    buttonContent: React.ReactNode;
}

export const PanelSectionWithButton: React.FC<PanelSectionWithButtonProps> = ({ header, buttonProps, buttonContent, children }) => {
    return (
        <PanelSection
            header={
                <PanelHeaderWithButton
                    button={
                        <Button {...buttonProps} size={ButtonSize.Small}>{buttonContent}</Button>
                    }
                >
                    {header}
                </PanelHeaderWithButton>
            }
        >
            {children}
        </PanelSection>
    );
};

export interface ExpandablePanelSectionProps {
    header: React.ReactNode;
    className?: string;
    id?: string;
    initialState?: boolean;
}

export const ExpandablePanelSection: React.FC<ExpandablePanelSectionProps> = ({ header, className, id, initialState = true, children }) => {
    const [open, setOpen] = useState(initialState);

    return (
        <Card>
            <Card.Header
                role="button"
                onClick={() => setOpen(!open)}
                aria-controls={id}
                aria-expanded={open}
            >{header}</Card.Header>
            <Collapse in={open}>
                <Card.Body style={{fontSize: '14px'}} className={className} id={id}>
                    {children}
                </Card.Body>
            </Collapse>
        </Card>
    );
};
