import { isString } from "../../../domain/validateUnknown";
import { assertNever } from "../../../services/TypeUtils";
import { isEmailAddress } from "../../../textUtils";
import { ValueType } from "../../ui/elements/MultiDropDown";

export interface FieldRequired {
    type: 'required';
    message: string;
}

export interface FieldMinLength {
    type: 'minLength';
    message: string;
    length: number;
}

export interface FieldMaxLength {
    type: 'maxLength';
    message: string;
    length: number;
}

export interface FieldEqualsOtherField {
    type: 'equalsOtherField';
    message: string;
    otherFieldId: string;
}

export interface FieldMatchesRegExp {
    type: 'matchesRegExp';
    message: string;
    regexp: RegExp;
}

export interface FieldIsEmail {
    type: 'email';
    message: string;
}

export type CustomValidatorCallback = (value: any) => string|undefined;
export interface CustomValidator {
    type: 'custom';
    validate: CustomValidatorCallback;
}

export type ValidationRule =
    FieldRequired
    | FieldMinLength
    | FieldMaxLength
    | FieldEqualsOtherField
    | FieldMatchesRegExp
    | FieldIsEmail
    | CustomValidator;

export const fieldRequired = (message: string): FieldRequired => ({ type: 'required', message });
export const fieldMinLength = (length: number, message: string): FieldMinLength => ({ type: 'minLength', length, message });
export const fieldMaxLength = (length: number, message: string): FieldMaxLength => ({ type: 'maxLength', length, message });
export const fieldEqualsOtherField = (otherFieldId: string, message: string): FieldEqualsOtherField => ({ type: 'equalsOtherField', otherFieldId, message });
export const fieldMatchesRegExp = (regexp: RegExp, message: string): FieldMatchesRegExp => ({ type: 'matchesRegExp', regexp, message });
export const fieldIsEmail = (message: string): FieldIsEmail => ({ type: 'email', message });
export const fieldIsNumeric = (message: string): FieldMatchesRegExp => ({ type: 'matchesRegExp', regexp: /^\d+$/, message });
export const customValidator = (callback: CustomValidatorCallback): CustomValidator => ({ type: 'custom', validate: callback });

export const validateString = (rule: ValidationRule, value: string|undefined, formValues: any): string|undefined => {
    const trimmed = (value || '').trim();
    switch (rule.type) {
        case 'required':
            if (trimmed === '') {
                return rule.message;
            }
            return undefined;

        case 'minLength':
            if (trimmed.length < rule.length) {
                return rule.message;
            }
            return undefined;

        case 'maxLength':
            if (trimmed.length > rule.length) {
                return rule.message;
            }
            return undefined;

        case 'equalsOtherField':
            if (trimmed !== formValues[rule.otherFieldId].trim()) {
                return rule.message;
            }
            return undefined;

        case 'matchesRegExp':
            if (trimmed !== '' && !trimmed.match(rule.regexp)) {
                return rule.message;
            }
            return undefined;

        case 'email':
            if (trimmed !== '' && !isEmailAddress(trimmed)) {
                return rule.message;
            }
            return undefined;

        case 'custom':
            return rule.validate(trimmed);

        default: return assertNever(rule);
    }
};

export const validateNumber = (rule: ValidationRule, value: number|undefined, formValues: any): string|undefined => {
    switch (rule.type) {
        case 'required':
            if (value === undefined) {
                return rule.message;
            }
            return undefined;

        case 'equalsOtherField':
            if (value !== formValues[rule.otherFieldId]) {
                return rule.message;
            }
            return undefined;

        case 'custom':
            return rule.validate(value);

        case 'minLength':
        case 'maxLength':
        case 'matchesRegExp':
        case 'email':
            // not applicable for this value type
            return `Invalid validation rule type ${rule.type}`;

        default: return assertNever(rule);
    }
};

export const validateStringOrNumber = <T extends ValueType>(rule: ValidationRule, value: T|undefined, formValues: any): string|undefined => {
    if (isString(value)) {
        return validateString(rule, value, formValues);
    } else {
        return validateNumber(rule, value, formValues);
    }
};

const areArraysEqual = <T extends ValueType>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
};

export const validateStringOrNumberArray = <T extends ValueType>(rule: ValidationRule, value: T[], formValues: any): string|undefined => {
    switch (rule.type) {
        case 'required':
            if (value.length === 0) {
                return rule.message;
            }
            return undefined;

        case 'equalsOtherField':
            if (!areArraysEqual(value, formValues[rule.otherFieldId])) {
                return rule.message;
            }
            return undefined;

        case 'custom':
            return rule.validate(value);

        case 'minLength':
        case 'maxLength':
        case 'matchesRegExp':
        case 'email':
            // not applicable for this value type
            return `Invalid validation rule type ${rule.type}`;

        default: return assertNever(rule);
    }
};

export const validateBoolean = (rule: ValidationRule, value: boolean, formValues: any): string|undefined => {
    switch (rule.type) {
        case 'required':
            if (!value) {
                return rule.message;
            }
            return undefined;

        case 'equalsOtherField':
            if (value !== formValues[rule.otherFieldId]) {
                return rule.message;
            }
            return undefined;

        case 'custom':
            return rule.validate(value);

        case 'minLength':
        case 'maxLength':
        case 'matchesRegExp':
        case 'email':
            // not applicable for this value type
            return `Invalid validation rule type ${rule.type}`;

        default: return assertNever(rule);
    }
};
