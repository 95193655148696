import React from 'react';
import { Col, Row } from 'react-bootstrap';

export interface ThreeColumnRowProps {
    leftCol?: JSX.Element;
    middleCol?: JSX.Element;
    rightCol?: JSX.Element;
}

export const ThreeColumnRow: React.VFC<ThreeColumnRowProps> = ({ leftCol, middleCol, rightCol }) => {
    return (
        <Row>
            <Col className="col-6">
                {leftCol}
            </Col>
            <Col className="col-3">
                {middleCol}
            </Col>
            <Col className="col-3">
                {rightCol}
            </Col>
        </Row>
    );
};
