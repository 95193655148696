import {Icon, IconType} from "./Icon";
import React, {useState, VFC} from "react";
import Modal from "./Modal";
import {FormField} from "./FormField";
import {TextareaElement} from "./TextareaElement";
import {Button} from "./Button";
import {WithTestID} from "./WithTestID";
import {TemplateSelector} from "./TemplateSelector";
import {Form} from "react-bootstrap";
import {TextInputElement} from "./TextInputElement";


export type SendEmailHandler = (data: { subject: string, note: string }) => void

interface Props {
    disabled: boolean
    allSelectedLabel: string
    onSubmit: SendEmailHandler
}

export const UsersSendEmailModalButton: VFC<Props & WithTestID> = ({allSelectedLabel, testID, onSubmit, disabled}) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [note, setNote] = useState<string>("")
    const [subject, setSubject] = useState<string>("")

    const onCancel = () => {
        setShowModal(false)
    }
    const onFormSubmit = () => {
        setShowModal(false)
        onSubmit({subject, note})
    }
    const valid = note.length && subject.length

    const buttonProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> =
        disabled ? {"title": "Select 1 or more users to send email"}
        : { onClick: () => setShowModal(true) }

    return <>
        <span role={"button"}
              className={["btn", "btn-primary", "d-flex", "gap-2", disabled ? 'disabled' : ''].join(' ')}
              style={{cursor: "default", pointerEvents: "all"}} // the "disable" css class also disables the pointerEvents preventing the browser displaying the tooltip
              data-testid={testID ? `${testID}:button` : undefined}
              {...buttonProps}
        >
            <Icon icon={IconType.Email}/>
            <span>Email {allSelectedLabel}</span>
        </span>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" testID={testID}>
            <Modal.Body>
                <FormField
                    id="subject"
                    label="Subject"
                    control={<TextInputElement testID={testID ? `${testID}:subject` : undefined} value={subject}
                                               onChange={setSubject}/>}
                />
                <FormField
                    id="note"
                    label="Message to users"
                    control={
                        <>
                            <div>
                                <Form.Text className="text-muted">
                                    Select a template from the list below, or enter your own note text.
                                </Form.Text>
                            </div>
                            <div className="mb-1">
                                <TemplateSelector
                                    onChange={(template) => setNote(template.text)}
                                    testID={testID ? `${testID}:template-select` : undefined}
                                />
                            </div>
                            <TextareaElement
                                value={note}
                                onChange={setNote}
                                required={true}
                                testID={testID ? `${testID}:note` : undefined}
                            />
                        </>
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    testID={testID ? `${testID}:submit` : undefined}
                    onClick={valid ? () => onFormSubmit() : undefined}
                    disabled={!valid}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    </>
}
