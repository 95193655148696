import * as Sentry from "@sentry/react";

import { Log } from './log/log';

export const makeWordPlural = (word: string, count: number, pluralWord?: string): string => {
    if (count === 1) {
        return word;
    }
    if (pluralWord !== undefined) {
        return pluralWord;
    }
    return `${word}s`;
};

export const makeWordPluralWithCount = (word: string, count: number, pluralWord?: string): string => {
    return `${count} ${makeWordPlural(word, count, pluralWord)}`;
};

export const isEmailAddress = (str: string): boolean => {
    // Note that this is not an exhaustive check
    return !!str.match(/^[^@.]([^@]*[^@.])?@([^@.]+\.)+[^@.]+$/);
};

export const removeCommentsFromNode = (n: Node) => {
    if (n.nodeType === Node.COMMENT_NODE) {
        n.parentNode?.removeChild(n);
    } else {
        Array.from(n.childNodes).forEach(removeCommentsFromNode);
    }
};

export const removeCommentsFromHtml = (html: string) => {
    try {
        const div = document.createElement('div');
        div.innerHTML = html;
        removeCommentsFromNode(div);
        return div.innerHTML;
    } catch (err) {
        Sentry.setContext('content', { html });
        Log.reportAndLogError('Error removing comments from html', err);

        // in case of any error just return the input
        return html;
    }
};

export const cleanTableStylesFromHtml = (html: string) => {
    try {
        const div = document.createElement('div');
        div.innerHTML = html;
        if (cleanTableStylesFromNode(div)) {
            return div.innerHTML;
        } else {
            return html;
        }
    } catch (err) {
        Sentry.setContext('content', { html });
        Log.reportAndLogError('Error cleaning table attributes from html', err);

        // in case of any error just return the input
        return html;
    }
};

export const cleanTableStylesFromNode = (n: Node): boolean => {
    let modified = false;
    if (n.nodeType === Node.ELEMENT_NODE && ['TABLE', 'TR', 'TD', 'TH'].includes(n.nodeName)) {
        const el = n as Element;
        if (el.hasAttribute('width')) {
            el.removeAttribute('width');
            modified = true;
        }
        if (el.hasAttribute('border')) {
            el.removeAttribute('border');
            modified = true;
        }
        const style = el.getAttribute('style');
        if (style) {
            const newStyle = style.split(';')
                .map((rule) => {
                    const match = rule.match(/\s*(width|border|border-top|border-left|border-bottom|border-right)\s*:/i);
                    if (match) {
                        modified = true;
                        return '';
                    } else {
                        return rule;
                    }
                })
                .join(';');
            el.setAttribute('style', newStyle);
        }
    }
    for (const childNode of Array.from(n.childNodes)) {
        if (cleanTableStylesFromNode(childNode)) {
            modified = true;
        }
    }
    return modified;
};

export const escapeHtml = (html: string) => {
    return html
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
};

type FormattedText = {
    text: string;
    style: string;
    occurrence: number;
};

export const extractBoldAndUnderlineTexts = (html: string): FormattedText[] => {
    let parser = new DOMParser();
    let htmlDoc = parser.parseFromString(html, 'text/html');

    let formattedTexts: FormattedText[] = [];
    let allTexts: string[] = [];
    let stack: Node[] = Array.from(htmlDoc.body.childNodes);
    
    while (stack.length > 0) {
        let node = stack.shift() as Node;
        
        if (node.nodeType === Node.TEXT_NODE) {
            let nodeValue = node.nodeValue?.trim();
            if (nodeValue) {
                allTexts.push(nodeValue);
            }
        } else {
            let el = node as HTMLElement;
            let style: string | null = null;

            switch (el.nodeName) {
                case 'U':
                    style = 'underline';
                    break;
                case 'STRONG':
                case 'B':
                    style = 'bold';
                    break;
            }
            
            // Check styles applied through CSS
            let fontWeight = el.style.fontWeight;
            let textDecoration = el.style.textDecoration;
            let textDecorationLine = el.style.textDecorationLine;
            let fontWeightNumberRegex = fontWeight.match(/\d+/);
            let fontWeightNumber = (fontWeightNumberRegex) ? Number(fontWeightNumberRegex[0]) : null;


            if (fontWeight === 'bold') {
                style ='bold';
            }
            else if ((fontWeightNumber && fontWeightNumber > 400)) {
                style ='bold';
            }

            if (style && el.textContent) {
                let occurrence = allTexts.filter(text => text.includes((el.textContent as string))).length;
                formattedTexts.push({text: el.textContent, style: style, occurrence: occurrence});
            }

            style = null;
            if (textDecoration === "underline" ||
                textDecorationLine === "underline") {
                style = 'underline';
            }
            
            if (style && el.textContent) {
                let occurrence = allTexts.filter(text => text.includes((el.textContent as string))).length;
                formattedTexts.push({text: el.textContent, style: style, occurrence: occurrence});
            }
            stack.splice(0, 0, ...Array.from(node.childNodes));
        }
    }

    return formattedTexts;
}


const replaceNthOccurrence = (str: string, substr: string, newSubstr: string, occurrence: number)  => {
    let i = 0;
    return str.replace(new RegExp(substr, 'g'), (match) => {
        i++;
        return (i === occurrence) ? newSubstr : match;
    });
}

export const addBoldAndUnderlineBackToPlainText = (plainText: string, formattedTexts: Array<{text: string, style: string, occurrence: number}>): string => {
    formattedTexts.forEach(item => {
        const textNode = document.createTextNode(item.text); // Create a text node
        let text = textNode.nodeValue || ""; // Get value
        text = text.replace(/\u00a0/g, ' ');

        switch (item.style) {
            case "bold":
                plainText = replaceNthOccurrence(plainText, text, `<b>${text}</b>`, item.occurrence + 1);
                break;
            case "underline":
                plainText = replaceNthOccurrence(plainText, text, `<u>${text}</u>`, item.occurrence + 1);
                break;
        }
    });
    return plainText;
}

export const isEmptyHTMLTag = (htmlString: string) => {
    // Strip leading and trailing whitespace
    const trimmedString = htmlString.trim();
    
    // Regular expression to match an empty HTML tag
    const regex = /^<(\w+)([^>]*)><\/\1>$/;
    
    // Test if the string matches the regex
    const match = regex.exec(trimmedString);
    
    if (match) {
        // Check if there's any content between the opening and closing tag
        const innerContent = trimmedString.slice(match[0].length, -match[0].length);
        
        return innerContent.trim() === '';
    }
    
    return false;
}