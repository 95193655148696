import React, { useEffect } from 'react';

import { IdleState, useIdleTimeout } from '../../../hooks/useIdleTimeout';
import { Button } from '../elements/Button';
import { Icon, IconType } from '../elements/Icon';
import Modal from '../elements/Modal';
import { WithTestID } from '../elements/WithTestID';

export interface EditInactivityModalProps extends WithTestID {
    onTimeout: () => void;
    enabled: boolean;
}

export const EditInactivityModal: React.VFC<EditInactivityModalProps> = ({ onTimeout, enabled, testID }) => {
    const idleState = useIdleTimeout(enabled);
    useEffect(() => {
        if (idleState === IdleState.Timeout) {
            onTimeout();
        }
    }, [idleState, onTimeout]);

    if (!enabled) {
        return null;
    }

    return (
        <Modal show={idleState === IdleState.Warning} testID={testID}>
            <Modal.Body>
                <p>
                    <Icon icon={IconType.Warning} />
                    Warning: your lock on this document is about to expire due to inactivity.
                    You will be automatically redirected to the dashboard when that happens.
                </p>
                <p>Click anywhere on this page to keep working.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};
