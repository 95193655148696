import React from 'react';
import { Field, FieldProps, useFormikContext } from "formik";
import {FormField} from "../../ui/elements/FormField";
import { WithTestID } from "../../ui/elements/WithTestID";
import { AppType } from '../../../domain/AppType';
import { TextInputElement } from '../../ui/elements/TextInputElement';
import { validateString, ValidationRule } from './FieldValidation';

export interface StandardFieldProps extends WithTestID {
    id: string;
    label?: React.ReactNode;
    disabled?: boolean;
    help?: string;
    required?: boolean;
    hasMarginBottom?: boolean;
    validationRules?: ValidationRule[];
}

export interface WithPlaceholder {
    placeholder?: string;
}

export interface WithAppType {
    appType: AppType;
}

export interface TextFieldProps extends StandardFieldProps, WithPlaceholder {
    type?: string;
}

export const TextField: React.VFC<TextFieldProps> = ({ id, label, disabled, help, type, placeholder, required, hasMarginBottom, validationRules = [], testID }) => {
    const formik = useFormikContext();
    const fieldRequired = required || validationRules.some(rule => rule.type === 'required');

    return (
        <Field name={id} validate={(value: any) => {
            const strValue = value === undefined ? undefined : `${value}`;
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateString(validationRules[i], strValue, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && !value) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<string|undefined, any>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        help={help}
                        label={label}
                        id={id}
                        error={touched ? error : undefined}
                        hasMarginBottom={hasMarginBottom}
                        control={
                            <TextInputElement
                                type={type}
                                testID={testID}
                                value={field.value || ''}
                                placeholder={placeholder}
                                isInvalid={touched && !!error}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                disabled={disabled}
                                required={fieldRequired}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
