import React, { useContext } from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router';

import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { appType } from '../../domain/AppType';
import { UnauthorizedErrorHandler } from './UnauthorizedErrorHandler';
import { RedirectToFirstAppType } from './RedirectToFirstAppType';
import { Account } from '../../domain/Account';
import { useGetLocation } from '../../Context/ServiceContext';

const userHasAccessToAppType = (userAccount: Account, appType: appType) => {
    return !!userAccount.appRoles[appType];
};

const getFirstAppType = (userAccount: Account): appType|undefined => {
    return Object.values(appType).find((at) => !!userAccount.appRoles[at]);
};

interface PrivateRouteProps extends RouteProps {
    appType?: appType;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ appType, component, children, ...rest }) => {
    const auth = useContext(AuthenticationContext);
    const getLocation = useGetLocation();

    const { userAccount } = auth.auth;
    const location = getLocation();

    // this route must only be accessible to authenticated users
    if (!userAccount) {
        // user is not authenticated - redirect to login page
        return (
            <Route {...rest}>
                <Redirect to="/login" />
            </Route>
        );
    }

    if (appType !== undefined) {
        // this route must only be accessible to authenticated users who have access to the specified appType
        const access = !!userAccount && userHasAccessToAppType(userAccount, appType);
        if (!access) {
            // user does not have access to the specified appType - redirect to the appropriate dashboard
            return (
                <Route {...rest}>
                    <RedirectToFirstAppType />
                </Route>
            );
        }
    }

    // if user account is not activated yet, redirect to the profile page
    if (!userAccount.activated) {
        const firstAppType = getFirstAppType(userAccount);
        if (firstAppType) {
            const profilePath = `/app/${firstAppType}/settings`;
            if (profilePath !== location) {
                return (
                    <Route exact {...rest}>
                        <Redirect to={`/app/${firstAppType}/settings`} />
                    </Route>
                );
            }
        }
    }

    return (
        <UnauthorizedErrorHandler>
            <Route {...rest} component={component} children={children} />
        </UnauthorizedErrorHandler>
    );
};
