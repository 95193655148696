import {AppType} from "../domain/AppType";
import {ExternalResource} from "./ExternalResource";
import {isList, validateList} from "../domain/validateUnknown";
import { isTopicDocuments, ReviewStatus, TopicDocuments } from "../domain/Document";
import { BulkPortfoliosRequest } from "./resources";

export function fetchMultipleDocumentsInOrderByPortfolios(
    externalResource: ExternalResource,
    appType: AppType,
    portfolioIds: number[],
    makeTopicsContiguous: boolean,
    reviewStatuses: ReviewStatus[],
): Promise<TopicDocuments[]> {
    const req: BulkPortfoliosRequest = {
        ids: portfolioIds,
        makeTopicsContiguous,
        reviewStatuses,
    };
    return externalResource.listAction(appType.Resources.getMultipleDocumentsInOrderByPortfolios, req)
        .then(validateList(isList(isTopicDocuments), "TopicDocuments"))
};
