import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { assertNever } from '../../../services/TypeUtils';
import { DropDownItem, ValueType } from './MultiDropDown';
import { WithTestID } from './WithTestID';

export interface SingleDropDownProps<T extends ValueType> extends WithTestID {
    noneSelectedLabel: React.ReactNode;
    value: T|undefined;
    items: DropDownItem<T>[];
    onChange: (newValue: T|undefined) => void;
    disabled?: boolean;
    isInvalid?: boolean;
    onBlur?: () => void;
}

export function SingleDropDown <T extends ValueType> ({ noneSelectedLabel, value, items, onChange, disabled, isInvalid, onBlur, testID }: SingleDropDownProps<T>) {

    let label: React.ReactNode = undefined;
    if (value !== undefined) {
        const opt = items.find(item => 'value' in item && item.value === value);
        if (opt && 'content' in opt) {
            label = opt.content;
        }
    }
    if (label === undefined) {
        label = noneSelectedLabel;
    }

    return (
        <div className={isInvalid ? 'is-invalid' : undefined} data-testid={testID}>
            <Dropdown onBlur={onBlur}>
                <Dropdown.Toggle variant={isInvalid ? 'outline-danger' : 'outline-primary'} disabled={disabled} className="w-100 d-flex align-items-center text-start" data-testid={testID ? `${testID}:btn` : undefined}>
                    <span className="flex-grow-1 flex-shrink-1 text-truncate">{label}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu data-testid={testID ? `${testID}:menu` : undefined}>
                    {items.map((item, idx) => {

                        switch (item.type) {
                            case 'divider':
                                return <Dropdown.Divider key={`item_${idx}`}/>;

                            case 'option':
                                return (
                                    <Dropdown.Item
                                        key={item.value}
                                        active={item.value === value}
                                        onClick={() => {
                                            if (item.value !== value) {
                                                onChange(item.value);
                                            }
                                        }}
                                        disabled={item.disabled}
                                    >
                                        {item.content}
                                    </Dropdown.Item>
                                );

                            default:
                                return assertNever(item);

                        }

                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

