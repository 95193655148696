import React, {FC, useContext} from "react";
import {
    ExternalResource,
    GetCurrentDocumentID,
    GetLocation,
} from "../services/ExternalResource";
import {AppType} from "../domain/AppType";

export type Services = {
    externalResource: ExternalResource
    getLocation: GetLocation
    getAppType: ()=>AppType
    getCurrentDocumentID: GetCurrentDocumentID
}

export const emptyServiceContext: Services = {
    externalResource: {action: () => Promise.reject("ServiceContext not set"),listAction: () => Promise.reject("ServiceContext not set")},
    getLocation: () => {throw new Error("ServiceContext not set")},
    getAppType: () => {throw new Error("ServiceContext not set")},
    getCurrentDocumentID: () => {throw new Error("ServiceContext not set")},
};
export const ServiceContext = React.createContext<Services>(emptyServiceContext);

export const ServiceContextProvider: FC<{value: Services}> = (props) => {
    return <ServiceContext.Provider {...props} />
}

export const useExternalResource = () => useContext(ServiceContext).externalResource
export const useGetLocation = (): GetLocation => useContext(ServiceContext).getLocation
export const useAppType = (): AppType => useContext(ServiceContext).getAppType()
export const useGetCurrentDocumentID = (): GetCurrentDocumentID => useContext(ServiceContext).getCurrentDocumentID
