import { useRef, useState } from "react";
import { DashboardActionType } from "../components/ui/elements/DashboardActions";
import { IsSelectable } from "../components/ui/elements/Table";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { Document } from "../domain/Document";
import { userHasAnyAppRole, AppRole } from '../domain/Account';
import { clearDocumentChanges } from "../services/clearDocumentChanges";
import { convertErrorToErrorWithDetails } from "./exceptionToError";
import { Deferred } from '../services/Deferred';
import { renumberDocuments } from "../services/renumberDocuments";
import { DocumentSearchSort } from "../services/searchDocuments";
import { cullDocuments } from "../services/cullDocuments";
import { uncullDocuments } from "../services/uncullDocuments";
import { useRouter } from "./useRouter";
import { DocumentIDsKey as ArchiveStorageKey } from "../views/app/PPQ/Archive";
import { DocumentIDsKey as ViewMultipleStorageKey } from "../views/app/PPQ/ViewMultiple";
import { useLocalStorage } from "./useLocalStorage";
import { moveDocuments } from "../services/moveDocuments";
import { assertNever } from "../services/TypeUtils";
import { deleteDocuments } from "../services/deleteDocuments";
import { markPendingReview } from '../services/markPendingReview';
import { useAuthenticatedUser } from "../Context/AuthenticationContext";
import { markReadyForPrint } from "../services/markReadyForPrint";
import { markWithDTPCoordinator } from "../services/markWithDTPCoordinator"
import { markApproved } from "../services/markApproved";
import { markWithMinister } from "../services/markWithMinister";

export const useDashboardActions = () => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const userAccount = useAuthenticatedUser();
    const [saving, setSaving] = useState(false);
    const [showCullCommentModal, setShowCullCommentModal] = useState(false);
    const [showMoveDocumentsModal, setShowMoveDocumentsModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAssigneeModal,  setShowAssigneeModal] = useState(false);
    const { goto } = useRouter();
    const storage = useLocalStorage();

    const deferredRef = useRef<Deferred<boolean>>();
    const documentsRef = useRef<Document[]>();

    const getSelectedDocuments = (documents: IsSelectable<Document>[]) => {
        return documents.filter(doc => doc.isSelected);
    };

    const getDocumentIds = (documents: Document[]) => {
        return documents.map(doc => doc.id);
    };


    const resetState = () => {
        setSaving(false);
        setShowCullCommentModal(false);
        setShowMoveDocumentsModal(false);
        setShowDeleteModal(false);
        setShowAssigneeModal(false);
        deferredRef.current = undefined;
        documentsRef.current = undefined;
    };

    const doAsyncAction = async (callback: () => Promise<void>) => {
        setSaving(true);
        try {
            await callback();
        } catch (err) {
            deferredRef.current?.reject(convertErrorToErrorWithDetails(err as Error));
        }
        deferredRef.current?.resolve(true);

        resetState();
    };

    const clearChanges = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await clearDocumentChanges(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const renumber = (sort: DocumentSearchSort|undefined) => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await renumberDocuments(externalResource, appType, getDocumentIds(documentsRef.current), sort);
            }
        });
    };

    const cull = (comment: string) => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await cullDocuments(externalResource, appType, getDocumentIds(documentsRef.current), comment);
            }
        });
    };

    const uncull = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await uncullDocuments(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const move = (topicId: number) => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await moveDocuments(externalResource, appType, getDocumentIds(documentsRef.current), topicId);
            }
        });
    };

    const doDeleteDocuments = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await deleteDocuments(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const doMarkPendingReview = (assignee: string|undefined, note: string|undefined) => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                const isMandatory = !userHasAnyAppRole(userAccount, appType, [AppRole.Admin, AppRole.Coordinator]);
                const assignToAuthor = !isMandatory && !assignee;
                await markPendingReview(externalResource, appType, getDocumentIds(documentsRef.current), note, assignee, assignToAuthor);
            }
        });
    };

    const doMarkReadyForPrint = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await markReadyForPrint(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const doMarkWithDTPCoordinator = () => {
            doAsyncAction(async () => {
                if (documentsRef.current) {
                    await markWithDTPCoordinator(externalResource, appType, getDocumentIds(documentsRef.current));
                }
            });
        };

    const doMarkApproved = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await markApproved(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const doMarkWithMinister = () => {
        doAsyncAction(async () => {
            if (documentsRef.current) {
                await markWithMinister(externalResource, appType, getDocumentIds(documentsRef.current));
            }
        });
    };

    const handleAction = (action: DashboardActionType, documents: IsSelectable<Document>[], sort: DocumentSearchSort|undefined) => {
        deferredRef.current = new Deferred<boolean>();
        documentsRef.current = getSelectedDocuments(documents);

        switch (action) {
            case DashboardActionType.ClearChanges:
                clearChanges();
                break;

            case DashboardActionType.Renumber:
                renumber(sort);
                break;

            case DashboardActionType.Cull:
                setShowCullCommentModal(true);
                break;

            case DashboardActionType.Uncull:
                uncull();
                break;

            case DashboardActionType.Archive:
                storage.set(ArchiveStorageKey, getDocumentIds(documentsRef.current));
                goto(`/app/${appType.appType}/archive`);
                break;

            case DashboardActionType.Move:
                setShowMoveDocumentsModal(true);
                break;

            case DashboardActionType.Delete:
                setShowDeleteModal(true);
                break;

            case DashboardActionType.MarkAsPendingReview:
                setShowAssigneeModal(true);
                break;

            case DashboardActionType.MarkAsApproved:
                doMarkApproved();
                break;

            case DashboardActionType.MarkAsReadyForPrint:
                doMarkReadyForPrint();
                break;

            case DashboardActionType.MarkAsWithDTPCoordinator:
                 doMarkWithDTPCoordinator();
                 break;

            case DashboardActionType.MarkAsWithMinister:
                doMarkWithMinister();
                break;

            case DashboardActionType.Print:
                storage.set(ViewMultipleStorageKey, getDocumentIds(documentsRef.current));
                goto(`/app/${appType.appType}/viewMultiple`);
                break;

            default:
                assertNever(action);
        }

        return deferredRef.current.promise;
    };

    const setCullComment = (comment: string) => {
        setShowCullCommentModal(false);
        cull(comment);
    };

    const cancelModals = () => {
        deferredRef.current?.resolve(false);

        resetState();
    };

    const moveDocumentsToTopic = (topicId: number) => {
        setShowMoveDocumentsModal(false);
        move(topicId);
    };

    const confirmDelete = () => {
        setShowDeleteModal(false);
        doDeleteDocuments();
    };

    const setAssignee = (assignee: string|undefined, note: string|undefined) => {
        setShowAssigneeModal(false);
        doMarkPendingReview(assignee, note);
    };

    return {
        handleAction,
        saving,
        showCullCommentModal,
        setCullComment,
        cancelModals,
        showMoveDocumentsModal,
        moveDocumentsToTopic,
        showDeleteModal,
        confirmDelete,
        showAssigneeModal,
        setAssignee,
    };
};

