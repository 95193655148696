import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { SaveConfirmationAction, SaveConfirmationModal } from '../modals/SaveConfirmationModal';

export interface NavigationPromptProps {
    enabled: boolean;
    onSave: () => Promise<void>;
}

type CallbackType = () => void;

export const NavigationPrompt: React.VFC<NavigationPromptProps> = ({enabled, onSave}) => {
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [navigationPath, setNavigationPath] = useState("");

    const unblockRef = useRef<CallbackType>();

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (enabled) {
                setNavigationPath(location.pathname);
                setShowModal(true);
                return false;
            }
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [enabled, history]);

    const confirmNavigation = () => {
        if (unblockRef.current) {
            unblockRef.current();
        }
        setShowModal(false);
        history.push(navigationPath);
    };

    if (!showModal) return null;
    return (
        <SaveConfirmationModal
            onAction={async (action) => {
                if (action === SaveConfirmationAction.DontSave) {
                    confirmNavigation();
                } else if (action === SaveConfirmationAction.Save) {
                    await onSave();
                    confirmNavigation();
                } else if (action === SaveConfirmationAction.Cancel) {
                    setShowModal(false);
                }
            }}
        />
    );

};
