import axios, { AxiosError } from "axios";
import { RequestError, RequestErrorReason } from "../../../errors/RequestError";

const getErrorReason = (axiosError: AxiosError): RequestErrorReason => {
    if (axiosError.response) {
        const status = axiosError.response.status;
        if (status === 400) {
            return RequestErrorReason.BadRequest;
        }
        if (status === 401) {
            return RequestErrorReason.Unauthorized;
        }
        if (status === 403) {
            return RequestErrorReason.Forbidden;
        }
        if (status === 404) {
            return RequestErrorReason.NotFound;
        }
        if (status === 409) {
            return RequestErrorReason.Conflict;
        }
        if (status >= 500 && status < 600) {
            return RequestErrorReason.ServerError;
        }
        return RequestErrorReason.Unknown;
    }
    return RequestErrorReason.Unknown;
};

const getErrorMessage = (axiosError: AxiosError): string => {
    if (axiosError.response) {
        if (axiosError.response.statusText) {
            return axiosError.response.statusText;
        }
        return `HTTP Error ${axiosError.response.status}`;
    }

    if (axiosError.message) {
        return axiosError.message;
    }

    return 'HTTP Request Error';
};

export class HttpRequestError extends RequestError {
    readonly axiosError: AxiosError;

    constructor(axiosError: AxiosError) {
        super(getErrorReason(axiosError), getErrorMessage(axiosError), axiosError.response?.data);
        this.axiosError = axiosError;
    }
}

const toErrorMessage = (e: any): string|undefined => {
    if (e === null || e === undefined) return undefined;
    if (e instanceof Error) {
        return e.message;
    }
    if (typeof e === 'string') {
        return e;
    }
    if (typeof e === 'object' && typeof e.toString === 'function') {
        return e.toString();
    }
    return `${e}`;
};

export const convertAxiosError = (e: any) => {
    if (axios.isAxiosError(e))  {
        return new HttpRequestError(e);
    } else {
        // Just a stock error
        const message = toErrorMessage(e);
        return new RequestError(RequestErrorReason.Unknown, message);
    }
};
