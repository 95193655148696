import { useCallback } from "react";

export const useDisableScroll = () => {

    const disableScroll = useCallback(() => {
        const { body } = document;
        const width = body.offsetWidth;
        body.style.overflow = 'hidden';
        body.style.position = 'fixed';
        body.style.width = `${width}px`;
    }, []);

    const enableScroll = useCallback(() => {
        const { body } = document;
        body.style.overflow = '';
        body.style.position = '';
        body.style.width = '';
    }, []);

    return {
        disableScroll,
        enableScroll,
    };
};
