import React, { useContext } from 'react';

import { DocumentUpdateAction } from '../../../services/resources';
import { SaveConfirmationAction, SaveConfirmationModal } from '../modals/SaveConfirmationModal';
import { WithTestID } from '../elements/WithTestID';
import { DocumentContext } from "../../../Context/DocumentContext";

interface Props extends WithTestID {
    show: boolean;
    onContinue: () => void;
    onCancel: () => void;
}

export const WorkflowSaveUnsavedChangesModal: React.VFC<Props> = ({ show, onContinue, onCancel, testID }) => {
    const documentContext = useContext(DocumentContext);

    const handleSaveAction = () => {
        return documentContext.handleUpdate(DocumentUpdateAction.SaveNoRedirect)
            .then(() => {
                onContinue();
            });
    };

    const handleOnAction = (action: SaveConfirmationAction) => {
        if (action === SaveConfirmationAction.Save) {
            return handleSaveAction();
        }

        if (action === SaveConfirmationAction.DontSave) {
            return onContinue();
        }

        if (action === SaveConfirmationAction.Cancel) {
            return onCancel();
        }
    }

    return <SaveConfirmationModal
        testID={testID}
        show={show}
        onAction={handleOnAction}
    />;
};

