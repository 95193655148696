import { useCallback, useEffect, useRef, useState } from "react";
import { AppRoleSelectValue } from "../components/ui/elements/AppRoleSelect";

import { useExternalResource } from "../Context/ServiceContext";
import { AdminRole } from "../domain/Account";
import { User } from "../domain/User";
import { queryAllUsers, QueryUsersRequest, QueryUsersSortColumn, QueryUsersSortDirection } from "../services/queryAllUsers";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "./exceptionToError";

export const DefaultUserLimit = 100;

const defaultFilters: QueryUsersRequest = {
    enabled: true,
    sortColumn: QueryUsersSortColumn.Name,
    sortDirection: QueryUsersSortDirection.Ascending,
    limit: DefaultUserLimit,
};

export const useFilteredUsers = () => {
    const externalResource = useExternalResource();
    const [filters, setFilters] = useState<QueryUsersRequest>(defaultFilters);
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<ErrorWithDetails>();
    const [loading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const currentQueryPromiseRef = useRef<Promise<any>>();
    const timerRef = useRef<NodeJS.Timer>();

    const queryUsers = useCallback((filters: QueryUsersRequest) => {
        setLoading(true);
        // keep track of the latest query promise
        const promise = queryAllUsers(externalResource, filters);
        currentQueryPromiseRef.current = promise;
        promise.then((users) => {
            // only update state if the request that returned this result is still the latest query
            if (promise === currentQueryPromiseRef.current) {
                setUsers(users);
                setLoading(false);
                setHasLoaded(true);
                setError(undefined);
                currentQueryPromiseRef.current = undefined;
            }
        }).catch((err) => {
            // only update state if the request that caused this error is still the latest query
            if (promise === currentQueryPromiseRef.current) {
                setError(convertErrorToErrorWithDetails(err as Error));
                setLoading(false);
                setHasLoaded(true);
                currentQueryPromiseRef.current = undefined;
            }
        });
    }, [externalResource]);

    useEffect(() => {
        // query users immediately on first mount
        queryUsers(defaultFilters);

        return () => {
            // clear refs on unmount
            currentQueryPromiseRef.current = undefined;
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, [queryUsers]);

    const setSearchText = (newText: string) => {
        // update the filters state immediately
        const newFilters = { ...filters, searchText: newText };
        setFilters(newFilters);

        // Debounce the call to query the API - we don't want to fire off an
        // API call on every keypress while a user is typing in the search field.
        // This will send the API call only when 500ms has elapsed since the last
        // character was typed.
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }

        timerRef.current = setTimeout(() => {
            timerRef.current = undefined;
            queryUsers(newFilters);
        }, 500);
    };

    const setLimit = (limit: number|undefined) => {
        const newFilters = { ...filters, limit };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const setDivisionId = (divisionId: number|undefined) => {
        const newFilters = { ...filters, divisionId };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const setPPQRole = (ppqRole: AppRoleSelectValue) => {
        const newFilters = { ...filters, ppqRole };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const setPAECRole = (paecRole: AppRoleSelectValue) => {
        const newFilters = { ...filters, paecRole };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const setAdminRole = (adminRole: AdminRole|undefined) => {
        const newFilters = { ...filters, adminRole };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const setEnabled = (enabled: boolean|undefined) => {
        const newFilters = { ...filters, enabled };
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    const changeSort = (col: QueryUsersSortColumn, dir: QueryUsersSortDirection) => {
        const newFilters = { ...filters, sortColumn: col, sortDirection: dir }
        setFilters(newFilters);
        queryUsers(newFilters);
    };

    return {
        filters,
        users,
        error,
        loading,
        hasLoaded,
        setLimit,
        setSearchText,
        setDivisionId,
        setPPQRole,
        setPAECRole,
        setAdminRole,
        setEnabled,
        changeSort,
    };

};
