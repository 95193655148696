import React from 'react';

import { AppLayout, CentredAppLayoutHeading } from '../components/ui/layouts/AppLayout';

export const Forbidden: React.VFC = () => {
    return (
        <AppLayout>
            <div className="pt-5">
                <CentredAppLayoutHeading>Forbidden</CentredAppLayoutHeading>
                <p className="text-center">
                    Sorry, you do not have access to this page.
                    Please check the link and try again, or contact your system
                    administrator for help.
                </p>
            </div>
        </AppLayout>
    );
};
