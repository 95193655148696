import {Unknown} from "../services/TypeUtils";
import {either, hasKey, isBoolean, isNull, isObject, isString, isStringMaybe} from "./validateUnknown";
import {Division, isDivision} from "./Division";
import { appType, AppType, GetAppType } from "./AppType";
import { Document, ReviewStatus } from "./Document";

export enum AppRole {
    Admin = 'ROLE_ADMIN',
    Minister = 'ROLE_MINISTER',
    Author = 'ROLE_AUTHOR',
    Approver = 'ROLE_APPROVER',
    Coordinator = 'ROLE_COORDINATOR',
    DTPCoordinator = 'ROLE_DTP_COORDINATOR',
}

export const AppRoleAny = 'ROLE_ANY';
export type AppRoleAnyType = typeof AppRoleAny;

export interface AccountAppRoles {
    PPQ?: AppRole;
    PAEC?: AppRole;
}

export enum AdminRole {
    SysAdmin = 'ROLE_SYS_ADMIN',
    Super = 'ROLE_SUPER',
}

export interface Account {
    firstName: string;
    lastName: string;
    phone: string|null;
    mobile: string|null;
    division: Division|null;
    login: string;
    langKey: string;
    appRoles: AccountAppRoles;
    adminRole: AdminRole|null;
    activated: boolean;
}

export function isAccount(account: Unknown<Account>): account is Account {
    return isString(account.firstName)
        && isString(account.lastName)
        && isString(account.langKey)
        && isStringMaybe(account.phone)
        && isStringMaybe(account.mobile)
        && isString(account.login)
        && either([isNull, isAdminRole])(account.adminRole)
        && either([isNull, isDivision])(account.division)
        && isAccountAppRoles(account.appRoles)
        && isBoolean(account.activated);
}

export function isAppRole(appRole: unknown): appRole is AppRole {
    return isString(appRole) && Object.values(AppRole).includes(appRole as AppRole);
}

export function isAccountAppRoles(appRoles: unknown): appRoles is AccountAppRoles {
    if (!isObject(appRoles)) return false;
    const record = appRoles as Record<string, any>;
    if (hasKey(record, 'PPQ')) {
        if (!isAppRole(record.PPQ)) return false;
    }
    if (hasKey(record, 'PAEC')) {
        if (!isAppRole(record.PAEC)) return false;
    }
    return true;
}

export function isAdminRole(role: unknown): role is AdminRole {
    return isString(role) && Object.values(AdminRole).includes(role as AdminRole);
}

export const getAppTypesForUser = (userAccount: Account): appType[] => {
    return Object.values(appType)
        .filter(val => GetAppType(val).GetAppRole(userAccount));
};

export const userHasAppRole = (userAccount: Account, appType: AppType, appRole: AppRole): boolean => {
    return appType.GetAppRole(userAccount) === appRole;
};

export const userHasAnyAppRole = (userAccount: Account, appType: AppType, appRoles: AppRole[]): boolean => {
    const userRole = appType.GetAppRole(userAccount);
    return !!userRole && appRoles.includes(userRole);
};

export const userHasAdminRole = (userAccount: Account, adminRole: AdminRole): boolean => {
    return userAccount.adminRole === adminRole;
};

export const userHasAnyRole = (userAccount: Account, appType: AppType, roles: (AppRole | AdminRole)[]): boolean => {
    const userAppRole = appType.GetAppRole(userAccount);
    const userAdminRole = userAccount.adminRole;
    return roles.some(role => role === userAppRole || role === userAdminRole);
};

export const isMinister = (userAccount: Account, appType: AppType) => userHasAppRole(userAccount, appType, AppRole.Minister);

export const canCreateNewBrief = (userAccount: Account, appType: AppType) => userHasAppRole(userAccount, appType, AppRole.Admin);

export const isSysAdmin = (userAccount: Account) => userHasAdminRole(userAccount, AdminRole.SysAdmin);

export const canEditBriefWithMinister = (userAccount: Account, appType: AppType) => userHasAppRole(userAccount, appType, AppRole.Admin);

export const canEditBriefDivision = (userAccount: Account, appType: AppType) => userHasAppRole(userAccount, appType, AppRole.Admin);

export const canEditBriefs = (userAccount: Account, appType: AppType) => userHasAnyAppRole(userAccount, appType, [AppRole.Admin, AppRole.Author, AppRole.Approver, AppRole.Coordinator, AppRole.DTPCoordinator]);

export const canMarkWithMinister = (userAccount: Account) => isSysAdmin(userAccount);

export const canViewDocument = (userAccount: Account, appType: AppType, document: Document) => {
    if (isMinister(userAccount, appType)) {
        return document.reviewStatus === ReviewStatus.WithMinister && !document.deleted;
    }
    return true;
};
