import React, { useState } from "react";
import type { Dispatch } from "react";

import { TextField } from "../../../components/form/fields/TextField";
import { CheckboxSwitchField } from "../../../components/form/fields/CheckboxSwitchField";
import { AddButton } from "../../../components/ui/elements/AddButton";
import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { ArchiveButton } from "../../../components/ui/elements/ArchiveButton";

import "./TopicsList.scss";
import { Topic } from "../../../domain/Document";
import { fetchTopicDocumentCount } from "../../../services/fetchTopicDocumentCount";
import { useAppType, useExternalResource } from "../../../Context/ServiceContext";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { Modal } from "react-bootstrap";
import { makeWordPluralWithCount } from "../../../textUtils";
import { Button } from "./Button";
import { LoadingOverlay } from "./LoadingOverlay";
import { AlertWithDetails } from "./AlertWithDetails";
import { AlertVariant } from "./Alert";
import { WithTestID } from "./WithTestID";

type TopicsFormValues = {
    topics: Topic[];
}

export interface TopicsListProps extends WithTestID {
    values: TopicsFormValues;
    setValues: Dispatch<any | undefined>;
};

const createNewTopic = (topics: Topic[]): Omit<Topic, 'id'> => {

    const nextNumber = topics.reduce((max, topic) => Math.max(max, topic.number), 0) + 1;

    return {
        number: nextNumber,
        name: `Topic ${nextNumber}`,
        culled: false,
        reportable: false,
    };
};

type TopicErrorMsgProps = {
    topics: Topic[];
    topic: Topic;
}
const TopicErrorMsg: React.VFC<TopicErrorMsgProps> = ({topics, topic}) => {
    const strNumber = `${topic.number}`;
    if (!strNumber.match(/^\d+$/)) {
        return (
            <div className="topics-list__err">Topic number must be a postive number with no decimal point</div>
        );
    }
    if (topic.name.trim() === '') {
        return (
            <div className="topics-list__err">Topic name cannot be empty</div>
        );
    }
    const count = topics.filter(t => `${t.number}` === strNumber).length;
    if (count > 1) {
        return <div className="topics-list__err">Topic numbers must be unique</div>
    }
    return null;
};

interface DeleteErrorModalProps extends WithTestID {
    show: boolean;
    onHide: () => void;
    count: number;
}
const DeleteErrorModal: React.VFC<DeleteErrorModalProps> = ({ show, onHide, count, testID }) => {
    return (
        <Modal show={show} onHide={onHide} data-testid={testID}>
            <Modal.Body>
                <p>
                    Unable to delete Topic. Topic contains {makeWordPluralWithCount('document', count)}.
                    Please move them to another Topic and try again.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export const TopicsList: React.VFC<TopicsListProps> = ({
    values,
    setValues,
    testID,
}) => {

    const externalResource = useExternalResource();
    const appType = useAppType();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();
    const [docCount, setDocCount] = useState<number>();
    const [showDocError, setShowDocError] = useState(false);

    const onAddNewTopic = () => {
        const newTopics = [
            ...values.topics,
            createNewTopic(values.topics),
        ];
        setValues({ ...values, topics: newTopics });
    };

    const doRemoveTopic = (rowIndex: number) => {
        if (!values.topics.length) {
            return;
        }
        const topics = [...values.topics];
        topics.splice(rowIndex, 1);
        setValues({ ...values, topics });
    };

    const onRemoveTopic = async (rowIndex: number) => {
        if (!values.topics.length) {
            return;
        }
        if (rowIndex < 0 || rowIndex >= values.topics.length) {
            return;
        }
        const topic = values.topics[rowIndex];
        if (topic.id) {
            // first see if there are any documents in this topic
            setLoading(true);
            try {
                const result = await fetchTopicDocumentCount(externalResource, appType, topic.id);
                if (result.count > 0) {
                    setDocCount(result.count);
                    setShowDocError(true);
                } else {
                    doRemoveTopic(rowIndex);
                }
            } catch (err) {
                setError(convertErrorToErrorWithDetails(err as Error));
            }
            setLoading(false);
        } else {
            doRemoveTopic(rowIndex);
        }
    };

    return (
        <div className="topics-list" data-testid={testID}>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <div className="topics-list__inner">
                <div className="topics-list__heading">
                    <span>#</span>
                    <span>Name</span>
                </div>
                {values.topics.map(
                    (topic, index) => {
                        return (
                            <div key={`${topic.id}-${index}`} className="topics-list__item">
                                <div
                                    className="topics-list__inputs"
                                >
                                    <TextField
                                        type="number"
                                        id={`topics.${index}.number`}
                                        hasMarginBottom={false}
                                    />
                                    <TextField
                                        id={`topics.${index}.name`}
                                        hasMarginBottom={false}
                                    />
                                    <span title="Show in reports">
                                        <CheckboxSwitchField
                                            key={topic.id}
                                            id={`topics.${index}.reportable`}
                                        />
                                    </span>
                                    <span>
                                        <ArchiveButton
                                            onClick={() => onRemoveTopic(index)}
                                            testID={testID ? `${testID}:items:${index}:archive-btn` : undefined}
                                        />
                                    </span>
                                </div>
                                <TopicErrorMsg topics={values.topics} topic={topic} />
                            </div>
                        );
                    }
                )}
            </div>
            <ButtonList className="mb-4">
                <AddButton onClick={onAddNewTopic}>Add Topic</AddButton>
            </ButtonList>
            <LoadingOverlay show={loading} />
            <DeleteErrorModal show={showDocError} onHide={() => setShowDocError(false)} count={docCount || 0} testID={testID ? `${testID}:delete-error-modal` : undefined} />
        </div>
    );
};
