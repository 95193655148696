import { VFC } from "react";

import {
    SelectPanel,
    SELECT_PANEL_CREATE_NEW_KEY,
} from "../../../components/ui/elements/SelectPanel";
import type {
    SelectPanelDataProps,
} from "../../../components/ui/elements/SelectPanel";
import { SideLayout, SideLayoutSide, SideLayoutMain } from "./SideLayout";
import type { WithTestID } from "../elements/WithTestID";

type SectionSelectPanelProps = {
    data: SelectPanelDataProps[];
    heading?: string;
    panelHeading?: string;
    fallBackComponent: VFC;
    createFormComponent: VFC;
    updateFormComponent: VFC;
    showNumberInPanel?: boolean;
    selectedItem: number|undefined;
    setSelectedItem: (id: number) => void;
} & WithTestID;

export const SectionSelectPanel: VFC<SectionSelectPanelProps> = ({
    testID,
    data,
    panelHeading = "Panel Heading",
    fallBackComponent: FallBackComponent,
    createFormComponent: CreateFormComponent,
    updateFormComponent: UpdateFormComponent,
    showNumberInPanel = false,
    selectedItem,
    setSelectedItem,
}) => {

    let MainComponent: VFC;
    if (selectedItem === undefined) {
        MainComponent = FallBackComponent;
    } else if (selectedItem === SELECT_PANEL_CREATE_NEW_KEY) {
        MainComponent = CreateFormComponent;
    } else {
        MainComponent = UpdateFormComponent;
    }

    // Adding key to MainComponent to force rerender
    return (
        <section data-testid={testID}>
            <SideLayout>
                <SideLayoutSide>
                    <SelectPanel
                        heading={panelHeading}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        items={data}
                        showNumber={showNumberInPanel}
                        testID={testID ? `${testID}:panel` : undefined}
                    />
                </SideLayoutSide>
                <SideLayoutMain>
                    <MainComponent key={selectedItem} />
                </SideLayoutMain>
            </SideLayout>
        </section>
    );
};
