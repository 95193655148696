import { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Bootstrap } from "./Bootstrap";
import { Col, Container, Row } from "react-bootstrap";

import "./AppLayout.scss";
import { AppNav, AppNavWithLogoutOnly } from "../elements/AppNav";
import { WithTestID } from "../elements/WithTestID";
import { SupportBanner, SupportBannerWithStatusBar } from "../elements/SupportBanner";

type WithPanel = {
    panel: JSX.Element;
};

interface AppLayoutHeadingProps extends WithTestID {
    className?: string;
}
export const AppLayoutHeading: React.FC<AppLayoutHeadingProps> = ({
    testID,
    className,
    children,
}) => (
    <h1 className={`mt-2 mb-4 ${className ?? ''}`} data-testid={testID}>
        {children}
    </h1>
);

export const CentredAppLayoutHeading: React.FC<WithTestID> = ({ children, ...props }) => (
    <AppLayoutHeading className="text-center" {...props}>{children}</AppLayoutHeading>
);

export const AppLayoutWithRightPanel: FC<WithPanel> = ({ children, panel }) => {
    return (
        <AppLayout>
            <Row>
                <Col md={{ span: 8 }}>{children}</Col>
                <Col md={{ span: 4 }}>{panel}</Col>
            </Row>
        </AppLayout>
    );
};

export const AppLayout: FC = ({ children }) => {
    return (
        <Bootstrap>
            <div className="app-layout">
                <AppNav testID="navigation" />
                <main className="main">
                    <Container>{children}</Container>
                </main>
            </div>
            <SupportBanner />
        </Bootstrap>
    );
};

export const AppLayoutWithStatusBar: FC = ({ children }) => {
    return (
        <Bootstrap>
            <div className="app-layout app-layout--status-bar">
                <AppNav />
                <main className="main">
                    <Container>{children}</Container>
                </main>
            </div>
            <SupportBannerWithStatusBar />
        </Bootstrap>
    );
};

export const AppLayoutWithoutContainer: FC = ({ children }) => {
    return (
        <Bootstrap>
            <div className="app-layout">
                <AppNav />
                {children}
            </div>
            <SupportBanner />
        </Bootstrap>
    );
};

// This is a standard app layout with only the logout link in the top navigation.
export const AppLayoutWithLogoutOnly: FC = ({ children }) => {
    return (
        <Bootstrap>
            <div className="app-layout">
                <AppNavWithLogoutOnly />
                <main className="main">
                    <Container>{children}</Container>
                </main>
            </div>
            <SupportBanner />
        </Bootstrap>
    );
};
