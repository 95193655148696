import React from "react";
import { Form } from "react-bootstrap";
import { WithTestID } from "./WithTestID";

export interface CheckboxProps extends WithTestID {
    id?: string;
    checked: boolean;
    onChange: (arg: boolean) => void;
    label: string;
    isInvalid?: boolean;
    disabled?: boolean;
    onBlur?: () => void;
}

export const Checkbox: React.VFC<CheckboxProps> = ({ id, checked, onChange, label, testID, isInvalid = false, disabled = false, onBlur }) => {
    return (
        <Form.Check
            type="checkbox"
            onChange={(event) => onChange(event.target.checked)}
            data-testid={testID}
            id={id}
            checked={checked}
            isInvalid={isInvalid}
            label={label}
            disabled={disabled}
            className={isInvalid ? 'is-invalid' : undefined}
            onBlur={onBlur}
        />
    );
};
