import React, { useState } from 'react';

import { Button, ButtonVariant } from '../elements/Button';
import { FormField } from '../elements/FormField';
import Modal from '../elements/Modal';
import { TextareaElement } from '../elements/TextareaElement';
import { WithTestID } from '../elements/WithTestID';

export interface CullCommentModalProps extends WithTestID {
    documentCount: number;
    show: boolean;
    onCancel: () => void;
    onSubmit: (comment: string) => void;
}

export const CullCommentModal: React.VFC<CullCommentModalProps> = ({ documentCount, show, onCancel, onSubmit, testID }) => {
    const [comment, setComment] = useState('');

    const cancelModal = () => {
        onCancel();
        setComment('');
    };

    return (
        <Modal show={show} onHide={cancelModal} testID={testID}>
            <Modal.Body>
                <p><strong>Are you sure you want to Cull {documentCount > 1 ? `these ${documentCount} documents` : 'this document'}?</strong></p>
                <FormField
                    label="Please enter a comment for this action"
                    help="The comment entered here will be visible in the document history."
                    id="comment"
                    control={
                        <TextareaElement
                            value={comment}
                            onChange={setComment}
                            required
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={cancelModal}>Cancel</Button>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        onSubmit(comment);
                        setComment('');
                    }}
                    disabled={!comment}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    )
};
