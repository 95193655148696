import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppType, useGetCurrentDocumentID } from '../../../Context/ServiceContext';
import { getDocumentStatusLabel } from '../../../domain/Document';
import { DocumentHistoryDto } from '../../../domain/DocumentHistory';
import { useDocumentHistoryList } from '../../../hooks/useDocumentHistoryList';
import { DateTimeFormat, formatDateTimeString } from '../../../services/DateTime';
import { AlertVariant } from './Alert';
import { AlertWithDetails } from './AlertWithDetails';
import { LoadingOverlay } from './LoadingOverlay';
import { ReviewStatusLabel } from './ReviewStatusLabel';

const DocumentHistoryRow: React.VFC<{ history: DocumentHistoryDto }> = ({ history }) => {
    const appType = useAppType();
    return (
        <tr key={history.id}>
            <td>
                <Link to={`/app/${appType.appType}/viewHistory/${history.id}`} target="_blank">{history.dateEdited ? formatDateTimeString(history.dateEdited, DateTimeFormat.DateTime) : ''}</Link>
            </td>
            <td>{history.editorUserFullName}</td>
            <td>{history.assigneeUserFullName}</td>
            <td>{history.status && getDocumentStatusLabel(history.status)}</td>
            <td>{history.reviewStatus && <ReviewStatusLabel reviewStatus={history.reviewStatus} />}</td>
            <td>{history.note}</td>
        </tr>
    );
};

export const DocumentHistoryList: React.VFC = () => {

    const documentId = useGetCurrentDocumentID()();
    const { data, error } = useDocumentHistoryList(documentId);

    if (error) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...error}/>;
    }

    if (!data) {
        return <LoadingOverlay show />;
    }

    return (
        <div>
            <Table variant={'condensed'} bordered={true}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Changed By</th>
                        <th>Assignee</th>
                        <th>Stage</th>
                        <th>Review Status</th>
                        <th>Note to Assignee</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((history) => <DocumentHistoryRow key={history.id} history={history} />)}
                    {data.length === 0 && (
                        <tr>
                            <td colSpan={6}>None found.</td>
                        </tr>
                )   }
                </tbody>
            </Table>
        </div>
    );

};
