import {Unknown} from "../../TypeUtils";
import {getAxios} from "./AxiosSettings";

export function fetchJSON<T>(URL: string): Promise<Unknown<T>> {
    return getAxios()
        .get<T>(URL)
        .then(r => r.data);
}

export function fetchJSONList<T>(URL: string): Promise<Unknown<T>[]> {
    return getAxios()
        .get<T[]>(URL)
        .then(r => r.data);
}
