import {VFC} from "react";
import {FormField} from "../../ui/elements/FormField";
import { Field, FieldProps, useFormikContext } from "formik";
import { StandardFieldProps, WithPlaceholder } from "./TextField";
import { UserAutoComplete } from "../../ui/elements/UserAutoComplete";
import { validateString } from "./FieldValidation";
import { userFullNameDisabled } from "../../../domain/User";
import { useFetchAllUsers } from "../../../hooks/useFetchAllUsers";
import { useAppType } from '../../../Context/ServiceContext';
import { AppRole } from "../../../domain/Account";
import { escapeHtml } from "../../../textUtils";


export interface UserAutoCompleteFieldProps extends StandardFieldProps, WithPlaceholder {
    includeDisabledUsers?: boolean;
    noApprovalAccessWarning?: string;
    requiredAppRole?: string;
}

export const UserAutoCompleteField: VFC<UserAutoCompleteFieldProps> = ({id, label, help, placeholder, disabled, required, validationRules = [], includeDisabledUsers, testID, requiredAppRole, noApprovalAccessWarning}) => {
    const formik = useFormikContext();
    const { data: users } = useFetchAllUsers(includeDisabledUsers);
    const appType = useAppType();
    return (
        <Field name={id} validate={(value: string) => {
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateString(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && !value) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<string|undefined, any>) => {
                const { error, touched } = form.getFieldMeta(id);
                const user = users?.find(u => u.login === field.value);

                let errorHtml = undefined;
                if (user &&
                    requiredAppRole &&
                    noApprovalAccessWarning &&
                    (appType.GetAppRoleForUser(user) !== requiredAppRole && appType.GetAppRoleForUser(user) !== AppRole.Admin)) {
                    errorHtml = noApprovalAccessWarning.replace('{name}', escapeHtml(userFullNameDisabled(user)));
                }

                return (
                    <FormField
                        help={help}
                        label={label}
                        id={id}
                        error={touched ? error : undefined}
                        errorHtml={errorHtml}
                        control={
                            <UserAutoComplete
                                value={field.value || ''}
                                testID={testID}
                                onChange={newValue => form.setFieldValue(id, newValue)}
                                isInvalid={touched && !!error}
                                disabled={disabled}
                                placeholder={placeholder}
                                onBlur={() => form.setFieldTouched(id)}
                                includeDisabledUsers={includeDisabledUsers}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
}
