import useSWR from 'swr';

import { useExternalResource } from '../Context/ServiceContext';
import { Division } from '../domain/Division';
import { fetchAllDivisions } from '../services/fetchAllDivisions';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

const sortDivisions = (list: Division[]) => {
    list.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    return list;
};

export function useDivisionList() {
    const externalResource = useExternalResource();

    return useSWR<Division[], ErrorWithDetails>('divisions', () => {
        return fetchAllDivisions(externalResource)
            .then(sortDivisions)
            .catch(throwErrorWithDetails);
    });
};
