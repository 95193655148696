import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppType } from '../../../Context/ServiceContext';
import { AppType } from '../../../domain/AppType';
import { Document, ReviewStatus } from '../../../domain/Document';
import { ExportFormat } from '../../../domain/ExportFormat';
import { useMultipleDocumentsInOrder } from '../../../hooks/useMultipleDocumentsInOrder';
import { DocumentID } from '../../../services/ExternalResource';
import { AlertVariant } from './Alert';
import { AlertWithDetails } from './AlertWithDetails';
import { Button, ButtonVariant } from './Button';
import { Checkbox } from './Checkbox';
import { FormField } from './FormField';
import { IndexTable } from './IndexTable';
import { LoadingOverlay } from './LoadingOverlay';
import { TextInputElement } from './TextInputElement';
import { ViewDocumentDetails } from './ViewDocumentDetails';

import './ViewDocumentDetails.scss';

export interface ViewMultipleDocumentsProps {
    ids: DocumentID[];
}

const getExportFilename = (appType: AppType, format: ExportFormat, separateFiles: boolean) => {

    let url: string;
    if (format === 'DOCX') {
        url = `/docx/${appType.appType}/bulk/`;
    } else {
        url = `/${appType.appType}/bulkPdf/`;
    }

    url = `${url}${appType.appType.toLocaleLowerCase()}_document`;
    if (separateFiles) {
        url = `${url}s`;
    }

    if (separateFiles) {
        url = `${url}.zip`;
    } else if (format === 'DOCX') {
        url = `${url}.docx`;
    } else {
        url = `${url}.pdf`;
    }

    return url;
};

export const ViewMultipleDocuments: React.VFC<ViewMultipleDocumentsProps> = ({ ids }) => {
    const appType = useAppType();
    const [options, setOptions] = useState(DefaultFormOptions);
    const formRef = useRef<HTMLFormElement>(null);

    const { documents, error } = useMultipleDocumentsInOrder(ids, options.contiguous);

    if (error) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...error} />;
    }

    if (!documents) {
        return <LoadingOverlay show />;
    }



    const handleDownload = (format: ExportFormat) => {
        if (formRef.current) {
            formRef.current.action = getExportFilename(appType, format, options.separateFiles);
            formRef.current.submit();
        }
    };

    return (
        <div className="view-document">
            <ViewMultipleOptions options={options} onChange={setOptions} onDownload={handleDownload} />
            <Container className="page-container">
                <ViewMultipleHeader options={options} documents={documents} />

                {options.indexOnly || documents.map((document) => {
                    if (options.omitNonMinister && document.reviewStatus !== ReviewStatus.WithMinister) {
                        return null;
                    }

                    return (
                        <div key={document.id}>
                            <div className="page-break"><span>PAGE BREAK</span></div>
                            <ViewDocumentDetails document={document} />
                        </div>
                    );
                })}

            </Container>

            {/* Note: this form is always hidden from view, and the form fields are set based on the
              * options passed back from the <ViewMultipleOptions> component above. The input values
              * are never set directly by the user, but React logs a warning if we have inputs with
              * a 'value' prop but no 'onChange' prop, which is why we have 'onChange={() => {}}` below.
              * TODO: look at refactoring this to use the inputs in <ViewMultipleOptions> as the
              * form - we may not even need this hidden form at all.
              * See also: ViewPortfolios.tsx
              */}
            <form className="d-none" method="POST" target="_blank" ref={formRef}>
                {ids.map(id => <input key={id} type="hidden" name="ids" value={id} />)}
                <input type="text" name="title1" value={options.title1} id="title1" onChange={() => {}} />
                <input type="text" name="title2" value={options.title2} id="title2" onChange={() => {}} />
                <input type="checkbox" name="contiguous" checked={options.contiguous} onChange={() => {}} />
                <input type="checkbox" name="indexOnly" checked={options.indexOnly} onChange={() => {}} />
                <input type="checkbox" name="omitNonMinister" checked={options.omitNonMinister} onChange={() => {}} />
                <input type="checkbox" name="separateFiles" checked={options.separateFiles} onChange={() => {}} />
            </form>
        </div>
    );
};

interface FormOptions {
    title1: string;
    title2: string;
    contiguous: boolean;
    indexOnly: boolean;
    omitNonMinister: boolean;
    separateFiles: boolean;
}
const DefaultFormOptions: FormOptions = {
    title1: '',
    title2: '',
    contiguous: false,
    indexOnly: false,
    omitNonMinister: false,
    separateFiles: false,
};

interface ViewMultipleOptionsProps {
    options: FormOptions;
    onChange: (options: FormOptions) => void;
    onDownload: (format: ExportFormat) => void;
}
const ViewMultipleOptions: React.VFC<ViewMultipleOptionsProps> = ({ options, onChange, onDownload }) => {
    const isSeparate = options.separateFiles && !options.indexOnly;

    const setField = (key: keyof FormOptions, value: FormOptions[keyof FormOptions]) => {
        onChange({ ...options, [key]: value });
    };

    return (
        <div className="head-section">
            <Container className="page-container py-2">
                <Row>
                    <Col xs={6}>
                        <FormField
                            id="title1"
                            label={<>Title <small>(line 1)</small></>}
                            control={
                                <TextInputElement
                                    value={options.title1}
                                    onChange={val => setField('title1', val)}
                                    placeholder="Title top line"
                                />
                            }
                        />
                        <FormField
                            id="title2"
                            label={<>Title <small>(line 2)</small></>}
                            control={
                                <TextInputElement
                                    value={options.title2}
                                    onChange={val => setField('title2', val)}
                                    placeholder="Title bottom line"
                                />
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <FormField
                            id="contiguous"
                            control={
                                <Checkbox
                                    label="Contiguous Numbering"
                                    checked={options.contiguous}
                                    onChange={val => setField('contiguous', val)}
                                />
                            }
                        />

                        <FormField
                            id="indexOnly"
                            control={
                                <Checkbox
                                    label="Index only"
                                    checked={options.indexOnly}
                                    onChange={val => setField('indexOnly', val)}
                                />
                            }
                        />

                        <FormField
                            id="omitNonMinister"
                            control={
                                <Checkbox
                                    label="Omit documents not with minister"
                                    checked={options.omitNonMinister}
                                    onChange={val => setField('omitNonMinister', val)}
                                />
                            }
                        />

                        <FormField
                            id="separateFiles"
                            control={
                                <Checkbox
                                    label="Download as separate files"
                                    checked={options.separateFiles}
                                    onChange={val => setField('separateFiles', val)}
                                    disabled={options.indexOnly}
                                />
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex gap-3 justify-content-center">
                            <Button variant={ButtonVariant.PRIMARY} onClick={() => onDownload('PDF')}>Download as {isSeparate ? 'PDFs' : 'PDF'}</Button>
                            <Button variant={ButtonVariant.PRIMARY} onClick={() => onDownload('DOCX')}>Download as {isSeparate ? 'DOCXs' : 'DOCX'}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


interface ViewMultipleHeaderProps {
    options: FormOptions;
    documents: Document[];
}

const ViewMultipleHeader: React.VFC<ViewMultipleHeaderProps> = ({ options, documents }) => {

    return (
        <div className="page">
            {(!!options.title1 || !!options.title2) && (
                <div className="index-header">
                    {!!options.title1 && <h2>{options.title1}</h2>}
                    {!!options.title2 && <h2>{options.title2}</h2>}
                </div>
            )}

            <IndexTable documents={documents} omitNonMinister={options.omitNonMinister} />
        </div>
    );
};
