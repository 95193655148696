import React from 'react';
import { DocumentHistoryList } from '../../../components/ui/elements/DocumentHistoryList';
import { AppLayout } from '../../../components/ui/layouts/AppLayout';

export const PPQHistoryList: React.VFC = () => {
    return (
        <AppLayout>
            <DocumentHistoryList />
        </AppLayout>
    );
};
