import React from 'react';
import { Document, DocumentDetails, isAtLeastApproved, isDocument } from '../../../domain/Document';
import { formatDateTimeString, DateTimeFormat } from '../../../services/DateTime';
import { useAppType } from '../../../Context/ServiceContext';
import { useFeatures } from '../../../hooks/useFeatures';
import { useTranslation } from '../../../hooks/useTranslation';
import { AlertWithDetails } from './AlertWithDetails';
import { AlertVariant } from './Alert';
import { LoadingOverlay } from './LoadingOverlay';
import { EditField } from '../../form/forms/EditForm';
import { useFetchUser } from '../../../hooks/useFetchUser';
import { DocumentHistory, isDocumentHistory } from '../../../domain/DocumentHistory';
import { useLatestDocumentHistory } from '../../../hooks/useLatestDocumentHistory';
import { assertNever, Unknown } from '../../../services/TypeUtils';

import './ViewDocumentDetails.scss';

export interface ViewDocumentDetailsProps {
    document: DocumentDetails;
}

export const ViewDocumentDetails: React.VFC<ViewDocumentDetailsProps> = ({ document }) => {
    const appType = useAppType();
    const { data: features, error: featuresError } = useFeatures();
    const { data: translations, error: translationsError } = useTranslation('document');

    const anyError = featuresError || translationsError;
    if (anyError) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...anyError} />;
    }

    if (!features || !translations) {
        return <LoadingOverlay show />;
    }

    const fields = appType.GetEditFields(features, translations);
    const getField = (key: string) => fields.find(f => f.id === key);


    return (
        <div className={`page ${appType.appType.toLowerCase()}`}>
            {appType.DisplayDocumentEditionDate(document) && (
                <div className="editionDate">
                    <strong>Edition Date:</strong><br />
                    {formatDateTimeString(document.dateLastSignificantChange, DateTimeFormat.DateTime)}
                </div>
            )}
            {document.portfolioName && (
                <div className="portfolio">
                    <span className="portfolio-label">Portfolio: </span>{document.portfolioName}
                </div>
            )}
            <div className="number">
                Number:{' '}
                {appType.ShortDocumentNumber(features) ? document.paecPdfFormattedNumber : document.formattedNumber}
            </div>
            <div className="title">{document.title}</div>
            <ContentField field={getField('question')} content={document.question} />
            <ContentField field={getField('reference')} content={document.reference} />
            <ContentField field={getField('table')} content={document.tableData} />
            <ContentField field={getField('talkingPoints')} content={document.talkingPoints} showIfEmpty />
            <ContentField field={getField('background')} content={document.background} showIfEmpty />
            <ContentField field={getField('commitments')} content={document.commitments} />

            <Endorsement document={document} />
            <RevisionInfoForDocDetails docDetails={document} />
        </div>
    );
};

export enum EndorsementDisplayType {
    Approver,
    AssigneeApproverShort,
    FullTable,
    None,
}

interface ContentFieldProps {
    field: EditField|undefined;
    content: string|null;
    showIfEmpty?: boolean;
}

const ContentField: React.VFC<ContentFieldProps> = ({ field, content, showIfEmpty = false }) => {
    if (!field) return null;
    if (!content && !showIfEmpty) return null;
    return (
        <div className={`section ${field.id}`}>
            <h2>{field.label}</h2>
            <div className="content" dangerouslySetInnerHTML={{__html: (content || '')}} />
        </div>
    );
};

interface UserInfoProps {
    userId: string | null;
}

const UserInfo: React.VFC<UserInfoProps> = ({ userId }) => {
    const { data: user } = useFetchUser(userId || undefined);
    if (!user) return null;

    let userStr = user.fullName;
    if (user.division) {
        userStr = `${userStr}, ${user.division.name}`;
    }
    if (user.phone) {
        userStr = `${userStr}, ${user.phone}`;
    }
    if (user.mobile) {
        if (user.phone) {
            userStr = `${userStr} or ${user.mobile}`;
        } else {
            userStr = `${userStr}, ${user.mobile}`;
        }
    }

    return <div className="user-info">{userStr}</div>;
};

interface RevisionInfoProps {
    history: DocumentHistory;
}

const RevisionInfo: React.VFC<RevisionInfoProps> = ({ history }) => {
    const { data: editorUser } = useFetchUser(history.editor || undefined);

    if (!editorUser || !history.dateEdited) return null;

    return (
        <div className="revision-info">
            <div>This revision was saved by {editorUser.fullName} at { formatDateTimeString(history.dateEdited)}</div>
            {history.comment && <div className="comment">{history.comment}</div>}
        </div>
    );
};

interface RevisionInfoForDocumentProps {
    document: Document;
}

const RevisionInfoForDocument: React.VFC<RevisionInfoForDocumentProps> = ({ document }) => {
    const { data: history } = useLatestDocumentHistory(document.id);
    if (!history) return null;
    return <RevisionInfo history={history} />;
};

interface RevisionInfoForDocDetailsProps {
    docDetails: DocumentDetails;
}

export const docDetailsIsDocument = (doc: DocumentDetails): doc is Document => {
    return isDocument(doc as Unknown<Document>);
};

export const docDetailsIsHistory = (doc: DocumentDetails): doc is DocumentHistory => {
    return isDocumentHistory(doc as Unknown<DocumentHistory>);
};

export const getRecordId = (doc: DocumentDetails): number|undefined => {
    if (docDetailsIsDocument(doc)) {
        return doc.id;
    } else if (docDetailsIsHistory(doc)) {
        return doc.id;
    }
    return undefined;
};

const RevisionInfoForDocDetails: React.VFC<RevisionInfoForDocDetailsProps> = ({ docDetails }) => {
    if (docDetailsIsDocument(docDetails)) {
        return <RevisionInfoForDocument document={docDetails} />;
    }
    if (docDetailsIsHistory(docDetails)) {
        return <RevisionInfo history={docDetails} />;
    }
    return null;
};

interface EndorsementProps {
    document: DocumentDetails;
}
const EndorsementApproverOnly: React.VFC<EndorsementProps> = ({ document }) => {
    const userId = document.roles?.APPROVER_3?.login
        || document.roles?.APPROVER_2?.login
        || document.dpcApprover;
    return <UserInfo userId={userId} />;
};

const EndorsementDetailsShort: React.VFC<EndorsementProps> = ({ document }) => {
    return (
        <div className="endorsement-short">
            <UserInfo userId={document.assignee} />
            {isAtLeastApproved(document) && <UserInfo userId={document.approver} />}
        </div>
    );
};

const EndorsementFullTable: React.VFC<EndorsementProps> = ({ document }) => {

    const { data: creator } = useFetchUser(document.creator);
    const { data: approver } = useFetchUser(document.approver || undefined);
    const { data: boardApprover } = useFetchUser(document.dpcApprover || undefined);
    const approved = isAtLeastApproved(document);

    return (
        <table className="details-table">
            <tbody>
                <tr>
                    <td>
                        <strong>Author</strong><br/>
                        {creator?.fullName}
                    </td>
                    <td>
                        <strong>Local Approver</strong><br/>
                        {approved && approver && <span>{approver.fullName}</span>}
                    </td>
                    <td>
                        <strong>Executive Board Approver</strong><br/>
                        {approved && boardApprover && <span>{boardApprover.fullName}</span>}
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>Ph: </strong>{creator?.phone}<br/>
                        <strong>Mob: </strong>{creator?.mobile}
                    </td>
                    <td>
                        {approved && (
                            <div>
                                <strong>Ph: </strong>{approver?.phone}<br/>
                                <strong>Mob: </strong>{approver?.mobile}
                            </div>
                        )}
                    </td>
                    <td>
                        {approved && (
                            <div>
                                <strong>Ph: </strong>{boardApprover?.phone}<br/>
                                <strong>Mob: </strong>{boardApprover?.mobile}
                            </div>
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const Endorsement: React.VFC<EndorsementProps> = ({ document }) => {
    const appType = useAppType();
    const { data: features } = useFeatures();

    if (!features) return null;

    const displayType = appType.EndorsementDisplay(features);
    switch (displayType) {
        case EndorsementDisplayType.Approver:
            return <EndorsementApproverOnly document={document} />;

        case EndorsementDisplayType.AssigneeApproverShort:
            return <EndorsementDetailsShort document={document} />;

        case EndorsementDisplayType.FullTable:
            return <EndorsementFullTable document={document} />;

        case EndorsementDisplayType.None:
            return null;

        default:
            return assertNever(displayType);
    }
};


