import React, {FC} from "react";
import {Button as BootstrapButton, ButtonProps as BSButtonProps} from "react-bootstrap";
import { Icon, IconType } from "./Icon";
import {WithTestID} from "./WithTestID";

import './Button.scss';
import { assertNever } from "../../../services/TypeUtils";

export enum ButtonVariant {
    DEFAULT = 'Default',
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    WARNING = 'Warning',
    LINK = 'Link',
    DANGER = 'Danger',
    LIGHT = 'Light',
}

export enum ButtonSize {
    Default = 'Default',
    Small = 'Small',
    Large = 'Large',
}

export type ButtonProps = WithTestID & {
    onClick?: () => void;
    variant?: ButtonVariant
    disabled?: boolean
    size?: ButtonSize
    className?: string;
}
type ButtonType = Pick<BSButtonProps, "type">

function translateVariant(variant: ButtonVariant): BSButtonProps['variant'] {
    switch (variant) {
        case ButtonVariant.DEFAULT:
            return "outline-primary"
        case ButtonVariant.PRIMARY:
            return "primary"
        case ButtonVariant.SECONDARY:
            return "info"
        case ButtonVariant.WARNING:
            return "warning"
        case ButtonVariant.LINK:
            return "link";
        case ButtonVariant.DANGER:
            return 'danger';
        case ButtonVariant.LIGHT:
            return 'light';
        default:
            return assertNever(variant);
    }
}

const translateSize = (size: ButtonSize): BSButtonProps['size'] => {
    switch (size) {
        case ButtonSize.Large: return 'lg';
        case ButtonSize.Small: return 'sm';
        default: return undefined;
    }
};

const ButtonBase: FC<ButtonProps&ButtonType> = ({type, variant = ButtonVariant.DEFAULT, disabled=false, children, testID, onClick, size = ButtonSize.Default, className}) => {
    return <BootstrapButton type={type} variant={translateVariant(variant)} disabled={disabled} data-testid={testID} onClick={onClick} size={translateSize(size)} className={className}>{children}</BootstrapButton>
}
export const Button: FC<ButtonProps> = (props) => {
    return <ButtonBase type="button" {...props} />
}
export const SubmitButton: FC<ButtonProps> = (props) => {
    return <ButtonBase type={"submit"}  {...props} />
}
export const ResetButton: FC<ButtonProps> = (props) => {
    return <ButtonBase type={"reset"} {...props} />
}

export enum IconPosition {
    Left = 'Left',
    Right = 'Right',
}

export type IconButtonProps = ButtonProps & {
    icon: IconType;
    iconPosition?: IconPosition;
};

const IconButtonBase: FC<IconButtonProps&ButtonType> = ({ icon, iconPosition = IconPosition.Left, children, ...props }) => {
    const cn = ['button--icon'];
    if (iconPosition === IconPosition.Right) {
        cn.push('button--icon--right');
    }

    return (
        <ButtonBase type="button" {...props}>
            <span className={cn.join(' ')}>
                <span>
                    <Icon icon={icon} />
                </span>
                {React.Children.count(children) > 0 && (
                    <span>
                        {children}
                    </span>
                )}
            </span>
        </ButtonBase>
    );
};

export const IconButton: FC<IconButtonProps> = (props) => (
    <IconButtonBase type="button" {...props} />
);

export const SubmitIconButton: FC<IconButtonProps> = (props) => (
    <IconButtonBase type="submit" {...props} />
);

export const ResetIconButton: FC<IconButtonProps> = (props) => (
    <IconButtonBase type="reset" {...props} />
);
