import React from 'react';
import { Field, FieldProps, useFormikContext } from "formik";
import {FormField} from "../../ui/elements/FormField";
import { StandardFieldProps, WithPlaceholder } from './TextField';
import { TextareaElement } from '../../ui/elements/TextareaElement';
import { validateString } from './FieldValidation';

export interface TextareaFieldProps extends StandardFieldProps, WithPlaceholder {
    rows?: number;
}

export const TextareaField: React.VFC<TextareaFieldProps> = ({ id, label, disabled, help, placeholder, required, rows, validationRules = [], testID }) => {
    const formik = useFormikContext();

    return (
        <Field name={id} validate={(value: string) => {
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateString(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && !value) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<string|undefined, any>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        help={help}
                        label={label}
                        id={id}
                        error={touched ? error : undefined}
                        control={
                            <TextareaElement
                                rows={rows}
                                testID={testID}
                                value={field.value || ''}
                                placeholder={placeholder}
                                isInvalid={touched && !!error}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                disabled={disabled}
                                required={required}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
