import {Column} from "../../components/ui/elements/Table";
import {Document, getDocumentStatusLabel, isAtLeastApproved} from "../Document";
import {CSSProperties, VFC} from "react";
import { UserPopup } from "../../components/ui/elements/UserPopup";
import { useFetchUser } from "../../hooks/useFetchUser";
import { DateTimeFormat, formatDateTimeString } from "../../services/DateTime";
import { CulledLabel, ReviewStatusLabel } from "../../components/ui/elements/ReviewStatusLabel";
import { DocumentView } from "../DocumentView/DocumentView";

type DocumentWithExecApproverRoles = Document & {
    ExecutiveApprover1?: string;
    ExecutiveApprover2?: string;
}

export type DocumentColumn = Column<DocumentWithExecApproverRoles>

const DocumentNumber: VFC<Pick<Document, 'formattedNumber'|'number'>> = ({number,formattedNumber}) => {
    const style: CSSProperties = { whiteSpace: 'nowrap' };
    if (!number) {
        style.color = '#999';
    }
    return <span style={style}>{formattedNumber}</span>
}

export const formattedNumber: DocumentColumn = {
    field: 'formattedNumber', label: "", sortable: true,
    displayCell: (row) => <DocumentNumber {...row} />,
    displayHeader: () => <></>,
}

export const title: DocumentColumn = {
    field: 'title', label: "", sortable: true,
    displayCell: (props) => <DocumentView document={props} />,
    displayHeader: () => <>Title</>,
}

interface UserColumnProps {
    login: string|undefined|null;
}
const UserColumn: React.VFC<UserColumnProps> = ({ login }) => {
    const { data: user } = useFetchUser(login ?? undefined);
    if (!user) return null;
    return <UserPopup user={user} />;
};

export const creator: DocumentColumn = {
    field: 'creator', label: "", sortable: true,
    displayCell: (row) => <UserColumn login={row.creator} />,
    displayHeader: () => <>Author</>,
};
export const assignee: DocumentColumn = {
    field: 'assignee', label: "", sortable: true,
    displayCell: (row) => <UserColumn login={row.assignee} />,
    displayHeader: () => <>Assignee</>,
};
export const approver: DocumentColumn = {
    field: 'approver', label: "", sortable: true,
    displayCell: (row) => <UserColumn login={row.approver} />,
    displayHeader: () => <>Approver</>,
}
export const dpcApprover: DocumentColumn = {
    field: 'dpcApprover', label: "", sortable: true,
    displayCell: (row) => <UserColumn login={row.dpcApprover} />,
    displayHeader: () => <>Executive Approver</>,
}
export const execApprover1: DocumentColumn = {
    field: 'ExecutiveApprover1',
    label: '',
    sortable: false,
    displayCell: (row) => <UserColumn login={row.roles?.APPROVER_2?.login ?? row.dpcApprover} />,
    displayHeader: () => <>Executive Approver 1</>,
};
export const execApprover2: DocumentColumn = {
    field: 'ExecutiveApprover2',
    label: '',
    sortable: false,
    displayCell: (row) => <UserColumn login={row.roles?.APPROVER_3?.login} />,
    displayHeader: () => <>Executive Approver 2</>,
};
export const dateLastSignificantChange: DocumentColumn = {
    field: 'dateLastSignificantChange', label: "", sortable: true,
    displayCell: (row) => {
        if (isAtLeastApproved(row)) {
            return <>{formatDateTimeString(row.dateLastSignificantChange, DateTimeFormat.Date)}</>;
        }
        return null;
    },
    displayHeader: () => <>Approved</>,
}
export const status: DocumentColumn = {
    field: 'status', label: "", sortable: true,
    displayCell: (row) => <>{getDocumentStatusLabel(row.status)}</>,
    displayHeader: () => <>Stage</>,
}
export const reviewStatus: DocumentColumn = {
    field: 'reviewStatus', label: "", sortable: true,
    displayCell: (row) => {
        if (row.deleted) {
            return <CulledLabel />;
        }
        return <ReviewStatusLabel reviewStatus={row.reviewStatus} />;
    },
    displayHeader: () => <>Review Status</>,
}
