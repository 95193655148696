import React from 'react';
import { ViewPortfolios } from '../../../components/ui/elements/ViewPortfolios';
import { AppLayoutWithoutContainer } from '../../../components/ui/layouts/AppLayout';

export const PAECPrintPortfolios: React.VFC = () => {
    return (
        <AppLayoutWithoutContainer>
            <ViewPortfolios />
        </AppLayoutWithoutContainer>
    );
};
