import {ExternalResource} from "./ExternalResource";
import {AppType} from "../domain/AppType";
import {validateList} from "../domain/validateUnknown";
import {isDocumentList, Document, ReviewStatus} from "../domain/Document";
import {SortDirection} from "../components/ui/elements/Table";

export type DocumentSearchSort = { key: DocumentSearchSortKey, dir: SortDirection }[]

export type Search = {
    searchQuery?: string
    topicIds?: number[]
    reviewStatuses?: ReviewStatus[]
    authorId?: string
    assignedToId?: string
    approverId?: string
    divisionIds?: number[]
    dateFrom?: string
    dateTo?: string
    sort?: DocumentSearchSort
    limit?: number
    includeCulled?: boolean
    showAll?: boolean
}

export type ApiSearch = {
    searchQuery?: string
    topicIds?: number[]
    reviewStatuses?: ReviewStatus[]
    authorId?: string
    assignedToId?: string
    approverId?: string
    divisionIds?: number[]
    dateFrom?: string
    dateTo?: string
    sort?: string // json encoded string
    limit?: number
    includeCulled?: boolean
    showAll?: boolean
}

export async function searchDocuments(externalResource: ExternalResource, appType: AppType, search: Search): Promise<Document[]> {
    const { sort, ...rest } = search;

    const req: ApiSearch = { ...rest };
    if (sort) {
        req.sort = JSON.stringify({ sort: search.sort });
    }
    return externalResource.listAction(appType.Resources.searchDocuments, req).then(validateList(isDocumentList, "Document"))
}

export type DocumentSearchSortKey = 'Number' |
    'Title' |
    'Author' |
    'Assignee' |
    'Approver' |
    'Approved' |
    'Status' |
    'ReviewStatus' | undefined
