import React, {FC, useContext} from 'react';
import { DocumentUpdateAction } from '../services/resources';

export type Document = {
    documentHasUnsavedChanges: () => boolean;
    documentIsReadOnly: () => boolean;
    handleUpdate: (action: DocumentUpdateAction) => Promise<unknown>;
    testID: () => string | undefined;
}

export const emptyDocumentContext: Document = {
    documentHasUnsavedChanges: () => {return false;},
    documentIsReadOnly: () => {return false;},
    handleUpdate: (action) => {return Promise.reject('DocumentContext not set');},
    testID: () => {return 'test';}
};

export const DocumentContext = React.createContext<Document>(emptyDocumentContext);

export const DocumentContextProvider: FC<{value: Document}> = (props) => {
    return <DocumentContext.Provider {...props} />
}

export const useDocumentHasUnsavedChanges = () => useContext(DocumentContext).documentHasUnsavedChanges();
export const useDocumentIsReadOnly = () => useContext(DocumentContext).documentIsReadOnly();
export const useDocumentTestID = () => useContext(DocumentContext).testID();
export const useHandleUpdate = (action: DocumentUpdateAction) => useContext(DocumentContext).handleUpdate(action);
