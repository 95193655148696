import React from 'react';

import { useDivisionList } from '../../../hooks/useDivisionList';
import { SelectElement, SelectOption } from './SelectElement';
import { WithTestID } from './WithTestID';

export interface DivisionSelectProps extends WithTestID {
    value: number|undefined;
    onChange: (val: number|undefined) => void;
}

export const DivisionSelect: React.VFC<DivisionSelectProps> = ({ value, onChange, testID }) => {
    const {data, error} = useDivisionList();

    const options: SelectOption<number>[] = [];
    if (error) {
        options.push({ value: -1, label: 'Error fetching Division list', disabled: true });
    } else if (data) {
        options.push({ value: -1, label: 'Unfiltered' });
        data.forEach((division) => {
            options.push({ value: division.id, label: division.name });
        });
    } else {
        options.push({ value: -1, label: 'Loading...', disabled: true});
    }

    return (
        <SelectElement
            disabled={!!error || !data}
            options={options}
            value={value}
            onChange={(val) => {
                if (val <= 0) {
                    onChange(undefined);
                } else {
                    onChange(val);
                }
            }}
            testID={testID}
        />
    )
};
