import React from 'react';

import { Button, ButtonVariant } from '../elements/Button';
import Modal from '../elements/Modal';
import { WithTestID } from '../elements/WithTestID';

export interface DeleteConfirmationProps extends WithTestID {
    show: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    documentCount: number;
}

export const DeleteConfirmationModal: React.VFC<DeleteConfirmationProps> = ({show, onCancel, onSubmit, documentCount, testID}) => {

    return (
        <Modal show={show} onHide={onCancel} testID={testID}>
            <Modal.Body>
                <p>
                    Are you sure you want to delete{' '}
                    {documentCount === 1 ? `this document` : <>these <strong>{documentCount}</strong> documents</>}?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>No</Button>
                <Button onClick={onSubmit} variant={ButtonVariant.DANGER}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
};
