import React, {CSSProperties, useCallback, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import {Document, userCanEditDocument, userCanViewDocument} from "../Document";
import {LockIcon, lockMine} from "../../components/ui/elements/LockIcon";
import {AppType} from "../AppType";
import { Account } from "../Account";
import { useAuthenticatedUser } from "../../Context/AuthenticationContext";
import { useAppType } from "../../Context/ServiceContext";
import { DocumentID } from '../../services/ExternalResource';

import './DocumentView.scss';
import { assertNever } from "../../services/TypeUtils";

export interface DocumentViewProps {
    document: Document;
}

export const DocumentView: React.VFC<DocumentViewProps> = ({ document }) => {
    const userAccount = useAuthenticatedUser();
    const appType = useAppType();

    if (userCanViewDocument(document, userAccount, appType)) {
        return <DocumentLink document={document} />;
    } else {
        return <DocumentTitleNoLink document={document} />;
    }
};

const getDocumentLink = (document: Document, appType: AppType, editable: boolean) => {
    return `/app/${appType.appType}/${editable ? 'edit' : 'view'}/${document.id}`;
};

const DocumentTitleNoLink: React.VFC<DocumentViewProps> = ({ document }) => (
    <span className="ppq-title">{document.title}</span>
);

enum LockedBy {
    Me = 'Me',
    Other = 'Other',
    None = 'None',
}

const DocumentLink: React.VFC<DocumentViewProps> = ({ document }) => {
    const userAccount = useAuthenticatedUser();
    const appType = useAppType();
    const [locked, setLocked] = useState(isLocked(document));
    const timerRef = useRef<NodeJS.Timeout>();

    const checkLock = useCallback(() => {
        if (locked) {
            timerRef.current = undefined;
            const docIsLocked = isLocked(document);
            setLocked(docIsLocked);
            if (docIsLocked) {
                timerRef.current = setTimeout(checkLock, 5000);
            }
        }
    }, [document, locked]);

    useEffect(() => {
        checkLock();
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, [checkLock]);

    const editable = userCanEditDocument(document, userAccount, appType);
    const link = getDocumentLink(document, appType, editable);
    const lockedBy = getLockedBy(document, userAccount);

    const cn: string[] = ['ppq-link'];
    if (!editable) cn.push('read-only');
    if (lockedBy === LockedBy.Me) cn.push('locked-mine');

    return (
        <Link to={link} className={cn.join(' ')} data-testid={`editlink-${document.id}`}>
            <DocumentLockIcon id={document.id} lockedBy={lockedBy} />
            <DocumentTitleNoLink document={document} />
        </Link>
    )
};

const getLockedBy = (document: Document, userAccount: Account): LockedBy => {
    if (isLocked(document)) {
        if (isLockMine(document, userAccount)) {
            return LockedBy.Me;
        } else {
            return LockedBy.Other;
        }
    } else {
        return LockedBy.None;
    }
};

interface DocumentLockIconProps {
    id: DocumentID;
    lockedBy: LockedBy;
}

const DocumentLockStyle: CSSProperties = { marginRight: 4 };
const DocumentMyLockStyle: CSSProperties = { ...DocumentLockStyle, ...lockMine };

const DocumentLockIcon: React.VFC<DocumentLockIconProps> = ({ id, lockedBy }) => {
    switch (lockedBy) {
        case LockedBy.None:     return null;
        case LockedBy.Me:       return <LockIcon id={id} style={DocumentMyLockStyle} />;
        case LockedBy.Other:    return <LockIcon id={id} style={DocumentLockStyle} />;
        default:                return assertNever(lockedBy);
    }
};

const LOCK_EXPIRY_MILLIES = 30*1000;
function isLockActive(dateLocked: Date | null) {
    if (!dateLocked) return false;
    const lockExipry = dateLocked.getTime() + LOCK_EXPIRY_MILLIES
    const now = new Date().getTime()
    return now < lockExipry
}

function isLocked(document: Document) {
    return isLockActive(document.dateLocked);
}
function isLockMine(document: Document, userAccount: Account) {
    return document.lockedBy === userAccount.login;
}
