import { FC, useState } from "react";
import { Button, ButtonVariant } from "./Button";
import { Action } from "../../../domain/Actions";
import { WorkflowActionNoteModal } from "../modals/WorkflowActionNoteModal";
import { WorkflowSaveUnsavedChangesModal } from "../modals/WorkflowSaveUnsavedChangesModal";
import { useDocumentHasUnsavedChanges } from "../../../Context/DocumentContext";
import { useDocumentTestID } from "../../../Context/DocumentContext";


interface Props {
    action: Action;
    submit: (action: string, note: string) => void;
}

export const WorkflowActionButton: FC<Props> = ({action, submit}) => {
    const [showWorkflowModal, setShowWorkflowModal] = useState(false);
    const [showSaveUnsavedChanges, setShowSaveUnsavedChanges] = useState(false);
    const documentHasUnsavedChanges = useDocumentHasUnsavedChanges();
    const testID = useDocumentTestID();

    const onSubmit = () => {
        if (action.code === 'APPROVE' && action.role === 'ASSIGNEE') {
            submit(action.code, 'Approved');
        } else {
            setShowWorkflowModal(true);
        }
    };

    const {label, role} = action;

    const variant: ButtonVariant = role === "AUTHOR" ? ButtonVariant.PRIMARY: ButtonVariant.WARNING;

    const handleSaveOnContinue = () => {
        setShowSaveUnsavedChanges(false);
        onSubmit();
    }

    return <>
            <WorkflowSaveUnsavedChangesModal
                show={showSaveUnsavedChanges}
                testID={`${testID}:unsaved-changes-modal`}
                onContinue={handleSaveOnContinue}
                onCancel={() => {
                    setShowSaveUnsavedChanges(false);
                }}
            />

            <WorkflowActionNoteModal
                show={showWorkflowModal}
                onSubmit={(note: string) => {
                    submit(action.code, note);
                    setShowWorkflowModal(false);
                }}
                testID={`${testID}:workflow-action-note-modal`}
                onCancel={() => {
                    setShowWorkflowModal(false);
                }}
            />

            <Button
                variant={variant}
                testID={`${testID}:${action.code}`}
                onClick={() => {
                    (documentHasUnsavedChanges) ? setShowSaveUnsavedChanges(true) : onSubmit();
                }}
            >
                {label}
            </Button>
        </>;
}

