import React from 'react';
import { Col, Row } from 'react-bootstrap';

export interface MultiColumnRowProps {
    columns?: JSX.Element[];
}

export const MultiColumnRow: React.VFC<MultiColumnRowProps> = ({ columns = [] }) => {
    return (
        <Row>
            {columns.map((col, idx) => <Col className="col-3" key={`col-${idx}`}>{col}</Col>)}
        </Row>
    );
};
