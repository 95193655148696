import {Unknown} from "./TypeUtils";
import {appType} from "../domain/AppType";

export enum resource {
    Document = 'Document',
    DocumentHistory = 'DocumentHistory',
    User = 'User',
    Account = 'Account',
    Workflow = 'Workflow',
    Login = 'Login',
    Logout = 'Logout',
    SystemBasics = 'SystemBasics',
    Portfolio = 'Portfolio',
    Division = 'Division',
    Feature = 'Feature',
    Translations = 'Translations',
    Template = 'Template',
    Topic = 'Topic',
    UserEmail = 'UserEmail',
}
export interface Resource {
    resource: resource
    appType?: appType}
export enum SingleActions {
    Create = 'Create',
    Send = 'Send',
    Update = 'Update',
    Delete = 'Delete',
    Login = 'Login',
    Logout = 'Logout',
    Read = 'Read',
    ReadLatest = 'ReadLatest',
    ObtainLock = 'ObtainLock',
    PreviewPDF = 'PreviewPDF',
    AdminAlter = 'AdminAlter',
    GetCurrentNote = 'GetCurrentNote',
    ClearChanges = 'ClearChanges',
    Renumber = 'Renumber',
    Cull = 'Cull',
    Uncull = 'Uncull',
    Move = 'Move',
    MarkPendingReview = 'MarkPendingReview',
    MarkReadyForPrint = 'MarkReadyForPrint',
    MarkApproved = 'MarkApproved',
    MarkWithDTPCoordinator = 'MarkWithDTPCoordinator',
    MarkWithMinister = 'MarkWithMinister',
    MarkNoteSeen = 'MarkNoteSeen',
    RequestAccess = 'RequestAccess',
    CreateExtract = 'CreateExtract',
    RequestPasswordReset = 'RequestPasswordReset',
    SubmitPasswordActivate = 'SubmitPasswordActivate',
    SubmitPasswordReset = 'SubmitPasswordReset',
    CountDocuments = 'CountDocuments',
}
export interface sendDataSingle<SEND, RECV> extends Resource {
    action: SingleActions
    sendType: SEND
   receiveType: Unknown<RECV>
}
export enum ListActions {
    Search = 'Search',
    List = 'List',
    PartialList = 'PartialList',
    ListSelectedInOrder = 'ListSelectedInOrder',
    ListSelectedInOrderByPortfolios = 'ListSelectedInOrderByPortfolios',
    StatusReport = 'StatusReport',
    ListWithDisabled = 'ListWithDisabled',
}

export interface sendDataList<SEND, RECV> extends Resource {
    action: ListActions
    sendType: SEND
    receiveType: Unknown<RECV>[]
}


export function CreateResource<SEND, RECV>(resource: Omit<SingleResource<SEND, RECV>, 'sendType' | 'receiveType'>) {
    return {
        ...resource,
        sendType: undefined as unknown as SEND,
        receiveType: undefined as unknown as Unknown<RECV>
    } as sendDataSingle<SEND, RECV>
}

export function CreateListResource<SEND, RECV>(resource: Omit<ListResource<SEND, RECV>, 'sendType' | 'receiveType'>) {
    return {
        ...resource,
        sendType: undefined as unknown as SEND,
        receiveType: undefined as unknown as Unknown<RECV>[]
    } as sendDataList<SEND, RECV>
}

export type SingleResource<SEND, RECV> = sendDataSingle<SEND, RECV>

export type ListResource<SEND, RECV> = sendDataList<SEND, RECV>

export interface ExternalResource {
    action<SEND, RECV, R extends SingleResource<SEND, RECV>>(resource: R, data: SEND): Promise<R["receiveType"]>
    listAction<SEND, RECV, R extends ListResource<SEND, RECV>>(resource: R, data: SEND): Promise<R["receiveType"]>
}

type LocationPath = string
export type GetLocation = () => LocationPath

export type DocumentID = number
export type GetCurrentDocumentID = () => DocumentID
