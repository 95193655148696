import {useContext, useState, VFC} from "react";
import {AuthenticationContext, useAuthenticatedUser} from "../../../Context/AuthenticationContext";
import { useExternalResource } from "../../../Context/ServiceContext";
import { Account, AppRole, canCreateNewBrief, isSysAdmin, userHasAppRole } from "../../../domain/Account";
import { AppType } from "../../../domain/AppType";
import { DefaultAuthentication } from "../../../domain/Authentication";
import { convertErrorToErrorWithDetails } from "../../../hooks/exceptionToError";
import { useAppTypeWithFallback } from "../../../hooks/useAppTypeWithFallback";
import { useRouter } from "../../../hooks/useRouter";
import { createExtract } from "../../../services/createExtract";
import { notificationService } from "../../../services/notificationService";
import { submitLogout } from "../../../services/submitLogout";
import { DropdownItemProps, NavLayout, NavLinkProps } from "../layouts/NavLayout";
import { ExtractDataModal } from "../modals/ExtractDataModal";
import { IconType } from "./Icon";
import { LoadingOverlay } from "./LoadingOverlay";
import { WithTestID } from "./WithTestID";

const appLink = (appType: AppType, link: string) => `/app/${appType.appType}${link}`;

const getNavLinks = (appType: AppType, userAccount: Account): NavLinkProps[] => {
    const userHasAccess = !!appType.GetAppRole(userAccount);
    const userHasCreateAccess = canCreateNewBrief(userAccount, appType);

    return [
        { label: 'Dashboard', link: appLink(appType, ''), icon: IconType.Dashboard, access: userHasAccess },
        { label: `New ${appType.appType}`, link: appLink(appType, '/edit'), icon: IconType.Document, access: userHasCreateAccess },
        { label: 'Print Portfolios', link: appLink(appType, '/printPortfolios'), icon: IconType.Portfolio, access: userHasAccess && appType.CanPrintPortfolios },
        { label: 'Report', link: appLink(appType, '/report/status'), icon: IconType.Spreadsheet, access: userHasAccess },
    ];
};

const getDropDownItems = (
    appType: AppType,
    userAccount: Account,
    onExtract: (culled: boolean) => void,
    onLogout: () => void,
): DropdownItemProps[] => {
    const userIsAdmin = userHasAppRole(userAccount, appType, AppRole.Admin);
    const userIsSysAdmin = isSysAdmin(userAccount);

    return [
        { label: 'Manage Divisions', icon: IconType.Division, type: 'link', link: appLink(appType, '/admin/division'), access: userIsSysAdmin },
        { label: 'Manage Portfolios', icon: IconType.Portfolio, type: 'link', link: appLink(appType, '/admin/portfolios'), access: userIsSysAdmin },
        { label: 'Manage Users', icon: IconType.Users, type: 'link', link: appLink(appType, '/admin/user'), access: userIsSysAdmin },
        { label: 'Manage Templates', icon: IconType.Document, type: 'link', link: appLink(appType, '/admin/templates'), access: userIsAdmin },
        { label: "My Details", icon: IconType.SingleUser, type: "link", link: appLink(appType, "/settings")},//TODO: Can not make this a straight /#/settings url like on uat
        { label: 'Data Extract', icon: IconType.Spreadsheet, type: 'callback', onClick: () => onExtract(false) },
        { label: 'Data Extract (Culled)', icon: IconType.Spreadsheet, type: 'callback', onClick: () => onExtract(true), access: userIsSysAdmin },
        { type: 'divider' },
        { label: 'Logout', icon: IconType.Logout, type: 'callback', onClick: onLogout },
    ];
};

const AppNavWithAuthenticatedUser: VFC<WithTestID> = ({ testID }) => {
    const user = useAuthenticatedUser();
    const appType = useAppTypeWithFallback();
    const externalResource = useExternalResource();
    const auth = useContext(AuthenticationContext);

    const [loggingOut, setLoggingOut] = useState(false);
    const { gotoOrReload } = useRouter();

    const [showExtract, setShowExtract] = useState(false);
    const [extractCulled, setExtractCulled] = useState(false);

    if (!appType) return null;

    const logout = async () => {
        setLoggingOut(true);
        await submitLogout(externalResource);
        auth.setAuth(DefaultAuthentication());
        // redirect back to login screen
        gotoOrReload('/login');
    };

    const extract = (culled: boolean) => {
        setExtractCulled(culled);
        setShowExtract(true);
    };

    const doExtract = async () => {
        setShowExtract(false);
        try {
            const culled = extractCulled;
            await createExtract(externalResource, appType, culled);
            notificationService.showSuccessNotification(`Data extract request ${culled ? '(culled) ' : ''}completed`);
        } catch (err) {
            const error = convertErrorToErrorWithDetails(err as Error);
            notificationService.showErrorNotification(error.message);
        }
    };

    return (
        <>
            <NavLayout
                links={getNavLinks(appType, user)}
                dropDownTitle={user.login}
                dropDownItems={getDropDownItems(appType, user, extract, logout)}
                testID={testID}
            />
            <LoadingOverlay show={loggingOut} message="Please wait..." />
            <ExtractDataModal
                show={showExtract}
                onHide={() => setShowExtract(false)}
                culled={extractCulled}
                onSubmit={doExtract}
                testID={testID ? `${testID}:extract` : undefined}
            />
        </>
    );
};

// This shows a navigation bar that only includes the user dropdown and logout link.
const AppNavWithAuthenticatedUserLogoutOnly: VFC<WithTestID> = ({ testID }) => {
    const user = useAuthenticatedUser();
    const externalResource = useExternalResource();
    const auth = useContext(AuthenticationContext);

    const [loggingOut, setLoggingOut] = useState(false);
    const { gotoOrReload } = useRouter();

    const logout = async () => {
        setLoggingOut(true);
        await submitLogout(externalResource);
        auth.setAuth(DefaultAuthentication());
        // redirect back to login screen
        gotoOrReload('/login');
    };

    return (
        <>
            <NavLayout
                links={[]}
                dropDownTitle={user.login}
                dropDownItems={[{ label: 'Logout', icon: IconType.Logout, type: 'callback', onClick: logout }]}
                logoutOnly
                testID={testID}
            />
            <LoadingOverlay show={loggingOut} message="Please wait..." />
        </>
    );
};

export const AppNav: VFC<WithTestID> = ({ testID }) => {
    const { auth } = useContext(AuthenticationContext);
    const theAppType = useAppTypeWithFallback();

    if (auth.userAccount) {
        if (theAppType) {
            return <AppNavWithAuthenticatedUser testID={testID} />;
        } else {
            // User is logged in but has access to no app types.
            // This shouldn't happen, but just in case, render a nav bar with logout option only.
            return <AppNavWithAuthenticatedUserLogoutOnly testID={testID} />;
        }
    } else {
        return null;
    }
};

// This shows a navigation bar with only the logout link if the user is authenticated.
export const AppNavWithLogoutOnly: VFC<WithTestID> = ({ testID }) => {
    const { auth } = useContext(AuthenticationContext);
    if (auth.userAccount) {
        return <AppNavWithAuthenticatedUserLogoutOnly testID={testID} />;
    } else {
        return null;
    }
};
