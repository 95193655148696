import React, { useState } from 'react';
import { useAuthenticatedUser } from '../../../Context/AuthenticationContext';
import { useAppType } from '../../../Context/ServiceContext';
import { AppRole, userHasAppRole } from '../../../domain/Account';
import { Document, getDocumentStatusLabel, isAtLeastApproved } from '../../../domain/Document';
import { DocumentLock } from '../../../domain/DocumentLock';
import { useDocumentReadOnly } from '../../../hooks/useDocumentReadOnly';
import { formatDateTimeString } from '../../../services/DateTime';
import { DocumentUpdateAction } from '../../../services/resources';
import { ButtonVariant } from '../elements/Button';
import { PanelSection, PanelSectionWithButton } from '../elements/PanelSection';
import { ReviewStatusLabel } from '../elements/ReviewStatusLabel';
import { AlterDocumentDetailsModal } from '../modals/AlterDocumentDetailsModal';
import { useDocumentTestID } from "../../../Context/DocumentContext";

import './EditStatusSection.scss';
import {WorkflowActions} from "./WorkflowActions";
import {ReassignAction} from "./ReassignAction";

export interface EditStatusSectionProps {
    document: Document;
    lock: DocumentLock|undefined;
    handleUpdate: (action: DocumentUpdateAction) => Promise<unknown>;
    onAlter: (values: Partial<Document>) => void;
}

const EditStatusContent: React.VFC<Omit<EditStatusSectionProps, 'onAlter'>> = ({ document, lock, handleUpdate }) => {
    const testID = useDocumentTestID();

    return (
        <>
            {document.topic && (
                <div className="status-item">
                    <label>Number</label>
                    <span>
                        {document.formattedNumber}
                    </span>
                </div>
            )}
            <div className="status-item">
                <label>Status</label>
                <span>
                    <ReviewStatusLabel reviewStatus={document.reviewStatus} />
                </span>
            </div>
            <div className="status-item">
                <label>Stage</label>
                <span>{getDocumentStatusLabel(document.status)}</span>
            </div>
            {document.dateCreated && (
                <div className="status-item">
                    <label>Created</label>
                    <span>{formatDateTimeString(document.dateCreated)}</span>
                </div>
            )}
            {document.dateModified && (
                <div className="status-item">
                    <label>Updated</label>
                    <span>{formatDateTimeString(document.dateModified)}</span>
                </div>
            )}
            {document.dateLastSignificantChange && isAtLeastApproved(document) && (
                <div className="status-item">
                    <label>Approved</label>
                    <span>{formatDateTimeString(document.dateLastSignificantChange)}</span>
                </div>
            )}
            {document.id && (
                <WorkflowActions testID={testID} documentID={`${document.id}`} />
            )}
            <ReassignAction document={document} lock={lock} handleUpdate={handleUpdate} />
        </>
    );
};

export const EditStatusSection: React.VFC<EditStatusSectionProps> = ({ document, lock, handleUpdate, onAlter }) => {
    const user = useAuthenticatedUser();
    const appType = useAppType();
    const isAdminUser = userHasAppRole(user, appType, AppRole.Admin);
    const readOnly = useDocumentReadOnly(document, lock);
    const [showAlterModal, setShowAlterModal] = useState(false);

    const content = <EditStatusContent document={document} lock={lock} handleUpdate={handleUpdate} />;

    if (isAdminUser && !!document.id && !readOnly) {
        return (
            <PanelSectionWithButton
                header="Status"
                buttonContent="Alter"
                buttonProps={{
                    variant: ButtonVariant.PRIMARY,
                    onClick: () => setShowAlterModal(true),
                }}
            >
                {content}
                <AlterDocumentDetailsModal
                    document={document}
                    show={showAlterModal}
                    onCancel={() => setShowAlterModal(false)}
                    onSave={(values) => {
                        setShowAlterModal(false);
                        onAlter(values);
                    }}
                />
            </PanelSectionWithButton>
        );
    } else {
        return (
            <PanelSection header="Status">
                {content}
            </PanelSection>
        );
    }

};
