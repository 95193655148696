import React from "react";
import { Dropdown } from "react-bootstrap";
import { useAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { useAppType } from "../../../Context/ServiceContext";
import { AppRole, canEditBriefs, canMarkWithMinister, canViewDocument, userHasAppRole } from "../../../domain/Account";
import { RightContainer } from "../layouts/RightContainer";
import { Icon, IconStack, IconType } from "./Icon";
import { SelectElement } from "./SelectElement";

import './DashboardActions.scss';
import { Document, ReviewStatus } from "../../../domain/Document";
import { ReviewStatusLabel } from "./ReviewStatusLabel";
import { useFeatures } from "../../../hooks/useFeatures";
import { IconButton } from "./Button";

export interface DashboardActionsProps {
    limit: number;
    setLimit: (arg: number) => void;
    handleAction: (action: DashboardActionType) => void;
    selectedDocuments: Document[];
}

export enum DashboardActionType {
    ClearChanges = 'ClearChanges',
    Renumber = 'Renumber',
    Archive = 'Archive',
    Cull = 'Cull',
    Uncull = 'Uncull',
    Move = 'Move',
    Delete = 'Delete',
    Print = 'Print',
    MarkAsPendingReview = 'MarkAsPendingReview',
    MarkAsWithDTPCoordinator = 'MarkAsWithDTPCoordinator',
    MarkAsApproved = 'MarkAsApproved',
    MarkAsReadyForPrint = 'MarkAsReadyForPrint',
    MarkAsWithMinister = 'MarkAsWithMinister',
}

export const DashboardActions: React.VFC<DashboardActionsProps> = ({ limit, setLimit, handleAction, selectedDocuments }) => {

    const limitOptions = [
        { value: 200, label: 'Show 200' },
        { value: 300, label: 'Show 300' },
        { value: 500, label: 'Show 500' },
        { value: 0, label: 'Show All' },
    ];

    return (
        <RightContainer>
            <div className="mb-3">
                <SelectElement
                    value={limit}
                    options={limitOptions}
                    onChange={setLimit}
                    testID="dash-limit"
                />
            </div>
            <ActionDropdown onAction={handleAction} anyDocumentsSelected={selectedDocuments.length > 0} />
            <PrintButton onAction={handleAction} selectedDocuments={selectedDocuments} />
            <MarkAsDropdown onAction={handleAction} selectedDocuments={selectedDocuments} />
        </RightContainer>
    );

};

interface ActionDropdownProps {
    onAction: (action: DashboardActionType) => void;
    anyDocumentsSelected: boolean;
}
const ActionDropdown: React.VFC<ActionDropdownProps> = ({ onAction, anyDocumentsSelected }) => {
    const appType = useAppType();
    const currentUser = useAuthenticatedUser();
    const isAdmin = userHasAppRole(currentUser, appType, AppRole.Admin);

    if (!isAdmin) return null;

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary" disabled={!anyDocumentsSelected}>
                <Icon icon={IconType.Settings} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.ClearChanges)}>
                    <Icon icon={IconType.Clear} className="action-icon" />
                    Clear changes
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Renumber)}>
                    <Icon icon={IconType.NumberList} className="action-icon" />
                    Auto-number
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Archive)}>
                    <Icon icon={IconType.Archive} className="action-icon" />
                    Archive
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Cull)}>
                    <Icon icon={IconType.Cull} className="action-icon" />
                    Cull
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Uncull)}>
                    <IconStack className="action-icon">
                        <Icon icon={IconType.Cull} stack="1x" />
                        <Icon icon={IconType.Cancel} stack="2x" danger />
                    </IconStack>
                    Un-cull
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Move)}>
                    <Icon icon={IconType.Move} className="action-icon" />
                    Move
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => onAction(DashboardActionType.Delete)}>
                    <IconStack className="action-icon">
                        <Icon icon={IconType.Square} stack="2x" danger />
                        <Icon icon={IconType.Warning} stack="1x" inverse />
                    </IconStack>
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};


interface MarkAsDropdownProps {
    onAction: (action: DashboardActionType) => void;
    selectedDocuments: Document[];
}

const MarkAsDropdown: React.VFC<MarkAsDropdownProps> = ({ onAction, selectedDocuments }) => {
    const userAccount = useAuthenticatedUser();
    const appType = useAppType();
    const { data: features } = useFeatures();

    if (!features) return null;
    if (!canEditBriefs(userAccount, appType)) return null;
    if (!appType.UserCanMarkAsPendingReview(userAccount)) return null;

    let disabled = selectedDocuments.length === 0
        || !appType.UserCanChangeDocumentsStatus(userAccount, selectedDocuments);

    const isAdmin = userHasAppRole(userAccount, appType, AppRole.Admin);
    const canMarkApproved = isAdmin && appType.CanMarkDocumentsAs(ReviewStatus.Approved, features);
    const canMarkReadyForPrint = isAdmin && appType.CanMarkDocumentsAs(ReviewStatus.ReadyForPrint, features);
    const canMarkAsWithMinister = canMarkWithMinister(userAccount);
    const canMarkWithDTPCoordinator = appType.EnabledDTPCoordinator(features);

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary" disabled={disabled}>
                Mark As...
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => onAction(DashboardActionType.MarkAsPendingReview)}>
                    <ReviewStatusLabel reviewStatus={ReviewStatus.PendingReview} />
                </Dropdown.Item>
                {canMarkApproved && (
                    <Dropdown.Item onClick={() => onAction(DashboardActionType.MarkAsApproved)}>
                        <ReviewStatusLabel reviewStatus={ReviewStatus.Approved} />
                    </Dropdown.Item>
                )}
                {canMarkReadyForPrint && (
                    <Dropdown.Item onClick={() => onAction(DashboardActionType.MarkAsReadyForPrint)}>
                        <ReviewStatusLabel reviewStatus={ReviewStatus.ReadyForPrint} />
                    </Dropdown.Item>
                )}
                {canMarkWithDTPCoordinator && (
                    <Dropdown.Item onClick={() => onAction(DashboardActionType.MarkAsWithDTPCoordinator)}>
                        <ReviewStatusLabel reviewStatus={ReviewStatus.WithDtpCoordinator} />
                    </Dropdown.Item>
                )}
                {canMarkAsWithMinister && (
                    <Dropdown.Item onClick={() => onAction(DashboardActionType.MarkAsWithMinister)}>
                        <ReviewStatusLabel reviewStatus={ReviewStatus.WithMinister} />
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

interface PrintButtonProps {
    onAction: (action: DashboardActionType) => void;
    selectedDocuments: Document[];
}

const PrintButton: React.VFC<PrintButtonProps> = ({ onAction, selectedDocuments }) => {

    const currentUser = useAuthenticatedUser();
    const appType = useAppType();
    const canPrint = selectedDocuments.some(doc => canViewDocument(currentUser, appType, doc));

    return (
        <div>
            <IconButton
                onClick={() => onAction(DashboardActionType.Print)}
                icon={IconType.Print}
                disabled={!canPrint}
            >Print</IconButton>
        </div>
    );
};
