import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { CheckboxSwitch } from "../../ui/elements/CheckboxSwitch";
import { FormField } from "../../ui/elements/FormField";
import { validateBoolean } from "./FieldValidation";
import { StandardFieldProps } from "./TextField";

export const CheckboxSwitchField: React.VFC<StandardFieldProps> = ({
    id,
    disabled,
    help,
    required,
    validationRules = [],
    testID,
}) => {
    const formik = useFormikContext();

    return (
        <Field
            name={id}
            validate={(value: boolean) => {

                for (let i = 0; i < validationRules.length; i++) {
                    const result = validateBoolean(validationRules[i], value, formik.values);
                    if (result !== undefined) {
                        return result;
                    }
                }

                if (required && !value) {
                    return "Required";
                }
            }}
        >
            {({ field, form }: FieldProps<boolean, any>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        help={help}
                        id={id}
                        error={touched ? error : undefined}
                        hasMarginBottom={false}
                        control={
                            <CheckboxSwitch
                                disabled={disabled}
                                id={id}
                                checked={field.value}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                testID={testID}
                                isInvalid={touched && !!error}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
