import React, { useState } from 'react';

import { Document } from '../../../domain/Document';
import { DateTimeFormat, formatDateTimeString, parseDateTimeString } from '../../../services/DateTime';
import { Button, ButtonVariant } from '../elements/Button';
import { FormField } from '../elements/FormField';
import Modal from '../elements/Modal';
import { TextInputElement } from '../elements/TextInputElement';
import { WithTestID } from '../elements/WithTestID';

export interface AlterDocumentDetailsModalProps extends WithTestID {
    document: Document;
    show: boolean;
    onSave: (values: Partial<Document>) => void;
    onCancel: () => void;
}

const parseDocNumber = (str: string): number|undefined => {
    let trimmed = str.trim();
    if (trimmed === '') return undefined;
    if (trimmed.match(/[^\d]/)) return undefined;
    return Number(trimmed);
};

const parseDate = (str: string): Date|undefined => {
    let trimmed = str.trim();
    if (trimmed === '') return undefined;
    return parseDateTimeString(trimmed, DateTimeFormat.ISODate);
};

export const AlterDocumentDetailsModal: React.VFC<AlterDocumentDetailsModalProps> = ({ document, show, onSave, onCancel, testID }) => {
    const [docNumber, setDocNumber] = useState(`${document.number || ''}`);
    const [created, setCreated] = useState(formatDateTimeString(document.dateCreated, DateTimeFormat.ISODate));
    const [docNumberShowError, setDocNumberShowError] = useState(false);
    const [createdShowError, setCreatedShowError] = useState(false);

    const parsedDocNbr = parseDocNumber(docNumber);
    const docNumberError = docNumber === ''
        ? 'This field is required'
        : (parsedDocNbr === undefined ? 'This field should be a number' : undefined);

    const parsedDate = parseDate(created);
    const createdError = parsedDate === undefined ? 'This field is required' : undefined;

    return (
        <Modal show={show} onHide={onCancel} testID={testID}>
            <Modal.Header>Alter Document</Modal.Header>
            <Modal.Body>
                <FormField
                    id="docNumber"
                    label="Number"
                    error={docNumberShowError ? docNumberError : undefined}
                    control={
                        <TextInputElement
                            value={docNumber}
                            onChange={setDocNumber}
                            onBlur={() => setDocNumberShowError(true)}
                            required
                            testID={testID ? `${testID}:number` : undefined}
                            isInvalid={!!docNumberError && docNumberShowError}

                        />
                    }
                />
                <FormField
                    id="created"
                    label="Date Created"
                    error={createdError}
                    control={
                        <TextInputElement
                            type="date"
                            value={created}
                            onChange={setCreated}
                            onBlur={() => setCreatedShowError(true)}
                            required
                            testID={testID ? `${testID}:created` : undefined}
                            isInvalid={!!createdError && createdShowError}
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    onClick={() => {
                        onSave({
                            number: parsedDocNbr,
                            dateCreated: parsedDate,
                        });
                    }}
                    disabled={!parsedDocNbr || !parsedDate}
                    variant={ButtonVariant.PRIMARY}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    );
};
