import {
    creator,
    assignee,
    dateLastSignificantChange,
    formattedNumber,
    title,
    status,
    reviewStatus,
    DocumentColumn,
    execApprover1,
    execApprover2
} from "./Dashboard/columns";
import {AppTypeResources, CreateAppTypeResources} from "../services/resources";
import { Account, AppRole, canEditBriefs, canMarkWithMinister, isMinister, userHasAppRole, isAppRole } from "./Account";
import { Feature, FeatureType, isFeatureEnabled } from "./Feature";
import { EditField, EditFieldType } from "../components/form/forms/EditForm";
import { getTranslation, Translations } from "./Translations";
import { Document, DocumentDetails, isAtLeastApproved, isAtLeastReviewByCentralTeam, ReviewStatus } from "./Document";
import { EndorsementDisplayType } from "../components/ui/elements/ViewDocumentDetails";
import { ExportFormat } from "./ExportFormat";
import { DocumentHistory } from "./DocumentHistory";
import { assertNever } from "../services/TypeUtils";
import { fieldRequired } from "../components/form/fields/FieldValidation";
import { User } from "./User";

interface AssigneeRemovalRule {
    status: ReviewStatus;
    roles: string[];
}

export interface AppType {
    readonly appType: appType;
    readonly Resources: AppTypeResources
    readonly CanPrintPortfolios: boolean;
    readonly BriefHashtagsSectionEnabled: boolean;
    readonly BriefApproverRequired: boolean;
    readonly RequestApprovalLabel: string;

    GetDashboardColumns(account: Account): DocumentColumn[];
    GetAppRole(account: Account): AppRole|undefined;
    GetAppRoleForUser(user: User): AppRole|undefined;
    MinistersCanShowAllDocuments: boolean;
    ApproverWorkflowEnabled(features: Feature[]): boolean;
    UserCanSeeUserDetails(account: Account): boolean;
    UserCanMarkAsPendingReview(account: Account): boolean;
    UserCanChangeDocumentsStatus(account: Account, documents: Document[]): boolean;

    BriefDivisionEnabled(features: Feature[]): boolean;
    BriefQuestionSectionEnabled(features: Feature[]): boolean;
    BriefReferenceSectionEnabled(features: Feature[]): boolean;
    BriefTableSectionEnabled(features: Feature[]): boolean;
    BriefCommitmentsSectionEnabled(features: Feature[]): boolean;
    BriefApproverEnabled(features: Feature[]): boolean;
    BriefBoardApproverEnabled(features: Feature[]): boolean;
    GetEditFields(features: Feature[], translations: Translations): EditField[];
    DisplayDocumentEditionDate(document: DocumentDetails): boolean;
    ShortDocumentNumber(features: Feature[]): boolean;
    EndorsementDisplay(features: Feature[]): EndorsementDisplayType;
    CanMarkDocumentsAs(status: ReviewStatus, features: Feature[]): boolean;
    EnabledDTPCoordinator(features: Feature[]): boolean;


    GetDocumentExportUrl(document: Document, format: ExportFormat): string;
    GetDocumentHistoryExportUrl(history: DocumentHistory, format: ExportFormat): string;

    GetAppRoles(features: Feature[] | undefined): {key: string, name: string}[];
    GetUserDropdownFilter(): { [key: string]: AppRole };
    GetAssigneeRemovalRules(): AssigneeRemovalRule[];
}

const DashboardAllColumns: DocumentColumn[] = [
    formattedNumber,
    title,
    creator,
    assignee,
    execApprover1,
    execApprover2,
    dateLastSignificantChange,
    status,
    reviewStatus
];

const DashboardRestrictedColumns: DocumentColumn[] = [
    formattedNumber,
    title,
    execApprover1,
    execApprover2,
    dateLastSignificantChange,
    status,
    reviewStatus
];

export enum appType {
    PPQ = "PPQ",
    PAEC = "PAEC"
}

class AppTypePPQ implements AppType {
    readonly appType = appType.PPQ;
    readonly Resources: AppTypeResources
    readonly MinistersCanShowAllDocuments = true;
    readonly CanPrintPortfolios = false;
    readonly BriefHashtagsSectionEnabled = false;
    readonly BriefApproverRequired = false;
    readonly RequestApprovalLabel = 'Request Approval from Executive Approver';

    constructor() {
        this.Resources = CreateAppTypeResources(appType.PPQ);
    }

    GetDashboardColumns(account: Account): DocumentColumn[] {
        const canSeeUserDetails = !isMinister(account, this);
        return canSeeUserDetails ? DashboardAllColumns : DashboardRestrictedColumns;
    }

    GetAppRole(account: Account): AppRole|undefined {
        return account.appRoles.PPQ;
    }

    GetAppRoleForUser(user: User): AppRole|undefined {
        if (isAppRole(user.ppqRole)) {
            return user.ppqRole;
         }
         return undefined;
    }

    ApproverWorkflowEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_APPROVER);
    }

    UserCanSeeUserDetails(account: Account): boolean {
        const role = this.GetAppRole(account);
        return !!role && role !== AppRole.Minister;
    }

    UserCanMarkAsPendingReview(account: Account): boolean {
        return canEditBriefs(account, this);
    }

    UserCanChangeDocumentsStatus(account: Account, documents: Document[]): boolean {
        return true;
    }

    BriefDivisionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_BRIEF_USE_DIVISION);
    }

    BriefQuestionSectionEnabled(features: Feature[]): boolean {
        return false;
    }

    BriefReferenceSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_REFERENCE_SECTION);
    }

    BriefTableSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_TABLE_SECTION);
    }

    BriefCommitmentsSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_COMMITMENTS_SECTION);
    }

    BriefApproverEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_1_APPROVER)
            || isFeatureEnabled(features, FeatureType.PPQ_2_APPROVERS);
    }

    BriefBoardApproverEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PPQ_2_APPROVERS);
    }

    GetEditFields(features: Feature[], translations: Translations): EditField[] {
        const fields: EditField[] = [];
        if (this.BriefDivisionEnabled(features)) {
            fields.push({
                id: 'division.id',
                label: 'Division',
                type: EditFieldType.Division,
                validationRules: [
                    fieldRequired('Please select a Division'),
                ],
            });
        }
        fields.push({
            id: 'topic.id',
            label: 'Portfolio',
            type: EditFieldType.Topic,
            validationRules: [
                fieldRequired('Please select a Portfolio'),
            ],
        });
        fields.push({
            id: 'title',
            label: getTranslation(translations, 'ppqApp.document.title.PPQ.label'),
            type: EditFieldType.Text,
            validationRules: [
                fieldRequired(getTranslation(translations, 'ppqApp.document.title.PPQ.error')),
            ],
        });
        if (this.BriefReferenceSectionEnabled(features)) {
            fields.push({
                id: 'reference',
                label: getTranslation(translations, 'ppqApp.document.reference.PPQ.label'),
                type: EditFieldType.RichText,
            });
        }
        if (this.BriefTableSectionEnabled(features)) {
            fields.push({
                id: 'tableData',
                label: getTranslation(translations, 'ppqApp.document.table.PPQ.label'),
                type: EditFieldType.RichText,
            });
        }
        fields.push({
            id: 'talkingPoints',
            label: getTranslation(translations, 'ppqApp.document.talkingPoints.PPQ.label'),
            type: EditFieldType.RichText,
            help: getTranslation(translations, 'ppqApp.document.talkingPoints.PPQ.tooltip'),
        });
        fields.push({
            id: 'background',
            label: getTranslation(translations, 'ppqApp.document.background.PPQ.label'),
            type: EditFieldType.RichText,
            help: getTranslation(translations, 'ppqApp.document.background.PPQ.tooltip'),
        });
        if (this.BriefCommitmentsSectionEnabled(features)) {
            fields.push({
                id: 'commitments',
                label: getTranslation(translations, 'ppqApp.document.commitments.PPQ.label'),
                type: EditFieldType.RichText,
            });
        }
        fields.push({
            id: 'creator',
            label: getTranslation(translations, 'ppqApp.document.creator'),
            type: EditFieldType.User,
        });
        return fields;
    }

    DisplayDocumentEditionDate(document: DocumentDetails): boolean {
        return true;
    }

    ShortDocumentNumber(features: Feature[]): boolean {
        return false;
    }

    EndorsementDisplay(features: Feature[]): EndorsementDisplayType {
        return EndorsementDisplayType.Approver;
    }

    CanMarkDocumentsAs(status: ReviewStatus, features: Feature[]): boolean {
        if (status === ReviewStatus.PendingReview || status === ReviewStatus.WithMinister) return true;
        if (status === ReviewStatus.Approved || status === ReviewStatus.ReadyForPrint) {
            return isFeatureEnabled(features, FeatureType.PPQ_APPROVER);
        }
        return false;
    }

    EnabledDTPCoordinator(features: Feature[]): boolean {
            return false;
        }

    GetDocumentExportUrl(document: Document, format: ExportFormat): string {
        switch (format) {
            case 'PDF':
                return `/pdf/PPQ/${document.id}/ppq_document.pdf`;
            case 'DOCX':
                return `/docx/PPQ/${document.id}/ppq_document.docx`;
            default:
                return assertNever(format);
        }
    }

    GetDocumentHistoryExportUrl(history: DocumentHistory, format: ExportFormat): string {
        switch (format) {
            case 'PDF':
                return `/pdf/PPQ/history/${history.id}/ppq_document.pdf`;
            case 'DOCX':
                return `/docx/PPQ/history/${history.id}/ppq_document.docx`;
            default:
                return assertNever(format);
        }
    }

    GetAppRoles(features: Feature[] | undefined): {key: string, name: string}[] {
        return [
            { key: AppRole.Admin, name: 'Administrator' },
            { key: AppRole.Approver, name: 'Approver' },
            { key: AppRole.Author, name: 'Author' },
            { key: AppRole.Minister, name: 'Minister' },
        ];
    }

    GetUserDropdownFilter(): { [key: string]: AppRole } {
        // Filters by the global 'role' of the users shown in the dropdown - e.g. APPROVER_1 = only users with the AppRole.Approver
        const userDropdownFilterRules: { [key: string]: AppRole } = {
            "APPROVER_1": AppRole.Approver,
            "APPROVER_2": AppRole.Approver,
            "APPROVER_3": AppRole.Approver,
        };
        return userDropdownFilterRules;
    }

    GetAssigneeRemovalRules(): AssigneeRemovalRule[] {
        const assigneeRemovalRules: AssigneeRemovalRule[] = [
            { status: ReviewStatus.PendingReview, roles: ['REVIEWER_1', 'REVIEWER_2'] },
            { status: ReviewStatus.PendingApproval, roles: ['APPROVER_1', 'APPROVER_2', 'APPROVER_3'] },
        ];
        return assigneeRemovalRules;
    }
}

class AppTypePAEC implements AppType {
    readonly appType = appType.PAEC;
    readonly Resources: AppTypeResources
    readonly MinistersCanShowAllDocuments = false;
    readonly CanPrintPortfolios = true;
    readonly BriefHashtagsSectionEnabled = true;
    readonly BriefApproverRequired = true;
    readonly RequestApprovalLabel = 'Request Approval from Branch Approver';

    constructor() {
        this.Resources = CreateAppTypeResources(appType.PAEC)
    }

    GetDashboardColumns(account: Account): DocumentColumn[] {
        return DashboardAllColumns;
    }

    GetAppRole(account: Account): AppRole|undefined {
        return account.appRoles.PAEC;
    }

    GetAppRoleForUser(user: User): AppRole|undefined {
        if (isAppRole(user.paecRole)) {
            return user.paecRole;
         }
         return undefined;
    }

    ApproverWorkflowEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_APPROVER);
    }

    UserCanSeeUserDetails(account: Account): boolean {
        return true;
    }

    UserCanMarkAsPendingReview(account: Account): boolean {
        return canEditBriefs(account, this) && !userHasAppRole(account, this, AppRole.Author);
    }

    UserCanChangeDocumentsStatus(account: Account, documents: Document[]): boolean {
        if (userHasAppRole(account, this, AppRole.Coordinator)) {
            // coordinators may not change status of any briefs that have status of
            // "Approved by Group", "Assigned to central team" or "With Minister"
            if (documents.some(doc => isAtLeastApproved(doc))) return false;
        }

        if (userHasAppRole(account, this, AppRole.Approver)) {
            if (documents.some(doc => isAtLeastReviewByCentralTeam(doc))) return false;
        }

        if (!canMarkWithMinister(account)) {
            // user doesn't have access to move briefs into or out of the "with minister" status
            if (documents.some(doc => doc.reviewStatus === ReviewStatus.WithMinister)) return false;
        }

        return true;
    }

    BriefDivisionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_BRIEF_USE_DIVISION);
    }

    BriefQuestionSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.QUESTION_SECTION);
    }

    BriefReferenceSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_REFERENCE_SECTION);
    }

    BriefTableSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_TABLE_SECTION);
    }

    BriefCommitmentsSectionEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_COMMITMENTS_SECTION);
    }

    BriefApproverEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_1_APPROVER)
            || isFeatureEnabled(features, FeatureType.PAEC_2_APPROVERS);
    }

    BriefBoardApproverEnabled(features: Feature[]): boolean {
        return isFeatureEnabled(features, FeatureType.PAEC_2_APPROVERS);
    }

    GetEditFields(features: Feature[], translations: Translations): EditField[] {
        const fields: EditField[] = [];
        if (this.BriefDivisionEnabled(features)) {
            fields.push({
                id: 'division.id',
                label: 'Division',
                type: EditFieldType.Division,
                validationRules: [
                    fieldRequired('Please select a Division'),
                ],
            });
        }
        fields.push({
            id: 'topic.id',
            label: 'Portfolio',
            type: EditFieldType.Topic,
            validationRules: [
                fieldRequired('Please select a Portfolio'),
            ],
        });
        fields.push({
            id: 'title',
            label: getTranslation(translations, 'ppqApp.document.title.PAEC.label'),
            type: EditFieldType.Text,
            validationRules: [
                fieldRequired(getTranslation(translations, 'ppqApp.document.title.PAEC.error')),
            ],
        });
        if (this.BriefQuestionSectionEnabled(features)) {
            fields.push({
                id: 'question',
                label: getTranslation(translations, 'ppqApp.document.question.PAEC.label'),
                type: EditFieldType.RichText,
                help: getTranslation(translations, 'ppqApp.document.question.PAEC.tooltip'),
            });
        }
        if (this.BriefReferenceSectionEnabled(features)) {
            fields.push({
                id: 'reference',
                label: getTranslation(translations, 'ppqApp.document.reference.PAEC.label'),
                type: EditFieldType.RichText,
            });
        }
        if (this.BriefTableSectionEnabled(features)) {
            fields.push({
                id: 'tableData',
                label: getTranslation(translations, 'ppqApp.document.table.PAEC.label'),
                type: EditFieldType.RichText,
            });
        }
        fields.push({
            id: 'talkingPoints',
            label: getTranslation(translations, 'ppqApp.document.talkingPoints.PAEC.label'),
            type: EditFieldType.RichText,
            help: getTranslation(translations, 'ppqApp.document.talkingPoints.PAEC.tooltip'),
        });
        fields.push({
            id: 'background',
            label: getTranslation(translations, 'ppqApp.document.background.PAEC.label'),
            type: EditFieldType.RichText,
            help: getTranslation(translations, 'ppqApp.document.background.PAEC.tooltip'),
        });
        if (this.BriefCommitmentsSectionEnabled(features)) {
            fields.push({
                id: 'commitments',
                label: getTranslation(translations, 'ppqApp.document.commitments.PAEC.label'),
                type: EditFieldType.RichText,
            });
        }
        fields.push({
            id: 'hashTags',
            label: 'Hash Tags',
            type: EditFieldType.Text,
            help: 'Enter optional tags to identify common topics: for example #economicstats #gsp #finaldemand',
        });
        fields.push({
            id: 'creator',
            label: getTranslation(translations, 'ppqApp.document.creator'),
            type: EditFieldType.User,
        });
        return fields;
    }

    DisplayDocumentEditionDate(document: DocumentDetails): boolean {
        return isAtLeastApproved(document);
    }

    ShortDocumentNumber(features: Feature[]): boolean {
        return !isFeatureEnabled(features, FeatureType.PAEC_USE_FULL_DOCUMENT_NUMBER);
    }

    EndorsementDisplay(features: Feature[]): EndorsementDisplayType {
        if (isFeatureEnabled(features, FeatureType.PAEC_ENDORSEMENT_TABLE)) {
            return EndorsementDisplayType.FullTable;
        }
        if (isFeatureEnabled(features, FeatureType.PAEC_ENDORSEMENT_SHORT)) {
            return EndorsementDisplayType.AssigneeApproverShort;
        }
        return EndorsementDisplayType.None;
    }

    CanMarkDocumentsAs(status: ReviewStatus, features: Feature[]): boolean {
        if (status === ReviewStatus.PendingReview || status === ReviewStatus.WithMinister) return true;
        if (status === ReviewStatus.Approved || status === ReviewStatus.ReadyForPrint) {
            return isFeatureEnabled(features, FeatureType.PAEC_APPROVER);
        }
        return false;
    }

     EnabledDTPCoordinator(features: Feature[]): boolean {
                return isFeatureEnabled(features, FeatureType.PAEC_DTP_COORDINATOR);
            }

    GetDocumentExportUrl(document: Document, format: ExportFormat): string {
        switch (format) {
            case 'PDF':
                return `/pdf/PAEC/${document.id}/paec_document.pdf`;
            case 'DOCX':
                return `/docx/PAEC/${document.id}/paec_document.docx`;
            default:
                return assertNever(format);
        }
    }

    GetDocumentHistoryExportUrl(history: DocumentHistory, format: ExportFormat): string {
        switch (format) {
            case 'PDF':
                return `/pdf/PAEC/history/${history.id}/paec_document.pdf`;
            case 'DOCX':
                return `/docx/PAEC/history/${history.id}/paec_document.docx`;
            default:
                return assertNever(format);
        }
    }

    GetAppRoles(features: Feature[] | undefined): {key: string, name: string}[] {
        const enabledDtpCoord = features && this.EnabledDTPCoordinator(features);

        let roles = [
            { key: AppRole.Admin, name: 'Administrator' },
            { key: AppRole.Approver, name: 'Approver' },
            { key: AppRole.Author, name: 'Author' },
            { key: AppRole.Coordinator, name: 'Coordinator' }
        ];

        if (enabledDtpCoord) 
        {
            roles.push(
                { key: AppRole.DTPCoordinator, name: 'DTP-Coordinator' },
            );
        }

        roles.push(
            { key: AppRole.Minister, name: 'Minister' },
        );
        return roles;
    }

    GetUserDropdownFilter(): { [key: string]: AppRole } {
        // Filters by the global 'role' of the users shown in the dropdown - e.g. APPROVER_1 = only users with the AppRole.Approver
        const userDropdownFilterRules: { [key: string]: AppRole } = {
            "APPROVER_1": AppRole.Approver,
            "APPROVER_2": AppRole.Approver,
            "APPROVER_3": AppRole.Approver,
            "DTP_COORDINATOR": AppRole.DTPCoordinator,
        };
        return userDropdownFilterRules;
    }

    GetAssigneeRemovalRules(): AssigneeRemovalRule[] {
        const assigneeRemovalRules: AssigneeRemovalRule[] = [
            { status: ReviewStatus.PendingReview, roles: ['REVIEWER_1', 'REVIEWER_2'] },
            { status: ReviewStatus.PendingApproval, roles: ['APPROVER_1', 'APPROVER_2', 'APPROVER_3'] },
            { status: ReviewStatus.WithDtpCoordinator, roles: ['DTP_COORDINATOR'] },
        ];
        return assigneeRemovalRules;
    }
}

export const GetAppType = (theAppType: appType): AppType => {
    switch (theAppType) {
        case appType.PPQ:
            return new AppTypePPQ()
        case appType.PAEC:
            return new AppTypePAEC()
        default:
            return assertNever(theAppType);
    }
};
