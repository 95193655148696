import {VFC} from "react";
import {Col, Container, Navbar, Row} from "react-bootstrap";

const navStyle = {backgroundColor: '#f8f8f8', borderTop: '1px solid #e7e7e7', zIndex: 1};

export const FixedBottomSupportBanner:VFC = () => (
    <Navbar fixed="bottom" style={navStyle}>
        <SupportBannerContent />
    </Navbar>
);

export const SupportBanner:VFC = () => (
    <Navbar style={navStyle}>
        <SupportBannerContent />
    </Navbar>
);

export const SupportBannerWithStatusBar:VFC = () => (
    <Navbar style={navStyle}>
        <SupportBannerContent statusBar />
    </Navbar>
);

interface SupportBannerContentProps {
    statusBar?: boolean;
}
const SupportBannerContent: VFC<SupportBannerContentProps> = ({ statusBar = false }) => (

    <Container fluid style={{padding: statusBar ? '10px 10px 35px 30px' : '10px 10px 10px 30px'}}>
        <Row>
            <Col>
                <span className="text-muted" style={{fontSize: '0.8em'}}>Supported by&nbsp;
                    <a href="https://www.portable.com.au" target="_blank" rel="noreferrer">Portable</a> in Melbourne, Australia.</span>
            </Col>
        </Row>
    </Container>
);
