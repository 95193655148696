import { VFC } from "react";
import { Form } from "react-bootstrap";

interface FormFieldProps {
    id: string;
    label?: React.ReactNode;
    help?: string;
    error?: string;
    errorHtml?: string;
    control: JSX.Element;
    hasMarginBottom?: boolean;
}

export const FormField: VFC<FormFieldProps> = ({
    id,
    label,
    help,
    error,
    errorHtml,
    control,
    hasMarginBottom = true,
}) => {

    const cn = [];
    if (hasMarginBottom) {
        cn.push("mb-3");
    }
    return (
        <Form.Group className={cn.join(" ")} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            {control}
            {error !== undefined && <div className="text-danger mt-1">{error}</div>}
            {errorHtml !== undefined && <div className="text-danger mt-1" dangerouslySetInnerHTML={{__html: errorHtml}} />}
            {help !== undefined && (
                <Form.Text className="text-muted">{help}</Form.Text>
            )}
        </Form.Group>
    );
};
