import { useState, VFC } from "react";

import { useRouter } from "../../../hooks/useRouter";
import {
    useAppType,
    useExternalResource,
} from "../../../Context/ServiceContext";

import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { SectionSelectPanel } from "../../../components/ui/layouts/SectionSelectPanel";
import {
    AppLayout,
    AppLayoutHeading,
} from "../../../components/ui/layouts/AppLayout";
import { useSidePanelAppId } from "../../../hooks/useSidePanelAppId";
import { useTemplates } from "../../../hooks/useTemplates";
import { ErrorInLayout } from "../../../components/ui/elements/ErrorInLayout";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { AlertVariant } from "../../../components/ui/elements/Alert";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { saveTemplate } from "../../../services/saveTemplate";
import { InstructionInfoContent } from "../../../components/ui/elements/InstructionInfoContent";
import { Icon, IconType } from "../../../components/ui/elements/Icon";
import { Template } from "../../../domain/Template";
import { Form } from "../../../components/form/forms/Form";
import { TextField } from "../../../components/form/fields/TextField";
import { TextareaField } from "../../../components/form/fields/TextareaField";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { ArchiveButton } from "../../../components/ui/elements/ArchiveButton";
import { createTemplate } from "../../../services/createTemplate";
import { CreateTemplateAPIRequest } from "../../../services/resources";
import { deleteTemplate } from "../../../services/deleteTemplate";
import { notificationService } from "../../../services/notificationService";
import { SELECT_PANEL_CREATE_NEW_KEY } from "../../../components/ui/elements/SelectPanel";
import { NotFound } from "../../NotFound";
import { WithTestID } from "../../../components/ui/elements/WithTestID";
import { fieldRequired } from "../../../components/form/fields/FieldValidation";

const ManageTemplatesFallback: React.FC = () => (
    <InstructionInfoContent>
        <Icon icon={IconType.Division} className="m-auto fa-5x" />
        <p className="lead">
            Choose a template or create a new one on the left.
        </p>
    </InstructionInfoContent>
);

interface UpdateTemplateFormProps extends WithTestID {
    onSaved?: () => void;
    template: Template;
}
const UpdateTemplateForm: React.VFC<UpdateTemplateFormProps> = ({ onSaved, template, testID }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();

    const onSubmit = async (template: Template) => {
        setSaving(true);
        try {
            await saveTemplate(externalResource, appType, template);
            gotoOrReload(`/app/${appType.appType}/admin/templates`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Template updated');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    const onArchive = async () => {
        setSaving(true);
        try {
            await deleteTemplate(externalResource, appType, template.id);
            gotoOrReload(`/app/${appType.appType}/admin/templates`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Template deleted');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    if (!template) {
        return <LoadingOverlay show />;
    }

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <Form
                testID={testID}
                initialValues={{ ...template }}
                onSubmit={onSubmit}
                FormBody={() => (
                    <>
                        <TextField
                            id="name"
                            label="Template Name"
                            validationRules={[
                                fieldRequired('Please provide a Template name'),
                            ]}
                        />
                        <TextareaField
                            id="text"
                            label="Template Text"
                            validationRules={[
                                fieldRequired('Please provide the Template text'),
                            ]}
                        />
                    </>
                )}
                FormActions={({ dirty, isValid }) => {
                    return (
                        <ButtonList className="gap-2">
                            <ArchiveButton onClick={onArchive} testID={testID ? `${testID}:archive-btn` : undefined}>
                                Delete
                            </ArchiveButton>
                            <SaveButton disabled={!dirty || !isValid}>
                                Save Template
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

interface CreateTemplateFormProps {
    onSaved?: () => void;
}
const CreateTemplateForm: React.VFC<CreateTemplateFormProps> = ({ onSaved }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();

    const onSubmit = async (values: Record<string, any>) => {
        setSaving(true);
        try {
            const template: CreateTemplateAPIRequest = {
                name: values.name,
                text: values.text,
                appType: appType.appType,
                sortOrder: null,
            };
            await createTemplate(externalResource, appType, template);
            gotoOrReload(`/app/${appType.appType}/admin/templates`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Template created');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <Form
                initialValues={{ name: '', text: '' }}
                onSubmit={onSubmit}
                FormBody={() => (
                    <>
                        <h4>Create Template</h4>
                        <TextField
                            id="name"
                            label="Template Name"
                            validationRules={[
                                fieldRequired('Please provide a Template name'),
                            ]}
                        />
                        <TextareaField
                            id="text"
                            label="Template Text"
                            validationRules={[
                                fieldRequired('Please provide the Template text'),
                            ]}
                        />
                    </>
                )}
                FormActions={({ dirty, isValid }) => {
                    return (
                        <ButtonList className="justify-content-start gap-1">
                            <SaveButton disabled={!isValid}>
                                Create Template
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

export const ManageTemplates: VFC<WithTestID> = ({ testID }) => {
    const { data, error, mutate } = useTemplates();
    const appType = useAppType();
    const { selectedItem, setSelectedItem } = useSidePanelAppId(`/app/${appType.appType}/admin/templates`);

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    const onSaved = () => mutate();

    let createFormComponent: VFC = () => null;
    let updateFormComponent: VFC = () => null;

    if (selectedItem === undefined) {
    } else if (selectedItem === SELECT_PANEL_CREATE_NEW_KEY) {
        createFormComponent = () => <CreateTemplateForm onSaved={onSaved} />;
    } else {
        if (data === undefined) {
            updateFormComponent = () => <LoadingOverlay show />;
        } else {
            const template = data?.find(t => t.id === selectedItem);
            if (!template) {
                return <NotFound />;
            }

            updateFormComponent = () => <UpdateTemplateForm onSaved={onSaved} template={template} testID={testID ? `${testID}:update-form` : undefined} />;
        }
    }

    return (
        <AppLayout>
            <AppLayoutHeading>Manage Templates</AppLayoutHeading>
            <LoadingOverlay show={!data} />
            <SectionSelectPanel
                testID={testID}
                panelHeading="New Template"
                data={data || []}
                fallBackComponent={ManageTemplatesFallback}
                createFormComponent={createFormComponent}
                updateFormComponent={updateFormComponent}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
            />
        </AppLayout>
    );
};
