import { Unknown } from "../services/TypeUtils";
import { has, isString, isStringMaybe } from "./validateUnknown";

export interface SystemBasic {
    loginTitle: string | null;
    ppqDepartment: string;
}

export const isSystemBasic = (data: Unknown<SystemBasic>): data is SystemBasic => {
    return has(data, 'loginTitle', isStringMaybe)
        && has(data, 'ppqDepartment', isString);
};
