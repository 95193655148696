import useSWR from 'swr';
import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { DocumentHistory } from '../domain/DocumentHistory';
import { DocumentID } from "../services/ExternalResource";
import { fetchDocumentHistoryPartial } from '../services/fetchDocumentHistoryPartial';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useDocumentHistoryPartial = (id: DocumentID) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    return useSWR<DocumentHistory[], ErrorWithDetails>(`documentHistoryPartial:${appType.appType}:${id}`, () => {
        return fetchDocumentHistoryPartial(externalResource, appType, id)
            .catch(throwErrorWithDetails);
    });
};

export const useOptionalDocumentHistoryPartial = (id: DocumentID|undefined) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    return useSWR<DocumentHistory[], ErrorWithDetails>(
        () => id === undefined ? null : `documentHistoryPartial:${appType.appType}:${id}`,
        () => {
            if (id) {
                return fetchDocumentHistoryPartial(externalResource, appType, id)
                    .catch(throwErrorWithDetails);
            } else {
                return [];
            }
        }
    );
};
