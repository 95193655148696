import {appType} from "../../domain/AppType";
import {isAppType} from "../../domain/Document";

export type Location = {
    location: string
    appType?: appType
    documentId?: number
}
export const ParseLocation = (url: string): Location => {

    const [,location] = url.indexOf('#') !== -1 ? url.split("#", 2) : ["",url]

    const [,,appTypeUnknon,,documentIdString] = location.split("/")

    let appType: appType|undefined = undefined
    if(isAppType(appTypeUnknon)) {
        appType = appTypeUnknon
    }
    let documentId: number|undefined = undefined
    if(documentIdString) {
        documentId = Number.parseInt(documentIdString, 10)
    }

    return {
        location,
        appType,
        documentId
    }
}
