import React from 'react';

import { Button, ButtonVariant } from '../elements/Button';
import Modal from '../elements/Modal';
import { WithTestID } from '../elements/WithTestID';

export interface ExtractDataModalProps extends WithTestID {
    show: boolean;
    onHide: () => void;
    culled: boolean;
    onSubmit: () => void;
}

export const ExtractDataModal: React.VFC<ExtractDataModalProps> = ({ show, onHide, culled, onSubmit, testID }) => {
    return (
        <Modal show={show} onHide={onHide} testID={testID}>
            <Modal.Body>
                <p>
                    The system will perform a data extract{culled && ' of all culled briefs'},
                    and the result will be sent to your email address as a CSV file.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={onSubmit}
                    testID={testID ? `${testID}:extract` : undefined}
                >Start extract</Button>
            </Modal.Footer>
        </Modal>
    );
};
