import {FC} from "react";
import {Bootstrap} from "./Bootstrap";
import {Col, Container, Row} from "react-bootstrap";
import {FixedBottomSupportBanner} from "../elements/SupportBanner";

export const CenterSmallContainerWithSupportBanner: FC = ({children}) => {
    return <Bootstrap>
        <Container fluid style={{backgroundColor: '#eee', display: "flex", minHeight: '100%', paddingBottom: 77}}>
            <Container>
                <Row>
                    <Col md={{span: 4, offset: 4}} style={{paddingTop: 60}}>
                        {children}
                    </Col>
                </Row>
            </Container>
            <FixedBottomSupportBanner/>
        </Container>
    </Bootstrap>
}
