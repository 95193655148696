import React, { useContext } from 'react';
import { Redirect } from 'react-router';

import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { Account } from '../../domain/Account';
import { appType } from '../../domain/AppType';
import { ErrorWithDetails } from '../../hooks/exceptionToError';
import { Error } from '../../views/Error';
import { ErrorInLayout } from '../ui/elements/ErrorInLayout';
import { AppLayoutWithLogoutOnly } from '../ui/layouts/AppLayout';

const getFirstAppType = (userAccount: Account): appType|undefined => {
    return Object.values(appType).find((at) => !!userAccount.appRoles[at]);
};

export const RedirectToFirstAppType: React.VFC = () => {

    const auth = useContext(AuthenticationContext);
    const { userAccount } = auth.auth;
    if (!userAccount) {
        return <Error message="User is not logged in" />;
    }

    const appType = getFirstAppType(userAccount);
    if (!appType) {
        const error: ErrorWithDetails = {
            message: 'Access denied. Please contact the system administrator.',
            details: 'User does not have access to any app types.',
        };
        return <ErrorInLayout Layout={AppLayoutWithLogoutOnly} error={error} />;
    }

    return <Redirect to={`/app/${appType}`} />;

};
