import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
import reportWebVitals from './reportWebVitals';
import {ServiceContextProvider} from "./Context/ServiceContext";
import {HTTPServiceContext} from "./services/HTTP/HTTPServiceContext";

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://891840fe82224ab189f9e32f06236b20@o421518.ingest.sentry.io/6374202",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
    });
}


ReactDOM.render(
  <React.StrictMode>
    <ServiceContextProvider value={HTTPServiceContext}>
        <App />
    </ServiceContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
