import React, { useEffect, useState } from 'react';
import { NotificationProps, notificationService } from '../../../services/notificationService';
import { ToastContainer } from '../layouts/ToastContainer';
import { AutoHideToast } from './AutoHideToast';

export const Notifications: React.VFC = () => {
    const [notifications, setNotifications] = useState<NotificationProps[]>([]);

    useEffect(() => {
        const callback = (arr: NotificationProps[]) => {
            setNotifications([ ...arr ]);
        };
        notificationService.addCallback(callback);
        return () => {
            notificationService.removeCallback(callback);
        };
    }, []);

    return (
        <ToastContainer>
            {notifications.map((notification) => {
                const { id, content, ...props } = notification;
                return (
                    <AutoHideToast
                        key={id}
                        {...props}
                        onHide={() => {
                            notificationService.hideNotification(id);
                        }}
                    >
                        {content}
                    </AutoHideToast>
                );
            })}
        </ToastContainer>
    );
};
