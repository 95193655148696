import { usePortfolioList } from "../../../hooks/usePortfolioList";
import { DropDownOption, MultiDropDown, MultiDropDownProps } from "../../ui/elements/MultiDropDown";
import { SingleDropDown, SingleDropDownProps } from "./SingleDropDown";

export interface SinglePortfolioTopicDropDownProps extends Omit<SingleDropDownProps<number>, 'items'|'noneSelectedLabel'> {

};

const usePortfolioItems = () => {
    const { data, error } = usePortfolioList();

    let options: DropDownOption<number>[] = [];
    if (error) {
        options.push({ value: -1, content: <i>Error loading Portfolios</i>, disabled: true, type: 'option' });
    } else if (!data) {
        options.push({ value: -1, content: <i>Loading Portfolios...</i>, disabled: true, type: 'option' });
    } else {
        data.forEach((portfolio) => {
            portfolio.topics.forEach((topic) => {
                options.push({
                    value: topic.id,
                    content: (
                        <span className="d-flex gap-2">
                            <span className="fw-bold">{portfolio.number}.{topic.number}</span>
                            <span>{portfolio.name}</span>
                            <span className="text-muted">{topic.name}</span>
                        </span>
                    ),
                    type: 'option',
                });
            });
        });
    }

    return options;
};

export const SinglePortfolioTopicDropDown: React.VFC<SinglePortfolioTopicDropDownProps> = (props) => {
    const options = usePortfolioItems();
    return (
        <SingleDropDown
            {...props}
            noneSelectedLabel="Select Portfolio"
            items={options}
        />
    );
};

const displayContent = (value: number[]): React.ReactNode => {
    const { length } = value;
    if (length === 0) {
        return 'All Portfolios';
    } else if (length === 1) {
        return '1 Portfolio';
    } else {
        return `${length} Portfolios`;
    }
};

export interface MultiPortfolioTopicDropDownProps extends Omit<MultiDropDownProps<number>, 'items'|'label'> {
}

export const MultiPortfolioTopicDropDown: React.VFC<MultiPortfolioTopicDropDownProps> = (props) => {
    const options = usePortfolioItems();
    return (
        <MultiDropDown
            {...props}
            label={displayContent(props.value)}
            items={options}
        />
    );
};
