import { Division } from "../../../domain/Division";
import { useDivisionList } from "../../../hooks/useDivisionList";
import { DropDownOption, MultiDropDown, MultiDropDownProps } from "../../ui/elements/MultiDropDown";
import { SingleDropDown, SingleDropDownProps } from "./SingleDropDown";

type FilterCallback = (division: Division) => boolean;
const noFilter: FilterCallback = () => true;

const useDivisionItems = (filter: FilterCallback = noFilter) => {

    const { data, error } = useDivisionList();

    let options: DropDownOption<number>[] = [];
    if (error) {
        options.push({ value: -1, content: <i>Error loading Divisions</i>, disabled: true, type: 'option' });
    } else if (!data) {
        options.push({ value: -1, content: <i>Loading Divisions...</i>, disabled: true, type: 'option' });
    } else {
        options = data
            .filter(filter)
            .map((division) => ({
                value: division.id,
                content: division.name,
                type: 'option',
            }));
    }

    return options;
};

export interface SingleDivisionDropDownProps extends Omit<SingleDropDownProps<number>, 'items'|'noneSelectedLabel'> {
    filter?: FilterCallback;
}

export const SingleDivisionDropDown: React.VFC<SingleDivisionDropDownProps> = ({ filter, ...props }) => {
    const options = useDivisionItems(filter);
    return (
        <SingleDropDown
            {...props}
            noneSelectedLabel="Select Division"
            items={options}
        />
    );
};

const displayContent = (value: number[]): React.ReactNode => {
    const { length } = value;
    if (length === 0) {
        return 'All Divisions';
    } else if (length === 1) {
        return '1 Division';
    } else {
        return `${length} Divisions`;
    }
};

export interface MultiDivisionDropDownProps extends Omit<MultiDropDownProps<number>, 'items'|'label'> {
    filter?: FilterCallback;
}

export const MultiDivisionDropDown: React.VFC<MultiDivisionDropDownProps> = ({ filter, ...props }) => {
    const options = useDivisionItems(filter);
    return (
        <MultiDropDown
            {...props}
            label={displayContent(props.value)}
            items={options}
        />
    );
};
