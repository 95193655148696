import {AppType} from "../domain/AppType";
import { DocumentLock, isDocumentLock } from "../domain/DocumentLock";
import { validate } from "../domain/validateUnknown";
import {DocumentID, ExternalResource} from "./ExternalResource";

export const fetchDocumentLock = async (externalResource: ExternalResource, appType: AppType, id: DocumentID): Promise<DocumentLock> => {
    try {
        const response = await externalResource.action(appType.Resources.obtainDocumentLock, id);
        return validate(isDocumentLock, 'DocumentLock')(response);
    } catch (err) {
        console.log('error: ', err);
        return { ok: false };
    }
};
