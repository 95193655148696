import {Document, isDocument, isUnsavedDocument, UnsavedDocument} from "../domain/Document";
import {AppType} from "../domain/AppType";
import {DocumentID, ExternalResource} from "./ExternalResource";
import {validate} from "../domain/validateUnknown";

export function fetchDocument(externalResource: ExternalResource, appType: AppType, id: DocumentID): Promise<Document> {
    return externalResource.action(appType.Resources.getDocument, id).then(validate(isDocument, "Document"))
}
export function fetchNewDocument(externalResource: ExternalResource, appType: AppType): Promise<UnsavedDocument> {
    return externalResource.action(appType.Resources.getNewDocument, null).then(validate(isUnsavedDocument, "Document"))
}
