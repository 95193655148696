import { RequestError, RequestErrorReason } from "../errors/RequestError"

export interface ErrorWithDetails {
    message: string
    details: string
    reason?: RequestErrorReason;
}

export const ExceptionToError = (callback: (e: ErrorWithDetails) => void) => (e: Error) => {
    callback(convertErrorToErrorWithDetails(e));
};

export const convertErrorToErrorWithDetails = (e: Error): ErrorWithDetails => {
    const err: ErrorWithDetails = {
        message: e.message,
        details: e.message
    };
    if (e instanceof RequestError) {
        err.reason = e.reason;
    }
    return err;
};

export const throwErrorWithDetails = (e: Error) => {
    throw convertErrorToErrorWithDetails(e);
};
