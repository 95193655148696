import {FC} from "react";
import {Alert as BootstrapAlert, AlertProps as BSAlertProps} from "react-bootstrap";
import { assertNever } from "../../../services/TypeUtils";
import {WithTestID} from "./WithTestID";

export enum AlertVariant {
    INFO,
    ERROR,
    WARNING,
}

export type AlertProps = WithTestID & {
    variant: AlertVariant
    disabled?: boolean
}

function translateVariant(variant: AlertVariant): BSAlertProps['variant'] {
    switch (variant) {
        case AlertVariant.INFO:
            return "info"
        case AlertVariant.ERROR:
            return "danger"
        case AlertVariant.WARNING:
            return "warning"
        default:
            return assertNever(variant);
    }
}

export const Alert: FC<AlertProps> = ({variant, ...props}) => {
    return <BootstrapAlert variant={translateVariant(variant)} {...props} />
}
