import {DateTime, LocaleOptions} from 'luxon';
import { assertNever } from './TypeUtils';

export enum DateTimeFormat {
    DateTime,
    Date,
    DateLong,
    Time,
    ISODate,
}

export interface DateTimeStringProps {
    date: Date;
    format?: DateTimeFormat;
}

export const formatDateTimeString = (date: Date, format = DateTimeFormat.DateTime) => {
    const dt = DateTime.fromJSDate(date);

    // Override the browsers default as some australian users will have en-US or other locales set in their browser without knowing
    const locateOptions: LocaleOptions = {
        locale: 'en-AU'
    }

    switch (format) {
        case DateTimeFormat.DateTime:
            return dt.toLocaleString({ year: '2-digit', month: '2-digit', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }, locateOptions);
        case DateTimeFormat.Date:
            return dt.toLocaleString({ year: '2-digit', month: '2-digit', day: 'numeric' }, locateOptions);
        case DateTimeFormat.DateLong:
            return dt.toLocaleString({ year: 'numeric', month: 'long', day: 'numeric' }, locateOptions);
        case DateTimeFormat.Time:
            return dt.toLocaleString({ hour: 'numeric', minute: '2-digit', hour12: true }, locateOptions);
        case DateTimeFormat.ISODate:
            return dt.toISODate();
        default:
            return assertNever(format);
    }

};

export const parseDateTimeString = (str: string, format: DateTimeFormat): Date|undefined => {
    switch (format) {
        case DateTimeFormat.ISODate: {
            const dt = DateTime.fromISO(str);
            if (dt.isValid) {
                return dt.toJSDate();
            }
            return undefined;
        }

        default:
            throw new Error(`Format ${format} is not currently supported for parsing`);
    }
};
