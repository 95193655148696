import { VFC } from "react";
import { Form, FormActions, FormBody } from "../../components/form/forms/Form";
import { TextField } from "../../components/form/fields/TextField";
import { CenterSmallContainerWithSupportBanner } from "../../components/ui/layouts/CenterSmallContainerWithSupportBanner";
import {
    ButtonVariant,
    SubmitButton,
} from "../../components/ui/elements/Button";
import { ButtonList } from "../../components/ui/layouts/ButtonList";
import { useLoginTitle } from "../../hooks/useLoginTitle";
import { Alert, AlertVariant } from "../../components/ui/elements/Alert";
import { useSubmitLogin } from "../../hooks/useSubmitLogin";
import { useOnSuccessfulLogin } from "../../hooks/useOnSuccessfulLogin";
import { useExternalResource } from "../../Context/ServiceContext";
import { useFeature } from "../../hooks/useFeature";
import { FeatureType } from "../../domain/Feature";
import { Link } from "react-router-dom";

export const Login: VFC = () => {
    const loginTitle = useLoginTitle();

    return (
        <CenterSmallContainerWithSupportBanner>
            <h1 data-testid={"heading"}>{loginTitle}</h1>
            <EmailPasswordLoginForm />
            <LoginOptionSeparator />
            <SSOLoginForm />
        </CenterSmallContainerWithSupportBanner>
    );
};

const EmailPasswordLoginForm: React.VFC = () => {
    const externalResource = useExternalResource();
    const onSuccessfulLogin = useOnSuccessfulLogin(externalResource);
    const [submitLogin, loginError] = useSubmitLogin(
        externalResource,
        onSuccessfulLogin
    );

    const { data: emailLoginEnabled } = useFeature(
        FeatureType.LOGIN_EMAIL_PASSWORD
    );
    if (!emailLoginEnabled) return null;

    const initialValue = {
        email: "",
        password: "",
    };
    const submit = (values: any) => {
        submitLogin({ password: values.password, username: values.email });
    };

    const formBody: FormBody = ({ values, handleChange }) => {
        return (
            <>
                {loginError && (
                    <Alert variant={AlertVariant.ERROR}>
                        <strong>{loginError}!</strong> Please check your
                        credentials and try again.
                    </Alert>
                )}
                <TextField label={"Email address"} id={"email"} />
                <TextField
                    label={"Password"}
                    id={"password"}
                    type={"password"}
                />
            </>
        );
    };
    const formActions: FormActions = ({ isSubmitting }) => {
        return (
            <ButtonList>
                <SubmitButton
                    variant={ButtonVariant.PRIMARY}
                    testID={"signIn"}
                    disabled={isSubmitting}
                >
                    Sign in
                </SubmitButton>
                <Link to="/reset/request" data-testid="forgotPassword">
                    Forgot Password
                </Link>
            </ButtonList>
        );
    };

    return (
        <div className="py-2">
            <Form
                FormBody={formBody}
                onSubmit={submit}
                initialValues={initialValue}
                FormActions={formActions}
            />
        </div>
    );
};

const LoginOptionSeparator: React.VFC = () => {
    const { data: emailLoginEnabled } = useFeature(
        FeatureType.LOGIN_EMAIL_PASSWORD
    );
    const { data: ssoEnabled } = useFeature(FeatureType.LOGIN_SSO);

    // only render if both login options are enabled
    if (!emailLoginEnabled || !ssoEnabled) return null;

    return (
        <div className="d-flex gap-3 py-3 align-items-center">
            <div className="flex-grow-1 border-top border-info" />
            <div className="flex-grow-0">Or</div>
            <div className="flex-grow-1 border-top border-info" />
        </div>
    );
};

const SSOLoginForm: React.VFC = () => {
    const { data: ssoEnabled } = useFeature(FeatureType.LOGIN_SSO);

    // only render if sso option is enabled
    if (!ssoEnabled) return null;

    return (
        <div className="py-2">
            <p>Login using Single Sign-On</p>
            <ButtonList>
                <a data-testid={"sso"} href="/oauth2/authorization/azureAD" className="btn btn-primary">Single Sign-On</a>
            </ButtonList>
        </div>
    );
};
