import { useFetchAllUsers } from './useFetchAllUsers';

export const useFetchUser = (login: string|undefined) => {
    const { data, error } = useFetchAllUsers(true);

    const user = (login === undefined || data === undefined)
        ? undefined
        : data.find(u => u.login === login);

    return { data: user, error };
};
