import React from 'react';
import { ViewMultipleDocuments } from '../../../components/ui/elements/ViewMultipleDocuments';
import { AppLayoutWithoutContainer } from '../../../components/ui/layouts/AppLayout';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { DocumentID } from '../../../services/ExternalResource';
import { NotFound } from '../../NotFound';

export const DocumentIDsKey = 'ViewMultiple.DocumentIDs';

export const PPQViewMultiple: React.VFC = () => {
    const storage = useLocalStorage();
    const ids: DocumentID[]|undefined = storage.get(DocumentIDsKey);

    if (ids === undefined || ids.length === 0) {
        return <NotFound />;
    }

    return (
        <AppLayoutWithoutContainer>
            <ViewMultipleDocuments ids={ids} />
        </AppLayoutWithoutContainer>
    );
};
