import useSWR from 'swr';
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { Template } from "../domain/Template";
import { fetchTemplates } from "../services/fetchTemplates";
import { ErrorWithDetails, throwErrorWithDetails } from "./exceptionToError";

export const useTemplates = () => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<Template[], ErrorWithDetails>(`templates:${appType.appType}`, () => {
        // The api returns templates ordered alphabetically by title.
        // Even though there is a sortOrder field on the templates, it is not
        // exposed through the frontend or used to sort the templates.
        return fetchTemplates(externalResource, appType)
            .catch(throwErrorWithDetails);
    });
};
