import React from 'react';
import { Modal as BSModal, ModalProps as BSModalProps } from 'react-bootstrap';

import { WithTestID } from './WithTestID';

export interface ModalProps extends WithTestID {
    show?: boolean;
    onShow?: () => void;
    onHide?: () => void;
    size?: BSModalProps['size'];
}

const Modal: React.FC<ModalProps> = ({ show, onShow, onHide, size, children, testID }) => {
    return (
        <BSModal show={show} onShow={onShow} onHide={onHide} size={size} data-testid={testID}>
            {children}
        </BSModal>
    );
};

export interface ModalHeaderProps {
    closeButton?: boolean;
}

const Header: React.FC<ModalHeaderProps> = ({ closeButton, children }) => {
    return (
        <BSModal.Header closeButton={closeButton}>
            {children}
        </BSModal.Header>
    );
};

const Body: React.FC = ({ children }) => {
    return (
        <BSModal.Body>
            {children}
        </BSModal.Body>
    );
};

const Footer: React.FC = ({ children }) => {
    return (
        <BSModal.Footer>
            {children}
        </BSModal.Footer>
    );
};

const Title: React.FC = ({ children }) => {
    return (
        <BSModal.Title>
            {children}
        </BSModal.Title>
    );
};

export default Object.assign(Modal, {
    Header,
    Title,
    Body,
    Footer,
});
