import useSWR from 'swr';
import { useExternalResource } from '../Context/ServiceContext';

import { Translations } from "../domain/Translations";
import { fetchTranslations } from "../services/fetchTranslations";
import { ErrorWithDetails, throwErrorWithDetails } from "./exceptionToError";
import { useSystemBasic } from "./useSystemBasic";

export const useTranslation = (partName: string) => {
    const externalResource = useExternalResource();
    const {data: systemBasic} = useSystemBasic();
    const ppqDepartment = systemBasic?.ppqDepartment;

    return useSWR<Translations|undefined, ErrorWithDetails>(
        // only fetch if we have a ppqDepartment:
        // https://swr.vercel.app/docs/conditional-fetching
        () => ppqDepartment === undefined ? null : `translations:${ppqDepartment}:${partName}`,
        () => {
            return ppqDepartment === undefined
                ? undefined
                : fetchTranslations(externalResource, { partName, ppqDepartment })
                    .catch(throwErrorWithDetails);
        }
    );
}
