import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { AppType } from "../domain/AppType";
import { DocumentLock } from "../domain/DocumentLock";
import { ExternalResource } from "../services/ExternalResource";
import { fetchDocumentLock } from "../services/fetchDocumentLock";
import { ErrorWithDetails, ExceptionToError } from "./exceptionToError";

const DEFAULT_RELOCK_FREQUENCY_MILLIS = 24000;

const doLock = (
    externalResource: ExternalResource,
    appType: AppType,
    documentId: number,
    lock: DocumentLock | undefined,
    setLock: (arg: DocumentLock) => void,
    setError: (arg: ErrorWithDetails) => void,
    relockTimerRef: MutableRefObject<NodeJS.Timer | undefined>,
    relockTime: number,
) => {

    if (relockTimerRef.current) {
        clearTimeout(relockTimerRef.current);
        relockTimerRef.current = undefined;
    }

    fetchDocumentLock(externalResource, appType, documentId)
        .then((documentLock) => {
            if (documentLock.ok !== lock?.ok) {
                setLock(documentLock);
            }
            if (documentLock.ok) {
                // successfully locked the document, set a timer to relock
                relockTimerRef.current = setTimeout(() => {
                    doLock(externalResource, appType, documentId, documentLock, setLock, setError, relockTimerRef, relockTime);
                }, relockTime);
            }
        })
        .catch(ExceptionToError(setError));

};

export const useDocumentLock = (documentId: number, relockTime = DEFAULT_RELOCK_FREQUENCY_MILLIS) => {

    const [lock, setLock] = useState<DocumentLock>();
    const [error, setError] = useState<ErrorWithDetails>();

    const appType = useAppType();

    const docIdRef = useRef(documentId);
    const appTypeRef = useRef(appType);
    const extResRef = useRef(useExternalResource());
    const relockTimerRef = useRef<NodeJS.Timer>();
    const relockTimeRef = useRef(relockTime);

    useEffect(() => {
        if (docIdRef.current > 0) {
            doLock(extResRef.current, appTypeRef.current, docIdRef.current, undefined, setLock, setError, relockTimerRef, relockTimeRef.current);
        }
        return () => {
            if (relockTimerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                clearTimeout(relockTimerRef.current);
            }
        };
    }, []);

    return { lock, error };

};
