import React from "react";
import type { MouseEvent, Dispatch } from "react";
import { ListGroup } from "react-bootstrap";

import { Icon, IconType } from "../../../components/ui/elements/Icon";
import { WithTestID } from "./WithTestID";
import "./SelectPanel.scss";

export type SelectPanelDataProps = { id: number; name: string, number?: number };

type OnSelect = (event: MouseEvent, selectItem: number) => void;

type SelectPanelItemProps = WithTestID & {
    selectedItem: number | undefined;
    onSelect: OnSelect;
    panelId: number;
};

export const SELECT_PANEL_CREATE_NEW_KEY = -1;

const SelectPanelItem: React.FC<SelectPanelItemProps> = ({
    children,
    panelId,
    onSelect,
    testID,
}) => {
    return (
        <button
            className={"select-panel__link"}
            onClick={(event) => onSelect(event, panelId)}
            aria-label={`Load Page ${children}`}
            data-testid={testID}
        >
            {children}
            <Icon
                icon={
                    panelId === SELECT_PANEL_CREATE_NEW_KEY
                        ? IconType.Add
                        : IconType.ChevronRight
                }
            />
        </button>
    );
};

export type SelectPanelProps = WithTestID & {
    heading?: string;
    selectedItem?: number;
    setSelectedItem: Dispatch<number>;
    items: SelectPanelDataProps[];
    showNumber?: boolean;
};

export const SelectPanel: React.VFC<SelectPanelProps> = ({
    heading = "New Item",
    selectedItem,
    setSelectedItem,
    items,
    showNumber = false,
    testID,
}) => {
    const onSelect: OnSelect = (event, divisionId) => {
        event.preventDefault();
        setSelectedItem(divisionId);
    };

    const newItems: SelectPanelDataProps[] = [
        {
            id: SELECT_PANEL_CREATE_NEW_KEY,
            name: heading,
            number: -1,
        },
        ...items,
    ];

    const cn = ["select-panel"];

    if (showNumber) {
        cn.push("select-panel--shownbr");
    }

    return (
        <div className={cn.join(" ")} data-testid={testID}>
            <ListGroup>
                {newItems.map(({ id, name, number }, key) => {
                    return (
                        <ListGroup.Item
                            key={`${id}-${key}`}
                            className={
                                selectedItem === id ? "isActive" : undefined
                            }
                        >
                            <SelectPanelItem
                                selectedItem={selectedItem}
                                panelId={id}
                                onSelect={onSelect}
                                testID={testID ? `${testID}:item:${id}` : undefined}
                            >
                                <span className="select-panel__nbr">{id === SELECT_PANEL_CREATE_NEW_KEY ? '' : `${number}`}</span>
                                <span className="select-panel__name">{name}</span>
                            </SelectPanelItem>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </div>
    );
};
