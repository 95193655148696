import { AppType } from "../domain/AppType";
import { DocumentID, ExternalResource } from "./ExternalResource";
import { BulkDocumentRequestWithSort } from "./resources";
import { DocumentSearchSort } from "./searchDocuments";

export const renumberDocuments = (externalResource: ExternalResource, appType: AppType, documentIds: DocumentID[], sort: DocumentSearchSort|undefined) => {
    const req: BulkDocumentRequestWithSort = { ids: documentIds };
    if (sort) {
        req.sort = JSON.stringify({ sort });
    }
    return externalResource.action(appType.Resources.renumberDocuments, req);
};
