import React, { useEffect, useState } from 'react';

import { useAuthenticatedUser } from '../../../Context/AuthenticationContext';
import { useAppType, useExternalResource } from '../../../Context/ServiceContext';
import { Document } from '../../../domain/Document';
import { useOptionalDocumentCurrentNote } from '../../../hooks/useDocumentCurrentNote';
import { markNoteSeen } from '../../../services/markNoteSeen';
import { Button, ButtonVariant } from '../elements/Button';
import Modal from '../elements/Modal';

import { WithTestID } from '../elements/WithTestID';

export interface DocumentCurrentNoteModalProps extends WithTestID {
    document: Document;
}

export const DocumentCurrentNoteModal: React.VFC<DocumentCurrentNoteModalProps> = ({ document, testID }) => {

    const appType = useAppType();
    const externalResource = useExternalResource();
    const currentUser = useAuthenticatedUser();
    const [show, setShow] = useState(false);
    const { data } = useOptionalDocumentCurrentNote(document.id === 0 ? undefined : document.id);

    const shouldShow = data && !data.noteSeen && data.assignee === currentUser.login;

    useEffect(() => {
        if (shouldShow) {
            setShow(true);
            markNoteSeen(externalResource, appType, data.id);
        }
    }, [shouldShow, externalResource, appType, data?.id]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg" testID={testID}>
            <Modal.Body>
                <div className="note-contents">{data?.note}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => setShow(false)}
                >Ok</Button>
            </Modal.Footer>
        </Modal>
    );

};
