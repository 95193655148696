import type { WithTestID } from "../elements/WithTestID";

type SectionSingleColumnProps = {
    as?: string | React.FC;
    style?: any;
    children: React.ReactNode;
} & WithTestID;

export const SectionSingleColumn: React.FC<SectionSingleColumnProps> = ({
    children,
    testID,
    ...props
}) => (
    <section data-test-id={testID} style={{ maxWidth: "800px" }} {...props}>
        {children}
    </section>
);
