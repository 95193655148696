import {Unknown} from "../services/TypeUtils";

export function isString(data: unknown): data is string {
    return typeof data === "string"
}
export function isNull(data: unknown): boolean {
    return data === null
}
export function isUndefined(data: unknown): boolean {
    return data === undefined
}
export function isStringMaybe(data: unknown): boolean {
    return data === undefined || data === null || typeof data === "string"
}
export function either(validator: ((data: unknown)=>boolean)[]): (data: unknown)=>boolean {
    return (data: unknown): boolean => {
        return validator.some(v => v(data))
    }
}
export type DateKeysOf<T> = {
    [P in keyof T]: T[P] extends Date ? P : never;
}[keyof T]

export type MaybeDateKeysOf<T> = {
    [P in keyof T]: T[P] extends (Date|null|undefined) ? P : never;
}[keyof T]

export function isConvertedToDate<T>(data: Unknown<T>, key: DateKeysOf<T>): boolean {
    try {
        if(Object.prototype.toString.call(data[key]) === '[object Date]') {
            return true
        }
        if(typeof data[key] === "string") {
            const parseDate = new Date(data[key] as unknown as string)
            if(Object.prototype.toString.call(parseDate) === '[object Date]') {
                data[key] = parseDate
                return true
            }
        }
        return false
    } catch {
        return false
    }
}

export function isMaybeConvertedToDate<T>(data: Unknown<T>, key: MaybeDateKeysOf<T>): boolean {
    try {
        if(data[key] === null || data[key] === undefined || Object.prototype.toString.call(data[key]) === '[object Date]') {
            return true
        }
        if(typeof data[key] === "string") {
            const parseDate = new Date(data[key] as unknown as string)
            if(Object.prototype.toString.call(parseDate) === '[object Date]') {
                data[key] = parseDate
                return true
            }
        }
        return false
    } catch {
        return false
    }
}

export function isNumber(data: unknown): data is number {
    return typeof data === "number"
}
export function isNumberMaybe(data: unknown): boolean {
    return data === undefined || data === null || typeof data === "number"
}

export function isBoolean(data: unknown): boolean {
    return typeof data === "boolean"
}

export function isBooleanMaybe(data: unknown): boolean {
    return data === undefined || data === null || typeof data === "boolean"
}

export function hasKey<T extends object>(data: T, key: symbol | string | number): key is keyof T {
    return data.hasOwnProperty(key)
}

export function isObject(data: unknown): data is object {
    return typeof data == "object";
}

export function isArray(data: unknown): data is any[] {
    return Array.isArray(data);
}

export function isArrayOfStrings(data: unknown): data is string[] {
    return isArray(data) && data.every(val => isString(val));
}

export function has(data: unknown, key: symbol | string | number, typeCheck: (data: unknown) => boolean): boolean {
    if (isObject(data)) {
        if (hasKey(data, key)) {
            return typeCheck(data[key])
        }
    }
    return false
}

export function validate<T>(validator: (data: Unknown<T>) => data is T, TypeHintLabel: string): (unvalidatedData: Unknown<T>) => T {
    return (unvalidatedData: Unknown<T>) => {
        if (validator(unvalidatedData)) {
            return unvalidatedData
        }
        throw new Error(`${TypeHintLabel} was not in expected format: ${JSON.stringify(unvalidatedData)}`)
    }
}

export function validateList<T>(validator: (data: Unknown<T>[]) => data is T[], TypeHintLabel: string): (unvalidatedData: Unknown<T>[]) => T[] {
    return (unvalidatedData: Unknown<T>[]) => {
        if (validator(unvalidatedData)) {
            return unvalidatedData
        }
        throw new Error(`${TypeHintLabel} was not in expected format: ${JSON.stringify(unvalidatedData)}`)
    }
}

export const isList = <T>(itemValidator: (data: Unknown<T>) => data is T) => (list: Unknown<T>[]): list is T[] => {
    return list.every(itemValidator)
}

export const isBlob = (data: Unknown<Blob>): data is Blob => {
    return data instanceof Blob;
};
