import React, { useState } from 'react';

import {Document} from '../../../domain/Document';
import {useTranslation} from '../../../hooks/useTranslation';
import {getTranslation} from '../../../domain/Translations';
import { useOptionalDocumentCurrentNote } from '../../../hooks/useDocumentCurrentNote';
import { Button, ButtonSize, ButtonVariant } from '../elements/Button';

import './EditAssigneeSection.scss';
import { UserInfoSection } from './UserInfoSection';

export interface EditAssigneeSectionProps {
    document: Document;
}

export const EditAssigneeSection: React.VFC<EditAssigneeSectionProps> = ({ document }) => {
    const { data: translations } = useTranslation('document');
    const { data: history } = useOptionalDocumentCurrentNote(document.id);
    const [showMore, setShowMore] = useState(false);

    if (!translations) return null;

    return (

        <UserInfoSection
            header={getTranslation(translations, 'ppqApp.document.assignee')}
            userId={document.assignee}>
            {history && history.note && (
                <div className={`assignee-note ${showMore ? 'expanded' : ''}`}>
                    <b>Note to assignee:</b>
                    <div className="assignee-note-text">
                        {history.note}
                        <div className="assignee-note-fade"></div>
                    </div>
                    <Button size={ButtonSize.Small} className="assignee-note-btn" variant={ButtonVariant.LINK} onClick={() => setShowMore(true)}>Show more</Button>
                </div>
            )}
        </UserInfoSection>
    );
};
