import React, { useState } from 'react';

import { Button } from '../elements/Button';
import { FormField } from '../elements/FormField';
import Modal from '../elements/Modal';
import { TextareaElement } from '../elements/TextareaElement';
import { WithTestID } from '../elements/WithTestID';

export interface DocumentCommentModalProps extends WithTestID {
    show: boolean;
    onCancel: () => void;
    onSubmit: (comment: string) => void;
}

export const DocumentCommentModal: React.VFC<DocumentCommentModalProps> = ({ show, onCancel, onSubmit, testID }) => {
    const [comment, setComment] = useState<string>('');

    return (
        <Modal show={show} onHide={onCancel} size="lg" testID={testID}>
            <Modal.Body>
                <p><strong>Document Division updated</strong></p>
                <p>Please enter a comment for this action.</p>
                <FormField
                    id="comment"
                    help="The comment entered here will be visible in the document history."
                    control={
                        <TextareaElement
                            value={comment}
                            onChange={setComment}
                            required
                            testID={testID ? `${testID}:input` : undefined}
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    onClick={() => onSubmit(comment)}
                    disabled={!comment}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    )
};

