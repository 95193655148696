import {useAuthenticatedUser} from "../Context/AuthenticationContext";
import {useAppType} from "../Context/ServiceContext";
import {AppRole, canEditBriefWithMinister, userHasAppRole} from "../domain/Account";
import {Document, isAtLeastApproved, isSavedDocument, ReviewStatus, UnsavedDocument} from "../domain/Document";
import {DocumentLock} from "../domain/DocumentLock";

export const useDocumentReadOnly = (document: Document|UnsavedDocument, lock: DocumentLock|undefined) => {
    const currentUser = useAuthenticatedUser();
    const appType = useAppType();

    if(!isSavedDocument(document)) {
        return false
    }

    const isLockedByCurrentUser = !!lock && lock.ok;
    const isWithMinister = document.reviewStatus === ReviewStatus.WithMinister;
    return (!isLockedByCurrentUser)
        || (isWithMinister && !canEditBriefWithMinister(currentUser, appType))
        || document.deleted
        || (userHasAppRole(currentUser, appType, AppRole.Coordinator) && isAtLeastApproved(document));
};
