export enum RequestErrorReason {
    ServerError = 'ServerError',
    NotFound = 'NotFound',
    Unknown = 'Unknown',
    Unauthorized = 'Unauthorized',
    Forbidden = 'Forbidden',
    Conflict = 'Conflict',
    BadRequest = 'BadRequest',
}

export class RequestError extends Error {
    readonly reason: RequestErrorReason;
    readonly data: any;

    constructor(reason: RequestErrorReason, message?: string, data?: any) {
        super(message);
        this.reason = reason;
        this.data = data;
    }
}
