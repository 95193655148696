import React, {FC} from "react";
import {Button} from "../elements/Button";
import {useFormikContext} from "formik";
import {useDocumentReadOnly} from "../../../hooks/useDocumentReadOnly";
import {Document} from "../../../domain/Document";
import {DocumentLock} from "../../../domain/DocumentLock";
import {DocumentUpdateAction} from "../../../services/resources";

interface Props {
    document: Document;
    lock: DocumentLock|undefined;
    handleUpdate: (action: DocumentUpdateAction) => void;
}

export const ReassignAction: FC<Props> = ({document, lock, handleUpdate}) => {

    const { isValid } = useFormikContext();
    const readOnly = useDocumentReadOnly(document, lock);

    const onReassign = () => handleUpdate(DocumentUpdateAction.Reassign)

    return <>
        {!readOnly && document.id && (
            <div className="d-grid gap-2">
                <br/>
                <Button disabled={!isValid} onClick={onReassign} testID="button-reassign">Reassign</Button>
            </div>
        )}
    </>
}
