import React from 'react';

import { ErrorInLayout } from '../../../components/ui/elements/ErrorInLayout';
import { LoadingOverlay } from '../../../components/ui/elements/LoadingOverlay';
import { ViewDocumentDetails } from '../../../components/ui/elements/ViewDocumentDetails';
import { AppLayout, AppLayoutWithoutContainer } from '../../../components/ui/layouts/AppLayout';
import { ViewDocumentLayout } from '../../../components/ui/layouts/ViewDocumentLayout';
import { useDocumentHistory } from '../../../hooks/useDocumentHistory';
import { useIdParam } from '../../../hooks/useIdParam';
import { NotFound } from '../../NotFound';

export const PPQViewHistory: React.VFC = () => {
    const id = useIdParam();

    if (id === undefined) {
        return <NotFound />;
    }

    return <ViewHistoryCmp id={id} />;
};

const ViewHistoryCmp: React.VFC<{id: number}> = ({ id }) => {

    const { data: history, error } = useDocumentHistory(id);

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    if (!history) {
        return (
            <AppLayout>
                <LoadingOverlay show />
            </AppLayout>
        );
    }

    return (
        <AppLayoutWithoutContainer>
            <ViewDocumentLayout>
                <ViewDocumentDetails document={history} />
            </ViewDocumentLayout>
        </AppLayoutWithoutContainer>
    );
};
