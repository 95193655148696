import useSWR from 'swr';
import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { DocumentHistoryDto } from '../domain/DocumentHistory';
import { DocumentID } from "../services/ExternalResource";
import { fetchAllDocumentHistories } from '../services/fetchAllDocumentHistories';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useDocumentHistoryList = (id: DocumentID) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    return useSWR<DocumentHistoryDto[], ErrorWithDetails>(`documentHistoryList:${appType.appType}:${id}`, () => {
        return fetchAllDocumentHistories(externalResource, appType, id)
            .catch(throwErrorWithDetails);
    });
};
