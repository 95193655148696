import { useState, VFC } from "react";
import { useSWRConfig } from "swr";
import { Link, useLocation } from "react-router-dom";

import { useRouter } from "../../../hooks/useRouter";
import {
    useAppType,
    useExternalResource,
} from "../../../Context/ServiceContext";

import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { AppLayout, AppLayoutHeading } from "../../../components/ui/layouts/AppLayout";
import { SectionSingleColumn } from "../../../components/ui/layouts/SectionSingleColumn";

import { TextField } from "../../../components/form/fields/TextField";
import { SelectFormField } from "../../../components/form/fields/SelectFormField";
import { CheckboxField } from "../../../components/form/fields/CheckboxField";
import { Form } from "../../../components/form/forms/Form";

import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { AdminRole, AppRole } from "../../../domain/Account";
import { DivisionDropDownField } from "../../../components/form/fields/DivisionDropDownField";
import { appType } from "../../../domain/AppType";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { Alert, AlertVariant } from "../../../components/ui/elements/Alert";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { CreateUserAPIRequest } from "../../../services/resources";
import { fieldIsEmail, fieldMaxLength, fieldMinLength, fieldRequired } from "../../../components/form/fields/FieldValidation";
import { RequestErrorReason } from "../../../errors/RequestError";
import { createUser } from "../../../services/createUser";
import { notificationService } from "../../../services/notificationService";
import { AppRoleFormField } from "./EditUser";
import { useFeature } from "../../../hooks/useFeature";
import { FeatureType } from "../../../domain/Feature";

interface UserFormValues {
    enabled: 'Enabled' | 'Disabled';
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    divisionId: number | undefined;
    ppqRole: AppRole | '';
    paecRole: AppRole | '';
    systemAdmin: boolean;
    email: string;
}

const getInitialValues = (email: string|undefined): UserFormValues => ({
    enabled: 'Enabled',
    firstName: '',
    lastName: '',
    phone: '',
    mobile: '',
    divisionId: undefined,
    ppqRole: '',
    paecRole: '',
    systemAdmin: false,
    email: email ?? '',
});

const convertFormValuesToCreateRequest = (formValues: UserFormValues): CreateUserAPIRequest => {
    return {
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phone: formValues.phone,
        mobile: formValues.mobile,
        division: formValues.divisionId === undefined ? undefined : { id: formValues.divisionId, name: '' },
        enabled: formValues.enabled === 'Enabled',
        ppqRole: formValues.ppqRole || null,
        paecRole: formValues.paecRole || null,
        adminRole: formValues.systemAdmin ? AdminRole.SysAdmin : null,
    };
};

interface CreateUserFormProps {
    email?: string;
    onSave: () => void;
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({ email, onSave }) => {
    const externalResource = useExternalResource();
    const theAppType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();

    const { data: loginSSOEnabled } = useFeature(FeatureType.LOGIN_SSO);
    const { data: loginEmailEnabled } = useFeature(FeatureType.LOGIN_EMAIL_PASSWORD);

    const loginWithSSOOnly = loginSSOEnabled === true && loginEmailEnabled === false;

    const onSubmit = async (data: UserFormValues) => {
        setSaving(true);
        try {
            await createUser(externalResource, convertFormValuesToCreateRequest(data));
            notificationService.showSuccessNotification('User account created');
            onSave();
            setSaving(false);
            gotoOrReload(`/app/${theAppType.appType}/admin/user`);
        } catch (err) {
            let errorWithDetails = convertErrorToErrorWithDetails(err as Error);
            if (errorWithDetails.reason === RequestErrorReason.BadRequest) {
                // indicates that the email address is already in use
                errorWithDetails = {
                    message: 'Email address already in use',
                    details: 'A user account already exists with this email address.'
                };
            }
            setError(errorWithDetails);
            setSaving(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <AppLayoutHeading>Create New User</AppLayoutHeading>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}

            {loginWithSSOOnly && (
                <Alert variant={AlertVariant.INFO}>
                    User access to Web PPQ/PAEC is through Single Sign-On. Please make sure that the email
                    address entered below is correct and matches the address in the central directory.
                </Alert>
            )}

            <Alert variant={AlertVariant.INFO}>
                Users must activate their account by logging in within two days of the account's creation.
                If the account is not activated within two days it will be automatically removed.
            </Alert>

            <Form
                initialValues={getInitialValues(email)}
                onSubmit={onSubmit}
                FormBody={() => (
                    <div className="manage-admin-form">
                        <TextField
                            id="email"
                            label="Email"
                            validationRules={[
                                fieldRequired('Email is required'),
                                fieldIsEmail('Must be a valid email address'),
                                fieldMinLength(5, 'Email must be at least 5 characters'),
                                fieldMaxLength(255, 'Email cannot be longer than 255 characters'),
                            ]}
                        />
                        <SelectFormField
                            id="enabled"
                            options={[
                                { value: "Enabled", label: "Enabled" },
                                { value: "Disabled", label: "Disabled" },
                            ]}
                            label="Enabled"
                            help="If the user account is disabled, the user will not be able to log in."
                        />
                        <TextField
                            id="firstName"
                            label="First Name"
                            validationRules={[
                                fieldMaxLength(50, 'First name cannot be longer than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            validationRules={[
                                fieldMaxLength(50, 'Last name cannot be longer than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="phone"
                            label="Contact Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Phone Number cannot be longer than 20 characters'),
                            ]}
                        />
                        <TextField
                            id="mobile"
                            label="Contact Mobile Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Mobile Phone Number cannot be longer than 20 characters'),
                            ]}
                        />
                        <DivisionDropDownField
                            id="divisionId"
                            label="Division"
                            multiple={false}
                        />
                        <AppRoleFormField
                            id="ppqRole"
                            label="PPQ Role"
                            appType={appType.PPQ}
                        />
                        <AppRoleFormField
                            id="paecRole"
                            label="PAEC Role"
                            appType={appType.PAEC}
                            help="If no PPQ or PAEC role is selected, the user will not be able to log in."
                        />
                        <CheckboxField id="systemAdmin" label="System Admin" />
                    </div>
                )}
                FormActions={({ isValid }) => (
                    <ButtonList>
                        <Link to={`/app/${theAppType.appType}/admin/user`} className="btn btn-outline-primary">Cancel</Link>
                        <SaveButton disabled={!isValid}>Save</SaveButton>
                    </ButtonList>
                )}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

export const CreateUser: VFC = () => {
    const { mutate: globalMutate } = useSWRConfig();
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    return (
        <AppLayout>
            <SectionSingleColumn>
                <CreateUserForm email={params.get('email') || undefined} onSave={() => {
                    // clear the 'all users' caches
                    globalMutate('allUsers:PPQ');
                    globalMutate('allUsers:PAEC');
                }} />
            </SectionSingleColumn>
        </AppLayout>
    );
};
