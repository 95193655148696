import { has, isNumber, isString } from "./validateUnknown";

export interface CountDTO {
    resourceType: string;
    count: number;
}

export const isCountDTO = (count: unknown): count is CountDTO => {
    return has(count, 'resourceType', isString)
        && has(count, 'count', isNumber);
};
