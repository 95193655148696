import { useCallback, useEffect, useRef, useState } from "react";

export const INACTIVTY_WARNING_MILLIS = 900000; // 15 mins
export const INACTIVTY_TIMEOUT_MILLIS = 1200000; // 20 mins

export enum IdleState {
    Active = 'Active',
    Warning = 'Warning',
    Timeout = 'Timeout',
}

export const useIdleTimeout = (enabled = true, warningTime = INACTIVTY_WARNING_MILLIS, timeoutTime = INACTIVTY_TIMEOUT_MILLIS) => {
    const [idleState, setIdleState] = useState<IdleState>(IdleState.Active);

    const warningTimerRef = useRef<NodeJS.Timeout>();
    const timeoutTimerRef = useRef<NodeJS.Timeout>();

    const clearTimers = useCallback(() => {
        if (warningTimerRef.current) {
            clearTimeout(warningTimerRef.current);
            warningTimerRef.current = undefined;
        }
        if (timeoutTimerRef.current) {
            clearTimeout(timeoutTimerRef.current);
            timeoutTimerRef.current = undefined;
        }
    }, []);

    const resetTimers = useCallback(() => {
        clearTimers();
        setIdleState(IdleState.Active);
        warningTimerRef.current = setTimeout(() => {
            setIdleState(IdleState.Warning);
            warningTimerRef.current = undefined;
        }, warningTime);
        timeoutTimerRef.current = setTimeout(() => {
            setIdleState(IdleState.Timeout);
            timeoutTimerRef.current = undefined;
        }, timeoutTime);
    }, [clearTimers, timeoutTime, warningTime]);

    useEffect(() => {
        if (enabled) {
            resetTimers();
            document.body.addEventListener('keydown', resetTimers);
            document.body.addEventListener('click', resetTimers);
        }
        return () => {
            clearTimers();
            document.body.removeEventListener('keydown', resetTimers);
            document.body.removeEventListener('click', resetTimers);
        };
    }, [enabled, resetTimers, clearTimers]);

    return idleState;
};
