import { CSSProperties, FC } from "react";

export interface ButtonListProps {
    className?: string;
    align?: CSSProperties["justifyContent"];
}

export const ButtonList: FC<ButtonListProps> = ({
    align = "left",
    className,
    children,
}) => {
    let cs = ["d-flex gap-3 align-items-center"];
    if (className) {
        cs.push(className);
    }
    return (
        <div className={cs.join(" ")} style={{ justifyContent: align }}>
            {children}
        </div>
    );
};
