import {Services} from "../../Context/ServiceContext";
import {fetchJSON, fetchJSONList} from "./axios/fetchJSON";
import {submitForm} from "./axios/submitForm";
import {putJSON, submitJSON, submitJSONForCreate, submitJSONList} from "./axios/submitJSON";
import {AppType, appType, GetAppType} from "../../domain/AppType";
import {ParseLocation} from "../URL/ParseLocation";
import {
    ExternalResource,
    ListActions,
    ListResource,
    resource,
    SingleActions,
    SingleResource
} from "../ExternalResource";
import {assertNever} from "../TypeUtils";
import {deleteJSON} from "./axios/deleteJSON";
import {DeleteDivisionAPIRequest} from "../resources";
import {TranslationRequest} from "../fetchTranslations";
import {SubmitWorkflowAction} from "../../domain/Actions";

type UnknownResource = ListResource<unknown, unknown>|SingleResource<unknown, unknown>;

const singletons: Record<appType, AppType> = {
    [appType.PPQ]: GetAppType(appType.PPQ),
    [appType.PAEC]: GetAppType(appType.PAEC)
}
export type APIConfig = {
    urlFromResource: (r: UnknownResource, data: unknown) => string
}
export class HTTPExternalResourceAction implements ExternalResource {
    private apiConfig: APIConfig;

    constructor(apiConfig: APIConfig) {
        this.apiConfig = apiConfig;
    }

    action<SEND, RECV, R extends SingleResource<SEND, RECV>>(rsrc: R, data: SEND): Promise<R["receiveType"]> {
        const url = this.apiConfig.urlFromResource(rsrc, data)
        switch (rsrc.action) {
            case SingleActions.Send:
            case SingleActions.Create:
                if (rsrc.resource === resource.Document) {
                    return submitJSONForCreate<SEND,RECV>(url, data);
                } else {
                    return submitJSON<SEND,RECV>(url, data);
                }

            case SingleActions.Delete:
                if (rsrc.resource === resource.Document || rsrc.resource === resource.Division) {
                    return submitJSON<SEND,RECV>(url, data);
                } else {
                    return deleteJSON(url);
                }

            case SingleActions.Logout:
            case SingleActions.ObtainLock:
            case SingleActions.ClearChanges:
            case SingleActions.Renumber:
            case SingleActions.Cull:
            case SingleActions.Uncull:
            case SingleActions.Move:
            case SingleActions.MarkPendingReview:
            case SingleActions.MarkReadyForPrint:
            case SingleActions.MarkApproved:
            case SingleActions.MarkWithDTPCoordinator:
            case SingleActions.MarkWithMinister:
            case SingleActions.MarkNoteSeen:
            case SingleActions.RequestAccess:
            case SingleActions.CreateExtract:
            case SingleActions.SubmitPasswordActivate:
            case SingleActions.SubmitPasswordReset:
                return submitJSON<SEND,RECV>(url, data);
            case SingleActions.RequestPasswordReset:
                return submitJSON<SEND,RECV>(url, data, { headers: { 'Content-Type': 'text/plain' }});
            case SingleActions.Update:
                if (rsrc.resource === resource.User || rsrc.resource === resource.Account) {
                    return submitJSON<SEND,RECV>(url, data);
                } else {
                    return putJSON(url, data);
                }

            case SingleActions.Read:
            case SingleActions.ReadLatest:
            case SingleActions.GetCurrentNote:
            case SingleActions.CountDocuments:
                return fetchJSON(url)
            case SingleActions.Login:
                return submitForm(url, data)
            case SingleActions.PreviewPDF:
                return submitJSON<SEND,RECV>(url, data, { responseType: 'blob' });
            case SingleActions.AdminAlter:
                return submitJSON<SEND,RECV>(url, (data as any).body)
            default:
                return assertNever(rsrc.action);
        }
    }
    listAction<SEND, RECV, R extends ListResource<SEND, RECV>>(rsrc: R, data: SEND): Promise<R["receiveType"]> {
        const url = this.apiConfig.urlFromResource(rsrc, data)

        switch (rsrc.action) {
            case ListActions.List:
            case ListActions.PartialList:
            case ListActions.StatusReport:
            case ListActions.ListWithDisabled:
                return fetchJSONList<RECV>(url)

            case ListActions.Search:
                switch (rsrc.resource) {
                    case resource.Document:
                        return submitJSONList<SEND,RECV>(url, data)

                    case resource.User:
                        return fetchJSONList<RECV>(url)

                    case resource.Account:
                    case resource.Division:
                    case resource.DocumentHistory:
                    case resource.Feature:
                    case resource.Login:
                    case resource.Logout:
                    case resource.Portfolio:
                    case resource.SystemBasics:
                    case resource.Template:
                    case resource.Topic:
                    case resource.Translations:
                    case resource.UserEmail:
                    case resource.Workflow:
                        throw new Error(`Invalid action ${rsrc.action} for resource ${rsrc.resource}`);

                    default:
                        return assertNever(rsrc.resource);
                }

            case ListActions.ListSelectedInOrder:
            case ListActions.ListSelectedInOrderByPortfolios:
                return submitJSONList<SEND,RECV>(url, data)
            default:
                return assertNever(rsrc.action);
        }
    }
}

const serverOnSameDomainPathOnly = ""
export const api = (baseurl: string = serverOnSameDomainPathOnly) => {

    function resourceToPath<R extends UnknownResource> (externalResource: R, data: unknown): string|undefined {
        switch (externalResource.resource) {
            case resource.UserEmail: {
                switch (externalResource.action) {
                    case SingleActions.Send:
                        return "/admin/user/email"
                    default:
                        throw new Error(`Invalid action ${externalResource.action} for resource ${externalResource.resource}`);
                }
            }
            case resource.Document: {
                switch (externalResource.action) {
                    case SingleActions.Update:
                    case SingleActions.Create:
                        return `${externalResource.appType}/documents`;
                    case SingleActions.AdminAlter:
                        return `${externalResource.appType}/documents/${(data as any).id}/adminAlter`
                    case SingleActions.Delete:
                    case SingleActions.ObtainLock:
                    case SingleActions.PreviewPDF:
                    case SingleActions.ClearChanges:
                    case SingleActions.Renumber:
                    case SingleActions.Cull:
                    case SingleActions.Uncull:
                    case SingleActions.Move:
                    case SingleActions.MarkApproved:
                    case SingleActions.MarkPendingReview:
                    case SingleActions.MarkReadyForPrint:
                    case SingleActions.MarkWithDTPCoordinator:
                    case SingleActions.MarkWithMinister:
                    case SingleActions.MarkNoteSeen:
                    case ListActions.ListSelectedInOrder:
                    case ListActions.ListSelectedInOrderByPortfolios:
                        return `${externalResource.appType}/documents/${data}`;
                    case SingleActions.Read:
                        return `${externalResource.appType}/documents/${data ? data : 'new'}`;
                    case SingleActions.GetCurrentNote:
                        return `${externalResource.appType}/documents/${data}/currentNote`;
                    case ListActions.Search:
                        return `${externalResource.appType}/documents/search`;
                    case SingleActions.CreateExtract:
                        return `${externalResource.appType}/extract`;
                    case SingleActions.Login:
                    case SingleActions.Logout:
                    case SingleActions.ReadLatest:
                    case SingleActions.RequestAccess:
                    case SingleActions.RequestPasswordReset:
                    case SingleActions.SubmitPasswordActivate:
                    case SingleActions.SubmitPasswordReset:
                    case SingleActions.CountDocuments:
                    case SingleActions.Send:
                    case ListActions.List:
                    case ListActions.PartialList:
                    case ListActions.StatusReport:
                    case ListActions.ListWithDisabled:
                        throw new Error(`Invalid action ${externalResource.action} for resource ${externalResource.resource}`);

                    default:
                        return assertNever(externalResource);
                }
            }
            case resource.User:
                if (externalResource.action === SingleActions.Update) {
                    return `users/${(data as any).id}`;
                } else if (externalResource.action === SingleActions.Read) {
                    return `users/${data}`;
                } else if (externalResource.action === ListActions.Search) {
                    return 'users';
                } else if (externalResource.action === SingleActions.Create) {
                    return 'users';
                } else if (externalResource.action === ListActions.ListWithDisabled) {
                    return `${externalResource.appType}/allUsers`;
                } else {
                    return `${externalResource.appType}/users/`;
                }

            case resource.Account:
                return "account"
            case resource.Login:
                return `${resource}`
            case resource.Logout:
                return `${resource}`;
            case resource.Portfolio:
                if (externalResource.action === SingleActions.CountDocuments) {
                    return `${externalResource.appType}/portfolios/${data}`;
                } else {
                    return `${externalResource.appType}/portfolios`;
                }
            case resource.Topic:
                return `${externalResource.appType}/topics/${data}`;
            case resource.Division:
                if (externalResource.action === SingleActions.Update) {
                    return `divisions/${(data as any).id}`;
                } else {
                    return "divisions";
                }
            case resource.SystemBasics:
                throw new Error(`Unhandled special action URL ${resource}`)
            case resource.Feature:
                return 'features';
            case resource.Translations:
                const req = data as TranslationRequest;
                return `/i18n/en-${req.ppqDepartment}/${req.partName}.json`;
            case resource.Template:
                return `${externalResource.appType}/templates`;
            case resource.Workflow:
                switch (externalResource.action) {
                    case SingleActions.Send:
                        return `workflow/${(data as SubmitWorkflowAction).documentID}/process`
                    case ListActions.List:
                        return `workflow/${data}/actions`                }
                throw new Error(`Unhandled URL for Workflow action: ${externalResource.action}`)
            case resource.DocumentHistory:
                if (externalResource.action === ListActions.PartialList) {
                    return `${externalResource.appType}/documents/${data}/partialHistory`;
                } else if (externalResource.action === ListActions.List) {
                    return `${externalResource.appType}/documents/${data}/history`;
                } else if (externalResource.action === SingleActions.ReadLatest) {
                    return `${externalResource.appType}/documentHistorys/latest/${data}`;
                } else {
                    return `${externalResource.appType}/documentHistorys/${data}`;
                }
            default:
                assertNever(externalResource.resource);
        }
    }

    return function urlFromResource<R extends UnknownResource, OUT>(r: R, data?: OUT | undefined): string {

        switch (r.action) {
            case ListActions.Search:
                if (r.resource === resource.Document) {
                    return `/api/${resourceToPath(r, 'search')}/`
                } else if (r.resource === resource.User) {
                    return appendQueryParameters(`/api/${resourceToPath(r, data)}`, data as unknown as QueryParams);
                }
                return `/api/${resourceToPath(r, data)}`;

            case ListActions.List:
            case ListActions.PartialList:
            case ListActions.ListWithDisabled:
                return `/api/${resourceToPath(r, data)}`;
            case ListActions.ListSelectedInOrder:
                return `/api/${resourceToPath(r, 'getMultipleInOrder')}`;
            case ListActions.ListSelectedInOrderByPortfolios:
                return `/api/${resourceToPath(r, 'getMultipleInOrderByPortfolios')}`;
            case SingleActions.Read:
                if(r.resource === resource.SystemBasics) {
                    return "/system/basic"
                } else if (r.resource === resource.Translations) {
                    return `${resourceToPath(r, data)}`;
                }
                return `/api/${resourceToPath(r, data)}`
            case SingleActions.ReadLatest:
                return `/api/${resourceToPath(r, data)}`
            case SingleActions.Create:
                return `/api/${resourceToPath(r, data)}`
            case SingleActions.Update:
                return `/api/${resourceToPath(r, data)}`
            case SingleActions.Delete:
                if (r.resource === resource.Document) {
                    return `/api/${resourceToPath(r, 'delete')}`;
                } else if (r.resource === resource.Division) {
                    const req = data as unknown as DeleteDivisionAPIRequest;
                    return `/api/${resourceToPath(r, data)}/${req.id}/archive`;
                } else {
                    return `/api/${resourceToPath(r, '')}/${data}`;
                }

            case SingleActions.Login:
                return baseurl+"/api/authentication"
            case SingleActions.Logout:
                return `${baseurl}/api/logout`;

            case SingleActions.ObtainLock:
                return `/api/${resourceToPath(r, data)}/obtainLock`;

            case SingleActions.PreviewPDF:
                return `/pdf/${r.appType}/preview`;

            case SingleActions.AdminAlter:
                return `/api/${resourceToPath(r, data)}`;

            case SingleActions.GetCurrentNote:
                return `/api/${resourceToPath(r, data)}`;

            case SingleActions.ClearChanges:
                return `/api/${resourceToPath(r, 'clearChanges')}`;

            case SingleActions.Renumber:
                return `/api/${resourceToPath(r, 'renumber')}`;

            case SingleActions.Cull:
                return `/api/${resourceToPath(r, 'cull')}`;

            case SingleActions.Uncull:
                return `/api/${resourceToPath(r, 'uncull')}`;

            case SingleActions.Move:
                return `/api/${resourceToPath(r, 'move')}`;

            case SingleActions.MarkPendingReview:
                return `/api/${resourceToPath(r, 'markPendingReview')}`;

            case SingleActions.MarkReadyForPrint:
                return `/api/${resourceToPath(r, 'markReadyForPrint')}`;

            case SingleActions.MarkApproved:
                return `/api/${resourceToPath(r, 'markApproved')}`;

            case SingleActions.MarkWithDTPCoordinator:
                return `/api/${resourceToPath(r, 'markWithDTPCoordinator')}`;

            case SingleActions.MarkWithMinister:
                return `/api/${resourceToPath(r, 'markWithMinister')}`;

            case SingleActions.MarkNoteSeen:
                return `/api/${resourceToPath(r, 'markNoteSeen')}`;

            case SingleActions.RequestAccess:
                return '/api/requestAccess';

            case ListActions.StatusReport:
                return `/api/${resourceToPath(r, data)}/report/status`;

            case SingleActions.CreateExtract:
                return `/api/${resourceToPath(r, data)}`;

            case SingleActions.RequestPasswordReset:
                return '/api/account/reset_password/init';

            case SingleActions.SubmitPasswordActivate:
                return '/api/account/activate_password/finish';

            case SingleActions.SubmitPasswordReset:
                return '/api/account/reset_password/finish';

            case SingleActions.CountDocuments:
                return `/api/${resourceToPath(r, data)}/documents/count`;

            case SingleActions.Send:
                if(r.resource === resource.Workflow) {
                    return `/api/${resourceToPath(r, data)}`
                }
                return `/admin/user/email`;

            default:
                const exh : never = r
                throw new Error(`Unhandled action URL ${(exh as any).action}`)
        }
    }
}

type QueryParams = Record<string, string|number>;
const appendQueryParameters = (url: string, queryParameters: QueryParams) => {
    const query = Object.entries(queryParameters)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    return `${url}?${query}`;
};

export const HTTPServiceContext: Services = {
    externalResource: new HTTPExternalResourceAction({urlFromResource: api() }),
    getLocation: () => {
        const {location} = ParseLocation(window.location.hash)
        return location
    },
    getAppType: () => {
        const {appType} = ParseLocation(window.location.hash)
        if(appType === undefined) {
            throw new Error(`getAppType called outside known appType location: ${window.location.hash}`)
        }
        return singletons[appType]
    },
    getCurrentDocumentID: () => {
        const {documentId} = ParseLocation(window.location.hash)
        if(documentId === undefined) {
            throw new Error(`getCurrentDocumentID called at location without a document id: ${window.location.hash}`)
        }
        return documentId
    }
}

