import React from 'react';
import { getReviewStatusLabel, ReviewStatus } from '../../../domain/Document';
import { CulledIndicator, ReviewStatusIndicator } from './ReviewStatusIndicator';

export interface ReviewStatusLabelProps {
    reviewStatus: ReviewStatus;
    indicator?: boolean;
    label?: boolean;
}

export const ReviewStatusLabel: React.VFC<ReviewStatusLabelProps> = ({ reviewStatus, indicator = true, label = true }) => {
    return (
        <div className="d-flex gap-2 align-items-center">
            {indicator && <span className="flex-shrink-0 flex-grow-0"><ReviewStatusIndicator reviewStatus={reviewStatus} /></span>}
            {label && getReviewStatusLabel(reviewStatus)}
        </div>
    );
};

export interface CulledLabelProps {
    indicator?: boolean;
    label?: boolean;
}

export const CulledLabel: React.VFC<CulledLabelProps> = ({ indicator = true, label = true }) => {
    return (
        <div className="d-flex gap-2 align-items-center">
            {indicator && <span className="flex-shrink-0 flex-grow-0"><CulledIndicator /></span>}
            {label && 'Culled'}
        </div>
    );
};
