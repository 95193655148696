import useSWR from 'swr';
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { DocumentHistory } from "../domain/DocumentHistory";
import { DocumentID } from "../services/ExternalResource";
import { fetchDocumentCurrentNote } from "../services/fetchDocumentCurrentNote";
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useDocumentCurrentNote = (id: DocumentID) => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<DocumentHistory, ErrorWithDetails>(`documentCurrentNote:${appType.appType}:${id}`, () => {
        return fetchDocumentCurrentNote(externalResource, appType, id)
            .catch(throwErrorWithDetails);
    });
};

export const useOptionalDocumentCurrentNote = (id: DocumentID|undefined) => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<DocumentHistory|undefined, ErrorWithDetails>(
        () => id === undefined ? null : `documentCurrentNote:${appType.appType}:${id}`,
        () => {
            if (id) {
                return fetchDocumentCurrentNote(externalResource, appType, id)
                    .catch(throwErrorWithDetails);
            }
        }
    );
};
