import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { Checkbox } from "../../ui/elements/Checkbox";
import { FormField } from "../../ui/elements/FormField";
import { validateBoolean } from "./FieldValidation";
import { StandardFieldProps } from "./TextField";

export interface CheckboxFieldProps extends Omit<StandardFieldProps, 'label'> {
    label: string;
}

export const CheckboxField: React.VFC<CheckboxFieldProps> = ({ id, label, disabled, help, required, validationRules = [], testID }) => {
    const formik = useFormikContext();

    return (
        <Field name={id} validate={(value: boolean) => {
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateBoolean(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && !value) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<boolean, any>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        help={help}
                        id={id}
                        error={touched ? error : undefined}
                        control={
                            <Checkbox
                                disabled={disabled}
                                label={label}
                                checked={field.value}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                isInvalid={touched && !!error}
                                testID={testID}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
