import useSWR from 'swr';
import { useExternalResource } from '../Context/ServiceContext';
import { FeatureType, isFeatureEnabled } from "../domain/Feature";
import { fetchFeatures } from '../services/fetchFeatures';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useFeature = (type: FeatureType) => {
    const externalResource = useExternalResource();

    return useSWR<boolean, ErrorWithDetails>(`feature:${type}`, () => {
        return fetchFeatures(externalResource)
            .then(features => isFeatureEnabled(features, type))
            .catch(throwErrorWithDetails);
    })
};
