import useSWR from 'swr';
import { useExternalResource } from '../Context/ServiceContext';
import { Feature } from "../domain/Feature";
import { fetchFeatures } from "../services/fetchFeatures";
import { ErrorWithDetails, throwErrorWithDetails } from "./exceptionToError";

const sortFeatures = (list: Feature[]) => {
    list.sort((a, b) => a.name.localeCompare(b.name));
    return list;
};

export const useFeatures = () => {
    const externalResource = useExternalResource();

    return useSWR<Feature[], ErrorWithDetails>('features', () => {
        return fetchFeatures(externalResource)
            .then(sortFeatures)
            .catch(throwErrorWithDetails);
    });
};
