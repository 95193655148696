export class Deferred<T> {
    promise: Promise<T>;
    _resolve: ((arg: T | PromiseLike<T>) => void) | undefined;
    _reject: ((arg?: any) => void) | undefined;
    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
    resolve(arg: T | PromiseLike<T>) {
        if (this._resolve) {
            this._resolve(arg);
        }
    }
    reject(arg?: any) {
        if (this._reject) {
            this._reject(arg);
        }
    }
}
