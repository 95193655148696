import {Unknown} from "../../TypeUtils";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {getAxios} from "./AxiosSettings";
import {BustCache} from "../bustCache";

export function submitJSON<SEND, RECEIVE>(url: string, data: SEND, config?: Partial<AxiosRequestConfig<RECEIVE>>): Promise<Unknown<RECEIVE>> {
    return getAxios(config)
        .post<SEND, AxiosResponse<RECEIVE>>(BustCache(url), data)
        .then(r => r.data ?? {} as Unknown<RECEIVE>);
}
export function submitJSONForCreate<SEND, RECEIVE>(url: string, data: SEND, config?: Partial<AxiosRequestConfig<RECEIVE>>): Promise<Unknown<RECEIVE>> {
    return getAxios(config)
        .post<SEND, AxiosResponse<RECEIVE>>(BustCache(url), data)
        .then(r => Number(r.headers.location) as Unknown<RECEIVE>);
}
export function putJSON<SEND, RECEIVE>(url: string, data: SEND): Promise<Unknown<RECEIVE>> {
    return getAxios()
        .put<SEND, AxiosResponse<RECEIVE>>(BustCache(url), data)
        .then(r => r.data ?? {} as Unknown<RECEIVE>);
}

export function submitJSONList<SEND, RECEIVE>(URL: string, data: SEND): Promise<Unknown<RECEIVE>[]> {
    return getAxios()
        .post<SEND, AxiosResponse<RECEIVE[]>>(BustCache(URL), data)
        .then(r => r.data ?? {} as Unknown<RECEIVE>);
}
