import {Unknown} from "../services/TypeUtils";
import {isBooleanMaybe, isMaybeConvertedToDate, isNumber, isNumberMaybe, isStringMaybe} from "./validateUnknown";
import { DocumentDetails, isDocumentDetails, DocumentStatus, ReviewStatus, isAppType, isDocumentStatusMaybe, isReviewStatusMaybe } from "./Document";
import {appType} from './AppType';

export interface DocumentHistory extends DocumentDetails {

    id: number;
    documentId: number;
    dateEdited: Date|null;
    editor: string|null;
    portfolioNumber: number|null;
    topicNumber: number|null;
    topicName: string|null;
    divisionName: string|null;
    note: string|null;
    noteSeen: boolean|null;
    comment: string|null;

}

export const isDocumentHistory = (data: Unknown<DocumentHistory>): data is DocumentHistory => {
    if (!isDocumentDetails(data as Unknown<DocumentDetails>)) return false;
    return isNumber (data.id)
        && isNumber (data.documentId)
        && isMaybeConvertedToDate (data, 'dateEdited')
        && isStringMaybe (data.editor)
        && isNumberMaybe (data.portfolioNumber)
        && isNumberMaybe (data.topicNumber)
        && isStringMaybe (data.topicName)
        && isStringMaybe (data.divisionName)
        && isStringMaybe (data.note)
        && isBooleanMaybe (data.noteSeen)
        && isStringMaybe (data.comment)
}

export const isDocumentHistoryList = (list: Unknown<DocumentHistory>[]): list is DocumentHistory[] => {
    return list.every(isDocumentHistory)
}

export interface DocumentHistoryDto {
    id: number;
    documentId: number;
    appType: appType;
    dateEdited: Date|null;
    editor: string|null;
    editorUserFullName: string|null;
    assignee: string|null;
    assigneeUserFullName: string|null;
    portfolioNumber: number|null;
    portfolioName: string|null;
    topicNumber: number|null;
    topicName: string|null;
    status: DocumentStatus|null;
    reviewStatus: ReviewStatus|null;
    note: string|null;
    noteSeen: boolean|null;
    comment: string|null;
    reference: string|null;
    tableData: string|null;
    commitments: string|null;
}

export const isDocumentHistoryDto = (data: Unknown<DocumentHistoryDto>): data is DocumentHistoryDto => {
    return isNumber (data.id)
        && isNumber (data.documentId)
        && isAppType (data.appType)
        && isMaybeConvertedToDate (data, 'dateEdited')
        && isStringMaybe (data.editor)
        && isStringMaybe (data.editorUserFullName)
        && isStringMaybe (data.assignee)
        && isStringMaybe (data.assigneeUserFullName)
        && isNumberMaybe (data.portfolioNumber)
        && isStringMaybe (data.portfolioName)
        && isNumberMaybe (data.topicNumber)
        && isStringMaybe (data.topicName)
        && isDocumentStatusMaybe (data.status)
        && isReviewStatusMaybe (data.reviewStatus)
        && isStringMaybe (data.note)
        && isBooleanMaybe (data.noteSeen)
        && isStringMaybe (data.comment)
        && isStringMaybe (data.reference)
        && isStringMaybe (data.tableData)
        && isStringMaybe (data.commitments);
};

export const isDocumentHistoryDtoList = (list: Unknown<DocumentHistoryDto>[]): list is DocumentHistoryDto[] => {
    return list.every(isDocumentHistoryDto)
}
