import { ExternalResource } from "./ExternalResource";
import { AppType } from "../domain/AppType";
import { Division, isDivision } from "../domain/Division";
import { validate } from "../domain/validateUnknown";

export function updateDivision(
    externalResource: ExternalResource,
    appType: AppType,
    division: Division
): Promise<Division> {
    return externalResource
        .action(appType.Resources.saveDivision, division)
        .then(validate(isDivision, "Division"));
}
