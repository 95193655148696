import { useAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { useAppType } from "../../../Context/ServiceContext";
import { AppRole, userHasAnyAppRole } from "../../../domain/Account";
import { FeatureType, isFeatureEnabled } from "../../../domain/Feature";
import { ErrorWithDetails } from "../../../hooks/exceptionToError";
import { useDashboardActions } from "../../../hooks/useDashboardActions";
import { toDocumentSearchSort, useDashboardList } from "../../../hooks/useDashboardList";
import { useFeatures } from "../../../hooks/useFeatures";
import { notificationService } from "../../../services/notificationService";
import { makeWordPluralWithCount } from "../../../textUtils";
import { FixedBottomRightStatusBar } from "../layouts/FixedBottomRightStatusBar";
import { AssigneeNoteModal } from "../modals/AssigneeNoteModal";
import { CullCommentModal } from "../modals/CullCommentModal";
import { DeleteConfirmationModal } from "../modals/DeleteConfirmationModal";
import { MoveDocumentsModal } from "../modals/MoveDocumentsModal";
import { Alert, AlertVariant } from "./Alert";
import { DashboardActions, DashboardActionType } from "./DashboardActions";
import { DocumentFilters, translateFiltersToSearch } from "./DocumentFilters";
import { DocumentTable } from "./DocumentTable";
import { LoadingOverlay } from "./LoadingOverlay";

export const Dashboard: React.VFC = () => {

    const userAccount = useAuthenticatedUser();
    const appType = useAppType()
    const tableProps = useDashboardList(appType);
    const { data: features } = useFeatures();
    const {
        saving,
        handleAction,
        showCullCommentModal,
        cancelModals,
        setCullComment,
        showMoveDocumentsModal,
        moveDocumentsToTopic,
        showDeleteModal,
        confirmDelete,
        showAssigneeModal,
        setAssignee,
    } = useDashboardActions();

    const limitReached = tableProps.limit > 0 && tableProps.limit === tableProps.data.length;

    const statusBarEnabled = features && isFeatureEnabled(features, FeatureType.BRIEF_SELECT_COUNT);
    const selectedDocuments = tableProps.data.filter(doc => doc.isSelected);
    const selectedCount = selectedDocuments.length;

    const doHandleAction = (action: DashboardActionType) => {
        handleAction(action, tableProps.data, toDocumentSearchSort(tableProps.sort))
            .then((saved) => {
                if (saved) {
                    tableProps.doSearch();
                    notificationService.showSuccessNotification('Saved changes');
                }
            })
            .catch((err) => {
                const error = err as ErrorWithDetails;
                notificationService.showErrorNotification(error.message);
            });
    };

    const assignNoteMandatory = !userHasAnyAppRole(userAccount, appType, [AppRole.Admin, AppRole.Coordinator]);

    return (
        <>
            <DocumentFilters
                onSearch={(arg) => {
                    tableProps.setSearch(translateFiltersToSearch(arg));
                }}
                onClear={() => {
                    tableProps.setSearch(undefined);
                    tableProps.clearSort();
                }}
            />
            <DashboardActions limit={tableProps.limit} setLimit={tableProps.setLimit} handleAction={doHandleAction} selectedDocuments={selectedDocuments} />
            <DocumentTable testID={'document-table'} columns={[ ...appType.GetDashboardColumns(userAccount), 'SelectColumn' ]} {...tableProps} />
            {limitReached && (
                <Alert variant={AlertVariant.INFO}>
                    <strong>Showing maximum documents {tableProps.limit}.</strong>{' '}
                    Use the options at the top to show more.
                </Alert>
            )}
            {statusBarEnabled && (
                <FixedBottomRightStatusBar>
                    {makeWordPluralWithCount('brief', selectedCount)} selected
                </FixedBottomRightStatusBar>
            )}
            <CullCommentModal
                show={showCullCommentModal}
                onCancel={cancelModals}
                onSubmit={setCullComment}
                documentCount={selectedCount}
            />
            <MoveDocumentsModal
                show={showMoveDocumentsModal}
                onCancel={cancelModals}
                onSubmit={moveDocumentsToTopic}
                documentCount={selectedCount}
            />
            <DeleteConfirmationModal
                show={showDeleteModal}
                onCancel={cancelModals}
                onSubmit={confirmDelete}
                documentCount={selectedCount}
            />
            <AssigneeNoteModal
                show={showAssigneeModal}
                onCancel={cancelModals}
                onSubmit={setAssignee}
                mandatory={assignNoteMandatory}
                assignToAuthorByDefault={!assignNoteMandatory}
            />
            <LoadingOverlay show={tableProps.loading || saving} message={saving ? 'Please wait...' : 'Loading...'} />
        </>
    );
};

