import { IconType } from "../../../components/ui/elements/Icon";

import {
    ButtonVariant,
    IconButton,
    IconPosition,
    ButtonProps,
} from "../../../components/ui/elements/Button";

export const ArchiveButton: React.FC<ButtonProps> = ({
    children,
    ...props
}) => (
    <IconButton
        variant={ButtonVariant.DANGER}
        icon={IconType.Delete}
        iconPosition={IconPosition.Right}
        {...props}
    >
        {children}
    </IconButton>
);
