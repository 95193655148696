import useSWR from 'swr';

import { useExternalResource } from "../Context/ServiceContext";
import { User } from '../domain/User';
import { fetchUserById } from '../services/fetchUserById';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useFetchUserById = (id: number) => {
    const externalResource = useExternalResource();

    return useSWR<{ user: User|undefined }, ErrorWithDetails>(`userById:${id}`, () => {
        return fetchUserById(externalResource, id)
            .then(user => ({ user }))
            .catch(throwErrorWithDetails);
    });

};
