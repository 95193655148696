import React from 'react';
import * as Sentry from '@sentry/react';

import { AppLayout, CentredAppLayoutHeading } from '../ui/layouts/AppLayout';

const ErrorPage: React.VFC = () => (
    <AppLayout>
        <div className="pt-5">
            <CentredAppLayoutHeading>Application Error</CentredAppLayoutHeading>
            <p className="text-center">Sorry, an application error has occurred.</p>
            <p className="text-center">
                The error has been reported to the application team.
                Please try again, or contact your system administrator for help.
            </p>
        </div>
    </AppLayout>
);

export const ErrorBoundary: React.FC = ({children}) => (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>
);
