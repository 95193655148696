import {Division, isDivision} from "./Division";
import {assertNever, Unknown} from "../services/TypeUtils";
import {
    either,
    has,
    isArray,
    isBoolean,
    isConvertedToDate,
    isMaybeConvertedToDate,
    isNull,
    isNumber,
    isObject,
    isString,
    isStringMaybe
} from "./validateUnknown";
import {AppType, appType} from "./AppType";
import { Account, AppRole, canEditBriefs, isMinister, userHasAppRole } from "./Account";

export interface Topic {
    id: number;
    number: number;
    name: string;
    culled: boolean;
    reportable: boolean;
}

export interface TopicDocuments {
    topic: Topic;
    documents: Document[];
}

export interface Portfolio {
    id: number;
    number: number;
    name: string;
    appType: appType;
    topics: Topic[];
}

export interface PortfolioStatusReport {
    portfolio: string;
    count: number;
    pending: number;
    approved: number;
    percent: string;
}

export enum ReviewStatus {
    PendingReview = 'PENDING_REVIEW',
    InProgress = 'IN_PROGRESS',
    PendingApproval = 'PENDING_APPROVAL',
    RequiresUpdate = 'REQUIRES_UPDATE',
    Approved = 'APPROVED',
    WithDtpCoordinator = 'WITH_DTP_COORDINATOR',
    ReadyForPrint = 'READY_FOR_PRINT', // "Assigned to central team"
    WithMinister = 'WITH_MINISTER',
}

export const getReviewStatusLabel = (status: ReviewStatus): string => {
    switch (status) {
        case ReviewStatus.Approved: return 'Approved by Group';
        case ReviewStatus.InProgress: return 'In Progress';
        case ReviewStatus.PendingApproval: return 'Pending Approval';
        case ReviewStatus.PendingReview: return 'Pending Review';
        case ReviewStatus.ReadyForPrint: return 'Assigned to PAEC team';
        case ReviewStatus.RequiresUpdate: return 'Requires Update';
        case ReviewStatus.WithDtpCoordinator: return 'With DTP-Coordinator';
        case ReviewStatus.WithMinister: return 'With Minister';
        default: return assertNever(status);
    }
};

export enum DocumentStatus {
    New = 'NEW',
    ReviewedNoChange = 'REVIEWED_NO_CHANGE',
    Updated = 'UPDATED',
}

export const getDocumentStatusLabel = (status: DocumentStatus): string => {
    switch (status) {
        case DocumentStatus.New: return 'New';
        case DocumentStatus.ReviewedNoChange: return 'Reviewed';
        case DocumentStatus.Updated: return 'Updated';
        default: return assertNever(status);
    }
};

export interface DocumentRoles {
    id: number
    login: string
}

export interface AllowedRoles {
    key: string,
    label: string,
    required?: boolean
    noApprovalAccessWarning: string,
}

export interface DocumentDetails extends CommonDocumentFormFields {
    appType: appType;
    dateCreated: Date;
    dateModified: Date;
    dateLastChange: Date;
    dateLastSignificantChange: Date;
    status: DocumentStatus;
    reviewStatus: ReviewStatus;
    givenToMinister: string|null;
    assignee: string;
    approver: string|null;
    number: number;
    deleted: boolean;
    trash: boolean;
    dpcApprover: string|null;
    hashTags: string|null;
    reference: string|null;
    tableData: string|null;
    commitments: string|null;
    portfolioName: string;
    paecPdfFormattedNumber: string;
    formattedNumber: string;
    allowedRoles: AllowedRoles[]
}

export interface CommonDocumentFormFields {
    title: string;
    question: string|null;
    background: string|null;
    talkingPoints: string|null;
    creator: string;
    roles?: Record<string, DocumentRoles>
}

export interface Document extends DocumentDetails {
    id: number;
    lockedBy: string|null;
    dateLocked: Date|null;
    topic: Topic;
    division: Division|null;
    portfolioNumber: string;
    shortFormattedNumber: string;
    stringNumber: string;
    hasBackground: boolean;
    hasQuestion: boolean;
    hasReference: boolean;
    hasTableData: boolean;
    hasCommitments: boolean;
}

export interface UnsavedDocument extends Pick<DocumentDetails, "appType" | "allowedRoles" | "status" | "reviewStatus" | "assignee">, CommonDocumentFormFields, Pick<Document, "topic" | "division"> {

}

export function isAppType(appTypeUnknown: unknown): appTypeUnknown is appType {
    return isString(appTypeUnknown) && Object.values(appType).includes(appTypeUnknown as appType)
}

export function isReviewStatus(reviewStatus: unknown): reviewStatus is ReviewStatus {
    return isString(reviewStatus) && Object.values(ReviewStatus).includes(reviewStatus as ReviewStatus);
}

export function isReviewStatusMaybe (reviewStatus: unknown): boolean {
    return reviewStatus === null
        || reviewStatus === undefined
        || isReviewStatus(reviewStatus);
}

export function isDocumentStatus(documentStatus: unknown): documentStatus is DocumentStatus {
    return isString(documentStatus) && Object.values(DocumentStatus).includes(documentStatus as DocumentStatus);
}

export function isDocumentStatusMaybe(documentStatus: unknown): boolean {
    return documentStatus === null
        || documentStatus === undefined
        || isDocumentStatus(documentStatus);
}

export const isDocumentDetails = (data: Unknown<DocumentDetails>): data is DocumentDetails => {
    return isAppType (data.appType)
        && isConvertedToDate (data, 'dateCreated')
        && isConvertedToDate (data, 'dateModified')
        && isConvertedToDate (data, 'dateLastChange')
        && isConvertedToDate (data, 'dateLastSignificantChange')
        && isString (data.title)
        && either([isString, isNull])(data.question)
        && either([isString, isNull]) (data.background)
        && either([isString, isNull]) (data.talkingPoints)
        && isDocumentStatus (data.status)
        && isReviewStatus (data.reviewStatus)
        && either([isString, isNull]) (data.givenToMinister)
        && isString (data.creator)
        && isString (data.assignee)
        && either([isString, isNull]) (data.approver)
        && isNumber (data.number)
        && isBoolean (data.deleted)
        && isBoolean (data.trash)
        && either([isString, isNull]) (data.dpcApprover)
        && either([isString, isNull]) (data.hashTags)
        && either([isString, isNull]) (data.reference)
        && either([isString, isNull]) (data.tableData)
        && either([isString, isNull]) (data.commitments)
        && isString (data.portfolioName)
        && isString (data.paecPdfFormattedNumber)
        && isString (data.formattedNumber)
};

export const isDocument = (data: Unknown<Document>): data is Document => {
    if (!isDocumentDetails(data as Unknown<DocumentDetails>)) return false;
    return isNumber (data.id)
    && isStringMaybe (data.lockedBy)
    && isMaybeConvertedToDate (data, 'dateLocked')
    && isTopic (data.topic)
    && either([isNull, isDivision]) (data.division)
    && isString (data.portfolioNumber)
    && isString (data.shortFormattedNumber)
    && isString (data.stringNumber)
    && isBoolean (data.hasBackground)
    && isBoolean (data.hasQuestion)
    && isBoolean (data.hasReference)
    && isBoolean (data.hasTableData)
    && isBoolean (data.hasCommitments)
}

export const isUnsavedDocument = (data: Unknown<UnsavedDocument>): data is UnsavedDocument => {
    return isAppType (data.appType) &&
        isObject(data.allowedRoles)
        && isDocumentStatus (data.status)
        && isReviewStatus (data.reviewStatus)
}

export const isSavedDocument = (data: UnsavedDocument|Document): data is Document => {
    let id = (data as Document).id;
    return id !== undefined && id !== null
}

export const isDocumentList = (list: Unknown<Document>[]): list is Document[] => {
    return list.every(isDocument)
}

export function isTopic(topic: unknown): topic is Topic {
    return has(topic, "id",  isNumber)
    && has(topic, "number",  isNumber)
    && has(topic, "name",  isString)
    && has(topic, "culled",  isBoolean)
    && has(topic, "reportable",  isBoolean)
}

export const isTopicList = (list: Unknown<Topic>[]): list is Topic[] => {
    return list.every(isTopic);
};

export const isPortfolio = (portfolio: Unknown<Portfolio>): portfolio is Portfolio => {
    return isNumber (portfolio.id)
        && isNumber (portfolio.number)
        && isString (portfolio.name)
        && isAppType (portfolio.appType)
        && isArray (portfolio.topics)
        && isTopicList (portfolio.topics);
};

export const isPortfolioStatusReport = (statusReport: Unknown<PortfolioStatusReport>): statusReport is PortfolioStatusReport => {
    return has(statusReport, 'portfolio', isString)
        && has(statusReport, 'count', isNumber)
        && has(statusReport, 'pending', isNumber)
        && has(statusReport, 'approved', isNumber)
        && has(statusReport, 'percent', isString);
};

export const isTopicDocuments = (data: Unknown<TopicDocuments>): data is TopicDocuments => {
    return isTopic(data.topic)
        && isArray(data.documents)
        && isDocumentList(data.documents);
};

export const isAtLeastApproved = (document: DocumentDetails): boolean => {
    switch (document.reviewStatus) {
        case ReviewStatus.Approved:
        case ReviewStatus.WithMinister:
        case ReviewStatus.ReadyForPrint:
            return true;
        default:
            return false;
    }
};

export const isAtLeastReviewByCentralTeam = (document: DocumentDetails): boolean => {
    switch (document.reviewStatus) {
        case ReviewStatus.WithMinister:
        case ReviewStatus.ReadyForPrint:
            return true;
        default:
            return false;
    }
};

export const isRequiresAssigneeChange = (document: Document): boolean => {
    switch (document.reviewStatus) {
        case ReviewStatus.InProgress:
        case ReviewStatus.RequiresUpdate:
        case ReviewStatus.PendingApproval:
        case ReviewStatus.PendingReview:
            return true;
        default:
            return false;
    }
};

export const userCanEditDocument = (document: Document, userAccount: Account, appType: AppType) => {
    if (document.deleted) return false;
    if (userHasAppRole(userAccount, appType, AppRole.Admin)) return true;
    return canEditBriefs(userAccount, appType) && !isAtLeastApproved(document);
};

export const userCanViewDocument = (document: Document, userAccount: Account, appType: AppType) => {
    if (isMinister(userAccount, appType)) {
        return document.reviewStatus === ReviewStatus.WithMinister && !document.deleted;
    }
    return true;
};
