import {Document, ReviewStatus} from "../../../../domain/Document";
import React from "react";
import {Alert, AlertVariant} from "../../../ui/elements/Alert";

interface WithMinisterAlertProps {
    document: Document;
}

export const WithMinisterAlert: React.VFC<WithMinisterAlertProps> = ({document}) => {
    const isWithMinister = document.reviewStatus === ReviewStatus.WithMinister;
    if (!isWithMinister) return null;
    return (
        <Alert variant={AlertVariant.INFO}>
            This document is currently marked as <strong>With Minister</strong>
        </Alert>
    );
};
