import { AppType } from "../domain/AppType";
import { DocumentID, ExternalResource } from "./ExternalResource";
import { MarkPendingReviewRequest } from "./resources";

export const markPendingReview = (
    externalResource: ExternalResource,
    appType: AppType,
    documentIds: DocumentID[],
    note: string|undefined,
    assignee: string|undefined,
    assignToAuthor: boolean
) => {
    const req: MarkPendingReviewRequest = {
        ids: documentIds,
        note,
        assignee,
        assignToAuthor,
    };
    return externalResource.action(appType.Resources.markPendingReview, req);
};
