import React, {useMemo} from "react";
import {Form} from "react-bootstrap";
import {WithTestID} from "./WithTestID";

interface SelectOptionObj<T extends string|number> {
    value: T;
    label: string;
    disabled?: boolean;
}

interface InternalSelectElementProps<T extends string|number> extends WithTestID {
    id?: string;
    value: T|undefined;
    options: SelectOptionObj<T>[];
    onChange: (newValue: T) => void;
    isInvalid?: boolean;
    disabled?: boolean;
    required?: boolean;
    onBlur?: () => void;
}

function InternalSelectElement <T extends string|number> ({id, value, options, onChange, isInvalid, disabled, required, onBlur, testID}: InternalSelectElementProps<T>) {
    return (
        <Form.Select
            onChange={(event) => {
                const select = event.target as HTMLSelectElement;
                onChange(options[select.selectedIndex].value);
            }}
            data-testid={testID}
            id={id}
            value={value}
            isInvalid={isInvalid}
            disabled={disabled}
            required={required}
            onBlur={onBlur}
        >
            {options.map(toOption)}
        </Form.Select>
    );
}

function toOption <T extends string|number> (option: SelectOptionObj<T>): JSX.Element {
    return <option value={option.value} key={option.value} disabled={option.disabled}>{option.label}</option>
}


export type SelectOption<T extends string|number> = SelectOptionObj<T> | T;

function isSelectOptionObj <T extends string|number> (opt: SelectOption<T>): opt is SelectOptionObj<T> {
    return (typeof opt === 'object') && 'value' in opt && 'label' in opt;
}

export interface SelectElementProps<T extends string|number> extends Omit<InternalSelectElementProps<T>, 'options'> {
    options: SelectOption<T>[];
}

export function SelectElement <T extends string|number> ({options, ...props}: SelectElementProps<T>) {
    const internalOptions = useMemo(() => {
        return options.map((opt) => {
            if (isSelectOptionObj(opt)) {
                return opt;
            } else {
                return { value: opt, label: `${opt}` };
            }
        });
    }, [options]);
    return <InternalSelectElement {...props} options={internalOptions} />;
}
