import React from 'react';
import { Link } from 'react-router-dom';
import { useAppType } from '../../../Context/ServiceContext';
import { Document } from '../../../domain/Document';
import { userFullNameDisabled } from '../../../domain/User';
import { useOptionalDocumentHistoryPartial } from '../../../hooks/useDocumentHistoryPartial';
import { useFetchAllUsers } from '../../../hooks/useFetchAllUsers';
import { DateTimeFormat, formatDateTimeString } from '../../../services/DateTime';
import { ExpandablePanelSection } from '../elements/PanelSection';
import { ReviewStatusIndicator } from '../elements/ReviewStatusIndicator';

import './PartialHistorySection.scss';

export interface PartialHistorySectionProps {
    document: Document;
}

export const PartialHistorySection: React.FC<PartialHistorySectionProps> = ({ document }) => {

    const appType = useAppType();
    const { data: history } = useOptionalDocumentHistoryPartial(document.id);
    const { data: users } = useFetchAllUsers();

    if (!history || !users) return null;

    return (
        <ExpandablePanelSection header="History" initialState={false}>
            {history.map((history) => {
                const editor = users.find(u => u.login === history.editor);
                return (
                    <div key={history.id} className="history-item">
                        <div className="history-info">
                            <ReviewStatusIndicator reviewStatus={history.reviewStatus} />
                            <span className={`user-name ${editor && !editor.enabled ? 'user-disabled' : ''}`}>{editor ? userFullNameDisabled(editor) : ''}</span>
                            <span className="history-date">
                                {history.dateEdited && (
                                    <Link to={`/app/${appType.appType}/viewHistory/${history.id}`} target="_blank">
                                        {formatDateTimeString(history.dateEdited, DateTimeFormat.DateTime)}
                                    </Link>
                                )}
                            </span>
                        </div>
                        {history.comment && (<div className="history-comment">{history.comment}</div>)}
                    </div>
                );
            })}
            <div>
                <Link to={`/app/${appType.appType}/history/${document.id}`}>Show all</Link>
            </div>
        </ExpandablePanelSection>
    );

};
