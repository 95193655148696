import React from 'react';
import { Container } from 'react-bootstrap';

export const FixedBottomRightStatusBar: React.FC = ({ children }) => {
    return (
        <div className="fixed-bottom bg-light border-top">
            <Container>
                <div className="d-flex justify-content-end fw-bold" data-testid="statusbar">
                    {children}
                </div>
            </Container>
        </div>
    )
};
