import { useRef, useState } from "react";
import { useAuthenticatedUser } from "../Context/AuthenticationContext";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { AppRole, canEditBriefDivision, userHasAppRole } from "../domain/Account";
import {Document, isRequiresAssigneeChange, UnsavedDocument} from "../domain/Document";
import { FeatureType, isFeatureEnabled } from "../domain/Feature";
import { Deferred } from "../services/Deferred";
import { DocumentDto, DocumentUpdateAction, DocumentUpdateRequest } from "../services/resources";
import { assertNever } from "../services/TypeUtils";
import { adminAlterDocument as fetchAdminAlterDocument, DocumentAdminAlterRequest } from '../services/adminAlterDocument';
import { useFeatures } from "./useFeatures";
import { DocumentID } from "../services/ExternalResource";
import { useDivisionList } from "./useDivisionList";
import { usePortfolioList } from "./usePortfolioList";
import { useLocalStorage } from "./useLocalStorage";
import { NotificationMessageKey } from "../views/app/PPQ/Edit";

enum UpdateState {
    None,
    AssigneeModal,
    Comment,
    Saving,
}

export enum NextAction {
    Dashboard = 'Dashboard',
    Reload = 'Reload',
    Cancelled = 'Cancelled',
    None = 'None',
}

export type UpdateResolution = {
    action: NextAction.Dashboard;
} | {
    action: NextAction.Cancelled;
} | {
    action: NextAction.None;
} | {
    action: NextAction.Reload;
    id: number;
}


export const useUpdateDocument = (originalDocument: Document|UnsavedDocument) => {
    const {data: features} = useFeatures();
    const currentUser = useAuthenticatedUser();
    const appType = useAppType();
    const { data: divisions } = useDivisionList();
    const { data: portfolios } = usePortfolioList();

    const [updateState, setUpdateState] = useState(UpdateState.None);

    const originalDocRef = useRef(originalDocument);
    const actionRef = useRef<DocumentUpdateAction>();
    const docDtoRef = useRef<DocumentDto>();
    const commentRef = useRef<string>();

    const deferredRef = useRef<Deferred<UpdateResolution>>();

    const externalResource = useExternalResource();
    const storage = useLocalStorage();

    const authorRequestApprovalLockdownEnabled = !!features && isFeatureEnabled(features, FeatureType.AUTHOR_REQUEST_APPROVAL_LOCKDOWN);

    const divisionHasChanged = (doc: Document) => {
        const briefDivisionEditable = !!features && appType.BriefDivisionEnabled(features) && canEditBriefDivision(currentUser, appType);
        if (!briefDivisionEditable) return false;

        const divisionId = doc.division?.id;
        const originalDivisionId = originalDocRef.current.division?.id;
        return divisionId !== originalDivisionId;
    };

    const docNeedsAssigneeChange = (doc: Document) => {
        const docRequiresAssigneeChange = isRequiresAssigneeChange(doc);
        const hasAssigneeChanged = originalDocRef.current.assignee !== doc.assignee;
        const isSelfAssigned = currentUser.login === doc.assignee;
        return docRequiresAssigneeChange && (!hasAssigneeChanged || isSelfAssigned);
    };

    const checkAssignee = (doc: Document) => {
        if (authorRequestApprovalLockdownEnabled) {
            // Cannot be assigned to yourself when requesting approval/changes (applies to Authors only)
            if (userHasAppRole(currentUser, appType, AppRole.Author)) {
                if (docNeedsAssigneeChange(doc)) {
                    return false;
                }
            }
        }

        return true;
    };

    const isValidForUpdate = (action: DocumentUpdateAction, doc: Document) => {
        switch (action) {
            case DocumentUpdateAction.RequestApproval:
            case DocumentUpdateAction.ReviewedUpdated:
            case DocumentUpdateAction.ReviewedNoChange:
            case DocumentUpdateAction.ApprovalDenied:
                if (!checkAssignee(doc)) {
                    // document requires assignee change before saving
                    return false;
                }
                break;
        }

        return true;
    };

    const getDocumentForSave = (values: any): DocumentDto => {
        const doc = { ...values } as Document;
        if (!doc.creator) doc.creator = currentUser.login;
        if (!doc.assignee) doc.assignee = currentUser.login;

        if (divisions) {
            const division = divisions.find(div => div.id === doc.division?.id);
            if (division) {
                doc.division = division;
            }
        }

        if (portfolios) {
            for (let i = 0; i < portfolios.length; i++) {
                const topic = portfolios[i].topics.find(t => t.id === doc.topic?.id);
                if (topic) {
                    doc.topic = topic;
                    break;
                }
            }
        }

        return doc;
    };

    const saveDocument = async (nextAction: NextAction) => {
        let action = actionRef.current;

        const doc = docDtoRef.current;
        const deferred = deferredRef.current;
        if (!action || !doc || !deferred) return;

        if (action === DocumentUpdateAction.SaveOnTimeout
            || action === DocumentUpdateAction.SaveNoRedirect) {
            action = DocumentUpdateAction.Save;
        }

        let docId = doc.id;

        if (docId) {

            const req: DocumentUpdateRequest = {
                action,
                document: doc,
                comment: commentRef.current,
            };

            const result = await externalResource.action(appType.Resources.saveDocument, req);
            if (result === 'AutoReassigned') {
                storage.set(NotificationMessageKey, 'Current Assignee has been updated.');
            }

        } else {

            docId = await externalResource.action(appType.Resources.createDocument, doc);

        }

        switch (nextAction) {
            case NextAction.Cancelled:
            case NextAction.Dashboard:
                deferred.resolve({ action: nextAction });
                break;

            case NextAction.None:
            case NextAction.Reload:
                deferred.resolve({ action: nextAction, id: docId });
                break;

            default:
                assertNever(nextAction);
        }

    };

    const goToNextState = () => {
        // do the next thing required based on the current state and action
        const action = actionRef.current;
        const doc = docDtoRef.current;
        if (!action || !doc) return;

        if (updateState === UpdateState.None) {

            switch (action) {
                case DocumentUpdateAction.RequestApproval:
                case DocumentUpdateAction.ReviewedUpdated:
                case DocumentUpdateAction.ReviewedNoChange:
                case DocumentUpdateAction.ApprovalDenied:
                case DocumentUpdateAction.Reassign:
                    // show assignee modal
                    setUpdateState(UpdateState.AssigneeModal);
                    break;

                case DocumentUpdateAction.Approved:
                    // save and go to dashboard
                    setUpdateState(UpdateState.Saving);
                    saveDocument(NextAction.Dashboard);
                    break;

                case DocumentUpdateAction.ReviewedOnly:
                case DocumentUpdateAction.UpdatedOnly:
                    // save and reload page
                    setUpdateState(UpdateState.Saving);
                    saveDocument(NextAction.Reload);
                    break;

                case DocumentUpdateAction.Save:
                    if (!!doc.id && divisionHasChanged(doc)) {
                        // show comment modal
                        setUpdateState(UpdateState.Comment);
                    } else {
                        // save and reload page
                        setUpdateState(UpdateState.Saving);
                        saveDocument(NextAction.Reload);
                    }
                    break;

                case DocumentUpdateAction.SaveOnTimeout:
                    // save changes and go to dashboard
                    setUpdateState(UpdateState.Saving);
                    saveDocument(NextAction.Dashboard);
                    break;

                case DocumentUpdateAction.SaveNoRedirect:
                    saveDocument(NextAction.None);
                    break;

                default:
                    assertNever(action);
            }
            return;

        }

        if (updateState === UpdateState.AssigneeModal) {
            setUpdateState(UpdateState.Saving);
            if (action === DocumentUpdateAction.Reassign) {
                saveDocument(NextAction.Reload);
            } else {
                saveDocument(NextAction.Dashboard);
            }
            return;
        }

        if (updateState === UpdateState.Comment) {
            // save and reload page
            setUpdateState(UpdateState.Saving);
            saveDocument(NextAction.Reload);
            return;
        }

    };

    const updateDocument = (action: DocumentUpdateAction, formValues: any): Promise<UpdateResolution> => {
        if (updateState !== UpdateState.None) {
            return Promise.reject('Invalid state');
        }

        const doc = getDocumentForSave(formValues);
        if (!isValidForUpdate(action, doc)) {
            return Promise.reject('Invalid document state');
        }

        actionRef.current = action;
        docDtoRef.current = doc;
        commentRef.current = undefined;
    
        deferredRef.current = new Deferred<UpdateResolution>();

        goToNextState();

        return deferredRef.current.promise;
    };

    const adminAlterDocument = async (id: DocumentID, values: Partial<Document>): Promise<UpdateResolution> => {
        if (updateState !== UpdateState.None) {
            return Promise.reject('Invalid state');
        }

        setUpdateState(UpdateState.Saving);

        await fetchAdminAlterDocument(externalResource, appType, id, values as DocumentAdminAlterRequest);

        return Promise.resolve({ action: NextAction.Reload, id });
    };

    const cancelModals = () => {
        setUpdateState(UpdateState.None);
        const deferred = deferredRef.current;
        if (deferred) {
            deferred.resolve({ action: NextAction.Cancelled });
        }
    };

    const showAssigneeModal = updateState === UpdateState.AssigneeModal;
    const showCommentModal = updateState === UpdateState.Comment;
    const saving = updateState === UpdateState.Saving;

    const setAssigneeNote = (assignee: string, assigneeNote: string) => {
        const doc = docDtoRef.current;
        if (!doc) return;

        doc.assignee = assignee;
        doc.assigneeNote = assigneeNote;
        doc.roles = {
            ...doc.roles,
            ASSIGNEE: {
                id: 0,
                login: assignee,
            },
        };

        goToNextState();
    };

    const setComment = (comment: string) => {
        commentRef.current = comment;

        goToNextState();
    };

    return {
        updateDocument,
        cancelModals,
        showAssigneeModal,
        showCommentModal,
        setAssigneeNote,
        setComment,
        saving,
        adminAlterDocument,
    };
};
