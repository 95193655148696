import { userFullNameDisabled } from "../../../domain/User";
import { useFetchAllUsers } from "../../../hooks/useFetchAllUsers";
import { AutoComplete, AutoCompleteProps, Option } from "./AutoComplete";

export interface UserAutoCompleteProps extends Omit<AutoCompleteProps, 'value'|'onChange'|'options'> {
    onChange: (newValue: string|undefined) => void;
    value: string|undefined;
    includeDisabledUsers?: boolean;
}

export const UserAutoComplete: React.VFC<UserAutoCompleteProps> = ({ onChange, placeholder, disabled, includeDisabledUsers = false, ...props }) => {
    const { data: users, error } = useFetchAllUsers(includeDisabledUsers);
    const options: Option[] = users?.map(user => ({
        value: user.login,
        label: userFullNameDisabled(user),
        info: user.division?.name,
    })) || [];

    const placeholderStr = error ? `Error fetching users: ${error.message}` : (users ? placeholder : 'Loading...');
    const disabledProp = disabled || !users;

    return (
        <AutoComplete
            options={options}
            onChange={(val) => onChange(val === undefined ? val : `${val}`)}
            placeholder={placeholderStr}
            disabled={disabledProp}
            {...props}
        />
    );

};
