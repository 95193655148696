import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, AlertVariant } from '../../components/ui/elements/Alert';
import { Button, ButtonVariant } from '../../components/ui/elements/Button';
import { LoadingOverlay } from '../../components/ui/elements/LoadingOverlay';
import { CenterSmallContainerWithSupportBanner } from '../../components/ui/layouts/CenterSmallContainerWithSupportBanner';
import { useExternalResource } from '../../Context/ServiceContext';
import { appType } from '../../domain/AppType';
import { requestAccess } from '../../services/requestAccess';

enum PageState {
    Initial = 'Initial',
    Success = 'Success',
    Error = 'Error',
}

export const RequestAccess: React.VFC = () => {

    const [pageState, setPageState] = useState(PageState.Initial);

    return (
        <CenterSmallContainerWithSupportBanner>
            <h1>Request Access</h1>

            {pageState === PageState.Initial && <RequestAccessForm onSuccess={() => setPageState(PageState.Success)} onError={() => setPageState(PageState.Error)} />}
            {pageState === PageState.Success && (
                <div>
                    <p>
                        Your access request has been sent. An administrator will assess
                        your request and send you an email with further instructions.
                    </p>
                </div>
            )}
            {pageState === PageState.Error && (
                <div>
                    <Alert variant={AlertVariant.ERROR}>
                        An error occurred. Please try again.
                    </Alert>
                    <div className="d-flex justify-content-center">
                        <Link to="/login" className="btn btn-primary">Login</Link>
                    </div>
                </div>
            )}

        </CenterSmallContainerWithSupportBanner>
    );
};

interface RequestAccessFormProps {
    onSuccess: () => void;
    onError: () => void;
}
const RequestAccessForm: React.VFC<RequestAccessFormProps> = ({ onSuccess, onError }) => {
    const externalResource = useExternalResource();
    const [submitting, setSubmitting] = useState(false);

    const onClick = async (appType: appType) => {
        try {
            setSubmitting(true);
            await requestAccess(externalResource, appType);
            setSubmitting(false);
            onSuccess();
        } catch (err) {
            setSubmitting(false);
            onError();
        }
    };

    return (
        <>
            <p>
                You do not currently have access.
                Please use the buttons below to request access.
                An administrator will respond to your request, and
                you will receive an email with instructions for
                accessing your account.
            </p>
            <div className="d-flex flex-column gap-3 align-items-center">
                <Button variant={ButtonVariant.PRIMARY} disabled={submitting} onClick={() => onClick(appType.PPQ)}>Request access to PPQ</Button>
                <Button variant={ButtonVariant.PRIMARY} disabled={submitting} onClick={() => onClick(appType.PAEC)}>Request access to PAEC</Button>
            </div>
            <LoadingOverlay show={submitting} message="Please wait..." />
        </>
    );
};
