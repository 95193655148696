import {VFC} from 'react';
import {HashRouter, Route, Switch} from "react-router-dom";

import { AuthContextProvider } from './Context/AuthenticationContext';
import { AdminRole, AppRole } from './domain/Account';
import { appType } from './domain/AppType';
import { FeatureType } from './domain/Feature';
import { Login } from './views/login/login';
import { RequestAccess } from './views/login/RequestAccess';
import { ResetRequest } from './views/reset/ResetRequest';
import { ResetSubmit } from './views/reset/ResetSubmit';
import { ActivateSubmit } from './views/activate/ActivateSubmit';
import { PPQ } from './views/app/PPQ/PPQ';
import { PPQEdit } from './views/app/PPQ/Edit';
import { PPQHistoryList } from './views/app/PPQ/HistoryList';
import { PPQViewHistory } from './views/app/PPQ/ViewHistory';
import { PPQView } from './views/app/PPQ/View';
import { PPQArchive } from './views/app/PPQ/Archive';
import { PPQViewMultiple } from './views/app/PPQ/ViewMultiple';
import { ManageDivisions } from './views/app/PPQ/ManageDivisions';
import { ManagePortfolios } from './views/app/PPQ/ManagePortfolios';
import { ManageTemplates } from './views/app/PPQ/ManageTemplates';
import { Profile } from './views/app/PPQ/Profile';
import { EditUser } from './views/app/PPQ/EditUser';
import { CreateUser } from './views/app/PPQ/CreateUser';
import { ManageUsers } from './views/app/PPQ/ManageUsers';
import { PAEC } from './views/app/PAEC/PAEC';
import { PAECEdit } from './views/app/PAEC/Edit';
import { PAECHistoryList } from './views/app/PAEC/HistoryList';
import { PAECViewHistory } from './views/app/PAEC/ViewHistory';
import { PAECView } from './views/app/PAEC/VIew';
import { PAECArchive } from './views/app/PAEC/Archive';
import { PAECViewMultiple } from './views/app/PAEC/ViewMultiple';
import { PAECPrintPortfolios } from './views/app/PAEC/PrintPortfolios';
import { StatusReport } from './views/app/PPQ/StatusReport';
import { Notifications } from './components/ui/elements/Notifications';

import { UpdateSentryConfig } from './components/routing/UpdateSentryConfig';
import { HomeRedirect } from './components/routing/HomeRedirect';
import { PublicRoute } from './components/routing/PublicRoute';
import { PrivateRoute } from './components/routing/PrivateRoute';
import { RestrictedRoute } from './components/routing/RestrictedRoute';
import { CatchAllRoute } from './components/routing/CatchAllRoute';
import { ErrorBoundary } from './components/routing/ErrorBoundary';

import './global.scss';


const App: VFC = () => {
    return (
        <>
            <UpdateSentryConfig />
            <ErrorBoundary>
                <AuthContextProvider>
                    <Notifications />
                    <HashRouter>
                        <Switch>
                            <Route exact path="/">
                                <HomeRedirect />
                            </Route>

                            <PublicRoute exact path="/login">
                                <Login />
                            </PublicRoute>

                            <PublicRoute exact path="/RequestAccess">
                                <RequestAccess />
                            </PublicRoute>

                            <PublicRoute exact path="/reset/request" features={[FeatureType.LOGIN_EMAIL_PASSWORD]}>
                                <ResetRequest />
                            </PublicRoute>

                            <PublicRoute exact path="/reset/finish" features={[FeatureType.LOGIN_EMAIL_PASSWORD]}>
                                <ResetSubmit />
                            </PublicRoute>

                            <PublicRoute exact path="/activate" features={[FeatureType.LOGIN_EMAIL_PASSWORD]}>
                                <ActivateSubmit />
                            </PublicRoute>

                            <PrivateRoute appType={appType.PPQ} path="/app/PPQ">
                                <Switch>
                                    <Route exact path="/app/PPQ" component={PPQ}/>
                                    <RestrictedRoute exact path="/app/PPQ/edit/:id?" component={PPQEdit} appType={appType.PPQ} allowedRoles={[AppRole.Admin, AppRole.Approver, AppRole.Author, AppRole.Coordinator, AppRole.DTPCoordinator]} />
                                    <Route exact path="/app/PPQ/history/:id" component={PPQHistoryList} />
                                    <Route exact path="/app/PPQ/viewHistory/:id" component={PPQViewHistory} />
                                    <Route exact path="/app/PPQ/view/:id" component={PPQView} />
                                    <RestrictedRoute exact path="/app/PPQ/archive" component={PPQArchive} appType={appType.PPQ} allowedRoles={[AppRole.Admin, AppRole.Minister, AppRole.Approver, AppRole.Author]} />
                                    <Route exact path="/app/PPQ/viewMultiple" component={PPQViewMultiple} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/division/:id?" component={ManageDivisions} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/portfolios/:id?" component={ManagePortfolios} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin, AppRole.Admin]} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/templates/:id?" component={ManageTemplates} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin, AppRole.Admin]} />
                                    <Route exact path="/app/PPQ/settings" component={Profile} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/user/new" component={CreateUser} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/user/:id" component={EditUser} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PPQ/admin/user" component={ManageUsers} appType={appType.PPQ} allowedRoles={[AdminRole.SysAdmin]} />
                                    <Route exact path="/app/PPQ/report/status" component={StatusReport} />
                                    <CatchAllRoute />
                                </Switch>
                            </PrivateRoute>
                            <PrivateRoute appType={appType.PAEC} path="/app/PAEC">
                                <Switch>
                                    <Route exact path="/app/PAEC" component={PAEC}/>
                                    <RestrictedRoute exact path="/app/PAEC/edit/:id?" component={PAECEdit} appType={appType.PAEC} allowedRoles={[AppRole.Admin, AppRole.Approver, AppRole.Author, AppRole.Coordinator, AppRole.DTPCoordinator]} />
                                    <Route exact path="/app/PAEC/history/:id" component={PAECHistoryList} />
                                    <Route exact path="/app/PAEC/viewHistory/:id" component={PAECViewHistory} />
                                    <Route exact path="/app/PAEC/view/:id" component={PAECView} />
                                    <RestrictedRoute exact path="/app/PAEC/archive" component={PAECArchive} appType={appType.PAEC} allowedRoles={[AppRole.Admin, AppRole.Minister, AppRole.Approver, AppRole.Author, AppRole.DTPCoordinator]} />
                                    <Route exact path="/app/PAEC/viewMultiple" component={PAECViewMultiple} />
                                    <Route exact path="/app/PAEC/printPortfolios" component={PAECPrintPortfolios} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/division/:id?" component={ManageDivisions} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/portfolios/:id?" component={ManagePortfolios} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin, AppRole.Admin]} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/templates/:id?" component={ManageTemplates} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin, AppRole.Admin]} />
                                    <Route exact path="/app/PAEC/settings" component={Profile} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/user/new" component={CreateUser} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/user/:id" component={EditUser} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin]} />
                                    <RestrictedRoute exact path="/app/PAEC/admin/user" component={ManageUsers} appType={appType.PAEC} allowedRoles={[AdminRole.SysAdmin]} />
                                    <Route exact path="/app/PAEC/report/status" component={StatusReport} />
                                    <CatchAllRoute />
                                </Switch>
                            </PrivateRoute>

                            <CatchAllRoute />
                        </Switch>
                    </HashRouter>
                </AuthContextProvider>
            </ErrorBoundary>
        </>
    );
}

export default App;
