import useSWR from 'swr';
import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { DocumentHistory } from '../domain/DocumentHistory';
import { fetchDocumentHistory } from '../services/fetchDocumentHistory';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useDocumentHistory = (id: number) => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<DocumentHistory, ErrorWithDetails>(`documentHistory:${appType.appType}:${id}`, () => {
        return fetchDocumentHistory(externalResource, appType, id)
            .catch(throwErrorWithDetails);
    });
};
