import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useAuthenticatedUser } from '../../../Context/AuthenticationContext';
import { useAppType, useExternalResource } from '../../../Context/ServiceContext';
import { Account, isMinister } from '../../../domain/Account';
import { AppType } from '../../../domain/AppType';
import { Document, Portfolio, ReviewStatus, TopicDocuments } from '../../../domain/Document';
import { convertErrorToErrorWithDetails, ErrorWithDetails } from '../../../hooks/exceptionToError';
import { useFeatures } from '../../../hooks/useFeatures';
import { usePortfolioList } from '../../../hooks/usePortfolioList';
import { Log } from '../../../log/log';
import { fetchMultipleDocumentsInOrderByPortfolios } from '../../../services/fetchMultipleDocumentsInOrderByPortfolios';
import { notificationService } from '../../../services/notificationService';
import { makeWordPluralWithCount } from '../../../textUtils';
import { Alert, AlertVariant } from './Alert';
import { AlertWithDetails } from './AlertWithDetails';
import { Button, ButtonVariant } from './Button';
import { Checkbox } from './Checkbox';
import { FormField } from './FormField';
import { LoadingOverlay } from './LoadingOverlay';
import { DropDownItem, MultiDropDown } from './MultiDropDown';
import { ReviewStatusLabel } from './ReviewStatusLabel';
import { TextInputElement } from './TextInputElement';
import { ViewDocumentDetails } from './ViewDocumentDetails';

import './ViewPortfolios.scss';

export const ViewPortfolios: React.VFC = () => {

    const appType = useAppType();
    const [options, setOptions] = useState(DefaultFormOptions);
    const { topics, error, loading } = useTopics(options);
    const formRef = useRef<HTMLFormElement>(null);

    if (error) {
        return <Alert variant={AlertVariant.ERROR} {...error} />;
    }

    const handleDownload = (format: DownloadFormat) => {
        if (formRef.current) {
            formRef.current.action = getExportFilename(appType, format, options.separateFiles);
            formRef.current.submit();
        }
    };

    let documents: Document[] = [];
    if (topics) {
        topics.forEach((topic) => {
            documents = [
                ...documents,
                ...topic.documents,
            ];
        });
    }


    return (
        <div className="print-portfolios view-document">
            <ViewPortfoliosOptions options={options} onChange={setOptions} onDownload={handleDownload} />
            <LoadingOverlay show={loading} />
            {topics && (
                <>
                    <ViewPortfoliosIndex options={options} topics={topics} />
                    <Container className="page-container">
                        {documents.map((document) => (
                            <div key={document.id}>
                                <div className="page-break"><span>PAGE BREAK</span></div>
                                <ViewDocumentDetails document={document} />
                            </div>
                        ))}
                    </Container>
                </>
            )}

            {/* Note: this form is always hidden from view, and the form fields are set based on the
              * options passed back from the <ViewPortfoliosOptions> component above. The input values
              * are never set directly by the user, but React logs a warning if we have inputs with
              * a 'value' prop but no 'onChange' prop, which is why we have 'onChange={() => {}}` below.
              * TODO: look at refactoring this to use the inputs in <ViewPortfoliosOptions> as the
              * form - we may not even need this hidden form at all.
              * See also: ViewMultipleDocuments.tsx
              */}
            <form className="d-none" method="POST" target="_blank" ref={formRef}>
                {options.portfolioIds.map(id => <input key={id} type="hidden" name="ids" value={id} />)}
                {options.reviewStatuses.map(status => <input key={status} type="hidden" name="reviewStatuses" value={status} />)}
                <input type="text" name="title1" value={options.title1} id="title1" onChange={() => {}} />
                <input type="text" name="title2" value={options.title2} id="title2" onChange={() => {}} />
                <input type="checkbox" name="makeTopicsContiguous" checked={options.contiguous} value="true" onChange={() => {}} />
                <input type="checkbox" name="separateFiles" checked={options.separateFiles} value="true" onChange={() => {}} />
            </form>
        </div>
    );
};

const getExportFilename = (appType: AppType, format: DownloadFormat, separateFiles: boolean) => {

    let url: string;
    if (format === 'DOCX') {
        url = `/docx/${appType.appType}/portfolio/`;
    } else {
        url = `/${appType.appType}/portfolioPdf/`;
    }

    url = `${url}${appType.appType.toLocaleLowerCase()}_document`;
    if (separateFiles) {
        url = `${url}s`;
    }

    if (separateFiles) {
        url = `${url}.zip`;
    } else if (format === 'DOCX') {
        url = `${url}.docx`;
    } else {
        url = `${url}.pdf`;
    }

    return url;
};

const useTopics = (options: FormOptions) => {

    const externalResource = useExternalResource();
    const appType = useAppType();
    const userAccount = useAuthenticatedUser();

    const [topics, setTopics] = useState<TopicDocuments[]>();
    const [error, setError] = useState<ErrorWithDetails>();
    const [loading, setLoading] = useState(false);

    const { portfolioIds, contiguous, reviewStatuses } = options;

    useEffect(() => {
        (async () => {
            if (portfolioIds.length === 0) {
                setTopics([]);
                setError(undefined);
            } else {

                setLoading(true);
                try {
                    const result = await fetchMultipleDocumentsInOrderByPortfolios(externalResource, appType, portfolioIds, contiguous, reviewStatuses);
                    setTopics(filterDocumentsForMinister(result, userAccount, appType));
                    setLoading(false);
                } catch (err) {
                    Log.reportAndLogError('Error loading documents', err);
                    notificationService.showErrorNotification('Error loading documents');
                    setError(convertErrorToErrorWithDetails(err as Error));
                    setLoading(false);
                }

            }
        })();
    }, [externalResource, appType, portfolioIds, contiguous, reviewStatuses, userAccount]);

    return {topics, error, loading};
};

interface FormOptions {
    portfolioIds: number[];
    title1: string;
    title2: string;
    reviewStatuses: ReviewStatus[];
    contiguous: boolean;
    separateFiles: boolean;
}
const DefaultFormOptions: FormOptions = {
    portfolioIds: [],
    title1: '',
    title2: '',
    reviewStatuses: [],
    contiguous: false,
    separateFiles: false,
};

type DownloadFormat = 'PDF' | 'DOCX';

interface ViewPortfoliosOptionsProps {
    options: FormOptions;
    onChange: (options: FormOptions) => void;
    onDownload: (format: DownloadFormat) => void;
}
const ViewPortfoliosOptions: React.VFC<ViewPortfoliosOptionsProps> = ({ options, onChange, onDownload }) => {

    const { data, error } = usePortfolioList();
    const [lastClickedIdx, setLastClickedIdx] = useState<number>();

    if (error) {
        return <Container><AlertWithDetails variant={AlertVariant.ERROR} {...error} /></Container>;
    }

    if (!data) {
        return <Container><Alert variant={AlertVariant.INFO}>Loading...</Alert></Container>;
    }

    const setField = (key: keyof FormOptions, value: FormOptions[keyof FormOptions]) => {
        onChange({ ...options, [key]: value });
    };

    const handleClick = (event: MouseEvent, portfolio: Portfolio) => {
        let newValue: number[] = [];
        const idx = data.findIndex(p => p.id === portfolio.id);
        if (event.shiftKey && lastClickedIdx !== undefined) {
            // select all items from the previous one to this one

            const min = Math.min(lastClickedIdx, idx);
            const max = Math.max(lastClickedIdx, idx);
            newValue = [ ...options.portfolioIds ];
            for (let i = min; i <= max; i++) {
                const p = data[i];
                if (!newValue.includes(p.id)) {
                    newValue.push(p.id);
                }
            }
        } else {
            newValue = options.portfolioIds.includes(portfolio.id)
                ? options.portfolioIds.filter(val => val !== portfolio.id)
                : [ ...options.portfolioIds, portfolio.id ];
        }
        setField('portfolioIds', newValue);
        setLastClickedIdx(idx);
    };

    const half = Math.ceil(data.length / 2);
    const col1 = data.slice(0, half);
    const col2 = data.slice(half);

    return (
        <div className="head-section">
            <Container className="page-container py-2">
                <Row>
                    <Col>Select one or more portfolios to view</Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PortfolioMenu portfolios={col1} onClick={handleClick} selectedIds={options.portfolioIds} />
                    </Col>
                    <Col sm={6}>
                        <PortfolioMenu portfolios={col2} onClick={handleClick} selectedIds={options.portfolioIds} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={6}>
                        <FormField
                            id="title1"
                            label={<>Title <small>(line 1)</small></>}
                            control={
                                <TextInputElement
                                    value={options.title1}
                                    onChange={val => setField('title1', val)}
                                    placeholder="Title top line"
                                />
                            }
                        />
                        <FormField
                            id="title2"
                            label={<>Title <small>(line 2)</small></>}
                            control={
                                <TextInputElement
                                    value={options.title2}
                                    onChange={val => setField('title2', val)}
                                    placeholder="Title bottom line"
                                />
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <div style={{paddingTop: 32}}>
                            <ReviewStatusSelect
                                value={options.reviewStatuses}
                                onChange={val => setField('reviewStatuses', val)}
                            />
                        </div>
                        <div className="py-2">
                            <Checkbox
                                id="contiguous"
                                label="Contiguous topic numbering"
                                checked={options.contiguous}
                                onChange={val => setField('contiguous', val)}
                            />
                        </div>
                        <div className="py-2">
                            <Checkbox
                                id="separateFiles"
                                label="Download as separate files"
                                checked={options.separateFiles}
                                onChange={val => setField('separateFiles', val)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex gap-3 justify-content-center">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                onClick={() => onDownload('PDF')}
                                disabled={options.portfolioIds.length === 0}
                            >Download as {options.separateFiles ? 'PDFs' : 'PDF'}</Button>
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                onClick={() => onDownload('DOCX')}
                                disabled={options.portfolioIds.length === 0}
                            >Download as {options.separateFiles ? 'DOCXs' : 'DOCX'}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

interface ReviewStatusSelectProps {
    value: ReviewStatus[];
    onChange: (arr: ReviewStatus[]) => void;
}
const ReviewStatusSelect: React.VFC<ReviewStatusSelectProps> = ({ value, onChange}) => {

    const account = useAuthenticatedUser();
    const appType = useAppType();
    const userIsMinister = isMinister(account, appType);

    let items: DropDownItem<ReviewStatus>[] = [];
    if (userIsMinister) {
        // Only allow "With Minister" for Ministers [PPQ-148]
        items.push({
            value: ReviewStatus.WithMinister,
            content: <ReviewStatusLabel reviewStatus={ReviewStatus.WithMinister} />,
            type: 'option',
        });
    } else {
        items = Object.values(ReviewStatus).map((str) => ({
            value: str,
            content: <ReviewStatusLabel reviewStatus={str} />,
            type: 'option',
        }));
    }

    let reviewStatusLabel = '';
    if (value.length === 0) {
        reviewStatusLabel = 'All Statuses';
    } else {
        reviewStatusLabel = makeWordPluralWithCount('Status', value.length, 'Statuses');
    }

    return (
        <MultiDropDown
            label={reviewStatusLabel}
            value={value}
            onChange={onChange}
            items={items}
        />
    );
};

interface PortfolioMenuProps {
    selectedIds: number[];
    onClick: (event: MouseEvent, portfolio: Portfolio) => void;
    portfolios: Portfolio[];
}
const PortfolioMenu: React.VFC<PortfolioMenuProps> = ({ selectedIds, onClick, portfolios }) => {
    return (
        <Dropdown.Menu show className="position-static user-select-none">
            {portfolios.map((portfolio) => (
                <Dropdown.Item key={portfolio.id} onClick={(event) => onClick(event, portfolio)} active={selectedIds.includes(portfolio.id)}>
                    <span className="portfolio-option">
                        <strong>{portfolio.number}</strong>
                        <span className="portfolio-name">{portfolio.name}</span>
                    </span>
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    );
};

const filterDocumentsForMinister = (topics: TopicDocuments[], userAccount: Account, appType: AppType): TopicDocuments[] => {
    if (isMinister(userAccount, appType)) {
        return topics.map((topicDocuments) => {
            return {
                ...topicDocuments,
                documents: topicDocuments.documents.filter((document) => {
                    return document.reviewStatus === ReviewStatus.WithMinister && !document.deleted;
                }),
            };
        });
    }
    return topics;
};

interface ViewPortfoliosIndexProps {
    options: FormOptions;
    topics: TopicDocuments[];
}
const ViewPortfoliosIndex: React.VFC<ViewPortfoliosIndexProps> = ({ options, topics }) => {
    const appType = useAppType();
    const { data: features } = useFeatures();

    const shortDocNumber = features && appType.ShortDocumentNumber(features);

    return (
        <Container className="page-container py-2 print-portfolios-index">

            {options.title1 && <div className="print-portfolios-title">{options.title1}</div>}
            {options.title2 && <div className="print-portfolios-title">{options.title2}</div>}

            {topics.map((topicDocuments) => (
                <div key={topicDocuments.topic.id} className="topic-group">
                    <div className="topic">
                        <span className="topic-number">{topicDocuments.topic.number}</span>
                        <span className="topic-name">{topicDocuments.topic.name}</span>
                    </div>
                    {topicDocuments.documents.map((document) => (
                        <div key={document.id} className="topic-doc">
                            {shortDocNumber
                                ? <span className="topic-doc-number">{topicDocuments.topic.number}.{document.shortFormattedNumber}</span>
                                : <span className="topic-doc-number">{document.formattedNumber}</span>
                            }
                            <span className="topic-doc-name">{document.title}</span>
                        </div>
                    ))}
                </div>
            ))}
        </Container>
    );

};
