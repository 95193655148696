import { FC } from "react";
import { useWorkflowActions } from "../../../hooks/useGetWorkflowAction";
import { useSubmitWorkflowAction } from "../../../hooks/useSubmitWorkflowAction";
import { useRouter } from "../../../hooks/useRouter";
import { useAppType } from "../../../Context/ServiceContext";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { NotificationStorageKey, NotificationMessageKey } from "../../../views/app/PPQ/Edit";
import { WorkflowActionButton } from "../elements/WorkflowActionButton";

interface Props {
    documentID: string;
    testID: string | undefined;
}

export const WorkflowActions: FC<Props> = ({documentID}) => {
    const {refresh, actions} = useWorkflowActions(documentID)
    const submit = useSubmitWorkflowAction()
    const { gotoOrReload } = useRouter();
    const appType = useAppType();
    const storage = useLocalStorage();

    const refreshPage = () => {
        gotoOrReload(`/app/${appType.appType}/edit/${documentID}`);
    };

    const showSuccessNotification = (code: string) => {
        // save a flag in local storage so that the notification will be shown after reload
        storage.set(NotificationStorageKey, true);

        const message = {
            'REQUEST_REVIEW': 'Review requested',
            'ACCEPTED': 'Accepted',
            'REQUEST_APPROVAL': 'Approval requested',
            'REQUIRES_UPDATE': 'Update required',
            'APPROVE': 'Approved',
            'SEND_TO_AUTHOR': 'Sent to author',
            'SEND_TO_DTP_COORDINATOR': 'Sent to DTP Coordinator'
        }[code] ?? null;

        if (message) {
            storage.set(NotificationMessageKey, message);
        }
    };

    const handleSubmit = (code: string, note: string) => {
        return submit(documentID, code, note)
            // get new workflow actions from the api
            .then(refresh)
            .then(() => showSuccessNotification(code))
            // refresh the page - TODO: only do this for certain action types?
            .then(refreshPage);
    }

    return <div className="d-grid gap-2">
        {actions.map(a => <WorkflowActionButton key={a.label} action={a} submit={handleSubmit} />)}
    </div>
}
