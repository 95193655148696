import { IconType } from "../../../components/ui/elements/Icon";

import {
    ButtonVariant,
    SubmitIconButton,
    IconPosition,
    ButtonProps,
} from "../../../components/ui/elements/Button";

export const SaveButton: React.FC<ButtonProps> = ({
    children = "Save Changes",
    ...props
}) => (
    <SubmitIconButton
        variant={ButtonVariant.PRIMARY}
        icon={IconType.Submit}
        iconPosition={IconPosition.Right}
        {...props}
    >
        {children}
    </SubmitIconButton>
);
