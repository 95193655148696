import { Field, FieldProps, useFormikContext } from "formik";
import { MultiDropDown, DropDownItem, ValueType } from "../../ui/elements/MultiDropDown";
import { FormField } from "../../ui/elements/FormField";
import { StandardFieldProps } from "./TextField";
import { validateStringOrNumberArray } from "./FieldValidation";

export interface MultiDropDownFieldProps <T extends ValueType> extends StandardFieldProps {
    displayContent: (value: T[]) => React.ReactNode;
    items: DropDownItem<T>[];
}

export function MultiDropDownField <T extends ValueType> ({ id, label, disabled, help, required, displayContent, items, validationRules = [], testID }: MultiDropDownFieldProps<T>) {
    const formik = useFormikContext();

    return (
        <Field name={id} validate={(value: T[]) => {

            for (let i = 0; i < validationRules.length; i++) {
                const result = validateStringOrNumberArray(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && value.length === 0) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<T[]>) => {
                const { error, touched } = form.getFieldMeta(id);
                return (
                    <FormField
                        help={help}
                        label={label}
                        id={id}
                        error={touched ? error : undefined}
                        control={
                            <MultiDropDown
                                disabled={disabled}
                                label={displayContent(field.value === undefined ? [] : field.value)}
                                value={field.value === undefined ? [] : field.value}
                                items={items}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                isInvalid={touched && !!error}
                                testID={testID}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );
};
