import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { Button } from '../elements/Button';
import { FormField } from '../elements/FormField';
import Modal from '../elements/Modal';
import { TextareaElement } from '../elements/TextareaElement';
import { WithTestID } from '../elements/WithTestID';
import {TemplateSelector} from "../elements/TemplateSelector";

interface Props extends WithTestID {
    show: boolean;
    onCancel: () => void;
    onSubmit: (note: string) => void;
    mandatory?: boolean;
}

export const WorkflowActionNoteModal: React.VFC<Props> = ({ show, onCancel, onSubmit, mandatory = false, testID }) => {
    const [note, setNote] = useState<string>('');

    const isValid = mandatory ? (!!note) : true;

    return (
        <Modal show={show} onHide={onCancel} size="lg" testID={testID}>
            <Modal.Body>
                <FormField
                    id="note"
                    label="Note to assignee"
                    help={`Text entered here will be emailed to the assignee, and visible in the document history.`}
                    control={
                        <>
                            <div>
                                <Form.Text className="text-muted">
                                    Select a template from the list below, or enter your own note text.
                                </Form.Text>
                            </div>
                            <div className="mb-1">
                                <TemplateSelector
                                    onChange={(template) => setNote(template.text)}
                                    testID={testID ? `${testID}:template-select` : undefined}
                                />
                            </div>
                            <TextareaElement
                                value={note}
                                onChange={setNote}
                                required={mandatory}
                                testID={testID ? `${testID}:note` : undefined}
                            />
                        </>
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    onClick={isValid ? () => onSubmit(note) : undefined}
                    disabled={!isValid}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    )
};

