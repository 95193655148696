import {CSSProperties, VFC} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import {SortDirection} from "./Table";


export type TableHeaderSortIndicatorProps = {
    direction: SortDirection
}

export const TableHeaderSortIndicator: VFC<TableHeaderSortIndicatorProps> = ({direction}) => {
    const styleInactive: CSSProperties = {color: '#ccc'}
    switch (direction) {
        case "ASC":
            return <FontAwesomeIcon icon={faSortUp} />
        case "DESC":
            return <FontAwesomeIcon icon={faSortDown} />
        default:
            return <FontAwesomeIcon icon={faSort} style={styleInactive} />
    }
}
