import React, { useState } from 'react';

import { makeWordPlural } from '../../../textUtils';
import { Button, ButtonVariant } from '../elements/Button';
import Modal from '../elements/Modal';
import { SinglePortfolioTopicDropDown } from '../elements/PortfolioTopicDropDown';
import { WithTestID } from '../elements/WithTestID';

export interface MoveDocumentsModalProps extends WithTestID {
    documentCount: number;
    show: boolean;
    onCancel: () => void;
    onSubmit: (topicId: number) => void;
}

export const MoveDocumentsModal: React.VFC<MoveDocumentsModalProps> = ({ documentCount, show, onCancel, onSubmit, testID }) => {
    const [topicId, setTopicId] = useState<number>();

    const cancelModal = () => {
        onCancel();
        setTopicId(undefined);
    };

    return (
        <Modal show={show} onHide={cancelModal} testID={testID}>
            <Modal.Body>
                <p>Move <strong>{documentCount}</strong> {makeWordPlural('document', documentCount)} to</p>
                <SinglePortfolioTopicDropDown value={topicId} onChange={setTopicId} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={cancelModal}>Cancel</Button>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        onSubmit(topicId!);
                        setTopicId(undefined);
                    }}
                    disabled={topicId === undefined}
                >Continue</Button>
            </Modal.Footer>
        </Modal>
    );
};
