import { useEffect, useState } from 'react';
import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { Document } from '../domain/Document';
import { DocumentID } from '../services/ExternalResource';
import { fetchMultipleDocumentsInOrder } from '../services/fetchMultipleDocumentsInOrder';
import { ErrorWithDetails, ExceptionToError } from './exceptionToError';

export const useMultipleDocumentsInOrder = (ids: DocumentID[], makeContiguous?: boolean) => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    const [documents, setDocuments] = useState<Document[]>();
    const [error, setError] = useState<ErrorWithDetails>();

    useEffect(() => {
        fetchMultipleDocumentsInOrder(externalResource, appType, ids, makeContiguous)
            .then(setDocuments)
            .catch(ExceptionToError(setError));
    }, [appType, externalResource, ids, makeContiguous]);

    return { documents, error };
};
