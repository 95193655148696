import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AppLayout, CentredAppLayoutHeading } from "../components/ui/layouts/AppLayout";
import { ButtonList } from "../components/ui/layouts/ButtonList";
import { AuthenticationContext } from "../Context/AuthenticationContext";

export const NotFound: React.VFC = () => {
    const {auth} = useContext(AuthenticationContext);
    const isLoggedIn = !!auth.userAccount;

    return (
        <AppLayout>
            <div className="pt-5">
                <CentredAppLayoutHeading>Not found</CentredAppLayoutHeading>
                <p className="text-center">
                    Sorry, the requested page could not be found.
                    Please check the link and try again, or contact your system
                    administrator for help.
                </p>
            </div>
            <ButtonList align="center">
                <Link to="/login" className="btn btn-primary">
                    {isLoggedIn ? 'Home' : 'Login'}
                </Link>
            </ButtonList>
        </AppLayout>

    );
};
