import {AppType} from "../domain/AppType";
import {DocumentID, ExternalResource} from "./ExternalResource";
import {isList, validateList} from "../domain/validateUnknown";
import { Document, isDocument } from "../domain/Document";
import { BulkDocumentRequestWithContiguous } from "./resources";

export function fetchMultipleDocumentsInOrder(
    externalResource: ExternalResource,
    appType: AppType,
    ids: DocumentID[],
    makeContiguous?: boolean,
): Promise<Document[]> {
    const req: BulkDocumentRequestWithContiguous = { ids };
    if (makeContiguous !== undefined) {
        req.makeContiguous = makeContiguous;
    }
    return externalResource.listAction(appType.Resources.getMultipleDocumentsInOrder, req)
        .then(validateList(isList(isDocument), "Document"))
};
