import { AppType } from "../domain/AppType";
import { isTemplate, Template } from "../domain/Template";
import { validate } from "../domain/validateUnknown";
import { ExternalResource } from "./ExternalResource";
import { CreateTemplateAPIRequest } from "./resources";

export const createTemplate = (externalResource: ExternalResource, appType: AppType, template: CreateTemplateAPIRequest): Promise<Template> => {
    return externalResource.action(appType.Resources.createTemplate, template)
        .then(validate(isTemplate, 'Template'));
};
