import { Formik, FormikConfig } from "formik";
import { Dispatch, VFC } from "react";
import { WithTestID } from "../../ui/elements/WithTestID";

export const Form: VFC<FormProps> = ({
    initialValues,
    onSubmit,
    FormBody,
    FormActions = BasicAction,
    onReset,
    testID,
    validate,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, helpers) => {
                onSubmit(values);
                helpers.setSubmitting(false);
            }}
            onReset={onReset}
            data-testid={testID}
            validate={validate}
            validateOnMount
        >
            {({
                values,
                setValues,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                errors,
                isValid,
                dirty,
                // other helpers are available, see https://formik.org/docs/api/formik
            }) => {
                return (
                    <form data-testid={testID} onSubmit={handleSubmit}>
                        <FormBody
                            values={values}
                            setValues={setValues}
                            handleChange={handleChange}
                            errors={errors as Record<string, string>}
                            dirty={dirty}
                        />
                        <FormActions
                            isSubmitting={isSubmitting}
                            resetForm={resetForm}
                            isValid={isValid}
                            dirty={dirty}
                            values={values}
                            setValues={setValues}
                        />
                    </form>
                );
            }}
        </Formik>
    );
};

export type FormValues = Record<string, any>;

export type HandleChange = (args: unknown) => void;
export interface FormBodyProps {
    values: FormValues;
    setValues: Dispatch<FormValues>;

    handleChange: HandleChange;
    errors: Record<string, string>;
    dirty: boolean;
}
export type FormBody = VFC<FormBodyProps>;

export interface FormActionProps {
    isSubmitting: boolean;
    resetForm: () => void;
    setValues: Dispatch<FormValues>;
    isValid: boolean;
    values: FormValues;
    dirty: boolean;
}
export type FormActions = VFC<FormActionProps>;

export interface FormProps extends WithTestID {
    initialValues: FormValues;
    onSubmit: (values: any) => void;
    FormBody: FormBody;
    FormActions?: FormActions;
    onReset?: (values: any) => void;
    validate?: FormikConfig<FormValues>['validate'];
}

export const BasicAction: FormActions = ({ isSubmitting }) => {
    return (
        <button type="submit" data-testid="submit" disabled={isSubmitting}>
            Submit
        </button>
    );
};
