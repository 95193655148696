import {VFC} from "react";
import {AppLayoutWithStatusBar} from "../../../components/ui/layouts/AppLayout";
import { Dashboard } from "../../../components/ui/elements/Dashboard";

export const PAEC: VFC = () => {
    return (
        <AppLayoutWithStatusBar>
            <Dashboard />
        </AppLayoutWithStatusBar>
    );
}
