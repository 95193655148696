import { useState, VFC } from "react";

import { useRouter } from "../../../hooks/useRouter";
import {
    useExternalResource,
} from "../../../Context/ServiceContext";

import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import { SectionSingleColumn } from "../../../components/ui/layouts/SectionSingleColumn";
import {
    AppLayout,
    AppLayoutHeading,
} from "../../../components/ui/layouts/AppLayout";

import { TextField } from "../../../components/form/fields/TextField";
import { Form } from "../../../components/form/forms/Form";

import { DivisionDropDownField } from "../../../components/form/fields/DivisionDropDownField";
import { Account } from '../../../domain/Account';
import { useAuthenticatedUser, useRefreshAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { saveProfile } from "../../../services/saveProfile";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { Alert, AlertVariant } from "../../../components/ui/elements/Alert";
import { SaveProfileAPIRequest } from "../../../services/resources";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { ReadOnlyField } from "../../../components/form/fields/ReadOnlyField";
import { fieldMaxLength, fieldMinLength, fieldRequired } from "../../../components/form/fields/FieldValidation";
import { notificationService } from "../../../services/notificationService";

type ManageAdminFormProps = { account: Account };

const createInitialValues = (account: Account) => ({
    ...account,
    // convert nulls to undefined for form
    phone: account.phone ?? undefined,
    mobile: account.mobile ?? undefined,
    division: account.division ?? undefined,
    adminRole: account.adminRole ?? undefined,
});

const ManageProfileForm: React.FC<ManageAdminFormProps> = ({ account }) => {
    const externalResource = useExternalResource();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();
    const { refresh } = useRefreshAuthenticatedUser();

    const onSubmit = async (arg: Account) => {
        if (arg.division) {
            setSaving(true);
            try {
                const req: SaveProfileAPIRequest = {
                    firstName: arg.firstName,
                    lastName: arg.lastName,
                    phone: arg.phone || '',
                    mobile: arg.mobile || '',
                    division: arg.division,
                };
                await saveProfile(externalResource, req);
                notificationService.showSuccessNotification('Profile details saved');
                if (!account.activated) {
                    await refresh();
                    setSaving(false);
                    gotoOrReload('/');
                } else {
                    setSaving(false);
                }
            } catch (err) {
                setError(convertErrorToErrorWithDetails(err as Error));
                setSaving(false);
            }
        }
    };

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <Form
                initialValues={createInitialValues(account)}
                onSubmit={onSubmit}
                FormBody={() => (
                    <div className="manage-admin-form">
                        <ReadOnlyField id="login" label="Email" />
                        <TextField
                            id="firstName"
                            label="First Name"
                            validationRules={[
                                fieldRequired('First Name is required'),
                                fieldMinLength(1, 'First Name must be at least 1 character'),
                                fieldMaxLength(50, 'First Name must be no more than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            validationRules={[
                                fieldRequired('Last Name is required'),
                                fieldMinLength(1, 'Last Name must be at least 1 character'),
                                fieldMaxLength(50, 'Last Name must be no more than 50 characters'),
                            ]}
                        />
                        <TextField
                            id="phone"
                            label="Contact Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Phone Number must be no more than 20 characters'),
                            ]}
                        />
                        <TextField
                            id="mobile"
                            label="Contact Mobile Phone Number"
                            validationRules={[
                                fieldMaxLength(20, 'Contact Mobile Phone Number must be no more than 20 characters'),
                            ]}
                        />
                        <DivisionDropDownField
                            id="division.id"
                            label="Division"
                            multiple={false}
                            validationRules={[
                                fieldRequired('Division is required'),
                            ]}
                        />
                    </div>
                )}
                FormActions={({ isValid, dirty }) => {
                    const canSave = isValid && (dirty || !account.activated);
                    return (
                        <ButtonList>
                            <SaveButton disabled={!canSave}>Save</SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

export const Profile: VFC = () => {
    const account = useAuthenticatedUser();
    return (
        <AppLayout>
            <AppLayoutHeading>My Details</AppLayoutHeading>
            <SectionSingleColumn testID="sectionProfile">
                {!account.activated && (
                    <Alert variant={AlertVariant.INFO}>
                        Please check your details below and Save to continue.
                    </Alert>
                )}
                <ManageProfileForm account={{...account}} />
            </SectionSingleColumn>
        </AppLayout>
    );
};
