import useSWR from 'swr';
import {fetchSystemBasic} from "../services/fetchSystemBasic";
import { SystemBasic } from "../domain/SystemBasic";
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';
import { useExternalResource } from '../Context/ServiceContext';

export const useSystemBasic = () => {
    const externalResource = useExternalResource();

    return useSWR<SystemBasic|undefined, ErrorWithDetails>('systemBasic', () => {
        return fetchSystemBasic(externalResource)
            .catch(throwErrorWithDetails);
    });
}
