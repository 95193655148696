import {DocumentID} from "../services/ExternalResource";
import {Document} from "../domain/Document";
import {useEffect, useState} from "react";
import {fetchDocument} from "../services/fetchDocument";
import {convertErrorToErrorWithDetails, ErrorWithDetails} from "./exceptionToError";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { Log } from "../log/log";
import { RequestErrorReason } from "../errors/RequestError";

export function useFetchDocument(id: DocumentID) {
    const appType = useAppType();
    const externalResource = useExternalResource();

    const [document, setDocument] = useState<Document>();
    const [error, setError] = useState<ErrorWithDetails>();

    useEffect(() => {
        (async () => {
            try {
                const result = await fetchDocument(externalResource, appType, id);
                setDocument(result);
            } catch (e) {
                Log.reportAndLogError('Error fetching document', e);
                const ex = convertErrorToErrorWithDetails(e as Error);
                if (ex.reason === RequestErrorReason.NotFound) {
                    ex.message = 'Document not found';
                } else {
                    ex.message = 'Error loading document';
                }
                setError(ex);
            }
        })();
    }, [externalResource, appType, id])
    return {document, error}
}
