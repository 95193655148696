import { RequestError, RequestErrorReason } from "../errors/RequestError";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../hooks/exceptionToError";
import { ExternalResource } from "./ExternalResource";
import { DeleteDivisionAPIRequest, resources } from "./resources";

export interface DeleteDivisionResultSuccess {
    success: true;
}

export interface DeleteDivisionResultDocumentsExistError {
    success: false;
    reason: 'documents';
}

export interface DeleteDivisionResultOtherError {
    success: false;
    reason: 'other';
    error: ErrorWithDetails;
}

export type DeleteDivisionResultError = DeleteDivisionResultDocumentsExistError | DeleteDivisionResultOtherError;
export type DeleteDivisionResult = DeleteDivisionResultSuccess | DeleteDivisionResultError;

export const deleteDivision = async (externalResource: ExternalResource, req: DeleteDivisionAPIRequest): Promise<DeleteDivisionResult> => {
    try {
        await externalResource.action(resources.deleteDivision, req);
        return { success: true };
    } catch (err) {
        if (err instanceof RequestError && err.reason === RequestErrorReason.Conflict) {
            // 409 Conflict response indicates that the division has documents attached
            return {
                success: false,
                reason: 'documents',
            };
        }

        return {
            success: false,
            reason: 'other',
            error: convertErrorToErrorWithDetails(err as Error),
        }
    }
};
