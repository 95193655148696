import { Unknown } from "../services/TypeUtils";
import { Division, isDivision } from "./Division";
import { either, isArrayOfStrings, isBoolean, isNull, isNumber, isString, isStringMaybe, isUndefined } from "./validateUnknown";

export interface User {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    fullName: string;
    division?: Division;
    phone?: string;
    mobile?: string;
    langKey: string;
    adminRole?: string;
    ppqRole?: string;
    paecRole?: string;
    currentRoles: string[];
    enabled: boolean;
    activated: boolean;
}

export function isUser(user: Unknown<User>): user is User {
    return isNumber(user.id)
        && isString(user.login)
        && isString(user.firstName)
        && isString(user.lastName)
        && isString(user.fullName)
        && either([isNull, isUndefined, isDivision]) (user.division)
        && isStringMaybe(user.phone)
        && isStringMaybe(user.mobile)
        && isString(user.langKey)
        && isStringMaybe(user.adminRole)
        && isStringMaybe(user.ppqRole)
        && isStringMaybe(user.paecRole)
        && isArrayOfStrings(user.currentRoles)
        && isBoolean(user.enabled)
        && isBoolean(user.activated);
}

export const userFullNameDisabled = (user: User): string => {
    let name = `${user.firstName} ${user.lastName}`;
    if (!user.enabled) {
        // label as "inactive" instead of "disabled" (term for person with a disability)
        name = `${name} (inactive)`;
    }
    return name;
};
