import { IconType } from "../../../components/ui/elements/Icon";

import {
    ButtonVariant,
    ButtonSize,
    IconButton,
    IconPosition,
    ButtonProps,
} from "../../../components/ui/elements/Button";

export const AddButton: React.FC<ButtonProps> = ({ children, ...props }) => (
    <IconButton
        variant={ButtonVariant.LIGHT}
        icon={IconType.Add}
        iconPosition={IconPosition.Left}
        size={ButtonSize.Small}
        {...props}
    >
        {children}
    </IconButton>
);
