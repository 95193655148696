import {useRouter} from "./useRouter";
import {useContext} from "react";
import {AuthenticationContext} from "../Context/AuthenticationContext";
import {fetchAccount} from "../services/fetchAccount";
import {UserAuthentication} from "../domain/Authentication";
import {ExternalResource} from "../services/ExternalResource";
import { getAppTypesForUser } from "../domain/Account";

export function useOnSuccessfulLogin(externalResource: ExternalResource): (() => void) {
    const {goto} = useRouter()
    const {setAuth} = useContext(AuthenticationContext)

    return () => {
        fetchAccount(externalResource).then((userAccount) => {
            setAuth(UserAuthentication(userAccount))
            const appTypes = getAppTypesForUser(userAccount);
            if (appTypes.length > 0) {
                goto(`/app/${appTypes[0]}`);
            }
        })
    }
}
