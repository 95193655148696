import { useMemo } from "react";
import { Log } from "../log/log";

export const useLocalStorage = () => {
    return useMemo(() => {

        const get = <T extends any>(key: string, andRemove = false): T|undefined => {
            try {
                const value = localStorage.getItem(key);
                if (andRemove) {
                    localStorage.removeItem(key);
                }
                if (value === null) return undefined;

                return JSON.parse(value);
            } catch (err) {
                Log.reportAndLogError('Error retrieving value from LocalStorage: ', err);
                return undefined;
            }
        };

        const set = <T extends any>(key: string, value: T): boolean => {
            try {
                localStorage.setItem(key, JSON.stringify(value));
                return true;
            } catch (err) {
                Log.reportAndLogError('Error storing value in LocalStorage: ', err);
                return false;
            }
        };

        const remove = (key: string): boolean => {
            try {
                localStorage.removeItem(key);
                return true;
            } catch (err) {
                Log.reportAndLogError('Error removing value from LocalStorage: ', err);
                return false;
            }
        };

        return {
            get,
            set,
            remove,
        };

    }, []);
};

export type LocalStorageHook = ReturnType<typeof useLocalStorage>;
