import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { assertNever } from '../../../services/TypeUtils';
import { WithTestID } from './WithTestID';

export type ValueType = string|number;

export interface DropDownOption<T extends ValueType> {
    type: 'option';
    value: T;
    content: React.ReactNode;
    disabled?: boolean;
}

export interface DropDownDivider {
    type: 'divider';
}

export type DropDownItem<T extends ValueType> = DropDownOption<T> | DropDownDivider;

export interface MultiDropDownProps<T extends ValueType> extends WithTestID {
    label: React.ReactNode;
    value: T[];
    items: DropDownItem<T>[];
    onChange: (newValue: T[]) => void;
    disabled?: boolean;
    isInvalid?: boolean;
    onBlur?: () => void;
}

export function MultiDropDown <T extends ValueType> ({ label, value, items, onChange, disabled, isInvalid, onBlur, testID }: MultiDropDownProps<T>) {

    const [lastClickedItem, setLastClickedItem] = useState(-1);
    const isActive = (opt: DropDownOption<T>) => value.includes(opt.value);
    const selectValue = (opt: DropDownOption<T>) => ([ ...value, opt.value ]);
    const deselectValue = (opt: DropDownOption<T>) => value.filter(val => val !== opt.value);

    const itemOnClick = (event: React.MouseEvent, item: DropDownOption<T>, idx: number) => {
        let newValue: T[] = [];
        if (event.shiftKey && lastClickedItem >= 0) {
            // select all items from the previous one to this one
            const min = Math.min(lastClickedItem, idx);
            const max = Math.max(lastClickedItem, idx);
            newValue = [ ...value ];
            for (let i = min; i <= max; i++) {
                const thisItem = items[i];
                if ('value' in thisItem && !newValue.includes(thisItem.value)) {
                    newValue.push(thisItem.value);
                }
            }
        } else {
            // just toggle this value
            newValue = isActive(item) ? deselectValue(item) : selectValue(item);
        }
        onChange(newValue);
        setLastClickedItem(idx);
    };

    return (
        <div className={isInvalid ? 'is-invalid' : undefined} data-testid={testID}>
            <Dropdown autoClose="outside" className="bg-white" onBlur={onBlur}>
                <Dropdown.Toggle variant={isInvalid ? 'outline-danger' : 'outline-primary'} disabled={disabled} className="w-100 d-flex align-items-center text-start" data-testid={testID ? `${testID}:btn` : undefined}>
                    <span className="flex-grow-1 flex-shrink-1 text-truncate">{label}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu data-testid={testID ? `${testID}:menu` : undefined}>
                    {items.map((item, idx) => {

                        switch (item.type) {
                            case 'divider':
                                return <Dropdown.Divider key={`item_${idx}`}/>;

                            case 'option':
                                return (
                                    <Dropdown.Item
                                        key={item.value}
                                        active={isActive(item)}
                                        onClick={(event) => itemOnClick(event, item, idx)}
                                        disabled={item.disabled}
                                        style={{userSelect: 'none'}}
                                    >
                                        {item.content}
                                    </Dropdown.Item>
                                );

                            default:
                                return assertNever(item);
                        }

                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
