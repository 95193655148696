import { useEffect, useRef, useState } from "react";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { Document } from "../domain/Document";
import { fetchPDFPreview } from "../services/fetchPDFPreview";
import { ErrorWithDetails, ExceptionToError } from "./exceptionToError";

export const usePDFPreview = (document: Document) => {
    const [data, setData] = useState<Blob>();
    const [error, setError] = useState<ErrorWithDetails>();

    const externalResource = useExternalResource();
    const appType = useAppType();

    const extResRef = useRef(externalResource);
    const appTypeRef = useRef(appType);

    useEffect(() => {
        fetchPDFPreview(extResRef.current, appTypeRef.current, document)
            .then(setData)
            .catch(ExceptionToError(setError));
    }, [document]);

    return { data, error };
};
