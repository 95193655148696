import useSWR from 'swr';

import {User} from "../domain/User";
import {fetchAllUsers} from "../services/fetchAllUsers";
import {ErrorWithDetails, throwErrorWithDetails} from "./exceptionToError";
import { useAppType, useExternalResource } from "../Context/ServiceContext";
import { fetchAllUsersWithDisabled } from '../services/fetchAllUsersWithDisabled';

export function useFetchAllUsers(includeDisabled = false) {

    const appType = useAppType();
    const externalResource = useExternalResource();

    return useSWR<User[], ErrorWithDetails>(`allUsers:${appType.appType}:${includeDisabled}`, () => {

        const fetcher = includeDisabled ? fetchAllUsersWithDisabled : fetchAllUsers;

        return fetcher(externalResource, appType)
            .catch(throwErrorWithDetails);

    });
};
