import {User} from "../../../../domain/User";
import React from "react";
import {Alert, AlertVariant} from "../../../ui/elements/Alert";
import {UserPopup} from "../../../ui/elements/UserPopup";

interface LockAlertProps {
    isLockedByOtherUser: boolean;
    lockUser: User | undefined;
}

export const LockAlert: React.VFC<LockAlertProps> = ({isLockedByOtherUser, lockUser}) => {
    if (!isLockedByOtherUser) return null;
    if (!lockUser) return null;

    return (
        <Alert variant={AlertVariant.WARNING}>
            <strong>You cannot edit this document</strong>. It is locked by{' '}
            <UserPopup user={lockUser}/>
        </Alert>
    );
};
