import React from 'react';
import {AllowedRoles, CommonDocumentFormFields, Document} from '../../../domain/Document';
import { DocumentLock } from '../../../domain/DocumentLock';
import { getTranslation } from '../../../domain/Translations';
import { useTranslation } from '../../../hooks/useTranslation';
import { DocumentUpdateAction } from '../../../services/resources';
import { RightPanel } from '../layouts/RightPanel';
import { EditAssigneeSection } from './EditAssigneeSection';
import { EditStatusSection } from './EditStatusSection';
import { PartialHistorySection } from './PartialHistorySection';
import { UserInfoSection } from './UserInfoSection';

export interface EditRightPanelProps {
    document: Document;
    lock: DocumentLock|undefined;
    handleUpdate: (action: DocumentUpdateAction) => Promise<unknown>;
    onAlter: (values: Partial<Document>) => void;
}

function DocumentRolesUserInfoSections({allowedRoles, roles}: { allowedRoles: AllowedRoles[], roles: CommonDocumentFormFields["roles"] }) {
    const sections = allowedRoles
        .filter(({key}) => roles?.[key]?.login)
        .map(({key, label}) => {
            return <UserInfoSection
                key={key}
                userId={roles?.[key].login ?? ""}
                header={label}
            />
        })
    return <>{sections}</>
}

export const EditRightPanel: React.VFC<EditRightPanelProps> = ({ document, lock, handleUpdate, onAlter }) => {
    const { data: translations } = useTranslation('document');

    return (
        <RightPanel>
            <EditStatusSection document={document} lock={lock} handleUpdate={handleUpdate} onAlter={onAlter} />
            <EditAssigneeSection document={document} />
            <UserInfoSection
                userId={document.creator}
                header={translations ? getTranslation(translations, 'ppqApp.document.creator') : 'Author'}
            />
            <DocumentRolesUserInfoSections allowedRoles={document.allowedRoles} roles={document.roles} />
            <PartialHistorySection document={document} />
        </RightPanel>
    );
};
