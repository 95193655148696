import useSWR from 'swr';

import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { Portfolio } from '../domain/Document';
import { fetchAllPortfolios } from '../services/fetchAllPortfolios';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

const sortPortfolioData = (list: Portfolio[]) => {
    list.sort((a, b) => a.number - b.number);
    list.forEach((portfolio) => {
        portfolio.topics.sort((a, b) => a.number - b.number);
    });
    return list;
};

export function usePortfolioList() {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<Portfolio[], ErrorWithDetails>(`portfolios:${appType.appType}`, () => {
        return fetchAllPortfolios(externalResource, appType)
            .then(sortPortfolioData)
            .catch(throwErrorWithDetails);
    });
};
