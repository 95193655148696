import {isList, validate, validateList} from "../domain/validateUnknown";
import {ExternalResource} from "./ExternalResource";
import {resources} from "./resources";
import {Action, isAction, isWorkflowActionResult, SubmitWorkflowAction, WorkflowActionResult} from "../domain/Actions";

export const getWorkflowActions = async (externalResource: ExternalResource, documentID: string): Promise<Action[]> => {
    const response = await externalResource.listAction(resources.getWorkflowActions, documentID);
    return validateList(isList(isAction), 'Action')(response);
};

export const submitWorkflowAction = async (externalResource: ExternalResource, data: SubmitWorkflowAction): Promise<WorkflowActionResult> => {
    const response = await externalResource.action(resources.submitWorkflowAction, data);
    return validate(isWorkflowActionResult, 'Action')(response);
};





