import { FC, useEffect, useState, VFC } from "react";

import { AppLayout, AppLayoutWithRightPanel } from "../../../components/ui/layouts/AppLayout";
import { useAppType } from "../../../Context/ServiceContext";
import { EditForm } from "../../../components/form/forms/EditForm";
import { EditRightPanel } from "../../../components/ui/panels/EditRightPanel";
import { Formik, FormikErrors } from "formik";
import {
    Document,
    isSavedDocument,
    UnsavedDocument,
    userCanEditDocument
} from "../../../domain/Document";
import { NextAction, UpdateResolution, useUpdateDocument } from "../../../hooks/useUpdateDocument";
import { Log } from "../../../log/log";
import { DocumentUpdateAction } from "../../../services/resources";
import { useRouter } from "../../../hooks/useRouter";
import { WithTestID } from "../../../components/ui/elements/WithTestID";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { AssigneeNoteModal } from "../../../components/ui/modals/AssigneeNoteModal";
import { DocumentCommentModal } from "../../../components/ui/modals/DocumentCommentModal";
import { NavigationPrompt } from "../../../components/ui/elements/NavigationPrompt";
import { EditInactivityModal } from "../../../components/ui/modals/EditInactivityModal";
import { useDocumentReadOnly } from "../../../hooks/useDocumentReadOnly";
import { DocumentLock } from "../../../domain/DocumentLock";
import { notificationService } from "../../../services/notificationService";
import { ErrorWithDetails } from "../../../hooks/exceptionToError";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { useParams } from "react-router";
import { NotFound } from "../../NotFound";
import { canEditBriefs } from "../../../domain/Account";
import { useFetchDocument } from "../../../hooks/useFetchDocument";
import { useDocumentLock } from "../../../hooks/useDocumentLock";
import { ErrorInLayout } from "../../../components/ui/elements/ErrorInLayout";
import { LoadingMessage } from "../../../components/ui/elements/LoadingMessage";
import { DocumentID } from "../../../services/ExternalResource";
import { DocumentCurrentNoteModal } from "../../../components/ui/modals/DocumentCurrentNoteModal";
import { useFetchNewDocument } from "../../../hooks/useFetchNewDocument";
import { DocumentContextProvider } from "../../../Context/DocumentContext";

export const NotificationStorageKey = 'EditPage.Notification';
export const NotificationMessageKey = 'EditPage.notificationMessage';

interface EditPageProps extends WithTestID {
    document: Document|UnsavedDocument;
    lock: DocumentLock|undefined;
}
const EditPage: VFC<EditPageProps> = ({ document, lock, testID }) => {
    const appType = useAppType();
    const { goto, gotoOrReload } = useRouter();
    const {
        updateDocument,
        cancelModals,
        showAssigneeModal,
        showCommentModal,
        setAssigneeNote,
        setComment,
        saving,
        adminAlterDocument,
    } = useUpdateDocument(document);
    const readOnly = useDocumentReadOnly(document, lock);

    const [updating, setUpdating] = useState(false);
    const storage = useLocalStorage();

    useEffect(() => {
        // if notificationFlag is set in local storage, show a success notification
        const notificationFlag: boolean|undefined = storage.get(NotificationStorageKey, true);
        const notificationMessage: string|undefined = storage.get(NotificationMessageKey, true);

        if (notificationFlag === true) {
            notificationService.showSuccessNotification(notificationMessage ?? 'Saved document');
        }
    }, [storage]);

    const showSuccessNotification = (resolution: UpdateResolution) => {
        // save a flag in local storage so that the notification will be shown after reload
        storage.set(NotificationStorageKey, true);
        return resolution;
    };

    const navigate = (resolution: UpdateResolution) => {
        switch (resolution.action) {
            case NextAction.Dashboard:
                goto(`/app/${appType.appType}`);
                break;
            case NextAction.Reload:
                gotoOrReload(`/app/${appType.appType}/edit/${resolution.id}`);
                break;
        }
    };

    const handleError = (err: ErrorWithDetails) => {
        Log.reportAndLogError('Error saving document', err);
        notificationService.showErrorNotification('An error occurred saving the document. Please try again.');
    };

    const handleUpdate = (action: DocumentUpdateAction, values: any) => {
        if (readOnly) {
            return Promise.reject('Cannot update, Document is read only.');
        }

        values.roles = values.roles ?? {};
        document.allowedRoles.forEach(({key}) => {
            values.roles = values.roles ?? {};
            values.roles[key] = {id: 0, login: values[key as keyof UnsavedDocument] as string}
        })
        values.roles['CREATOR'] = {id: 0, login: values.creator}
        values.roles['ASSIGNEE'] = {id: 0, login: values.assignee}

        setUpdating(true);

        return updateDocument(action, values)
            .then(showSuccessNotification)
            .then(navigate)
            .catch(handleError);
    };

    const adminAlter = (values: Partial<Document>) => {
        if(!isSavedDocument(document)) {
            return
        }
        setUpdating(true);
        adminAlterDocument(document.id , values)
            .then(showSuccessNotification)
            .then(navigate)
            .catch(handleError);
    };

    let initialErrors: FormikErrors<Document>|undefined = undefined;
    if (!isSavedDocument(document)) {
        // Creating a new document.
        // Set an initial error, so that the 'Create' button is initially disabled
        // the form is revalidated on any input change, so the button will be enabled
        // when all required fields are filled out.
        // This is a workaround because the 'validateOnMount' property doesn't work.
        // We use a field that is not displayed in the form, along with a dummy error
        // message, so that the UI doesn't display any error message, but the button
        // will still be disabled until the form is valid.
        initialErrors = { hasQuestion: 'Enter required fields' };
    }

    const roles: Record<string, number> = !!document.roles ? Object.keys(document.roles).reduce((result, key) => ({...result, [key]: document.roles?.[key].login}), {}) : {};

    return (
        // TODO: refactor using the custom Form component to reduce the impact of Formik changes on PPQ Application Code
        <Formik
            initialValues={{...document,...roles}}
            onSubmit={(values, helpers) => {
                handleUpdate(DocumentUpdateAction.Save, values);
                helpers.setSubmitting(false);
            }}
            data-testid={testID}
            initialErrors={initialErrors}
        >
            {({values, dirty, isValid}) => (
                <>
                    <AppLayoutWithRightPanel panel={
                        // TODO refactor bootstrap classes into Layout Object (+ add to StoryBook for Visual Regression testing)
                        <div className="mt-3">
                            {isSavedDocument(document) && <DocumentContextProvider value={{
                                documentHasUnsavedChanges: () => {
                                    return dirty;
                                },
                                documentIsReadOnly: () => {
                                    return readOnly;
                                },
                                handleUpdate: (action) => handleUpdate(action, values),
                                testID: () => {
                                    return testID
                                }
                            }}>
                                <EditRightPanel
                                    document={document}
                                    lock={lock}
                                    handleUpdate={(action) => handleUpdate(action, values)}
                                    onAlter={adminAlter}
                                />
                            </DocumentContextProvider>}
                        </div>
                    }>
                        <div className="mt-3">
                            <EditForm testID={testID} document={document} lock={lock} handleUpdate={(action) => handleUpdate(action, values)} />
                        </div>
                    </AppLayoutWithRightPanel>
                    <NavigationPrompt enabled={dirty && !updating} onSave={() => {
                        return handleUpdate(DocumentUpdateAction.SaveOnTimeout, values)
                            .then(() => {}) // convert Promise<NextAction> to Promise<void>
                            .catch(handleError);
                    }} />
                    <EditInactivityModal
                        enabled={!readOnly}
                        onTimeout={() => {
                            if (isValid) {
                                handleUpdate(DocumentUpdateAction.SaveOnTimeout, values);
                            } else {
                                navigate({ action: NextAction.Dashboard });
                            }
                        }}
                    />
                    <AssigneeNoteModal
                        show={showAssigneeModal}
                        onCancel={cancelModals}
                        onSubmit={(assignee, note) => setAssigneeNote(assignee!, note)}
                        mandatory
                    />
                    <DocumentCommentModal
                        show={showCommentModal}
                        onCancel={cancelModals}
                        onSubmit={setComment}
                    />
                    {isSavedDocument(document) && <DocumentCurrentNoteModal document={document} />}
                    <LoadingOverlay show={saving} message="Saving..." />
                </>
            )}
        </Formik>
    );
};

interface CreateAction {
    type: 'create';
}

interface EditAction {
    type: 'edit';
    id: number;
}

interface InvalidAction {
    type: 'invalid';
}

type PageAction = CreateAction | EditAction | InvalidAction;

// TODO use the common useIdParam hook when other PRs are merged
const useIdParam = (): PageAction => {

    // first get the id parameter from the route
    const { id } = useParams<{ id?: string }>();

    // if no parameter is specified, return undefined - this means we're creating a new brief
    if (id === undefined) return { type: 'create' };

    // now convert it to a number, if possible
    const str = id.trim();
    if (str === '') return { type: 'invalid' };
    if (str.match(/[^\d]/)) return { type: 'invalid' };
    const nbr = Number(str);
    if (isNaN(nbr)) return { type: 'invalid' };
    return { type: 'edit', id: nbr };

};

export const PPQEdit: VFC = () => {
    const action = useIdParam();

    switch (action.type) {
        case "invalid":
            return <NotFound />;
        case "create":
            return <PPQNewAndEdit />
        case "edit":
            return <PPQLoadAndEdit id={action.id} />;
    }
};

interface PPQLoadAndEditProps {
    id: DocumentID;
}
const PPQLoadAndEdit: FC<PPQLoadAndEditProps> = ({ id }) => {

    const userAccount = useAuthenticatedUser();
    const appType = useAppType();
    const { document, error} = useFetchDocument(id);
    const { lock, error: lockError } = useDocumentLock(id);
    const { goto } = useRouter();

    const anyError = error || lockError;
    if (anyError) {
        return <ErrorInLayout Layout={AppLayout} error={anyError} />;
    }

    if (!document) {
        return <LoadingMessage Layout={AppLayout} />;
    }

    if (!userCanEditDocument(document, userAccount, appType)) {
        goto(`/app/${appType.appType}/view/${id}`);
        return null;
    }

    return <EditPage document={document} lock={lock} testID="edit-page" />;
};
const PPQNewAndEdit: FC = () => {

    const userAccount = useAuthenticatedUser();
    const appType = useAppType();
    const { document, error} = useFetchNewDocument();
    const { goto } = useRouter();

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    if (!document) {
        return <LoadingMessage Layout={AppLayout} />;
    }

    if (!canEditBriefs(userAccount, appType)) {
        goto(`/app/${appType.appType}`);
        return null;
    }

    return <EditPage document={document} lock={undefined} />;
};
