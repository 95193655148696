import { Unknown } from "../services/TypeUtils";
import { appType } from "./AppType";
import { isAppType } from "./Document";
import { has, isNumber, isString } from "./validateUnknown";

export interface Template {
    id: number;
    appType: appType;
    name: string;
    sortOrder: number;
    text: string;
}

export const isTemplate = (data: Unknown<Template>): data is Template => {
    return has(data, 'id', isNumber)
        && has(data, 'appType', isAppType)
        && has(data, 'name', isString)
        && has(data, 'sortOrder', isNumber)
        && has(data, 'text', isString);
};
