import { ExternalResource } from "./ExternalResource";
import { AppType } from "../domain/AppType";
import { Portfolio, isPortfolio } from "../domain/Document";
import { validate } from "../domain/validateUnknown";

export function updatePortfolio(
    externalResource: ExternalResource,
    appType: AppType,
    portfolio: Portfolio
): Promise<Portfolio> {
    return externalResource
        .action(appType.Resources.savePortfolio, portfolio)
        .then(validate(isPortfolio, "Portfolio"));
}
