import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useSystemBasic } from '../../hooks/useSystemBasic';

export const UpdateSentryConfig: React.VFC = () => {
    const { data } = useSystemBasic();
    useEffect(() => {
        if (data) {
            Sentry.configureScope(function(scope) {
                scope.update({
                    extra: { ppqDepartment: data.ppqDepartment },
                });
            });
        }
    }, [data]);
    return null;
};
