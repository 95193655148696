import React from 'react';
import { userFullNameDisabled } from '../../../domain/User';
import { useFetchUser } from '../../../hooks/useFetchUser';
import { Icon, IconType } from '../elements/Icon';
import { PanelSection } from '../elements/PanelSection';

import './UserInfoSection.scss';

export interface UserInfoSectionProps {
    header: React.ReactNode;
    userId: string;
}

export const UserInfoSection: React.FC<UserInfoSectionProps> = ({ header, userId, children }) => {
    const { data: user } = useFetchUser(userId);
    if (!user) return null;

    return (
        <PanelSection header={header} className="user-info-section">
            <div>
                <a
                    className={`user-email ${user.enabled ? '' : 'user-disabled'}`}
                    href={`mailto:${user.login}`}
                >{userFullNameDisabled(user)}</a>
            </div>
            {user.phone && (
                <div>
                    <Icon icon={IconType.Phone} className="contact-icon" />
                    {user.phone}
                </div>
            )}
            {user.mobile && (
                <div>
                    <Icon icon={IconType.MobilePhone} className="contact-icon" />
                    {user.mobile}
                </div>
            )}
            <div>
                <Icon icon={IconType.Email} className="contact-icon" />
                {user.login}
            </div>
            {children}
        </PanelSection>
    );

};
