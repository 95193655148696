import React from 'react';

import { AdminRole } from '../../../domain/Account';
import { SelectElement, SelectOption } from './SelectElement';
import { WithTestID } from './WithTestID';

export interface AdminRoleSelectProps extends WithTestID {
    value: AdminRole|undefined;
    onChange: (val: AdminRole|undefined) => void;
}

export const AdminRoleSelect: React.VFC<AdminRoleSelectProps> = ({ value, onChange, testID }) => {

    const options: SelectOption<string>[] = [
        { value: '', label: 'Unfiltered' },
        { value: AdminRole.SysAdmin, label: 'Administrator' },
    ];

    return (
        <SelectElement
            options={options}
            value={value}
            onChange={(val) => {
                if (Object.values(AdminRole).includes(val as AdminRole)) {
                    onChange(val as AdminRole);
                } else {
                    onChange(undefined);
                }
            }}
            testID={testID}
        />
    );
};
