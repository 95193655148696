import {
    FormEventHandler,
    KeyboardEventHandler,
    KeyboardEvent,
    useEffect,
    useState,
    VFC,
    useMemo,
} from "react";
import {Form} from "react-bootstrap";

import {WithTestID} from "./WithTestID";
import './AutoComplete.scss';

interface OptionObj {
    value: string|number;
    label: string;
    info?: string;
}

interface AutoCompleteInternalProps extends WithTestID {
    onChange: (newValue: string|number) => void;
    value: string|number|undefined;
    options: OptionObj[];
    id?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    onBlur?: () => void;
}

const AutoCompleteInternal: VFC<AutoCompleteInternalProps> = ({onChange, options, testID, id, isInvalid, value, disabled = false, placeholder = '', required, onBlur}) => {
    const [filter, setFilter] = useState<string>(''); // the value that is currently displayed in the text input field
    const [selected, setSelected] = useState<string>(''); // the option value that has been "selected"
    const [hasFocus, setHasFocus] = useState(false); // whether the text input field currently has focus

    useEffect(() => {
        // input value has changed from parent component, so update the filter state
        const opt = options.find(o => o.value === value);
        setFilter(opt ? opt.label : '');
    }, [value, options]);

    useEffect(() => {
        // when the input field blurs, reset the filter state to the currently-selected value
        if (!hasFocus) {
            const opt = options.find(o => o.value === value);
            setFilter(opt ? opt.label : '');
        }
    }, [hasFocus, value, options]);

    const isHTMLInput = (currentTarget: {}): currentTarget is HTMLInputElement => {
        return currentTarget.hasOwnProperty("value");
    };

    const catchOnChange: FormEventHandler = (e) => {
        // called when the input value changes due to the user typing into it
        if (isHTMLInput(e.currentTarget)) {
            const inputValue = e.currentTarget.value;
            setFilter(inputValue);
            // if the value typed in exactly matches an option in the list, then select that option
            const opt = options.find(o => o.label === inputValue);
            if (opt) {
                optionSelected(opt);
            } else if (inputValue === '') {
                optionSelected(undefined);
            }
        }
    };

    const filterOptions = (options: OptionObj[], filter: string) => {
        const normalizedFilter = filter.toLocaleLowerCase();
        return options.filter(o => o.label.toLocaleLowerCase().indexOf(normalizedFilter) !== -1);
    };

    const getFilteredOptions = () => {
        return filterOptions(options, filter);
    };

    const getFilteredOptionSelectedIndex = () => {
        if (selected === '') return -1;
        const filteredOptions = getFilteredOptions();
        return filteredOptions.findIndex(opt => opt.label === selected);
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e: KeyboardEvent<HTMLInputElement>) => {
        // this stops the form from submitting when the user hits enter on the input field
        if (e.code === 'Enter') {
            e.preventDefault();
        }
    };

    const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e: KeyboardEvent<HTMLInputElement>) => {
        const filteredOptions = getFilteredOptions();
        const idx = getFilteredOptionSelectedIndex();

        if (e.code === 'Enter') {
            if (idx >= 0) {
                // Enter key pressed - if the user has navigated to an option via the arrow keys, select it
                optionSelected(filteredOptions[idx]);
            } else {
                // Otherwise, select the first option
                if (filteredOptions.length > 0) {
                    optionSelected(filteredOptions[0]);
                }
            }
            e.preventDefault();
        }
        if (e.code === 'ArrowDown') {
            // Down arrow key pressed - update the selected option
            if (idx < 0 || idx === filteredOptions.length - 1) {
                setSelected(filteredOptions[0].label);
            } else {
                setSelected(filteredOptions[idx + 1].label);
            }
        }
        if (e.code === 'ArrowUp') {
            // Up arrow key pressed - update the selected option
            if (idx <= 0) {
                setSelected(filteredOptions[filteredOptions.length - 1].label);
            } else {
                setSelected(filteredOptions[idx - 1].label);
            }
        }
    };

    const onFocus = () => setHasFocus(true);
    const onBlurHandler = () => {
        setHasFocus(false);
        if (onBlur) {
            onBlur();
        }
    };

    const optionSelected = (opt: OptionObj | undefined) => {

        if (opt) {
            // call onChange with the new value
            onChange(opt.value);

            // update the filter state
            setFilter(opt.label);

            // update the selected value state
            setSelected(opt.label);
        } else {
            onChange('');
            setFilter('');
            setSelected('');
        }

    };

    const strValue = (value === null || value === undefined) ? '' : `${value}`;
    return (
        <div className={`autocomplete ${hasFocus ? 'autocomplete--focus' : ''} ${isInvalid ? 'is-invalid' : ''}`} data-testid={testID ? `${testID}:container` : undefined}>
            <input
                type="hidden"
                data-testid={testID ? `${testID}:hidden` : undefined}
                value={strValue}
            />
            <Form.Control
                value={filter}
                isInvalid={isInvalid}
                id={id}
                onFocus={onFocus}
                onBlur={onBlurHandler}
                onChange={catchOnChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                data-testid={testID}
                autoComplete={"off"}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
            />
            <Options
                options={getFilteredOptions()}
                testID={testID ? `${testID}:options` : undefined}
                selected={selected}
                clicked={optionSelected}
            />
        </div>
    );
}

interface OptionsProps extends WithTestID {
    options: OptionObj[];
    clicked: (opt: OptionObj) => void;
    selected: string;
}

const Options: VFC<OptionsProps> = ({options, testID, clicked, selected}) => {

    const noneFoundEl = options.length === 0 ? (
        <OptionCmp option={{ value: 0, label: 'None found' }} disabled={true} />
    ) : undefined;

    return (
        <div data-testid={testID} className="autocomplete__options">
            {options.map((opt, i) => (
                <OptionCmp
                    option={opt}
                    key={opt.value}
                    clicked={() => clicked(opt)}
                    selected={selected === opt.label}
                    testID={testID ? `${testID}:item-${i}` : undefined}
                />
            ))}
            {noneFoundEl}
        </div>
    );
}


interface OptionProps extends WithTestID {
    option: OptionObj;
    clicked?: () => void;
    selected?: boolean;
    disabled?: boolean;
}

const OptionCmp: VFC<OptionProps> = ({option, clicked, selected = false, testID, disabled = false}) => {
    const cn = 'autocomplete__option';
    const classNames: string[] = [cn];
    if (disabled) classNames.push(`${cn}--disabled`);
    if (selected) classNames.push(`${cn}--selected`);
    return (
        <div
            className={classNames.join(' ')}
            onMouseDown={clicked}
            data-testid={testID}
            aria-selected={selected}
            aria-disabled={disabled}
        >
            {option.label}
            {option.info && <span className="autocomplete__option__info">{option.info}</span>}
        </div>
    );
};

export type Option = string | OptionObj;

export interface AutoCompleteProps extends Omit<AutoCompleteInternalProps, 'options'> {
    options: Option[];
}

export const AutoComplete: React.VFC<AutoCompleteProps> = ({ options, ...props }) => {

    const convertedOptions = useMemo<OptionObj[]>(() => {
        if (options.length === 0) return [];
        return options.map((opt) => {
            if (typeof opt === 'string') {
                return { value: opt, label: opt };
            } else {
                return opt;
            }
        });
    }, [options]);

    return <AutoCompleteInternal options={convertedOptions} {...props} />;

};
