import {CreateListResource, CreateResource, DocumentID, ListActions, resource, SingleActions} from "./ExternalResource";
import {formCredentials} from "./submitLogin";
import {User} from "../domain/User";
import {
    Document,
    Portfolio,
    PortfolioStatusReport,
    ReviewStatus,
    Topic,
    TopicDocuments,
    UnsavedDocument
} from "../domain/Document";
import {ApiSearch} from "./searchDocuments";
import {appType} from "../domain/AppType";
import {Account, AdminRole, AppRole} from "../domain/Account";
import {Division} from "../domain/Division";
import {Feature} from "../domain/Feature";
import {DocumentLock} from "../domain/DocumentLock";
import {SystemBasic} from "../domain/SystemBasic";
import {TranslationRequest} from "./fetchTranslations";
import {Translations} from "../domain/Translations";
import {Template} from "../domain/Template";
import {DocumentHistory, DocumentHistoryDto} from "../domain/DocumentHistory";
import {CountDTO} from "../domain/CountDTO";
import {Action, SubmitWorkflowAction, WorkflowActionResult} from "../domain/Actions";

export enum DocumentUpdateAction {
    RequestApproval = 'approveRequest',     // request approval from approver
    ApprovalDenied = 'approveUpdate',       // requires update - approver rejected the document
    Approved = 'approveApprove',            // approved - approver accepted the document
    ReviewedUpdated = 'reviewedUpdated',    // reviewed and updated - document will need to be approved again
    ReviewedNoChange = 'reviewedNoChange',  // reviewed with no change - document will need to be approved again
    UpdatedOnly = 'reviewedUpdatedOnly',    // mark as updated - no change to review status
    ReviewedOnly = 'reviewedReviewedOnly',  // mark as "reviewed no change" - no change to review status
    Save = 'save',                          // just save changes to the document - no change to status or review status
    SaveOnTimeout = 'saveOnTimeout',        // save changes to the document on timeout
    SaveNoRedirect = 'saveNoRedirect',      // save changes and then do not redirect
    Reassign = 'reassign',                  // reassign then save
}

export interface DocumentDto extends Document {
    assigneeNote?: string;
}

export interface DocumentUpdateRequest {
    action: DocumentUpdateAction;
    comment?: string;
    document: DocumentDto;
}

export interface DocumentAdminAlterAPIRequest {
    date: string;
    number: number;
}

export interface BulkDocumentRequest {
    ids: DocumentID[];
}

export interface BulkDocumentRequestWithSort extends BulkDocumentRequest {
    sort?: string; // json string
}

export interface BulkDocumentRequestWithComment extends BulkDocumentRequest {
    comment: string;
}

export interface BulkDocumentRequestWithTopicId extends BulkDocumentRequest {
    topicId: number;
}

export interface MarkPendingReviewRequest extends BulkDocumentRequest {
    note?: string;
    assignee?: string;
    assignToAuthor: boolean;
}

export interface BulkDocumentRequestWithContiguous extends BulkDocumentRequest {
    makeContiguous?: boolean;
}

export interface BulkPortfoliosRequest {
    ids: number[];
    makeTopicsContiguous: boolean;
    reviewStatuses: ReviewStatus[];
}

export interface NoteSeenRequest {
    id: DocumentID;
}

export interface RequestAccessRequest {
    appType: appType;
}

export enum QueryUsersAPIAdminRole {
    UserAdmin = 'UserAdmin',
}

export enum QueryUsersAPIAppRole {
    Any = 'Any',
    Admin = 'Admin',
    Approver = 'Approver',
    Coordinator = 'Coordinator',
    DTPCoordinator = 'DTP-Coordinator',
    Author = 'Author',
    Minister = 'Minister',
}

export interface QueryUsersAPIRequest {
    adminRole?: QueryUsersAPIAdminRole;
    paecRole?: QueryUsersAPIAppRole;
    ppqRole?: QueryUsersAPIAppRole;
    filter?: string;
    division?: number;
    sort?: string;
    status?: boolean;
    limit?: number;
}

export interface InviteUserAPIRequest {
    email: string;
    role: AppRole;
    appType: appType;
}

export interface SaveProfileAPIRequest {
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    division: Division;
}

export interface CreateExtractAPIRequest {
    culled: boolean;
}

export interface CreatePortfolioAPIRequest extends Omit<Portfolio, 'id'|'topics'> {
    topics: Omit<Topic, 'id'>[];
}

export interface CreateTemplateAPIRequest extends Omit<Template, 'id'|'sortOrder'> {
    sortOrder: number|null;
}

export interface CreateDivisionAPIRequest extends Omit<Division, 'id'> {
}

export interface DeleteDivisionAPIRequest {
    id: number;
    migrateTo?: Division;
}

export interface RedeemSSOAPIRequest {
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    division: Division;

    key: string;
}

export interface RedeemPasswordAPIRequest extends RedeemSSOAPIRequest {
    password: string;
}

export interface SubmitPasswordActivateRequest {
    key: string;
    newPassword: string;
}

export interface SubmitPasswordResetRequest {
    key: string;
    newPassword: string;
}

export interface SaveUserAPIRequest {
    id: number;
    firstName: string;
    lastName: string;
    phone: string|null;
    mobile: string|null;
    division: Division;
    enabled: boolean;
    ppqRole: AppRole|null;
    paecRole: AppRole|null;
    adminRole: AdminRole.SysAdmin|null;
}

export interface CreateUserAPIRequest extends Omit<SaveUserAPIRequest, 'id'|'division'> {
    email: string;
    division?: Division;
}
export interface SendUsersEmailAPIRequest {
    subject: string;
    body: string;
    users?: string;
}
export interface SendUserEmailAPIResponse {

}
export type AdminAlterRequest = { id: DocumentID, body: DocumentAdminAlterAPIRequest };


export const resources = {
    login: CreateResource<formCredentials, User>({action: SingleActions.Login, resource: resource.Login}),
    logout: CreateResource<null, null>({action: SingleActions.Logout, resource: resource.Logout}),
    systemBasic: CreateResource<null, SystemBasic>({action:SingleActions.Read, resource:resource.SystemBasics}),
    getAccount: CreateResource<null, Account>({action: SingleActions.Read, resource: resource.Account}),
    features: CreateListResource<null, Feature>({action: ListActions.List, resource: resource.Feature}),
    translations: CreateResource<TranslationRequest, Translations>({action: SingleActions.Read, resource: resource.Translations}),
    requestAccess: CreateResource<RequestAccessRequest, null>({action: SingleActions.RequestAccess, resource: resource.Account}),
    queryAllUsers: CreateListResource<QueryUsersAPIRequest, User>({action: ListActions.Search, resource: resource.User}),
    getUserById: CreateResource<number, User>({action: SingleActions.Read, resource: resource.User}),
    createUser: CreateResource<CreateUserAPIRequest, User>({action: SingleActions.Create, resource: resource.User}),
    sendUsersEmail: CreateResource<SendUsersEmailAPIRequest, SendUserEmailAPIResponse>({action: SingleActions.Send, resource: resource.UserEmail}),
    saveUser: CreateResource<SaveUserAPIRequest, null>({action: SingleActions.Update, resource: resource.User}),
    saveProfile: CreateResource<SaveProfileAPIRequest, null>({action: SingleActions.Update, resource: resource.Account}),
    getAllDivisions: CreateListResource<{}, Division>({action: ListActions.List, resource: resource.Division}),
    createDivision: CreateResource<CreateDivisionAPIRequest, Division>({action: SingleActions.Create, resource: resource.Division}),
    deleteDivision: CreateResource<DeleteDivisionAPIRequest, null>({action: SingleActions.Delete, resource: resource.Division}),
    requestPasswordReset: CreateResource<string, null>({action: SingleActions.RequestPasswordReset, resource: resource.Account}),
    submitPasswordActivate: CreateResource<SubmitPasswordActivateRequest, null>({action: SingleActions.SubmitPasswordActivate, resource: resource.Account}),
    submitPasswordReset: CreateResource<SubmitPasswordResetRequest, null>({action: SingleActions.SubmitPasswordReset, resource: resource.Account}),
    getWorkflowActions: CreateListResource<string, Action>({action: ListActions.List, resource: resource.Workflow}),
    submitWorkflowAction: CreateResource<SubmitWorkflowAction, WorkflowActionResult>({action: SingleActions.Send, resource: resource.Workflow}),
};


export function CreateAppTypeResources(appType: appType) {
    return {
        searchDocuments: CreateListResource<ApiSearch, Document>({action: ListActions.Search, resource: resource.Document, appType: appType}),
        getDocument: CreateResource<number, Document>({action: SingleActions.Read, resource: resource.Document, appType: appType}),
        getNewDocument: CreateResource<null, UnsavedDocument>({action: SingleActions.Read, resource: resource.Document, appType: appType}),
        getAllUsers: CreateListResource<{limit?: number}, User>({action: ListActions.List, resource: resource.User, appType: appType}),
        getAllUsersWithDisabled: CreateListResource<null, User>({action: ListActions.ListWithDisabled, resource: resource.User, appType}),
        saveDocument: CreateResource<DocumentUpdateRequest, string>({action: SingleActions.Update, resource: resource.Document, appType: appType}),
        createDocument: CreateResource<Document, number>({action: SingleActions.Create, resource: resource.Document, appType }),
        adminAlterDocument: CreateResource<AdminAlterRequest, null>({action: SingleActions.AdminAlter, resource: resource.Document, appType: appType}),
        getAllPortfolios: CreateListResource<{}, Portfolio>({action: ListActions.List, resource: resource.Portfolio, appType}),
        createPortfolio: CreateResource<CreatePortfolioAPIRequest, Portfolio>({action: SingleActions.Create, resource: resource.Portfolio, appType}),
        savePortfolio: CreateResource<Portfolio, Portfolio>({action: SingleActions.Update, resource: resource.Portfolio, appType}),
        deletePortfolio: CreateResource<number, null>({action: SingleActions.Delete, resource: resource.Portfolio, appType}),
        getPortfolioStatusReport: CreateListResource<null, PortfolioStatusReport>({action: ListActions.StatusReport, resource: resource.Portfolio, appType}),
        getPortfolioDocumentCount: CreateResource<number, CountDTO>({action: SingleActions.CountDocuments, resource: resource.Portfolio, appType}),
        getTopicDocumentCount: CreateResource<number, CountDTO>({action: SingleActions.CountDocuments, resource: resource.Topic, appType}),
        saveDivision: CreateResource<Division, Division>({ action: SingleActions.Update, resource: resource.Division, appType: appType, }),
        obtainDocumentLock: CreateResource<number, DocumentLock>({action: SingleActions.ObtainLock, resource: resource.Document, appType: appType}),
        getAllTemplates: CreateListResource<null, Template>({action: ListActions.List, resource: resource.Template, appType}),
        createTemplate: CreateResource<CreateTemplateAPIRequest, Template>({action: SingleActions.Create, resource: resource.Template, appType}),
        saveTemplate: CreateResource<Template, Template>({action: SingleActions.Update, resource: resource.Template, appType}),
        deleteTemplate: CreateResource<number, null>({action: SingleActions.Delete, resource: resource.Template, appType}),
        previewDocumentPDF: CreateResource<Document, Blob>({action: SingleActions.PreviewPDF, resource: resource.Document, appType}),
        getDocumentCurrentNote: CreateResource<DocumentID, DocumentHistory>({action: SingleActions.GetCurrentNote, resource: resource.Document, appType}),
        getDocumentPartialHistory: CreateListResource<DocumentID, DocumentHistory>({action: ListActions.PartialList, resource: resource.DocumentHistory, appType}),
        getAllDocumentHistories: CreateListResource<DocumentID, DocumentHistoryDto>({ action: ListActions.List, resource: resource.DocumentHistory, appType}),
        getDocumentHistory: CreateResource<number, DocumentHistory>({action: SingleActions.Read, resource: resource.DocumentHistory, appType}),
        getLatestDocumentHistory: CreateResource<DocumentID, DocumentHistory>({action: SingleActions.ReadLatest, resource: resource.DocumentHistory, appType}),
        clearDocumentChanges: CreateResource<BulkDocumentRequest, null>({action: SingleActions.ClearChanges, resource: resource.Document, appType }),
        renumberDocuments: CreateResource<BulkDocumentRequestWithSort, null>({action: SingleActions.Renumber, resource: resource.Document, appType }),
        cullDocuments: CreateResource<BulkDocumentRequestWithComment, null>({action: SingleActions.Cull, resource: resource.Document, appType }),
        uncullDocuments: CreateResource<BulkDocumentRequest, null>({action: SingleActions.Uncull, resource: resource.Document, appType }),
        getMultipleDocumentsInOrder: CreateListResource<BulkDocumentRequestWithContiguous, Document>({action: ListActions.ListSelectedInOrder, resource: resource.Document, appType}),
        getMultipleDocumentsInOrderByPortfolios: CreateListResource<BulkPortfoliosRequest, TopicDocuments>({action: ListActions.ListSelectedInOrderByPortfolios, resource: resource.Document, appType}),
        moveDocuments: CreateResource<BulkDocumentRequestWithTopicId, null>({action: SingleActions.Move, resource: resource.Document, appType }),
        deleteDocuments: CreateResource<BulkDocumentRequest, null>({action: SingleActions.Delete, resource: resource.Document, appType }),
        markPendingReview: CreateResource<MarkPendingReviewRequest, null>({action: SingleActions.MarkPendingReview, resource: resource.Document, appType }),
        markReadyForPrint: CreateResource<BulkDocumentRequest, null>({action: SingleActions.MarkReadyForPrint, resource: resource.Document, appType }),
        markWithDTPCoordinator: CreateResource<BulkDocumentRequest, null>({action: SingleActions.MarkWithDTPCoordinator, resource: resource.Document, appType }),
        markApproved: CreateResource<BulkDocumentRequest, null>({action: SingleActions.MarkApproved, resource: resource.Document, appType }),
        markWithMinister: CreateResource<BulkDocumentRequest, null>({action: SingleActions.MarkWithMinister, resource: resource.Document, appType }),
        markNoteSeen: CreateResource<NoteSeenRequest, null>({action: SingleActions.MarkNoteSeen, resource: resource.Document, appType}),
        createExtract: CreateResource<CreateExtractAPIRequest, null>({action: SingleActions.CreateExtract, resource: resource.Document, appType}),
    }
}

export type AppTypeResources = ReturnType<typeof CreateAppTypeResources>
type allResources = (typeof resources) & AppTypeResources
export type OneOfResource = allResources[keyof allResources]

type allSingleResources = {
    [k in keyof allResources]: allResources[k]["action"] extends SingleActions  ? allResources[k] : never;
}
type allListResources = {
    [k in keyof allResources]: allResources[k]["action"] extends ListActions  ? allResources[k] : never;
}
export type OneOfSingleResource = allSingleResources[keyof allSingleResources]
export type OneOfListResource = allListResources[keyof allListResources]
