import {Dispatch, MouseEvent, ReactChild, useState, VFC} from "react";
import {Form, Table as BootstrapTable} from "react-bootstrap";
import {WithTestID} from "./WithTestID";
import {TableHeaderSortIndicator} from "./TableHeaderSortIndicator";

export interface Column<T> {
    label: string
    field: keyof T
    sortable: boolean
    displayHeader?: VFC
    displayCell: VFC<T>
}

export type SortDirection = 'ASC' | 'DESC' | undefined;
export type Sort<T> = Partial<{
    [K in keyof T]: SortDirection
}>

function nextSortDirection(current: SortDirection): SortDirection {
    if (current === "ASC") {
        return "DESC"
    }
    if (current === "DESC") {
        return undefined
    }
    return "ASC"
}

export function CreateTable<D extends {id: string|number}>() {
    type T = IsSelectable<D>
    type RowKey = keyof T
    type DataKeys = keyof D
    type IDType = string|number
    type SortEventHandler = (sortKey: keyof D) => void

    function emptySort(): Sort<D> {
        return {}
    }

    type SelectColumn = "SelectColumn"
    const isSelectColumn = (c: Column<D> | SelectColumn): c is SelectColumn => {
        return c === "SelectColumn"
    }
    const selectColumn: SelectColumn = "SelectColumn"

    interface TableProps {
        columns: (Column<D> | SelectColumn)[]
        data: T[]
        sort: Sort<T>
        onSort: (sort: Sort<D>) => void
        onSelect: (id: (string|number)[]) => void
        noneFoundMessage?: string;
        extraTheadRows?: ReactChild
    }

    const mapStringsToColumns = (fields: (RowKey)[]): (Column<T> | SelectColumn)[] => {
        return fields.map((field) => {
            if (field === "isSelected") {
                return selectColumn
            }
            const c: Column<T> = ({
                label: `${field}`,
                field: field,
                sortable: true,
                displayHeader: () => {
                  return <>{field}</>
                },
                displayCell: (data) => {
                    return <>{data[field]}</>
                }
            })
            return c
        })
    }



    const Table: VFC<TableProps&WithTestID> = ({columns, data, onSelect, testID, sort, onSort, noneFoundMessage , extraTheadRows}) => {

        const RenderTable = () => {
            return <BootstrapTable data-testid={testID} variant={'condensed'} bordered={true}>
                <thead>
                    {extraTheadRows}
                <tr>
                    {columns.map(headerCell)}
                </tr>
                </thead>
                <tbody data-testid={testID ? `${testID}:body` : undefined}>
                {data.map(row)}
                {data.length === 0 && noneFoundMessage && (
                    <tr>
                        <td colSpan={columns.length}>{noneFoundMessage}</td>
                    </tr>
                )}
                </tbody>
            </BootstrapTable>
        }

        const onSingleSort:SortEventHandler = (sortKey) => {
            const nextDirection = nextSortDirection(sort[sortKey])
            const nextSort = emptySort()
            nextSort[sortKey] = nextDirection
            onSort(nextSort)
        }
        const onMultiColumnSort:SortEventHandler = (sortKey) => {
            const nextSort = {...sort}
            nextSort[sortKey] = nextSortDirection(sort[sortKey])
            onSort(nextSort)
        }

        const selectToggleAll = () => {
            if(allSelected(data)) {
                const EmptySelection: IDType[] = [];
                onSelect(EmptySelection)
            } else {
                onSelect(allIds(data))
            }
        }
        function getSelected(data: T[]): T[] {
            return data.filter(row => row.isSelected)
        }
        function allSelected(data: T[]): boolean {
            return getSelected(data).length === data.length;
        }
        function allIds(data: T[]): IDType[] {
            return data.map(row => row.id);
        }
        const selectToggleRow = (row: T) => {
           if(row.isSelected) {
               return onSelect(allIds(data.filter(r => r.isSelected && r.id !== row.id)))
           } else {
               return onSelect(allIds([...getSelected(data), row]))
           }
        }
        const doSort = (e: MouseEvent<HTMLTableHeaderCellElement>, field: DataKeys) => {
            if(e.ctrlKey) {
                onMultiColumnSort(field)
            } else {
                onSingleSort(field)
            }
        }

        const headerCell = (column: Column<D>|SelectColumn) => {
            if (isSelectColumn(column)) {
                return<th key={'isSelected'}>
                    <Form.Check checked={allSelected(data)} onChange={selectToggleAll} data-testid={"select-all"} />
                </th>
            }
            const Display = column.displayHeader
            return <th key={`${column.field}`} data-testid={`header-${column.field}`} onClick={column.sortable ? e => doSort(e, column.field) : undefined} style={column.sortable ? {cursor: 'pointer'} : undefined}>
                <div className="d-flex gap-2 align-items-center">
                    <span className="flex-grow-1">{Display && <Display />}</span>
                    {column.sortable && <span className="flex-grow-0"><TableHeaderSortIndicator direction={sort[column.field]} /></span>}
                </div>
            </th>
        }

        const row = (row: T, index: number) => {
            return <tr key={row.id} data-testid={`row-${index}`}>
                {columns.map((c) => isSelectColumn(c) ? selectCell(row, index) : cell(c, row, index))}
            </tr>
        }

        function cell(cell: Column<D>, row: T, rowNumber: number) {
            const CellCmp = cell.displayCell;
            return <td key={`${cell.field}`} data-testid={`${cell.field}-${rowNumber}`}>
                <CellCmp {...row} />
            </td>
        }
        function selectCell(row: T, rowNumber: number) {
            return <td key={"isSelected"}>
                <Form.Check checked={row.isSelected} onChange={() => selectToggleRow(row)} data-testid={`select-${rowNumber}`} />
            </td>
        }
        return RenderTable()
    }
    return {Table, mapStringsToColumns, selectColumn}
}

export type IsSelectable<T> = T & {
    isSelected: boolean
}

export function makeSelectable<T>(data: T[]): (IsSelectable<T>)[] {
    return data.map(data => ({...data, isSelected: false}))
}

export function useSelectableState<T extends {id:number|string}>(): [IsSelectable<T>[]|undefined, Dispatch<T[]>, (ids: (string|number)[])=>void] {
    const [data, setData] = useState<(IsSelectable<T>)[]>()
    const onSelect = (ids: (string|number)[]): void => {
        if(data) {
            const selected = data.map((d) => {
                const isSelected = ids.includes(d.id)
                return {...d, isSelected }
            })
            setData(selected)
        }
    }
    return [data, (data) => setData(makeSelectable(data)), onSelect]
}
