import {FC, VFC} from "react";
import {AlertWithDetails} from "./AlertWithDetails";
import {Alert, AlertVariant} from "./Alert";
import {ErrorWithDetails} from "../../../hooks/exceptionToError";

interface ErrorContentProps {
    error?: ErrorWithDetails;
}
const ErrorContent: VFC<ErrorContentProps> = ({error}) => {
    if (error !== undefined) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...error}/>;
    }

    return <Alert variant={AlertVariant.ERROR}>Unknown error occurred</Alert>;
};

interface ErrorInLayoutProps extends ErrorContentProps {
    Layout: FC;
}
export const ErrorInLayout: VFC<ErrorInLayoutProps> = ({Layout, error}) => {
    return (
        <Layout>
            <div className="pt-4">
                <ErrorContent error={error} />
            </div>
        </Layout>
    );
};
