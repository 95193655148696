import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { assertNever } from "../../../services/TypeUtils";
import React from "react";

// allow font-awesome icons to be referenced by name
library.add(fas);

export enum IconType {
    Add = 'Add',
    Archive = 'Archive',
    Cancel = 'Cancel',
    ChevronRight = 'ChevronRight',
    Clear = 'Clear',
    Cull = 'Cull',
    Dashboard = 'Dashboard',
    Delete = 'Delete',
    Division = 'Division',
    Document = 'Document',
    Email = 'Email',
    Export = 'Export',
    Logout = 'Logout',
    MobilePhone = 'MobilePhone',
    Move = 'Move',
    NumberList = 'NumberList',
    Phone = 'Phone',
    Portfolio = 'Portfolio',
    Print = 'Print',
    Search = 'Search',
    Settings = 'Settings',
    SingleUser = 'SingleUser',
    Spreadsheet = 'Spreadsheet',
    Square = 'Square',
    Submit = 'Submit',
    Success = 'Success',
    Users = 'Users',
    Warning = 'Warning',
}

const getFontAwesomeIconName = (icon: IconType): IconProp => {
    switch (icon) {
        case IconType.Clear:
            return "eraser";

        case IconType.Delete:
            return "trash";

        case IconType.Export:
            return "cloud-download-alt";

        case IconType.Search:
            return "search";

        case IconType.Submit:
            return "arrow-circle-right";

        case IconType.Dashboard:
            return "tachometer-alt";

        case IconType.Document:
            return "file-alt";

        case IconType.Portfolio:
            return "briefcase";

        case IconType.Spreadsheet:
            return "table";

        case IconType.Add:
            return "plus";

        case IconType.Division:
            return "sitemap";

        case IconType.Users:
            return "users";

        case IconType.SingleUser:
            return "user";

        case IconType.Logout:
            return "sign-out-alt";

        case IconType.Phone:
            return "phone";

        case IconType.MobilePhone:
            return 'mobile';

        case IconType.ChevronRight:
            return "chevron-right";

        case IconType.Warning:
            return "exclamation-triangle";

        case IconType.Email:
            return "envelope";

        case IconType.Settings:
            return "cog";

        case IconType.NumberList:
            return "list-ol";

        case IconType.Archive:
            return "print";

        case IconType.Cull:
            return "trash";

        case IconType.Cancel:
            return "ban";

        case IconType.Move:
            return "arrows-alt";

        case IconType.Square:
            return "square";

        case IconType.Success:
            return "check-circle";

        case IconType.Print:
            return "print";

        default:
            return assertNever(icon);
    }
};

export interface IconProps {
    icon: IconType;
    className?: string;
    inverse?: boolean;
    stack?: "1x" | "2x";
    danger?: boolean;
}

export const Icon: React.VFC<IconProps> = ({
    icon,
    className,
    inverse = false,
    stack,
    danger = false,
}) => {
    let cn: string[] = [];
    if (className) cn.push(className);
    if (inverse) cn.push("fa-inverse");
    if (stack) cn.push(`fa-stack-${stack}`);
    if (danger) cn.push("text-danger");

    return (
        <span className={cn.join(" ")}>
            <FontAwesomeIcon icon={getFontAwesomeIconName(icon)} />
        </span>
    );
};

export interface IconStackProps {
    className?: string;
}

export const IconStack: React.FC<IconStackProps> = ({
    className,
    children,
}) => {
    return <span className={`fa-stack ${className ?? ""}`}>{children}</span>;
};
