import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const TitleRow: React.FC = ({ children }) => {
    return (
        <Row>
            <Col className="d-flex align-items-center gap-2">
                {children}
            </Col>
        </Row>
    );
};
