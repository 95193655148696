import React from 'react';

import { AppRole, AppRoleAny, AppRoleAnyType } from '../../../domain/Account';
import { appType, GetAppType } from '../../../domain/AppType';
import { useFeatures } from '../../../hooks/useFeatures';
import { SelectElement, SelectOption } from './SelectElement';
import { WithTestID } from './WithTestID';

export type AppRoleSelectValue = AppRole | AppRoleAnyType | undefined;

export interface AppRoleSelectProps extends WithTestID {
    value: AppRoleSelectValue;
    onChange: (val: AppRoleSelectValue) => void;
    appType: appType;
}

export const AppRoleSelect: React.VFC<AppRoleSelectProps> = ({ value, onChange, appType, testID }) => {

    const theAppType = GetAppType(appType);
    const { data: features } = useFeatures();
    const roles = theAppType.GetAppRoles(features);

    const options: SelectOption<string>[] = [];
    options.push({ value: '', label: 'Unfiltered' });
    options.push({ value: AppRoleAny, label: 'Any Role' });
    roles.forEach((role) => {
        options.push({ value: role.key, label: role.name });
    });

    return (
        <SelectElement
            options={options}
            value={value}
            onChange={(val) => {
                if (val === AppRoleAny) {
                    onChange(val);
                } else if (val === '') {
                    onChange(undefined);
                } else {
                    onChange(val as AppRole);
                }
            }}
            testID={testID}
        />
    );
};
