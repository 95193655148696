import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppType } from "../../../Context/ServiceContext";
import { ReviewStatus } from "../../../domain/Document";
import { DivisionDropDownField } from "../../form/fields/DivisionDropDownField";
import { PortfolioTopicDropDownField } from "../../form/fields/PortfolioTopicDropDownField";
import { ReviewStatusDropDownField } from "../../form/fields/ReviewStatusDropDownField";
import { TextField } from "../../form/fields/TextField";
import { UserAutoCompleteField } from "../../form/fields/UserAutoCompleteField";
import { Form, FormBodyProps } from "../../form/forms/Form";
import { ResetIconButton, SubmitButton } from "./Button";
import { WithTestID } from "./WithTestID";
import { Search } from "../../../services/searchDocuments";
import { useAuthenticatedUser } from "../../../Context/AuthenticationContext";
import { IconType } from './Icon';
import { RightContainer } from "../layouts/RightContainer";
import { TitleRow } from "../layouts/TitleRow";
import { ThreeColumnRow } from "../layouts/ThreeColumnRow";
import { MultiColumnRow } from "../layouts/MultiColumnRow";
import { Container } from "react-bootstrap";
import { useTranslation } from "../../../hooks/useTranslation";
import { getTranslation } from "../../../domain/Translations";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export const DocumentSearchKey = 'DocSearchQuery';

export type DocumentStatusFilter = ReviewStatus | 'CULLED';

export const isReviewStatusArray = (status: DocumentStatusFilter[]): status is ReviewStatus[] => {
    return status.every(arg => arg !== 'CULLED');
};

export const filterReviewStatuses = (statuses: DocumentStatusFilter[]): ReviewStatus[] => {
    const result = statuses.filter(status => status !== 'CULLED');
    if (isReviewStatusArray(result)) {
        return result;
    }
    throw new Error('Found invalid values in statuses');
};

export interface DocumentFilterValues {
    searchText?: string;
    topicIds?: number[];
    reviewStatuses?: DocumentStatusFilter[];
    showAllStatuses?: boolean;
    authorLogin?: string;
    assigneeLogin?: string;
    approverLogin?: string;
    divisionIds?: number[];
}

const DefaultFilterValues: DocumentFilterValues = {};

export const translateFiltersToSearch = (arg: DocumentFilterValues): Search => {
    return {
        searchQuery: arg.searchText,
        topicIds: arg.topicIds,
        reviewStatuses: arg.reviewStatuses ? filterReviewStatuses(arg.reviewStatuses) : undefined,
        authorId: arg.authorLogin,
        assignedToId: arg.assigneeLogin,
        approverId: arg.approverLogin,
        divisionIds: arg.divisionIds,
        includeCulled: arg.reviewStatuses ? arg.reviewStatuses.includes('CULLED') : false,
        showAll: arg.showAllStatuses ?? false,
    };
};

export interface DocumentFiltersProps extends WithTestID {
    onSearch: (filterValues: DocumentFilterValues) => void;
    onClear: () => void;
}

const DocumentFiltersFormBody: React.VFC<FormBodyProps> = () => {
    const appType = useAppType();
    const currentUser = useAuthenticatedUser();
    const showUserSearch = appType.UserCanSeeUserDetails(currentUser);
    const { data: translations } = useTranslation('document');

    const trans = translations || {};
    const authorLabel = getTranslation(trans, 'ppqApp.document.creator');
    const assigneeLabel = getTranslation(trans, 'ppqApp.document.assignee');
    const approverLabel = getTranslation(trans, `ppqApp.document.approver.${appType.appType}`);

    const columns: JSX.Element[] = [];
    if (showUserSearch) {
        columns.push(<UserAutoCompleteField
            id="authorLogin"
            placeholder={authorLabel}
            testID="authorSearch"
        />);
        columns.push(<UserAutoCompleteField
            id="assigneeLogin"
            placeholder={assigneeLabel}
        />);
        columns.push(<UserAutoCompleteField
            id="approverLogin"
            placeholder={approverLabel}
        />);
    }
    columns.push(<DivisionDropDownField id="divisionIds" multiple />);

    return (
        <Container>
            <TitleRow>
                <FontAwesomeIcon icon="filter" color="#999" />
                <span>Filters</span>
            </TitleRow>
            <ThreeColumnRow
                leftCol={<TextField placeholder="Search" id="searchText" />}
                middleCol={<PortfolioTopicDropDownField id="topicIds" multiple />}
                rightCol={<ReviewStatusDropDownField id="reviewStatuses" showAllId="showAllStatuses" />}
            />
            <MultiColumnRow columns={columns} />
        </Container>
    );
};

export const DocumentFilters: React.VFC<DocumentFiltersProps> = ({ onSearch, onClear, testID }) => {

    const storage = useLocalStorage();
    const savedFilterValues = storage.get(DocumentSearchKey) as DocumentFilterValues|undefined;
    const initialValues: DocumentFilterValues = {
        ...DefaultFilterValues,
        ...savedFilterValues,
    };

    const doSearch = (values: DocumentFilterValues) => {
        storage.set(DocumentSearchKey, values);
        onSearch(values);
    };

    return (
        <div className="mb-3" data-testid={testID}>
            <Form
                FormBody={DocumentFiltersFormBody}
                onSubmit={doSearch}
                initialValues={initialValues}
                FormActions={({isSubmitting, setValues}) => (
                    <RightContainer>
                        <ResetIconButton
                            icon={IconType.Clear}
                            disabled={isSubmitting}
                            onClick={() => {
                                storage.remove(DocumentSearchKey);
                                setValues({});
                                onClear();
                            }}
                        >Clear</ResetIconButton>
                        <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
                    </RightContainer>
                )}
            />
        </div>
    );

};
