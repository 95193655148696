import { Account } from "./Account";

export type Authentication = {
    userAccount?: Account
}

export function DefaultAuthentication(): Authentication {
    return {}
}
export function UserAuthentication(userAccount: Account): Authentication {
    return {userAccount}
}
