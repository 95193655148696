import { Form } from "react-bootstrap";
import { WithTestID } from "./WithTestID";

export interface TextareaElementProps extends WithTestID {
    id?: string;
    value: string|undefined;
    onChange: (newValue: string) => void;
    placeholder?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    required?: boolean;
    rows?: number;
    onBlur?: () => void;
}

export const TextareaElement: React.VFC<TextareaElementProps> = ({ id, value, onChange, placeholder, isInvalid, disabled, required, rows = 5, onBlur, testID }) => {

    return (
        <Form.Control
            as="textarea"
            id={id}
            rows={rows}
            data-testid={testID}
            value={value || ''}
            placeholder={placeholder}
            isInvalid={isInvalid}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
            required={required}
            onBlur={onBlur}
        />
    );
};
