import { Container } from "react-bootstrap";
import { useAppType } from "../../../Context/ServiceContext";
import { Document, DocumentDetails } from "../../../domain/Document";
import { DocumentHistory } from "../../../domain/DocumentHistory";
import { docDetailsIsDocument, docDetailsIsHistory } from "./ViewDocumentDetails";

export interface ViewDocumentDownloadButtonsProps {
    document: DocumentDetails;
}

export const ViewDocumentDownloadButtons: React.VFC<ViewDocumentDownloadButtonsProps> = ({ document }) => {

    if (docDetailsIsHistory(document)) {
        return <DocumentHistoryDownloadButtons history={document} />;
    }

    if (docDetailsIsDocument(document)) {
        return <DocumentDownloadButtons document={document} />;
    }

    return null;
};

interface DocumentDownloadButtonsProps {
    document: Document;
}
const DocumentDownloadButtons: React.VFC<DocumentDownloadButtonsProps> = ({ document }) => {
    const appType = useAppType();
    return (
        <DownloadButtons
            pdfDownloadUrl={appType.GetDocumentExportUrl(document, 'PDF')}
            docxDownloadUrl={appType.GetDocumentExportUrl(document, 'DOCX')}
        />
    );
};

interface DocumentHistoryDownloadButtonsProps {
    history: DocumentHistory;
}
const DocumentHistoryDownloadButtons: React.VFC<DocumentHistoryDownloadButtonsProps> = ({ history }) => {
    const appType = useAppType();
    return (
        <DownloadButtons
            pdfDownloadUrl={appType.GetDocumentHistoryExportUrl(history, 'PDF')}
            docxDownloadUrl={appType.GetDocumentHistoryExportUrl(history, 'DOCX')}
        />
    );
};
interface DownloadButtonsProps {
    pdfDownloadUrl: string;
    docxDownloadUrl: string;
}

const DownloadButtons: React.VFC<DownloadButtonsProps> = ({ pdfDownloadUrl, docxDownloadUrl }) => {
    return (
        <div className="head-section">
            <Container className="page-container">
                <div className="download-btns">
                    <a href={pdfDownloadUrl} className="btn btn-info" target="_blank" rel="noreferrer">Download as PDF</a>
                    <a href={docxDownloadUrl} className="btn btn-info" target="_blank" rel="noreferrer">Download as DOCX</a>
                </div>
            </Container>
        </div>
    );
};
