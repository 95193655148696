import { Form } from "react-bootstrap";
import { WithTestID } from "./WithTestID";

export interface TextInputElementProps extends WithTestID {
    type?: string;
    value: string|undefined;
    onChange: (newValue: string) => void;
    placeholder?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    required?: boolean;
    onBlur?: () => void;
}

export const TextInputElement: React.VFC<TextInputElementProps> = ({ type, value, onChange, placeholder, isInvalid, disabled, required, onBlur, testID }) => {

    return (
        <Form.Control
            type={type}
            data-testid={testID}
            value={value || ''}
            placeholder={placeholder}
            isInvalid={isInvalid}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
            required={required}
            onBlur={onBlur}
        />
    );
};
