import useSWR from 'swr';
import { useAppType, useExternalResource } from '../Context/ServiceContext';
import { DocumentHistory } from '../domain/DocumentHistory';
import { DocumentID } from '../services/ExternalResource';
import { fetchLatestDocumentHistory } from '../services/fetchLatestDocumentHistory';
import { ErrorWithDetails, throwErrorWithDetails } from './exceptionToError';

export const useLatestDocumentHistory = (id: DocumentID) => {
    const externalResource = useExternalResource();
    const appType = useAppType();

    return useSWR<DocumentHistory, ErrorWithDetails>(`latestDocumentHistory:${appType.appType}:${id}`, () => {
        return fetchLatestDocumentHistory(externalResource, appType, id)
            .catch(throwErrorWithDetails);
    });
};
