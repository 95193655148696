import { useEffect, useRef, useState, VFC } from "react";

import { useRouter } from "../../../hooks/useRouter";
import { usePortfolioList } from "../../../hooks/usePortfolioList";
import {
    useAppType,
    useExternalResource,
} from "../../../Context/ServiceContext";
import { updatePortfolio } from "../../../services/updatePortfolio";
import { SectionSelectPanel } from "../../../components/ui/layouts/SectionSelectPanel";
import { ButtonList } from "../../../components/ui/layouts/ButtonList";
import {
    AppLayout,
    AppLayoutHeading,
} from "../../../components/ui/layouts/AppLayout";
import { TopicsList } from "../../../components/ui/elements/TopicsList";
import { TextField } from "../../../components/form/fields/TextField";
import { Icon, IconType } from "../../../components/ui/elements/Icon";
import { SaveButton } from "../../../components/ui/elements/SaveButton";
import { ArchiveButton } from "../../../components/ui/elements/ArchiveButton";
import { Form, FormValues } from "../../../components/form/forms/Form";
import { Portfolio } from "../../../domain/Document";
import { useSidePanelAppId } from "../../../hooks/useSidePanelAppId";
import { LoadingOverlay } from "../../../components/ui/elements/LoadingOverlay";
import { convertErrorToErrorWithDetails, ErrorWithDetails } from "../../../hooks/exceptionToError";
import { AlertWithDetails } from "../../../components/ui/elements/AlertWithDetails";
import { AlertVariant } from "../../../components/ui/elements/Alert";
import { ErrorInLayout } from "../../../components/ui/elements/ErrorInLayout";
import { InstructionInfoContent } from "../../../components/ui/elements/InstructionInfoContent";
import { createPortfolio } from "../../../services/createPortfolio";
import { CreatePortfolioAPIRequest } from "../../../services/resources";
import { fetchPortfolioDocumentCount } from "../../../services/fetchPortfolioDocumentCount";
import { Modal } from "react-bootstrap";
import { makeWordPluralWithCount } from "../../../textUtils";
import { Button } from "../../../components/ui/elements/Button";
import { deletePortfolio } from "../../../services/deletePortfolio";
import { notificationService } from "../../../services/notificationService";
import { NotFound } from "../../NotFound";
import { SELECT_PANEL_CREATE_NEW_KEY } from "../../../components/ui/elements/SelectPanel";
import { WithTestID } from "../../../components/ui/elements/WithTestID";
import { customValidator, fieldIsNumeric, fieldRequired } from "../../../components/form/fields/FieldValidation";

const areTopicsValid = (values: FormValues) => {
    return values.topics.every((topic: FormValues) => {
        const strNbr = `${topic.number}`;
        if (!strNbr.match(/^\d+$/)) return false;
        if (`${topic.name}`.trim() === '') return false;
        if (values.topics.filter((t: FormValues) => `${t.number}` === strNbr).length > 1) return false;
        return true;
    });
};

const ManagePortfoliosFallback: React.FC = () => (
    <InstructionInfoContent>
        <Icon icon={IconType.Portfolio} className="m-auto fa-5x" />
        <p className="lead">
            Choose a portfolio or create a new one on the left.
        </p>
    </InstructionInfoContent>
);

const usePortfolioDocumentCount = (id: number) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const [count, setCount] = useState<number>();
    const [error, setError] = useState<ErrorWithDetails>();
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;

        (async () => {
            try {
                const result = await fetchPortfolioDocumentCount(externalResource, appType, id);
                if (mountedRef.current) {
                    setCount(result.count);
                }
            } catch (err) {
                if (mountedRef.current) {
                    setError(convertErrorToErrorWithDetails(err as Error));
                }
            }
        })();

        return () => {
            mountedRef.current = false;
        };

    }, [externalResource, appType, id]);

    return { count, error };
};

interface DeleteErrorModalProps extends WithTestID {
    show: boolean;
    onHide: () => void;
    count: number;
}
const DeleteErrorModal: React.VFC<DeleteErrorModalProps> = ({ show, onHide, count, testID }) => {
    return (
        <Modal show={show} onHide={onHide} data-testid={testID}>
            <Modal.Body>
                <p>
                    Unable to delete Portfolio. Portfolio contains {makeWordPluralWithCount('document', count)}.
                    Please move them to another Portfolio and try again.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

interface UpdatePortfolioFormProps extends WithTestID {
    portfolio: Portfolio;
    onSaved?: () => void;
}

const UpdatePortfolioForm: React.VFC<UpdatePortfolioFormProps> = ({ portfolio, onSaved, testID }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();
    const { count: docCount, error: docCountError } = usePortfolioDocumentCount(portfolio.id);
    const [showDeleteError, setShowDeleteError] = useState(false);
    const { data: portfolios } = usePortfolioList();

    const onSubmit = async (portfolio: Portfolio) => {
        setSaving(true);
        try {
            await updatePortfolio(externalResource, appType, portfolio);
            gotoOrReload(`/app/${appType.appType}/admin/portfolios`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Portfolio saved');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    const doArchive = async () => {
        setSaving(true);
        try {
            await deletePortfolio(externalResource, appType, portfolio.id);
            gotoOrReload(`/app/${appType.appType}/admin/portfolios`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Portfolio deleted');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    const onArchive = async () => {
        if (docCount === 0) {
            doArchive();
        } else {
            setShowDeleteError(true);
        }
    };

    if (!portfolio) {
        return <LoadingOverlay show />;
    }

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            {docCountError && <AlertWithDetails variant={AlertVariant.ERROR} {...docCountError} />}
            <Form
                testID={testID}
                initialValues={{ ...portfolio }}
                onSubmit={onSubmit}
                FormBody={({ values, setValues }) => (
                    <>
                        <TextField
                            id="number"
                            type="number"
                            label="Portfolio Number"
                            placeholder="[Assign one for me]"
                            validationRules={[
                                fieldIsNumeric('Portfolio number must be a positive number with no decimal places'),
                                customValidator((value: string) => {
                                    if (value !== '' && portfolios) {
                                        const nbr = Number(value);
                                        if (portfolios.some(p => p.id !== portfolio.id && p.number === nbr)) {
                                            return 'Another Portfolio already exists with that number';
                                        }
                                    }
                                }),
                            ]}
                        />
                        <TextField id="name" label="Name" required />
                        <h3>Topics</h3>
                        <hr />
                        <TopicsList values={values as Portfolio} setValues={setValues} />
                    </>
                )}
                FormActions={({ isValid, dirty, values }) => {
                    return (
                        <ButtonList className="justify-content-left gap-1">
                            <ArchiveButton onClick={onArchive} testID={testID ? `${testID}:archive-btn` : undefined}>
                                Delete
                            </ArchiveButton>
                            <SaveButton disabled={!isValid || !dirty || !areTopicsValid(values)}>
                                Save Portfolio
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
            <DeleteErrorModal
                show={showDeleteError}
                onHide={() => setShowDeleteError(false)}
                count={docCount || 0}
                testID={testID ? `${testID}:delete-error-modal` : undefined}
            />
        </>
    );
};

interface CreatePortfolioFormProps {
    onSaved?: () => void;
}
const CreatePortfolioForm: React.VFC<CreatePortfolioFormProps> = ({ onSaved }) => {
    const externalResource = useExternalResource();
    const appType = useAppType();
    const { gotoOrReload } = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<ErrorWithDetails>();
    const { data: portfolios } = usePortfolioList();

    const onSubmit = async (values: Record<string, any>) => {
        setSaving(true);
        try {
            const portfolio: CreatePortfolioAPIRequest = {
                number: values.number || undefined,
                name: values.name,
                appType: appType.appType,
                topics: values.topics,
            };
            await createPortfolio(externalResource, appType, portfolio);
            gotoOrReload(`/app/${appType.appType}/admin/portfolios`);
            if (onSaved) {
                onSaved();
            }
            notificationService.showSuccessNotification('Portfolio created');
        } catch (err) {
            setError(convertErrorToErrorWithDetails(err as Error));
        }
        setSaving(false);
    };

    return (
        <>
            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <Form
                initialValues={{ name: '', number: '', topics: [ { number: 1, name: 'Topic 1', culled: false, reportable: false } ] }}
                onSubmit={onSubmit}
                FormBody={({ values, setValues }) => (
                    <>
                        <h4>Create Portfolio</h4>
                        <TextField
                            id="number"
                            type="number"
                            label="Portfolio Number"
                            placeholder="[Assign one for me]"
                            validationRules={[
                                fieldIsNumeric('Portfolio number must be a positive number with no decimal places'),
                                customValidator((value: string) => {
                                    if (value !== '' && portfolios) {
                                        const nbr = Number(value);
                                        if (portfolios.some(p => p.number === nbr)) {
                                            return 'Another Portfolio already exists with that number';
                                        }
                                    }
                                }),
                            ]}
                        />
                        <TextField
                            id="name"
                            label="Name"
                            required
                            validationRules={[
                                fieldRequired('Please provide a portfolio name'),
                            ]}
                        />
                        <h3>Topics</h3>
                        <hr />
                        <TopicsList values={values as Portfolio} setValues={setValues} />
                    </>
                )}
                FormActions={({ isValid, dirty, values }) => {
                    return (
                        <ButtonList className="justify-content-end">
                            <SaveButton disabled={!isValid}>
                                Create Portfolio
                            </SaveButton>
                        </ButtonList>
                    );
                }}
            />
            <LoadingOverlay show={saving} message="Saving..." />
        </>
    );
};

export const ManagePortfolios: VFC<WithTestID> = ({ testID }) => {
    const { data, error, mutate } = usePortfolioList();
    const appType = useAppType();
    const { selectedItem, setSelectedItem } = useSidePanelAppId(`/app/${appType.appType}/admin/portfolios`);

    if (error) {
        return <ErrorInLayout Layout={AppLayout} error={error} />;
    }

    const onSaved = () => mutate();

    let createFormComponent: VFC = () => null;
    let updateFormComponent: VFC = () => null;

    if (selectedItem === undefined) {
    } else if (selectedItem === SELECT_PANEL_CREATE_NEW_KEY) {
        createFormComponent = () => <CreatePortfolioForm onSaved={onSaved} />;
    } else {
        if (data === undefined) {
            updateFormComponent = () => <LoadingOverlay show />;
        } else {
            const portfolio = data.find(p => p.id === selectedItem);
            if (!portfolio) {
                return <NotFound />;
            }
            updateFormComponent = () => <UpdatePortfolioForm portfolio={portfolio} onSaved={onSaved} testID={testID ? `${testID}:update-form` : undefined} />;
        }
    }

    return (
        <AppLayout>
            <AppLayoutHeading>Manage Portfolios</AppLayoutHeading>
            <LoadingOverlay show={!data} />
            <SectionSelectPanel
                testID={testID}
                panelHeading="New Portfolio"
                data={data || []}
                fallBackComponent={ManagePortfoliosFallback}
                createFormComponent={createFormComponent}
                updateFormComponent={updateFormComponent}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                showNumberInPanel
            />
        </AppLayout>
    );
};
