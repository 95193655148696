import React from 'react';
import { Route } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import { useAuthenticatedUser } from '../../Context/AuthenticationContext';
import { AdminRole, AppRole, userHasAnyRole } from '../../domain/Account';
import { appType, GetAppType } from '../../domain/AppType';
import { Forbidden } from '../../views/Forbidden';

interface RestrictedRouteProps extends RouteProps {
    allowedRoles: (AppRole | AdminRole)[];
    appType: appType;
}

export const RestrictedRoute: React.FC<RestrictedRouteProps> = ({ allowedRoles, appType, component, children, ...rest }) => {
    const userAccount = useAuthenticatedUser();
    const theAppType = GetAppType(appType);

    if (!userHasAnyRole(userAccount, theAppType, allowedRoles)) {
        return (
            <Route {...rest}>
                <Forbidden />
            </Route>
        );
    }

    return (
        <Route {...rest} component={component} children={children} />
    );
};
