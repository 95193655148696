import React from 'react';
import { SelectElement, SelectOption } from './SelectElement';
import { WithTestID } from './WithTestID';

export interface UserStateSelectProps extends WithTestID {
    value: boolean|undefined;
    onChange: (val: boolean|undefined) => void;
}

export const UserStateSelect: React.VFC<UserStateSelectProps> = ({ value, onChange, testID }) => {

    const options: SelectOption<string>[] = [
        { value: '', label: 'Unfiltered' },
        { value: 'true', label: 'Enabled' },
        { value: 'false', label: 'Disabled' },
    ];

    return (
        <SelectElement
            value={value === undefined ? '' : `${value}`}
            onChange={(val) => {
                if (val === '') {
                    onChange(undefined);
                } else {
                    onChange(val === 'true');
                }
            }}
            options={options}
            testID={testID}
        />
    );
};
