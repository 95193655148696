import React from 'react';
import {Table} from "react-bootstrap";
import { AlertVariant } from '../../../components/ui/elements/Alert';
import { AlertWithDetails } from '../../../components/ui/elements/AlertWithDetails';
import { SubmitIconButton } from '../../../components/ui/elements/Button';
import { IconType } from '../../../components/ui/elements/Icon';
import { LoadingOverlay } from '../../../components/ui/elements/LoadingOverlay';

import { AppLayout } from '../../../components/ui/layouts/AppLayout';
import { useAppType } from '../../../Context/ServiceContext';
import { Document, Portfolio, Topic } from '../../../domain/Document';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useMultipleDocumentsInOrder } from '../../../hooks/useMultipleDocumentsInOrder';
import { usePortfolioList } from '../../../hooks/usePortfolioList';
import { DocumentID } from '../../../services/ExternalResource';
import { NotFound } from '../../NotFound';

export const DocumentIDsKey = 'Archive.DocumentIDs';

export const PPQArchive: React.VFC = () => {
    const storage = useLocalStorage();
    const ids: DocumentID[]|undefined = storage.get(DocumentIDsKey);

    if (ids === undefined || ids.length === 0) {
        return <NotFound />;
    }

    return (
        <AppLayout>
            <Archive ids={ids} />
        </AppLayout>
    );
};

interface ArchiveProps {
    ids: DocumentID[];
}

interface DocumentsByTopic {
    portfolio: Portfolio;
    topic: Topic;
    documents: Document[];
}

const getDocumentsByTopic = (documents: Document[], portfolios: Portfolio[]): DocumentsByTopic[] => {
    const result: DocumentsByTopic[] = [];
    portfolios.forEach((portfolio) => {
        portfolio.topics.forEach((topic) => {
            const docs = documents.filter(doc => doc.topic.id === topic.id);
            if (docs.length > 0) {
                result.push({
                    portfolio,
                    topic,
                    documents: docs,
                });
            }
        });
    });
    result.sort((a, b) => {
        let cmp = a.portfolio.number - b.portfolio.number;
        if (cmp === 0) {
            cmp = a.topic.number - b.topic.number;
        }
        return cmp;
    })
    return result;
};

const Archive: React.VFC<ArchiveProps> = ({ ids }) => {
    const { documents, error: docsError } = useMultipleDocumentsInOrder(ids);
    const { data: portfolios, error: portfoliosError } = usePortfolioList();
    const appType = useAppType();

    const anyError = docsError || portfoliosError;
    if (anyError) {
        return <AlertWithDetails variant={AlertVariant.ERROR} {...anyError} />;
    }

    if (!documents || !portfolios) {
        return <LoadingOverlay show />;
    }

    const documentsByTopic = getDocumentsByTopic(documents, portfolios);

    return (
        <Table variant={'condensed'} bordered={true}>
            <thead>
                <tr>
                    <th>Portfolio</th>
                    <th>Name</th>
                    <th>Archive</th>
                </tr>
            </thead>
            <tbody>
                {documentsByTopic.map((obj) => (
                    <tr key={obj.topic.id}>
                        <td>{obj.portfolio.number}.{obj.topic.number}</td>
                        <td>{obj.portfolio.name} - {obj.topic.name}</td>
                        <td>
                            <form action={`/${appType.appType}/bulkPdf`} method="POST">
                                {obj.documents.map((doc) => (
                                    <input type="hidden" name="ids" value={doc.id} key={doc.id} />
                                ))}
                                <input type="hidden" name="print" value="print" />
                                <SubmitIconButton icon={IconType.Export}>Archive.pdf</SubmitIconButton>
                            </form>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
