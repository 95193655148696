import React, { useRef, useEffect } from "react";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require plugins that we are using
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/image.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';


import FroalaEditor from 'react-froala-wysiwyg';

import './froala-highlight.js';

import { WithTestID } from "./WithTestID";
import { addBoldAndUnderlineBackToPlainText, cleanTableStylesFromHtml, extractBoldAndUnderlineTexts, removeCommentsFromHtml } from "../../../textUtils";

import './RichText.scss';
import '../fonts/carlito.scss';

const FroalaEditorCore = require('froala-editor') as any;

export interface RichTextProps extends WithTestID {
    onChange: (value: string) => void;
    value?: string;
    id?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onBlur?: () => void;
}

const adjustStickyHeaderOffset = (): void => {

    /* Sad sad hack for a bug 
        (sticky toolbar only sticks the last row of the toolbar - 
        there's two rows of buttons as we have a lot of buttons and 
        they can't fit on one row. 55px is the height of the row
    */

    const elements = document.querySelectorAll<HTMLElement>('.fr-toolbar.fr-sticky-on');
    elements.forEach(element => {
        const height = element.clientHeight - 48;
        element.style.marginTop = `${height}px`;
    });
};

const attachStickyToolbarOffsetAdjuster = () => {
    // Remove listeners to prevent duplicates
    window.removeEventListener('resize', adjustStickyHeaderOffset);
    window.removeEventListener('scroll', adjustStickyHeaderOffset);

    // Re-add listeners
    window.addEventListener('resize', adjustStickyHeaderOffset);
    window.addEventListener('scroll', adjustStickyHeaderOffset);
};


const FroalaButtons = [
    ["highlight"], ['fontFamily', 'fontSize'],
    ["bold", "italic", "underline", "strikeThrough"], ['lineHeight'],
    ['alignLeft', 'alignCenter', 'alignRight'],
    ["insertTable"], ['formatUL'], ['outdent', 'indent'],
    ["insertImage"], ['undo', 'redo'], ['clearFormatting'],
    ['boldAndUnderlineOnly'], ['plainPaste']
];
FroalaEditorCore.DefineIcon('boldAndUnderlineOnly', { NAME: 'star-half', template:'font_awesome' });




FroalaEditorCore.RegisterCommand('boldAndUnderlineOnly', {
    title: 'Clear Formatting (except Bold and Underline)',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        // make sure something is selected
        let selectedText = this.selection.text() ?? "";
        if (selectedText.trim() === '') return; 
        
        let origBlocks = [];
        let selectedBlocks = this.selection?.blocks();

        for (let block of selectedBlocks) {
            origBlocks.push({
                elem: block,
                html: block.innerHTML
            });
        }


        // leverage built in format removal
        this.format.remove();
        selectedText = this.selection.text() ?? "";
        if (selectedText.trim() === '') return; 

        // add bold and underline back to the formatless text
        selectedBlocks = this.selection?.blocks();

        for (let i = 0 ; i < selectedBlocks.length; i++) {
            const origHTML = origBlocks[i].html;
            const origBlock = origBlocks[i].elem;
            const noFormatHTML = selectedBlocks[i].innerHTML;
            if (origBlock === selectedBlocks[i]) {
                const boldAndUnderlineTexts = extractBoldAndUnderlineTexts(origHTML);
                const boldAndUnderlinedHTML = addBoldAndUnderlineBackToPlainText(noFormatHTML, boldAndUnderlineTexts);
                selectedBlocks[i].innerHTML = boldAndUnderlinedHTML;
            }
        }
    }
});

FroalaEditorCore.DefineIcon('plainPaste', { NAME: 'file', template:'font_awesome'});

FroalaEditorCore.RegisterCommand('plainPaste', {
    title: 'Plain Paste',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        navigator.clipboard.readText().then(text => {
            this.html.insert(text, true);
        });
    }
}); 

const FroalaConfig = {
    toolbarButtons: FroalaButtons,
    toolbarButtonsXS: FroalaButtons,
    toolbarButtonsSM: FroalaButtons,
    toolbarButtonsMD: FroalaButtons,
    imageUploadURL: '/image/fr-save',
    imageOutputSize: true,
    key: "dKA5cC3G3H3D2J2A5A4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4C2E4C2H3E3E1B3==",
    pluginsEnabled: [
        "align", "charCounter", "codeBeautifier", "codeView", "colors", "draggable", "embedly", "emoticons", "entities", "file", "fontFamily", "fontSize", "fullscreen", "image", "imageManager", "inlineStyle", "lineBreaker", 'lineHeight', "link", "lists", "paragraphFormat", "paragraphStyle", "quickInsert", "quote", "save", "table", "url", "video", "wordPaste"
    ],
    attribution: false,
    fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Calibri,Carlito,Arial,sans-serif': 'Calibri',
    },
    fontSize: [
        '8',
        '9',
        '10',
        '11',
        '12',
        '14',
    ],
    fontSizeUnit: 'pt',
    fontSizeDefaultSelection: '12',
    tableStyles: {
        'fr-alternate-rows': 'Alternate Rows',
    },
    tableEditButtons: [
        'tableHeader',
        'tableRemove',
        'tableRows',
        'tableColumns',
        'tableStyle',
        '-',
        'tableCells',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign'
    ]
};

export const RichText: React.VFC<RichTextProps> = ({ value, onChange, id, isInvalid = false, disabled = false, placeholder, onBlur, testID }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<any>();

    const disabledRef = useRef(disabled);
    disabledRef.current = disabled;

    useEffect(() => {
        if (editorRef.current) {
            // if disabled value changes after editor initialization, turn editing on/off here
            if (disabled) {
                editorRef.current.edit.off();
            } else {
                editorRef.current.edit.on();
            }
        }
    }, [disabled]);

    const cn: string[] = ['rich-text'];
    if (isInvalid) {
        cn.push('is-invalid');
    }

    return (
        <div ref={containerRef} className={cn.join(' ')}>
            <FroalaEditor
                tag="textarea"
                model={value || ''}
                onModelChange={onChange}
                config={{
                    ...FroalaConfig,
                    placeholderText: placeholder,
                    events: {
                        initialized: function() {
                            const editor = this as any;
                            editorRef.current = editor;
                            if (disabledRef.current) {
                                editor.edit.off();
                            }
                            const target = containerRef.current?.querySelector('textarea');
                            if (target && id) {
                                target.id = id;
                            }
                            if (target && testID) {
                                target.dataset['testid'] = testID;
                            }
                            attachStickyToolbarOffsetAdjuster();
                        },
                        blur: onBlur,

                        'paste.afterCleanup': function (html: string) {
                            // DED-126 remove comments from pasted content as it causes problems for the docx/pdf export,
                            // and is not useful to us
                            let str = removeCommentsFromHtml(html);
                            // DED-7 clean table attributes. Pasting from Excel includes width attributes that are larger
                            // than the doc page size, resulting in tables going past the page edge. Removing these tags
                            // lets Word choose a best size for the table.
                            str = cleanTableStylesFromHtml(str);
                            return str;
                        },
                    }
                }}
            />
        </div>
    );

};