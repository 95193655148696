import React, {FC, useContext, useEffect, useState} from "react";
import {Authentication, DefaultAuthentication, UserAuthentication} from "../domain/Authentication";
import {fetchAccount} from "../services/fetchAccount";
import {useExternalResource} from "./ServiceContext";
import { Account } from "../domain/Account";
import { ExternalResource } from "../services/ExternalResource";
import { CenterSmallContainerWithSupportBanner } from "../components/ui/layouts/CenterSmallContainerWithSupportBanner";
import { LoadingOverlay } from "../components/ui/elements/LoadingOverlay";

type AuthContext = {
    auth: Authentication
    setAuth: (auth: Authentication) => void
}

export const AuthenticationContext = React.createContext<AuthContext>({
    auth: DefaultAuthentication(),
    setAuth: ()=>{}
});

const fetchUserAccount = (externalResource: ExternalResource, setAuth: (auth: Authentication) => void) => {
    return fetchAccount(externalResource)
        .then(a => setAuth(UserAuthentication(a)))
        .catch(() => setAuth(DefaultAuthentication()));
};

export const AuthContextProvider: FC = ({children}) => {
    const fetchJSONService = useExternalResource()
    const [auth, setAuth] = useState<Authentication|null>(null)
    useEffect(() => {
        fetchUserAccount(fetchJSONService, setAuth);
    },[fetchJSONService])

    if (auth === null) {
        return (
            <CenterSmallContainerWithSupportBanner>
                <LoadingOverlay show />
            </CenterSmallContainerWithSupportBanner>
        );
    }
    return <AuthenticationContext.Provider value={{auth, setAuth}}>
        {children}
    </AuthenticationContext.Provider>
}

export const useAuthenticatedUser = (): Account => {
    const {auth} = useContext(AuthenticationContext)
    if(auth.userAccount) {
        return auth.userAccount
    }
    throw new Error("User is not authenticated")
}

export const useRefreshAuthenticatedUser = () => {
    const { setAuth } = useContext(AuthenticationContext);
    const externalResource = useExternalResource()
    const refresh = () => {
        return fetchUserAccount(externalResource, setAuth);
    };
    return { refresh };
};
