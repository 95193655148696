import { Unknown } from "../services/TypeUtils";
import { isObject } from "./validateUnknown";

export type Translations = Record<string, any>;

export const isTranslations = (data: Unknown<Translations>): data is Translations => {
    return isObject(data);
};

const findTranslationValue = (obj: Record<string, any>, key: string): string|undefined => {
    if (typeof obj[key] !== 'undefined') {
        return obj[key];
    }
    const idx = key.indexOf('.');
    if (idx >= 0) {
        const firstPart = key.substring(0, idx);
        const rest = key.substring(idx + 1);

        if (typeof obj[firstPart] === 'object') {
            return findTranslationValue(obj[firstPart], rest);
        }
    }

    return undefined;
};

export const getTranslation = (translations: Translations, key: string): string => {
    return findTranslationValue(translations, key) || key;
};
