import { Field, FieldProps, useFormikContext } from "formik";
import { MultiDivisionDropDown, SingleDivisionDropDown } from "../../ui/elements/DivisionDropDown";
import { FormField } from "../../ui/elements/FormField";
import { validateStringOrNumberArray, validateNumber } from "./FieldValidation";
import { StandardFieldProps } from "./TextField";

export interface DivisionDropDownFieldProps extends StandardFieldProps {
    multiple: boolean;
};

export const DivisionDropDownField: React.VFC<DivisionDropDownFieldProps> = ({ multiple, id, required, help, label, disabled, testID, validationRules = [] }) => {
    const formik = useFormikContext();
    const fieldRequired = required || validationRules.some(rule => rule.type === 'required');

    if (multiple) {
        return (
            <Field name={id} validate={(value: number[]) => {
                for (let i = 0; i < validationRules.length; i++) {
                    const result = validateStringOrNumberArray(validationRules[i], value, formik.values);
                    if (result !== undefined) {
                        return result;
                    }
                }

                if (fieldRequired && value.length === 0) {
                    return 'Required';
                }
            }}>
                {({ field, form }: FieldProps<number[]>) => {
                    const { error, touched } = form.getFieldMeta(id);

                    return (
                        <FormField
                            help={help}
                            label={label}
                            id={id}
                            error={touched ? error : undefined}
                            control={
                                <MultiDivisionDropDown
                                    disabled={disabled}
                                    value={field.value === undefined ? [] : field.value}
                                    onChange={(newValue) => form.setFieldValue(id, newValue)}
                                    isInvalid={touched && !!error}
                                    testID={testID}
                                    onBlur={() => form.setFieldTouched(id)}
                                />
                            }
                        />
                    );
                }}
            </Field>
        );
    }

    return (
        <Field name={id} validate={(value: number|undefined) => {
            for (let i = 0; i < validationRules.length; i++) {
                const result = validateNumber(validationRules[i], value, formik.values);
                if (result !== undefined) {
                    return result;
                }
            }

            if (required && value === undefined) {
                return 'Required';
            }
        }}>
            {({ field, form }: FieldProps<number>) => {
                const { error, touched } = form.getFieldMeta(id);

                return (
                    <FormField
                        help={help}
                        label={label}
                        id={id}
                        error={touched ? error : undefined}
                        control={
                            <SingleDivisionDropDown
                                disabled={disabled}
                                value={field.value}
                                onChange={(newValue) => form.setFieldValue(id, newValue)}
                                isInvalid={touched && !!error}
                                testID={testID}
                                onBlur={() => form.setFieldTouched(id)}
                            />
                        }
                    />
                );
            }}
        </Field>
    );

};
