import {useExternalResource} from "../Context/ServiceContext";
import {getWorkflowActions} from "../services/Workflow";
import {useEffect, useState} from "react";
import {Action} from "../domain/Actions";

export const useWorkflowActions = (documentId: string) => {
    const externalResource = useExternalResource();
    const [actions, setActions] = useState<Action[]>([])
    useEffect(() => {
        getWorkflowActions(externalResource, documentId).then(setActions)
    }, [externalResource, documentId])
    const refresh = () => getWorkflowActions(externalResource, documentId).then(setActions)
    return  {refresh, actions}
};
