import {Column, CreateTable} from "./Table";
import {User} from "../../../domain/User";
import {AppType, appType, GetAppType} from "../../../domain/AppType";
import {AdminRole} from "../../../domain/Account";
import React from "react";
import {Link} from "react-router-dom";
import { Feature } from "../../../domain/Feature";

const {Table} = CreateTable<User>()
export const UserTable = Table

interface Options {
    testID?: string
    theAppType: AppType,
    features?: Feature[]
}

export const UserTableColumns: (options: Options)=>Column<User>[] = ({testID, theAppType, features}) => [
    {
        field: "fullName",
        label: "Name",
        sortable: true,
        displayCell: (u, idx) => <Link
            to={`/app/${theAppType?.appType}/admin/user/${u.id}`}
            data-testid={testID ? `${testID}:data:${idx}:name` : undefined}
        >
            {u.fullName}
        </Link>,
        displayHeader: () => <>Name</>
    },
    {
        field: "login",
        label: "Email",
        sortable: true,
        displayCell: (u, idx) => <span data-testid={testID ? `${testID}:data:${idx}:login` : undefined}>{u.login}</span>,
        displayHeader: () => <>Email</>
    },
    {
        field: "division",
        label: "Division",
        sortable: true,
        displayCell: (u) => <>{u.division?.name}</>,
        displayHeader: () => <>Division</>
    },
    {
        field: "ppqRole",
        label: "PPQ",
        sortable: true,
        displayCell: (u) => <>{appRoleLabel(u.ppqRole, appType.PPQ, features)}</>,
        displayHeader: () => <>PPQ</>
    },
    {
        field: "paecRole",
        label: "PAEC",
        sortable: true,
        displayCell: (u) => <>{appRoleLabel(u.paecRole, appType.PAEC, features)}</>,
        displayHeader: () => <>PAEC</>
    },
    {
        field: "adminRole",
        label: "System Admin",
        sortable: true,
        displayCell: (u) => <>{adminRoleLabel(u.adminRole)}</>,
        displayHeader: () => <>System Admin</>
    },
    {
        field: "enabled",
        label: "State",
        sortable: true,
        displayCell: (u) => <>{u.enabled ? "Active" : "Inactive"}</>,
        displayHeader: () => <>State</>
    },
]

const appRoleLabel = (appRole: string|undefined, appType: appType, features?: Feature[]) => {
    const roles = GetAppType(appType).GetAppRoles(features);
    const role = roles.find(r => r.key === appRole);
    return role?.name || '';
};

const adminRoleLabel = (adminRole: string|undefined) => {
    switch (adminRole) {
        case AdminRole.SysAdmin: return 'Administrator';
    }
    return '';
};
