import React from 'react';

import { Button, ButtonVariant } from '../elements/Button';
import Modal from '../elements/Modal';
import { WithTestID } from '../elements/WithTestID';
import { useDocumentIsReadOnly } from '../../../Context/DocumentContext';

export enum SaveConfirmationAction {
    DontSave = 'DontSave',
    Cancel = 'Cancel',
    Save = 'Save',
}

export interface SaveConfirmationProps extends WithTestID {
    show?: boolean;
    onAction: (action: SaveConfirmationAction) => void;
}

export const SaveConfirmationModal: React.VFC<SaveConfirmationProps> = ({onAction, testID, show = true}) => {
    const isReadOnly = useDocumentIsReadOnly();
    const message = isReadOnly ?
        'You have made changes which are not yet saved. This document is currently locked by another user. Would you like to continue without saving?' :
        'You have made changes which are not yet saved. Do you want to save the changes?';

    return (
        <Modal show={show} testID={testID}>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onAction(SaveConfirmationAction.DontSave)} data-testid='dont-save'>Don't Save</Button>
                <Button onClick={() => onAction(SaveConfirmationAction.Cancel)} data-testid={testID}>Cancel</Button>
                <Button onClick={() => onAction(SaveConfirmationAction.Save)} variant={ButtonVariant.PRIMARY} data-testid={testID}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};
