import { Unknown } from "../services/TypeUtils";
import { isString } from "./validateUnknown";

export enum FeatureType {
    LOGIN_TITLE                         = 'LOGIN_TITLE',
    MINISTER_SHOW_ALL                   = 'MINISTER_SHOW_ALL',
    PPQ_APPROVER                        = 'PPQ_APPROVER',
    PPQ_1_APPROVER                      = 'PPQ_1_APPROVER',
    PPQ_2_APPROVERS                     = 'PPQ_2_APPROVERS',
    PAEC_APPROVER                       = 'PAEC_APPROVER',
    PAEC_1_APPROVER                     = 'PAEC_1_APPROVER',
    PAEC_2_APPROVERS                    = 'PAEC_2_APPROVERS',
    BRIEF_SELECT_COUNT                  = 'BRIEF_SELECT_COUNT',
    PAEC_2_FACTOR_QUESTION              = 'PAEC_2_FACTOR_QUESTION',
    QUESTION_SECTION                    = 'QUESTION_SECTION',
    PPQ_REFERENCE_SECTION               = 'PPQ_REFERENCE_SECTION',
    PAEC_REFERENCE_SECTION              = 'PAEC_REFERENCE_SECTION',
    PAEC_TABLE_SECTION                  = 'PAEC_TABLE_SECTION',
    PPQ_TABLE_SECTION                   = 'PPQ_TABLE_SECTION',
    PAEC_COMMITMENTS_SECTION            = 'PAEC_COMMITMENTS_SECTION',
    PPQ_COMMITMENTS_SECTION             = 'PPQ_COMMITMENTS_SECTION',
    PAEC_ENDORSEMENT_TABLE              = 'PAEC_ENDORSEMENT_TABLE',
    PAEC_ENDORSEMENT_SHORT              = 'PAEC_ENDORSEMENT_SHORT',
    PAEC_USE_FULL_DOCUMENT_NUMBER       = 'PAEC_USE_FULL_DOCUMENT_NUMBER',
    UPDATE_EDITION_DATE                 = 'UPDATE_EDITION_DATE',
    AUTHOR_REQUEST_APPROVAL_LOCKDOWN    = 'AUTHOR_REQUEST_APPROVAL_LOCKDOWN',
    PPQ_BRIEF_USE_DIVISION              = 'PPQ_BRIEF_USE_DIVISION',
    PAEC_BRIEF_USE_DIVISION             = 'PAEC_BRIEF_USE_DIVISION',
    BULK_EXPORT_FILENAME_TIMESTAMPS     = 'BULK_EXPORT_FILENAME_TIMESTAMPS',
    LOGIN_EMAIL_PASSWORD                = 'LOGIN_EMAIL_PASSWORD',
    LOGIN_SSO                           = 'LOGIN_SSO',
    PPQ_GLOBAL_APPROVER                 = 'PPQ_GLOBAL_APPROVER',
    PAEC_GLOBAL_APPROVER                = 'PAEC_GLOBAL_APPROVER',
    PAEC_DTP_COORDINATOR                = 'PAEC_DTP_COORDINATOR',
}

export interface Feature {
    name: FeatureType;
    status: 'on' | 'off';
}

export const isFeatureType = (name: unknown): name is FeatureType => {
    return isString(name)
        && Object.values(FeatureType).includes(name as FeatureType);
}

export const isFeature = (feature: Unknown<Feature>): feature is Feature => {
    return isFeatureType(feature.name)
        && isString(feature.status)
        && (feature.status === 'on' || feature.status === 'off');
};

export const isFeatureEnabled = (features: Feature[], type: FeatureType): boolean => {
    const feature = features.find(f => f.name === type);
    return !!feature && feature.status === 'on';
};
