import { useCallback } from "react";
import {useHistory} from "react-router";
import { useGetLocation } from "../Context/ServiceContext";

export function useRouter() {
    const history = useHistory();
    const getLocation = useGetLocation();

    const goto = useCallback((location: string) => history.push(location), [history]);
    const reload = useCallback(() => window.location.reload(), []);
    const gotoOrReload = useCallback((location: string) => {
        const path = getLocation();
        if (path === location) {
            reload();
        } else {
            goto(location);
        }
    }, [getLocation, goto, reload]);

    return {
        goto,
        reload,
        gotoOrReload,
    };
}
