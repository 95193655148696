import React, { useContext } from 'react';
import { Redirect } from 'react-router';

import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { RedirectToFirstAppType } from './RedirectToFirstAppType';

// redirect from the home url ("/") as appropriate
export const HomeRedirect: React.VFC = () => {
    const auth = useContext(AuthenticationContext);
    const { userAccount } = auth.auth;
    if (userAccount) {
        return <RedirectToFirstAppType />;
    } else {
        return <Redirect to="/login" />;
    }
};
