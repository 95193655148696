import { useEffect, useState } from "react";
import { useIdParam } from "./useIdParam";
import { useRouter } from "./useRouter";

const getUrlIdSegment = (id: number|undefined) => (id === undefined || id < 0) ? '' : `${id}`;
const getUrl = (prefix: string, id: number|undefined) => {
    let url = prefix;
    const idSegment = getUrlIdSegment(id);
    if (idSegment !== '') {
        url = `${url}/${idSegment}`;
    }
    return url;
};

export const useSidePanelAppId = (urlPrefix: string) => {

    const { gotoOrReload } = useRouter();

    const id = useIdParam();
    const [selectedId, setSelectedId] = useState(id);
    useEffect(() => {
        if (getUrlIdSegment(id) !== getUrlIdSegment(selectedId)) {
            setSelectedId(id);
        }
    }, [id, selectedId]);

    const setSelectedItem = (id: number) => {
        setSelectedId(id);
        if (getUrlIdSegment(id) !== getUrlIdSegment(selectedId)) {
            gotoOrReload(getUrl(urlPrefix, id));
        }
    };

    return {
        selectedItem: selectedId,
        setSelectedItem,
    };
};
