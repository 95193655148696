import React from 'react';
import { Container } from 'react-bootstrap';

export interface ViewDocumentLayoutProps {
    buttons?: React.ReactNode;
}

export const ViewDocumentLayout: React.FC<ViewDocumentLayoutProps> = ({ buttons, children }) => {
    return (
        <div className="view-document">
            {buttons}
            <Container className="page-container">
                {children}
            </Container>
        </div>
    );
};
