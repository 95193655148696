import { useParams } from "react-router";

// Returns the 'id' parameter for the current route as a number.
// If no 'id' value has been provided, or the parameter value is
// not a number, undefined will be returned.
export const useIdParam = () => {

    // first get the id parameter from the route
    const { id } = useParams<{ id?: string }>();

    // now convert it to a number, if possible
    if (id === undefined) return undefined;
    const str = id.trim();
    if (str === '') return undefined;
    if (str.match(/[^\d]/)) return undefined;
    const nbr = Number(str);
    if (isNaN(nbr)) return undefined;
    return nbr;

};
