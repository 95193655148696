import React from "react";
import {FormField} from "../../ui/elements/FormField";
import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";

export interface ReadOnlyFieldProps {
    label?: string;
    id: string;
    value?: any;
}

export const ReadOnlyField: React.VFC<ReadOnlyFieldProps> = ({ label, id, value }) => {
    return (
        <Field name={id}>
            {({ field }: FieldProps<any, any>) => (
                <FormField
                    label={label}
                    id={id}
                    control={<Form.Control value={value || field.value || ''} readOnly />}
                />
            )}
        </Field>
    );
}
