import React from "react";

export interface ErrorProps {
    message?: string;
}
export const Error: React.VFC<ErrorProps> = ({ message }) => {
    return (
        <div>
            <div>An error occurred</div>
            {message && <div>{message}</div>}
        </div>
    );
};
