import React from 'react';
import { Table } from 'react-bootstrap';
import { useAppType } from '../../../Context/ServiceContext';
import { Document, getDocumentStatusLabel, ReviewStatus } from '../../../domain/Document';
import { useFeatures } from '../../../hooks/useFeatures';
import { useFetchUser } from '../../../hooks/useFetchUser';
import { DateTimeFormat, formatDateTimeString } from '../../../services/DateTime';

import './IndexTable.scss';

export interface IndexTableProps {
    documents: Document[];
    omitNonMinister: boolean;
}

export const IndexTable: React.VFC<IndexTableProps> = ({ documents, omitNonMinister }) => {
    return (
        <Table className="index-table">
            <thead>
                <tr>
                    <th className="doc-nbr">No.</th>
                    <th className="doc-title">Title</th>
                    <th className="doc-lastchange">Last amended</th>
                    <th className="doc-status">Status</th>
                    <th className="doc-division">Division</th>
                </tr>
            </thead>
            <tbody>
                {documents.map((doc) => <IndexRow key={doc.id} document={doc} omitNonMinister={omitNonMinister} />)}
            </tbody>
        </Table>
    );
};

interface IndexRowProps {
    document: Document;
    omitNonMinister: boolean;
}

const IndexRow: React.VFC<IndexRowProps> = ({ document, omitNonMinister }) => {
    const appType = useAppType();
    const { data: features } = useFeatures();
    const { data: creatorUser } = useFetchUser(document.creator);
    if (!features) return null;

    return (
        <tr>
            <td>{appType.ShortDocumentNumber(features) ? document.paecPdfFormattedNumber : document.formattedNumber}</td>
            <td>{document.title}</td>
            <td>{formatDateTimeString(document.dateLastChange, DateTimeFormat.Date)}</td>
            <td>
                {(!omitNonMinister || document.reviewStatus === ReviewStatus.WithMinister) && getDocumentStatusLabel(document.status)}
            </td>
            <td>{appType.BriefDivisionEnabled(features) ? document.division?.name : creatorUser?.division?.name}</td>
        </tr>
    )
};

