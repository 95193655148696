import {ExternalResource} from "./ExternalResource";
import {resources} from "./resources";

export type Credentials = {
    username: string;
    password: string;
};

export type formCredentials = {
    j_username: string;
    j_password: string;
};

export function submitLogin(externalResource: ExternalResource, credentials: Credentials): Promise<unknown> {
    const form: formCredentials = {
        j_password: credentials.password,
        j_username: credentials.username,
    };
    return externalResource.action(resources.login, form);
};
