import FroalaEditor from 'froala-editor';

import './froala-highlight.css';

// Define an icon.
FroalaEditor.DefineIcon('alert', { NAME: 'eye', template: 'font_awesome' });

// Define a button.
FroalaEditor.RegisterCommand('highlight', {
    // Button title.
    title: 'Red Text',

    // Specify the icon for the button.
    // If this option is not specified, the button name will be used.
    icon: 'alert',

    // Save the button action into undo stack.
    undo: true,

    // Focus inside the editor before the callback.
    focus: true,

    // Show the button on mobile or not.
    showOnMobile: true,

    // Refresh the buttons state after the callback.
    refreshAfterCallback: true,

    // Called when the button is hit.
    callback: function () {
        // The current context is the editor instance.
        if(this.format.is('span',{'class':'attention'})) {
            this.format.remove('span',{'class':'attention'});
        } else {
            this.format.apply('span',{'class':'attention'});
        }
    },

    // Called when the button state might have changed.
    refresh: function ($btn) {
        // The current context is the editor instance.
        // console.log (this.selection.element());
    }
});
