import React from 'react';
import { getReviewStatusLabel, ReviewStatus } from '../../../domain/Document';

import './ReviewStatusIndicator.scss';

export interface ReviewStatusIndicatorProps {
    reviewStatus: ReviewStatus;
    tooltip?: boolean;
}

export const ReviewStatusIndicator: React.VFC<ReviewStatusIndicatorProps> = ({ reviewStatus, tooltip = true }) => {
    return (
        <span
            className={`indicator ${reviewStatus}`}
            title={tooltip ? getReviewStatusLabel(reviewStatus) : undefined}
        />
    );
};

export interface CulledIndicatorProps {
    tooltip?: boolean;
}

export const CulledIndicator: React.VFC<CulledIndicatorProps> = ({ tooltip = true }) => {
    return (
        <span
            className="indicator CULLED"
            title={tooltip ? 'Culled' : undefined}
        />
    );
};
