import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { AlertVariant } from '../../../components/ui/elements/Alert';
import { AlertWithDetails } from '../../../components/ui/elements/AlertWithDetails';
import { LoadingOverlay } from '../../../components/ui/elements/LoadingOverlay';
import { WithTestID } from '../../../components/ui/elements/WithTestID';
import { AppLayout, AppLayoutHeading } from '../../../components/ui/layouts/AppLayout';
import { useAppType, useExternalResource } from '../../../Context/ServiceContext';
import { PortfolioStatusReport } from '../../../domain/Document';
import { convertErrorToErrorWithDetails, ErrorWithDetails } from '../../../hooks/exceptionToError';
import { fetchPortfolioStatusReport } from '../../../services/fetchPortfolioStatusReport';

const StatusReportRow: React.VFC<PortfolioStatusReport> = (record) => {
    return (
        <tr>
            <td>{record.portfolio}</td>
            <td>{record.count}</td>
            <td>{record.pending}</td>
            <td>{record.approved}</td>
            <td>{record.percent}</td>
        </tr>
    );
};

interface ReportTotals {
    briefs: number;
    pending: number;
    approved: number;
    percent: number|undefined;
}

interface StatusReportFooterProps extends WithTestID {
    data: PortfolioStatusReport[];
}
const StatusReportFooter: React.VFC<StatusReportFooterProps> = ({ data, testID }) => {

    // calculate totals when data changes
    const totals: ReportTotals = {
        briefs: 0,
        pending: 0,
        approved: 0,
        percent: undefined,
    };
    data.forEach((record) => {
        totals.briefs += record.count;
        totals.pending += record.pending;
        totals.approved += record.approved;
    });
    if (totals.briefs > 0) {
        totals.percent = Math.round(1000 * totals.approved / totals.briefs) / 10;
    }

    return (
        <tfoot>
            <tr>
                <th>Totals</th>
                <th data-testid={testID ? `${testID}:briefs` : undefined}>{totals.briefs}</th>
                <th data-testid={testID ? `${testID}:pending` : undefined}>{totals.pending}</th>
                <th data-testid={testID ? `${testID}:approved` : undefined}>{totals.approved}</th>
                <th data-testid={testID ? `${testID}:percent` : undefined}>{totals.percent === undefined ? '-' : totals.percent}</th>
            </tr>
        </tfoot>
    );
};

export const StatusReport: React.VFC<WithTestID> = ({ testID }) => {

    const externalResource = useExternalResource();
    const appType = useAppType();
    const [data, setData] = useState<PortfolioStatusReport[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ErrorWithDetails>();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await fetchPortfolioStatusReport(externalResource, appType);
                setData(data);
            } catch (err) {
                setError(convertErrorToErrorWithDetails(err as Error));
            }
            setLoading(false);
        })();
    }, [appType, externalResource]);

    return (
        <AppLayout>
            <AppLayoutHeading testID={testID ? `${testID}:heading` : undefined}>Status Report</AppLayoutHeading>

            {error && <AlertWithDetails variant={AlertVariant.ERROR} {...error} />}
            <LoadingOverlay show={loading} />

            <Table hover>
                <thead>
                    <tr>
                        <th>Portfolio</th>
                        <th>Number of briefs</th>
                        <th>Number of pending briefs</th>
                        <th>Number of briefs approved</th>
                        <th>Percentage approved (%)</th>
                    </tr>
                </thead>
                <tbody data-testid={testID ? `${testID}:data` : undefined}>
                    {loading ? (
                        <tr>
                            <td colSpan={5} className="text-muted">Loading...</td>
                        </tr>
                    ) : (
                        <>
                            {data.map((record) => <StatusReportRow key={record.portfolio} {...record} />)}
                            {data.length === 0 && (
                                <tr>
                                    <td colSpan={5} className="text-muted">No data</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
                {!loading && <StatusReportFooter data={data} testID={testID ? `${testID}:footer` : undefined} />}
            </Table>

        </AppLayout>

    );

};
