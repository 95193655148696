import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { User } from '../../../domain/User';
import { Icon, IconType } from './Icon';


export interface UserPopupProps {
    user: User;
}

export const UserPopup: React.VFC<UserPopupProps> = ({ user }) => {

    const cn: string[] = ['user-email'];
    if (!user.enabled) {
        cn.push('user-disabled');
    }

    const link = <a className={cn.join(' ')} href={`mailto:${user.login}`}>{user.fullName}</a>;

    if (!user.phone && !user.mobile) {
        return link;
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
                <Popover>
                    <Popover.Body>
                        {user.phone && <div><Icon icon={IconType.Phone} /> {user.phone}</div>}
                        {user.mobile && <div><Icon icon={IconType.MobilePhone} /> {user.mobile}</div>}
                    </Popover.Body>
                </Popover>
            }
        >
            {link}
        </OverlayTrigger>
    );
};
